import { combineReducers } from 'redux';
import reviews from './reviews';
import bookings from './bookings';
import projectOverviews from './projectoverview';
import contactforms from './contactforms';
import projectTypes from './projecttypes';
import projectIds from './projectids';
import instagramPhotos from './instagram';
import documents from './documents';
import responses from './responses';
import authenticated from './authenticated';
import articles from './articles';
import projects from './projects';
import specificProjectTypes from './specificprojecttypes';
import keywords from './keywords';
import blogOverviews from './blogoverview';
import blogTypes from './blogtypes';
import blogs from './blogs';
import blogIds from './blogids';
import instagramCodes from './instagramcodes';

export default combineReducers({
  reviews,
  bookings,
  projectOverviews,
  contactforms,
  projectTypes,
  projectIds,
  instagramPhotos,
  documents,
  responses,
  authenticated,
  articles,
  projects,
  specificProjectTypes,
  keywords,
  blogOverviews,
  blogTypes,
  blogs,
  blogIds,
  instagramCodes
});