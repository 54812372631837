import React, { useRef } from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';

import { Typography, ImageArch, Button, PageTitle } from '../../components';
import { Grid } from '@mui/material';
import { getCsvDienstRow } from '../../helpers/diensten';

import brandingstrategie from '../../assets/images/diensten/brandingstrategie/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-strategie-document.jpg';
import archetype from '../../assets/images/diensten/strategie/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-strategie-archetype.jpg';
import associatie from '../../assets/images/diensten/strategie/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-strategie-associatie.jpg';
import strategie from '../../assets/images/diensten/strategie/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-strategie.jpg';


import { ReactComponent as Ipad } from '../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_ipad.svg';

import { Blog, Instagram, LieveWoorden, Match, Nieuwsgierig, RecenteProjecten, Shop } from '../../subpages';
import StartTextPhoto from './StartTextPhoto';

const CustomLineButton = styled.div`
    font-family: Mulish;
    font-size: 13px;
    font-weight: ${props => props.active ? '600' : '300'};
    text-transform: uppercase;
    text-decoration: none;
    color: ${colors.photo};
    letter-spacing: 1.4px;
    text-decoration: underline;
    text-underline-offset: 5px;
    margin: ${props => props.margin || '1vw'};

    &:hover {
        cursor: pointer;
        font-weight: 600;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 11px;
        padding-bottom: 2px;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 11px;
        padding-bottom: 1px;
    }
`;

export default function Strategie(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;
    const dienstenCsv = props.dienstenCsv;

    const brandingRef = useRef(null);

    const brandingStrategieRow = getCsvDienstRow(dienstenCsv, 'branding strategie');

    const executeScroll = (myRef, offSet=150) => {
        myRef && myRef.current && window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop - offSet })
    };
    
    function BrandingPage(props) {
        const { pageTitle, title, titleMargin, text, price, link, image, even } = props;

        return (
            <PageTitle title={pageTitle} left={titleMargin}>
                <Grid container justifyContent='space-evenly' alignItems='flex-start' style={{ flexDirection: even ? 'row-reverse' : 'row', margin: '5vw 0'}}>
                    <div style={{ width: hasPhone ? '85vw' : hasTablet ? '37vw' : '25vw', margin: even ? '3vw 0 0 3vw' : '3vw 0 0 5vw'}}>
                        <Typography variant='h2' >{title}</Typography>
                        <Typography variant='sub' style={{ fontSize: '12px', margin: hasPhone ? '3vw 0' : '0' }}>{price}</Typography>
                        <Typography style={{ margin: hasPhone ? '4vw 0' : '1vw 0'}}>{text}</Typography>
                        <Typography variant='sub'>Dagdeel op locatie</Typography>
                        <Button link={link} style={{ marginTop: hasPhone ? '3vw' : hasTablet ? '2vw' : '1vw'}}>Yes! Ik wil meer lezen</Button>
                    </div>
                    <ImageArch src={image} alt={title} width={hasPhone ? '80vw' : hasTablet ? '35vw' : '23vw'} style={{ marginTop: hasPhone ? '7vw' : '0', marginLeft: even ? '7vw' : '0' }} />
                </Grid>
            </PageTitle>
        )
    }

    return (
        <div>
            <StartTextPhoto
                image={strategie}
                title='strategie'
                {...props}
            >
                <Typography>
                    Een branding strategie zorgt ervoor dat jij perfect weet waar jij bedrijf voor staat én hoe je dit kan uitdragen in tekst en beeld.<br/><br/>
                    In een branding strategie kijken we wie jij bent, wat jij doet en waarom je dat doet. Maar vooral ook wat jouw dromen en doelen zijn. We kijken samen naar de manier waarop jij impact wil maken met jouw business.
                </Typography>
                <Button onClick={() => executeScroll(brandingRef)} style={{ marginTop: hasPhone ? '3vw' : '1vw'}}>Bekijk de strategiepakketten</Button>
            </StartTextPhoto>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', justifyContent: 'center', margin: hasPhone ? '10vw 0' : '7vw 0', position: 'relative' }}>
                <Typography variant='h2' style={{ width: '85vw' }}>het is tijd om jouw business de positionering te geven <br/> die het verdient. die bij jou past, alleen bij jou.</Typography>
                <Typography style={{ width: hasPhone ? '85vw' : hasTablet ? '40vw' : '25vw'}}>Door jouw merk herkenbaar, consistent en onderscheidend te maken in woord en beeld. Jouw bedrijf is een uniek merk, dat mag je laten zien. Jij mag impact maken én de juiste klanten aantrekken.</Typography>
                <CustomLineButton onClick={() => executeScroll(brandingRef)} margin={hasPhone ? '3vw 0' : hasTablet ? '3vw 0' : '1vw 0'}>Branding strategie</CustomLineButton>
                {!hasPhone && <Ipad style={{ fill: colors.illustration, position: 'absolute', left: hasTablet ? '5vw' : '8vw', top: '0', width: hasTablet ? '9vw' : '7vw'}} />}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start',  margin: hasPhone ? '12vw 0' : '7vw 0', gap: hasPhone ? '8vw' : hasTablet ? '2vw' : '3vw', flexWrap: 'wrap' }}>
                <Typography style={{ width: hasPhone ? '85vw' : hasTablet ? '30vw' : '23vw', marginRight: '4vw' }}>
                    Je wil laten zien wat je doet, maar vooral waarom jij doet wat je doet. <br/><br/>
                    Je wil weten waar je het allemaal voor doet, want wat zijn jouw dromen? En hoe draagt jouw bedrijf daaraan bij? <br/><br/> 
                    Je wil weten voor wie je het doet, jouw droomklant. <br/><br/>
                    En je wil weten hoe je dit alles het beste kan vertellen aan jouw klant, visueel en tekstueel.
                </Typography>
                <img src={associatie} alt='associatie' style={{ width: hasPhone ? '40vw' : hasTablet ? '25vw' : '15vw', height: hasPhone ? '40vw' : hasTablet ? '25vw' : '15vw', top: hasPhone ? '31vw' : '0', left: hasPhone ? '55vw' : '16vw', zIndex: '998' }} />
                <img src={archetype} alt='archetype' style={{ width: hasPhone ? '40vw' : hasTablet ? '25vw' : '15vw', height: hasPhone ? '40vw' : hasTablet ? '25vw' : '15vw', top: hasPhone ? '10vw' : '-10vw', left: hasPhone ? '25vw' : '3vw', zIndex: '999' }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center', flexDirection: 'column', margin: hasPhone ? '15vw 0' : '7vw 0'}}>
                <Typography variant='h2' style={{ width: '85vw' }}>je wil je business naar buiten brengen als {!hasPhone && <br/>} een mooi plaatje.<br/> 
                    maar niet enkel als een mooi plaatje. <br/>
                    je wil dat jouw verhaal overgebracht wordt.
                </Typography>
                <Typography style={{ width: hasPhone ? '85vw' : hasTablet ? '40vw' : '25vw'}}>
                Jij kan alleen impact maken met jouw merk, als dat mooie plaatje zorgt voor verkopen, je inkomsten laat stijgen en jou anders laat zijn dan je concurrentie. Allemaal zodat jij jouw dromen waar kan maken én daardoor nog meer impact kan maken.
                </Typography>
            </div>
            <div ref={brandingRef} style={{ margin: hasPhone && '20vw 0'}}>
                <BrandingPage 
                    pageTitle={brandingStrategieRow['dienst']}
                    titleMargin='-50px'
                    title={brandingStrategieRow['dienst']}
                    price={`Los te boeken voor ${brandingStrategieRow['prijs']} excl. btw\nOok onderdeel van het full branding pakket`}
                    text='Bij een branding strategie doorlopen we jouw verhaal en het verhaal achter jouw bedrijf, door middel van een toolbox. Na het doorlopen van de toolbox en invullen van de werkbladen, ga ik aan de slag met het maken van jouw branding strategie. Hierin komen vijf onderdelen aan bod: Foundation, Audience, Competition, Messaging en als laatste Visual Direction.'
                    link='/diensten/brandingstrategie'
                    image={brandingstrategie}
                    even={false}
                />
            </div>
            <RecenteProjecten {...props} />
            <Match {...props} />
            <LieveWoorden {...props} />
            <Shop {...props} />
            <Blog {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}