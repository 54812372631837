import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { colors } from '../../assets/colors';

import { Typography, ImageArch } from '../../components';
import { Instagram, Nieuwsgierig } from '../../subpages';

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { isDict } from '../../helpers';

import { getBlog, getBlogs } from '../../actions/blogs';
import { getBlogIds } from '../../actions/blogids';
import { getBlogTypes } from '../../actions/blogtypes';

const CustomButton = styled.div`
    font-family: Mulish;
    font-weight: 400;
    text-decoration: none;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    font-size: 13px;
    color: ${colors.photo};
    border: 1px solid ${colors.photo};
    padding: 8px;
    border-radius: 18px;
    outline: none;
    margin-top: 2vw;

    &:hover {
        font-weight: 600;
        cursor: pointer;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 11px;
        letter-spacing: 1.1px;
        padding: 5px;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 11px;
        letter-spacing: 1.1px;
        padding: 8px;
    }
`;

const BlogContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 5vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        margin-bottom: 20vw;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        margin-bottom: 15vw;
    }
`;

const BlogContent = styled.div`
    width: 35vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 85vw;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        width: 60vw;
    }
`;

const Grid = styled.div`
    display: flex;
    justify-content: ${props => props.grid === 1 ? 'left' : 'space-between'};
    margin-top: 1vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        flex-direction: column;
        justify-content: center;
        width: 100%;
    } 
`;

const StyledGridImage = styled.div`
    display: flex;
    justify-content: right;
    margin-right: 1vw;
    width: ${props => props.gridNmb === 2 ? '45%' : '100%'};
    height: ${props => props.shape === 'ARCH' || props.gridNmb === 1 ? 'auto' : '45%'};

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        flex-direction: column;
        align-items: center;
        margin: 5vw 0;
        width: 100%;
    } 
`;

const StyledGridContent = styled.div`
    display: inline-block;
    width: ${props => props.gridNmb === 1 ? '100%' : '50%'};

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        margin: 5vw 0;
        width: 100%;
    } 
`;

const StyledLink = styled(Link)`
    display: inline;
    text-decoration: none;
    color: ${colors.black};
    position: relative;
    cursor: pointer;
    
    &:hover {
        font-style: italic;
    }

    @media screen and (max-width: 766px) {
        font-size: 13px;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 13px;
    }
`;

const GridImage = (props) => {
    const { content, gridNmb } = props;
    return (
        <StyledGridImage gridNmb={gridNmb} shape={content.shape}>
            {content.shape === 'ARCH' ? <ImageArch style={{ width: '90%' }} width={gridNmb === 1 ? '100%' : '100%'} src={content.file} />
            : <img alt='blog foto' style={{ width: '100%', height: '100%',  }} src={content.file} />}
        </StyledGridImage>
    )
}

const GridVideo = (props) => {
    const { content, gridNmb } = props;
    return (
        <StyledGridImage gridNmb={gridNmb} shape={content.shape}>
            {content.shape === 'ARCH' ? <ImageArch style={{ width: '90%' }} video={true} width={gridNmb === 1 ? '100%' : '100%'} src={content.video} />
            : <video alt='blog foto' loop='true' autoplay='autoplay' muted style={{ width: '100%', height: '100%',  }} src={content.video} />}
        </StyledGridImage>
    )
}

const GridHeader = (props) => {
    const { content } = props;
    return (
        <Typography variant='sub' style={{ color: '#000', margin: '1vw 0 0vw 0'}}>{content.title}</Typography>
    )
}

function GridContent(props) {
    const { content, gridNmb } = props;

    const newContent = content;

    return (
        <StyledGridContent gridNmb={gridNmb}>
            {newContent.map((cont, i) => {
                if (isDict(cont)) { // dict
                    if (cont.linkType === 'Link') {
                        return (
                            <StyledLink key={i} to={`${cont.url}`}>{cont.text}</StyledLink>
                        )
                    } else if (cont.linkType === 'a') {
                        return <StyledLink key={i} as='a' href={`${cont.url}`}>{cont.text}</StyledLink>
                    } else {
                        return null;
                    }
                }
                return <Typography style={{ display: 'inline' }}>{cont}</Typography>
            })}
        </StyledGridContent>
    )
}

const BlogGrid = (props) => {
    const { grid } = props;
    const gridNmb = Number(grid.grid);
    const contents = grid.images.concat(grid.contents).concat(grid.headers).concat(grid.videos).sort((a, b) => a.order - b.order);

    return (
        <Grid grid={gridNmb}>
            {contents.map((cont, i) => {
                if ('title' in cont) {
                    return <GridHeader content={cont} key={i} />
                } else if ('content' in cont) { //Array.isArray(cont) && cont.length === 1
                    if (Array.isArray(cont.content) && cont.content.length > 1) {
                        return <GridContent key={i} content={cont.content} gridNmb={gridNmb} />
                    }

                    return (
                        <StyledGridContent key={i} gridNmb={gridNmb}>
                            <Typography style={{ display: 'inline' }}>{cont.content}</Typography>
                        </StyledGridContent>
                    )
                } else if (Array.isArray(cont) && cont.length > 1) {
                    return <GridContent key={i} content={cont} gridNmb={gridNmb} />
                } else if ('file' in cont) {
                    return <GridImage key={i} content={cont} gridNmb={gridNmb} />
                } else if ('video' in cont) {
                    return <GridVideo key={i} content={cont} gridNmb={gridNmb} />
                }

                return null;
            })}
        </Grid>
    );
}

export default function Artikel(props) {
    let { artikelId } = useParams();

    const dispatch = useDispatch();

    const blogsObject = useSelector((state) => state.blogs);
    const blogIds = useSelector((state) => state.blogIds).blogIds || [];
    const blogNumbers = blogIds.length > 0 && blogIds.map(id => Number(id.id));
    const blogs = (blogsObject && blogsObject.blogs && blogsObject.blogs.results) || [];
    const blogTypes = useSelector((state) => state.blogTypes).results;

    const blogsWithId = blogs.filter(a => Number(a.id) === Number(artikelId));
    let blog = {};
    if (blogsWithId.length > 0 && !isNaN(Number(artikelId))) {
        blog = blogsWithId[0]
    } else if (isNaN(Number(artikelId)) && blogs.length > 0) {
        let blogs_filt = blogs.filter(a => a.url_slug === artikelId);
        if (blogs_filt.length > 0) {
            blog = blogs_filt[0]
        }
    }

    const blogLength = Object.keys(blog).length;

    useEffect(() => {
        if (!blogTypes) {
            dispatch(getBlogTypes())
        }

        if (blogLength === 0 && (!isNaN(Number(artikelId)) && Number(blog.id) !== Number(artikelId))) {
            dispatch(getBlog(artikelId))
        }

        if (blogLength === 0 && artikelId && isNaN(Number(artikelId))) {
            const filters = { url_slug: artikelId };
            dispatch(getBlogs(filters));
        }

        if (blogLength === 0 || !blogNumbers || (blogNumbers.length > 0 && blogNumbers.includes(Number(artikelId) || Number(blog.id)))) {
            const filters = { published: true };
            dispatch(getBlogIds(Number(artikelId) || blog.id, filters))
        }
        
    }, [dispatch, blogLength, blog.id, artikelId, blogTypes, blogIds.length, blogNumbers])

    if (Object.keys(blog).length === 0) {
        return (
            <div>Geen blog gevonden</div>
        )
    }

    const buttonSpacing = props.hasPhone ? '0vw' : props.hasTablet ? '-5vw' : '-10vw';

    return (
        <div>
            <BlogContainer>
                <BlogContent>
                    <Typography variant='h1' style={{ textAlign: 'center' }}>{blog.title}</Typography>
                    <div style={{ display: 'flex', gap: '5px', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '2vw' }}>
                        {blog.types && blog.types.map((type, j) => {
                            return (
                                <Typography variant='sub' key={j} style={{ margin: '0' }}>
                                    {j > 0 && <span> | </span>}
                                    <span>{type.content}</span>
                                </Typography>
                            )
                        })}
                    </div>
                    {blog.grids.length > 0 && blog.grids.map((grid, i) => {
                        return <BlogGrid key={i} grid={grid} />
                    })}
                    {blogIds && blogIds.length > 0 && 
                        <div style={{ position: 'relative', margin: '3vw 0'}}>
                            {blogIds[0] && <div style={{ position: 'absolute', left: buttonSpacing }}>
                                <CustomButton as={Link} to={`/blog/${blogIds[0].url_slug}`}>Bekijk vorige</CustomButton>
                            </div>}
                            {blogIds[1] && <div style={{ position: 'absolute', right: buttonSpacing }}>
                                <CustomButton as={Link} to={`/blog/${blogIds[1].url_slug}`}>Bekijk volgende</CustomButton>
                            </div>}
                        </div>
                    }
                </BlogContent>
            </BlogContainer>
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}