import React from 'react';
import { PageTitle, Typography, LineButton, RotatingCircle } from '../../components';
import { colors } from '../../assets/colors';

import { ReactComponent as Notitieboek } from '../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_notitieboek.svg';
import { ReactComponent as Ontdekken } from '../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_ontdekken-creeren-ontwerpen.svg';

export default function Werkwijze(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    return (
        <PageTitle title='werkwijze' left='-8px'>
            <div style={{ position: 'relative', margin: hasPhone ? '15vw 0 35vw 0' : hasTablet ? '10vw 0 15vw 0' : '7vw 0 3vw 0' }}>
                <div style={{ width: hasPhone ? '72vw' : hasTablet ? '35vw' : '24vw', marginLeft: hasPhone ? '12vw' : hasTablet ? '12vw' : '20vw'}}>
                    <Typography variant='h2'>werkwijze</Typography>
                    <Typography style={{ marginBottom: hasPhone ? '1vw' : hasTablet ? '0.5vw' : '0'}}>Ik doe elke dag wat ik leuk vind om te doen:</Typography>
                    <Typography variant='sub' style={{ marginBottom: hasPhone ? '3vw' : hasTablet ? '2vw' : '1vw'}}>Ontdekken, creëren en ontwerpen.</Typography>
                    <Typography style={{ marginBottom: hasPhone ? '3vw' : hasTablet ? '2vw' : '1vw' }}>Binnen elk project starten we bij de basis, jouw verhaal. De antwoorden hierop vormen de strategische basis voor het visuele traject, waarin een concept-voorstel de start is. We blijven nauw samenwerken waardoor zowel jij als ik (en mijn team) uiteindelijk trots kunnen zijn op het ontwerp wat we neergezet hebben. Jij de klanten kan gaan aantrekken waar jij blij van wordt én jij 100% kan gaan knallen met jouw business!</Typography>
                    <Typography>We doorlopen samen <span style={{ fontStyle: 'italic'}}>7 fases</span> naar het ontwerp wat volledig bij jou past!</Typography>
                    <LineButton to='/werkwijze' style={{ marginLeft: '0'}}>Bekijk mijn werkwijze</LineButton>
                </div>
                <Notitieboek style={{ fill: colors.latte, transform: 'scaleX(-1)', position: 'absolute', width: hasPhone ? '25vw' : hasTablet ? '15vw' : '12vw', bottom: hasPhone ? '-20vw' : hasTablet ? '-7vw' : '5vw', right: hasPhone ? '12vw' : hasTablet ? '18vw' : '25vw' }} />
                {!hasPhone && <RotatingCircle style={{ position: 'absolute', right: hasTablet ? '8vw' : '15vw', top: hasTablet ? '-5vw' : '-3vw'}}>
                    <Ontdekken style={{ fill: colors.illustration, width: hasTablet ? '15vw' : '10vw' }} />
                </RotatingCircle>}
            </div>
        </PageTitle>
    )   
}