import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getAuthenticated } from "../actions/authentication";
import { getCookie } from '../actions/cookies';

const ProtectedRoute = (props) => {
    const { location } = props;

    const dispatch = useDispatch();
    const authenticated = useSelector((state) => state.authenticated);
    const status = (authenticated && authenticated.status) || false;
    const justLoggedIn = location && location.state && location.state.login;
    
    useEffect(() => {
        dispatch(getAuthenticated())
    }, [dispatch]);
    
    if (!status && !justLoggedIn && !(getCookie('isAuthenticated') === 'true')) {
        return <Redirect to='/login' />
    }
    
    return (
        <Route {...props}>
            {props.children}
        </Route>
        
    );
}

export default ProtectedRoute;