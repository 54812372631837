import { GET_BLOG_OVERVIEW } from '../actions/types';

const blogOverviews = (state = {}, action) => {
  switch (action.type) {
    case GET_BLOG_OVERVIEW:
      return {
        ...state,
        ...action.payload,
        types: action.payloadTypes,
        nextPage: action.payloadNextPage,
      };
    default:
      return state;
  }
};

export default blogOverviews;