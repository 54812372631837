import React from 'react';
import { Typography, Button, ImageArch } from '../../components';
import { Grid } from '@mui/material';
import contactBedankt from '../../assets/images/contact/Studio-Valerie-Maas-grafisch-ontwerp-contact-bedankt.jpg';

export default function ContactBedankt(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    return (
        <Grid container justifyContent='space-evenly' alignItems='center' style={{ margin: hasPhone ? '0 0 5vw 0' : '5vw 0'}}>
            <div style={{ textAlign: 'center', width: hasPhone ? '80vw' : hasTablet ? '35vw' : '25vw', margin: hasPhone && '10vw 0' }}>
                <Typography variant='h1' style={{ margin: hasPhone ? '0 0 5vw 0' : '0 0 2vw 0'}}>Bedankt voor <br/>je bericht</Typography>
                <Button link='/'>Terug naar de home-pagina</Button>
            </div>
            <div>
                <ImageArch src={contactBedankt} alt='Foto' width={hasPhone ? '80vw' : hasTablet ? '35vw' : '25vw'} />
            </div>
        </Grid>
    )
}