import { GET_BLOG, GET_BLOGS } from '../actions/types';

const blogs = (state = {}, action) => {
  switch (action.type) {
    case GET_BLOGS:
      return {
        ...state,
        blogs: action.payload,
      };
    case GET_BLOG:
      let newBlogsObject = {};
      if (state.blogs) {
        const oldBlogs = (state.blogs && state.blogs.results) || [];
        const oldBlog = oldBlogs.filter(s => Number(s.id) === Number(action.payload.data))[0];
        const idList = oldBlogs.indexOf(oldBlog);

        let newBlogs = oldBlogs;
        if (idList < 0) {
            newBlogs.push(action.payload.data);
        } else {
            newBlogs[idList] = action.payload.data;
        }

        newBlogsObject = action;
        newBlogsObject['results'] = newBlogs;
      } else {
        newBlogsObject['results'] = [action.payload.data];
      }

      return {
        ...state,
        blogs: newBlogsObject,
      };
    default:
      return state;
  }
};

export default blogs;