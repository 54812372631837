import { GET_ARTICLES, GET_ARTICLE } from '../actions/types';

const articles = (state = {}, action) => {
  switch (action.type) {
    case GET_ARTICLES:
      return {
        ...state,
        articles: action.payload,
      }
    case GET_ARTICLE:
      let newArticles = null;
      if (state.articles) {
        const oldArticle = state.articles.filter(s => Number(s.id) === Number(action.payload.id))[0];
        const idList = state.articles.indexOf(oldArticle);

        newArticles = state.articles;
        newArticles[idList] = action.payload;
      } else {
        newArticles = [action.payload];
      }

      return {
        ...state,
        articles: newArticles,
      }
    default:
      return state;
  }
  
};

export default articles;