import axiosInstance from './axios';
import { PUT_REVIEW, GET_REVIEW, GET_REVIEWS, POST_REVIEW, DELETE_REVIEW } from './types';
import { reportResponse } from './helpers';
import { getCookie } from './cookies';

const config = {
  headers: {
    'Authorization': getCookie('access_token') ? "Bearer " + getCookie('access_token') : null,
  }
}

const getFormData = (object, blobs, fileNames) => Object.keys(object).reduce((formData, key) => {
  if (key === 'signature_image' || key === 'main_image') {
    formData.append(key, blobs[key], fileNames[key]);
  } else {
    formData.append(key, object[key]);
  }
  
  return formData;
}, new FormData());

// GET REVIEWS
export const getReviews = (filters) => async dispatch => {
    let filter = '?'

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
          if (value) {
            filter = filter.concat(`&${key}=${value}`)
          }
      }
    }

    const res = await axiosInstance.get(`/api/reviews/${filter}`);
    dispatch({
      type: GET_REVIEWS,
      payload: res.data
    });
};

// GET REVIEW
export const getReview = (id) => async dispatch => {
  const res = await axiosInstance.get(`/api/reviews/${id}/`);
  dispatch({
    type: GET_REVIEW,
    payload: res.data,
  })
}

// PUT REVIEW
export const putReview = (review, blobs, fileNames) => async dispatch => {
  const res = await axiosInstance.put(`/api/reviews/${review.id}/`, getFormData(review, blobs, fileNames), config);
  
  return reportResponse(res, PUT_REVIEW, 'Review', dispatch);
};

// POST REVIEW
export const postReview = (review, blobs, fileNames) => async dispatch => {
  const res = await axiosInstance.post(`/api/reviews/`, getFormData(review, blobs, fileNames), config);

  return reportResponse(res, POST_REVIEW, 'Review', dispatch);
};

export const deleteReview = (id) => async dispatch => {
  const res = await axiosInstance.delete(`/api/reviews/${id}/`);

  return reportResponse(res, DELETE_REVIEW, 'Review', dispatch);
}