import React from 'react';
import { LieveWoorden, Match, Nieuwsgierig, Shop, Blog, Instagram } from '../../subpages';
import { Typography, Button, ImageArch } from '../../components';

import verpakking from '../../assets/images/diensten/designintensive/Studio-Valerie-Maas-grafisch-ontwerp-diensten-design-intensive-verzendverpakking.jpg';
import intensive from '../../assets/images/diensten/designintensive/Studio-Valerie-Maas-grafisch-ontwerp-diensten-design-intensive.jpg';
import social from '../../assets/images/diensten/designintensive/Studio-Valerie-Maas-grafisch-ontwerp-diensten-design-intensive-avw-social.jpg';
import ebook from '../../assets/images/diensten/designintensive/Studio-Valerie-Maas-grafisch-ontwerp-diensten-design-intensive-ebook.jpg';

import { getCsvDienstRow } from '../../helpers/diensten';


export default function DesignIntensive(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;
    const dienstenCsv = props.dienstenCsv;

    const intensiveRow = getCsvDienstRow(dienstenCsv, 'design intensive');

    return (
        <div>
            <div style={{ position: 'relative'}}>
                <div style={{ width: hasPhone ? '85vw' : hasTablet ? '40vw' : '25vw', margin: hasPhone ? '0 7vw 85vw 7vw' : hasTablet ? '10vw 0 10vw 10vw' : '10vw 0 10vw 20vw'}}>
                    <Typography variant='h1'>{intensiveRow['dienst']}</Typography>
                    <Typography variant='sub' style={{ margin: hasPhone ? '2vw 0' : '1vw 0'}}>Vanaf {intensiveRow['prijs']} excl. btw</Typography>
                    <Typography>
                    Heeft jouw branding of een ander design project veel haast of hoge prioriteit? <br/><br/>
                    Of heb je inmiddels een lijstje met ontwerp taken die al even op je wachten, maar nu écht wel prioriteit mogen krijgen? <br/><br/>
                    In een Design Intensive werken we (minimaal) één dag samen aan jouw merk. Dit kan bij jou op locatie óf op afstand. In een korte tijd kunnen we, door korte lijntjes, jouw design project snel afronden en jouw to do lijstje eindelijk eens korter maken! <br/><br/>
                    </Typography>
                    <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')} style={{ width: hasPhone && '80vw'}}>Plan een gratis kennismakingsgesprek in</Button>
                    <img src={verpakking} alt='The Legal House' style={{ height: hasPhone ? '37vw' : hasTablet ? '18vw' : '16vw', width: hasPhone ? '37vw' : hasTablet ? '18vw' : '16vw', position: 'absolute', bottom: hasPhone ? '-70vw' : hasTablet ? '10vw' : '0vw', right: hasPhone ? '10vw' : hasTablet ? '5vw' : '10vw'}} />
                    <ImageArch src={intensive} alt='Maaike Kastrop' width={hasPhone ? '37vw' : hasTablet ? '18vw' : '16vw'} style={{ position: 'absolute', right: hasPhone ? '53vw' : hasTablet ? '25vw' : '28vw', top: hasPhone ? '95vw' : '-5vw'}} />    
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '5vw', margin: hasPhone ? '0 0 90vw 0' : '0 0 10vw 0', flexWrap: 'wrap' }}>
                <div style={{ position: 'relative', width: hasPhone ? '85vw' : hasTablet ? '45vw' : '30vw' }}>
                    <img src={social} alt='Social' style={{ height: hasPhone ? '37vw' : hasTablet ? '18vw' : '16vw', width: hasPhone ? '37vw' : hasTablet ? '18vw' : '16vw', position: 'absolute', bottom: hasPhone ? '-175vw' : hasTablet ? '20vw' : '7vw', right: hasPhone ? '5vw' : '2vw'}} />
                    <img src={ebook} alt='E-book' style={{ height: hasPhone ? '37vw' : hasTablet ? '18vw' : '16vw', width: hasPhone ? '37vw' : hasTablet ? '18vw' : '16vw', position: 'absolute', bottom: hasPhone ? '-200vw' : hasTablet ? '10vw' : '-2vw', right: hasPhone ? '45vw' : hasTablet ? '22vw' : '20vw'}} />
                </div>
                <div style={{ width: hasPhone ? '85vw' : hasTablet ? '50vw' : '43vw'}}>
                    <Typography variant='h2'>Wat is er mogelijk?</Typography>
                    <Typography>» Illustraties</Typography>
                    <div style={{ display: 'flex', gap: '3vw', marginTop: hasPhone ? '5vw' : '2vw', flexWrap: 'wrap'}}>
                        <div>
                            <Typography variant='sub'>Print ontwerp</Typography>
                            <Typography>
                                » Visitekaartje<br/>
                                » Ansichtkaart / cadeaubon<br/>
                                » Flyers of ander promotiemateriaal<br/>
                                » Product verpakkingen<br/>
                                » Verzend-materiaal / -verpakkingen<br/>
                                » Gevelbekleding / signing<br/>
                                » (werk)boek opmaak
                            </Typography>
                        </div>
                        <div style={{ margin: hasPhone && '5vw 0'}}>
                            <Typography variant='sub'>Digitaal ontwerp</Typography>
                            <Typography>
                                » Social media templates<br/>
                                » Vormgeving presentatie (template)<br/>
                                » E-book vormgeving<br/>
                                » Logo-animatie<br/>
                                » Podcast / Youtube covers<br/>
                                » Webdesign (zonder development)<br/>
                                » Ontwerp e-learning (zonder development)<br/>
                            </Typography>
                        </div>
                    </div>
                    <Typography style={{ margin: hasPhone ? '3vw 0' : '1vw 0 2vw 0' }}>Heb je een ander idee? Neem contact met me op!</Typography>
                    <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')}>Plan een gratis kennismakingsgesprek in</Button>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: hasPhone ? '10vw 0' : '7vw 0'}}>
                <Typography variant='h2' style={{ width: '85vw'}}>ik kan bij jou op locatie werken of we hebben {!hasPhone && <br/>}digitaal contact gedurende de dag</Typography>
                <Typography style={{ width: hasPhone ? '85vw' : hasTablet ? '40vw' : '25vw'}}>We prikken samen een datum en bespreken van te voren wat jouw wensen zijn. Dat kan één grote taak zijn óf meerdere kleine taken.</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: hasPhone ? '10vw 0' : '7vw 0'}}>
                <Typography variant='h2'>{intensiveRow['dienst']}</Typography>
                <Typography variant='sub' style={{ margin: hasPhone ? '2vw 0' : '1vw 0'}}>Éen dag voor {intensiveRow['prijs']} excl. btw</Typography>
                <Typography style={{ width: hasPhone ? '85vw' : hasTablet ? '45vw' : '25vw', margin: hasPhone ? '3vw 0 4vw 0' : hasTablet ? '0 0 2vw 0' : '0 0 1vw 0'}}>Investering voor meerdere dagen op aanvraag.</Typography>
                <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')}>Plan een gratis kennismakingsgesprek in</Button>
            </div>
            <Match {...props} />
            <LieveWoorden {...props}  />
            <Shop {...props} />
            <Blog {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}