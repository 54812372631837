import React from 'react';
import { LieveWoorden, Match, Nieuwsgierig, Shop, Blog, Instagram } from '../../subpages';
import { Button, BulletPoint } from '../../components';
import StartTextPhoto from './StartTextPhoto';

import boost1 from '../../assets/images/diensten/zichtbaarheid/Studio-Valerie-Maas-grafisch-ontwerp-diensten-zichtbaarheids-huisstijldragers-1.jpg';

export default function Zichtbaarheid(props) {
    return (
        <div>
            <StartTextPhoto
                image={boost1}
                title={`zichtbaarheids-\nhuisstijldragers`}
                subtitle={'1 keuze incl. bij het Full branding óf \nFull branding en Web pakket.'}
                {...props}
            >
                <BulletPoint>Bedankkaartje / Ansichtkaartje</BulletPoint>
                <BulletPoint>Stempelkaart / Afsprakenkaart</BulletPoint>
                <BulletPoint>Sticker</BulletPoint>
                <BulletPoint>Flyer</BulletPoint>
                <BulletPoint>Social media templates (4 thema’s)</BulletPoint>
                <BulletPoint>Briefpapier</BulletPoint>
                <Button link='/diensten/fullbranding' style={{ marginTop: '2vw'}}>Bekijk het Full branding pakket</Button>
                <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')} style={{ marginTop: '1vw'}}>Plan een gratis kennismakingsgesprek in</Button>
            </StartTextPhoto>
            <Match {...props} />
            <LieveWoorden {...props}  />
            <Shop {...props} />
            <Blog {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}