import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/colors';

const Line = styled.div`
    font-family: Mulish;
    font-size: 13px;
    text-transform: uppercase;
    text-decoration: none;
    color: ${colors.photo};
    letter-spacing: 1.4px;
    text-align: center;
    border-bottom: 1px solid ${colors.photo};
    padding-bottom: 5px;
    width: max-content;

    margin: ${props => props.margin || '2vw'};

    &:hover {
        cursor: pointer;
        font-weight: 600;
        border-bottom: 2px solid ${colors.photo};
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 11px;
        padding-bottom: 2px;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 11px;
        padding-bottom: 1px;
    }
`;

export default function LineButton(props) {
    if (props.to) {
        return (
            <Link to={props.to} style={{ textDecoration: 'none'}} onClick={props.onClick}>
                <Line margin={props.margin} style={props.style}>
                    {props.children}
                </Line>
            </Link>
        )
    } else if (props.href) {
        return (
            <a href={props.href} target='_blank' rel="noreferrer" style={{ textDecoration: 'none'}} download={props.download}>
                <Line margin={props.margin} style={props.style}>
                    {props.children}
                </Line>
            </a>
        )
    } else if (props.onClick) {
        return (
            <div onClick={props.onClick}>
                <Line margin={props.margin} style={props.style}>
                    {props.children}
                </Line>
            </div>
        )
    } else {
        return null;
    }
}