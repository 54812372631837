import React from 'react';
import { Grid } from '@mui/material';
import { colors } from '../../assets/colors';
import { Typography, PageTitle, Button } from '../../components';
import { ReactComponent as Latte } from '../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_kopje-latte-macchiato.svg';

export default function Nieuwsgierig(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    return (
        <PageTitle title='contact' left='3px'>
            <div style={{ position: 'relative'}}>
                <div style={{ display: 'flex', justifyContent: 'center', margin: hasPhone ? '7vw 0 30vw 0' : hasTablet ? '7vw 0' : '7vw 0' }}>
                        <Grid container direction='column' justifyContent='center' alignItems='center' style={{ textAlign: 'center' }}>
                            <div>
                                <Typography variant='h2'>Nieuwsgierig geworden?</Typography>
                            </div>
                            <Typography>Heb je zin om te starten aan jouw project? <br/> Of heb je nog vragen?</Typography>
                            <Button link='/contact' style={{ margin: hasPhone ? '5vw 0 3vw 0' : '2vw 0 1vw 0'}}>Neem contact op</Button>
                            <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')}>
                                Plan een gratis 
                                {hasPhone && <br/>}
                                kennismakingsgesprek in
                            </Button>
                        </Grid>
                </div>
                {hasPhone ? 
                    <Latte style={{ fill: colors.latte, position: 'absolute', left: '10vw', bottom: '-20vw', width: '12vw' }} /> :
                    hasTablet ?
                        <Latte style={{ fill: colors.latte, position: 'absolute', left: '22vw', top: '-7vw', width: '5vw' }} /> :
                        <Latte style={{ fill: colors.latte, position: 'absolute', left: '15vw', top: '-5vw', width: '5vw' }} />
                }
            </div>
        </PageTitle>
    )
}