import React from 'react';
import { LieveWoorden, Match, Nieuwsgierig, Shop, Blog, Instagram } from '../../subpages';
import { Typography, Button, BulletPoint } from '../../components';
import StartTextPhoto from './StartTextPhoto';

import boost1 from '../../assets/images/diensten/visitekaartje/Studio-Valerie-Maas-grafisch-ontwerp-diensten-visitekaartje-1.jpg';
import { getCsvDienstRow } from '../../helpers/diensten';

export default function Visitekaartje(props) {
    const dienstenCsv = props.dienstenCsv;

    const visitekaartjeRow = getCsvDienstRow(dienstenCsv, 'visitekaartje');

    return (
        <div>
            <StartTextPhoto
                image={boost1}
                title={visitekaartjeRow['dienst']}
                subtitle={`Vanaf ${visitekaartjeRow['prijs']} excl. btw`}
                {...props}
            >
                <BulletPoint>Ontwerp visitekaartje</BulletPoint>
                <BulletPoint>Aanlevering drukbestand</BulletPoint>
                <Typography> Wat kan ik zelf al doen?</Typography>
                <BulletPoint>De informatie die op het visitekaartje moet komen, zoals naam, adres, telefoonnummer, e-mailadres, website, etc.</BulletPoint>
                <BulletPoint>Heb je al een huisstijl? Lever dan al je huisstijl elementen aan, die ik moet verwerken in het visitekaartje.</BulletPoint>
                <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')} style={{ marginTop: '1vw'}}>Plan een gratis kennismakingsgesprek in</Button>
            </StartTextPhoto>
            <Match {...props} />
            <LieveWoorden {...props}  />
            <Shop {...props} />
            <Blog {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}