import axiosInstance from './axios';
import { GET_INSTA_PHOTOS, GET_INSTAGRAM_CODES } from './types';

// GET BOOKINGS
export const getInstaPhotos = () => async dispatch => {
    const res = await axiosInstance.get(`/instagram/`);

    let response = res && res.data;
    let photos = response.photos;
    if (typeof response.photos === 'string') {
      photos = response.photos.replaceAll( "'", '"')
      photos = JSON.parse(photos)
      response['photos'] = photos;
    }

    dispatch({
      type: GET_INSTA_PHOTOS,
      payload: response,
    });
};

export const postInstagramCode = (instagram) => async dispatch => {
  await axiosInstance.post(`/api/instagram/`, instagram)
}

export const deleteInstagramCodes = (id) => async dispatch => {
  await axiosInstance.delete(`/instagram`);
}

export const getInstagramCodes = () => async dispatch => {
  const res = await axiosInstance.get('/api/instagram');

  dispatch({
    type: GET_INSTAGRAM_CODES,
    payload: res.data
  });
}