import React from 'react';
import { LieveWoorden, Match, Nieuwsgierig, Shop, Blog, Instagram } from '../../subpages';
import { Typography, Button, PageTitle, ImageArch } from '../../components';

import StartTextPhoto from './StartTextPhoto';
import brandingstrategie from '../../assets/images/diensten/brandingstrategie/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-strategie-document.jpg';
import archetype from '../../assets/images/diensten/strategie/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-strategie-archetype.jpg';
import associatie from '../../assets/images/diensten/strategie/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-strategie-associatie.jpg';
import bedrijf from '../../assets/images/diensten/brandingstrategie/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-strategie-jouw-bedrijf.jpg';

import { getCsvDienstRow } from '../../helpers/diensten';

export default function BrandingStrategie(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    const strategieSessieRow = getCsvDienstRow(props.dienstenCsv, 'branding strategie');

    return (
        <div>
            <StartTextPhoto
                image={brandingstrategie}
                title={strategieSessieRow['dienst']}
                subtitle={`Los te boeken voor ${strategieSessieRow['prijs']} excl. btw\nOok onderdeel van het full branding pakket`}
                titleVariant='h1'
                {...props}
            >
                <Typography>
                    Bij een branding strategie doorlopen we jouw verhaal en het verhaal achter jouw bedrijf, door middel van een werkboek. Na het invullen van het werkboek, ga ik aan de slag met het maken van jouw branding strategie. Hierin komen vier onderdelen aan bod: Jouw bedrijf, jouw doelgroep, jouw concurrentie en als laatste jouw visuele richting.
                </Typography>
                <Typography variant='sub' style={{ margin: hasPhone ? '3vw 0' : hasTablet ? '1.5vw 0' : '0 0 2vw 0'}}>Dagdeel op locatie</Typography>
                <Button link='/diensten/fullbranding'>Bekijk het full branding pakket</Button>
                <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')} style={{ marginTop: hasPhone ? '3vw' : '1vw'}}>Plan een gratis kennismakingsgesprek in</Button>
            </StartTextPhoto>
            <PageTitle title='waarde' left='-5px'>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center'}}>
                    <div style={{ width: hasPhone ? '80vw' : '100vw', margin: hasPhone ? '0 0 5vw 0' : '0'}}>
                        <Typography variant='h2'>wat brengt het jou en <br/> jouw bedrijf?</Typography>
                        <Typography style={{ marginBottom: '2vw'}}>Een pdf met een overzicht van jouw branding strategie.</Typography>
                        <Typography variant='h2'>maar belangrijker:</Typography>
                        <Typography>Een overzichtelijk plan voor de toekomst, waardoor jij {!hasPhone && <br/>}
                        gemakkelijker beslissingen kan maken, kan focussen op {!hasPhone && <br/>} de goede doelgroep en dus geen tijd verliest. </Typography>
                    </div>
                </div>
            </PageTitle>
            <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', margin: hasPhone ? '10vw 0 120vw 0' : '7vw 0'}}>
                <div style={{ position: 'relative', width: hasPhone ? '85vw' : hasTablet ? '50vw' : '45vw'}}>
                    <img src={archetype} alt='archetype' style={{ position: 'absolute', width: hasPhone ? '40vw' : hasTablet ? '20vw' : '15vw', height: hasPhone ? '40vw' : hasTablet ? '20vw' : '15vw', top: hasPhone ? '225vw' : hasTablet ? '48vw' : '25vw', left: hasPhone ? '10vw' : '15vw', zIndex: '998' }} />
                    <img src={associatie} alt='associatie' style={{ position: 'absolute', width: hasPhone ? '40vw' : hasTablet ? '20vw' : '15vw', height: hasPhone ? '40vw' : hasTablet ? '20vw' : '15vw', top: hasPhone ? 'inherit' : hasTablet ? '25vw' : '13vw', bottom: hasPhone && '-240vw', left: hasPhone ? '45vw' : '25vw', zIndex: '999' }} />       
                    <ImageArch src={bedrijf} alt='strategie' width={hasPhone ? '40vw' : hasTablet ? '20vw' : '15vw'} style={{ position: 'absolute', top: hasPhone ? 'inherit' : '0', bottom: hasPhone && '-210vw', left: hasPhone ? '0vw' : '7vw' }} />
                </div>
                <div style={{ width: hasPhone ? '80vw' : '50vw'}}>
                    <Typography variant='h2'>onderdelen van de branding strategie</Typography>
                    <Typography>
                        » Strategie-sessie op locatie (4-6 uur) <br/>
                        » Opgeleverd in een Branding Strategie document
                    </Typography>
                    <Typography variant='sub' style={{ margin: hasPhone ? '2vw 0 1vw 0' : '1vw 0 0.5vw 0'}}>
                        Foundation
                    </Typography>
                    <Typography>
                        » Waarom, hoe en wat? <br/>
                        » Je missie, je visie en je kernwaarden.<br/>
                        » De voordelen van jouw product of dienst<br/>
                        » Jouw functionele en intentionele doelen<br/>
                        » Een brand positionering en waarde propositie<br/>
                        » Jouw USP’s<br/>
                        » Archetype
                    </Typography>
                    <Typography variant='sub' style={{ margin: hasPhone ? '2vw 0 1vw 0' : '1vw 0 0.5vw 0' }}>
                        Audience
                    </Typography>
                    <Typography>
                        » Een doelgroep overzicht met brand persona <br/>
                        » Problemen vs. oplossingen <br/>
                        » Marketing tunnel en klanten reis <br/>
                        » Emotionele impact
                    </Typography>
                    <Typography variant='sub' style={{ margin: hasPhone ? '2vw 0 1vw 0' : '1vw 0 0.5vw 0'}}>
                        Competition
                    </Typography>
                    <Typography>
                        » Concurrentie overzicht met waarden, gelijkenissen en verschillen.
                    </Typography>
                    <Typography variant='sub' style={{ margin: hasPhone ? '2vw 0 1vw 0' : '1vw 0 0.5vw 0'}}>
                        Messaging
                    </Typography>
                    <Typography>
                        » Kernwoorden<br/>
                        » Tone of Voice<br/>
                        » Content pijlers
                    </Typography>
                    <Typography variant='sub' style={{ margin: hasPhone ? '2vw 0 1vw 0' : '1vw 0 0.5vw 0'}}>
                        Visual direction
                    </Typography>
                    <Typography>
                        » Algemene esthetische richting en moodboard<br/>
                        » Kleurenpalet gebaseerd op de kleurenpsychologie en typografische richting.
                    </Typography>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center', flexDirection: 'column', margin: hasPhone ? '10vw 0' : '7vw 0'}}>
                <Typography variant='h2' style={{ width: '85vw' }}>je wil je business naar buiten brengen als {!hasPhone && <br/>} een mooi plaatje.<br/> 
                    maar niet enkel als een mooi plaatje. <br/>
                    je wil dat jouw verhaal overgebracht wordt.
                </Typography>
                <Typography style={{ width: hasPhone ? '85vw' : hasTablet ? '40vw' : '25vw'}}>
                Jij kan alleen impact maken met jouw merk, als dat mooie plaatje zorgt voor verkopen, je inkomsten laat stijgen en jou anders laat zijn dan je concurrentie. Allemaal zodat jij jouw dromen waar kan maken én daardoor nog meer impact kan maken.
                </Typography>
                <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')} style={{ marginTop: hasPhone ? '3vw' : hasTablet ? '2vw' : '1vw'}}>Plan een gratis kennismakingsgesprek in</Button>
            </div>
            <Match {...props} />
            <LieveWoorden {...props} />
            <Shop {...props} />
            <Blog {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}