import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { showContent } from '../../helpers/routes';

import { getBookings } from '../../actions/bookings';

import PhoneMenu from './PhoneMenu';
import LaptopMenu from './LaptopMenu';

import logo from '../../assets/images/VM_LOGO.png';

export default function Menu(props) {
    const { isOpen, setOpen, winkelwagenCount } = props;

    const location = useLocation();

    const dispatch = useDispatch();
    const bookingObject = useSelector((state) => state.bookings);
    const bookingDate = bookingObject && bookingObject.results && bookingObject.results[0] && new Date(bookingObject.results[0].date);

    useEffect(() => {
        Object.keys(bookingObject).length < 1 && dispatch(getBookings());
    });

    if (!showContent(location)) {
        return null;
    } else if (props.hasPhone || props.hasPhoneWindow || props.hasTablet || props.hasTabletWindow) {
        return (
            <PhoneMenu 
                isPhone={props.hasPhone}
                isOpen={isOpen}
                setOpen={setOpen}
                bookingDate={bookingDate}
                logo={logo}
                winkelwagenCount={winkelwagenCount}
            />
        )
    } else {
        return (
            <LaptopMenu 
                bookingDate={bookingDate}
                logo={logo}
                winkelwagenCount={winkelwagenCount}
            />
        )
    }
}