import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from "react-cookie";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Provider } from 'react-redux';
import store from './store';

import App from './App';
import { colors } from './assets/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.title
    }
  }
});

const rootElement = document.getElementById('root');
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
    </ThemeProvider>
  </Provider>,
  rootElement
)