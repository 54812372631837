import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

// import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
// import DateAdapter from '@mui/lab/AdapterMoment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { Box, Button, TextField, Typography, Select, MenuItem, Checkbox } from '@mui/material';
import { Dialog } from '../../../components';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { deleteReview, getReview, postReview, putReview } from '../../../actions/reviews';

import styled from 'styled-components';
import moment from 'moment';

const Field = styled.div`
    display: flex;
    align-items: center;
    gap: 5vw;
    margin-bottom: 1vw;
`;

const Container = styled.div`
    width: ${props => props.width || 'calc(50vw - 30px)'};
    border: 1px solid #BFBEBD;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 1vw;
`;

const ClickImg = styled.img`
    &:hover {
        cursor: pointer;
    }

`;

export default function Review(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();

    const post = params.reviewId === 'new';

    const reviewsObject = useSelector((state) => state.reviews);
    const reviews = (reviewsObject && reviewsObject.reviews && reviewsObject.reviews.results) || [];

    let review = {
        'title': '',
        'type': 'BR',
        'show_at': 'OV',
        'content': '',
        'company_name': '',
        'signature_image': null,
        'main_image': null
    };

    if (!post) {
        review = reviews.filter(a => Number(a.id) === Number(params.reviewId))[0];
    }

    const hasReview = review && Object.keys(review).length > 0;

    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ titleValue, setTitleValue ] = useState((review && review.title) || '');
    const [ titleErrorValue, setTitleErrorValue ] = useState('');
    const [ typeValue, setTypeValue ] = useState((review && review.type) || 'BR');
    const [ showValue, setShowValue ] = useState((review && review.show_at) || 'OV');
    const [ showAtFullBrandingStory, setShowAtFullBrandingStory ] = useState((review && review.show_at_full_branding_story) || false);
    const [ contentValue, setContentValue ] = useState((review && review.content) || '');
    const [ dateValue, setDateValue ] = useState((review && review.created) || null);
    const [ contentErrorValue, setContentErrorValue ] = useState('');
    const [ companyValue, setCompanyValue ] = useState((review && review.company_name) || '');
    const [ signatureValue, setSignatureValue ] = useState((review && review.signature_image) || null);
    const [ imageValue, setImageValue ] = useState((review && review.main_image) || null);

    useEffect(() => {
        !post && dispatch(getReview(params.reviewId))
        if (!post && hasReview && !titleValue) {
            setTitleValue(review.title);
            setTypeValue(review.type);
            setShowValue(review.show_at);
            setShowAtFullBrandingStory(review.show_at_full_branding_story)
            setContentValue(review.content);
            setDateValue(review.created);
            setCompanyValue(review.company_name);
            setSignatureValue(review.signature_image);
            setImageValue(review.main_image);
        }
    }, [post, dispatch, params.reviewId, hasReview, titleValue])

    async function handleImage(image) {
        let newImage = null;
        let fileName = '';
        if (typeof image === 'string') {
            fileName = image;
            await fetch(image, { 'Content-Type': 'application/json' })
                .then(res => res.blob())
                .then(blob => newImage = blob)
        } else if (image && typeof image === 'object') {
            newImage = image;
            fileName = image.name;
        }

        return { 'blob': newImage, 'fileName': fileName };
    }

    async function handleSubmit() {
        let error = false;
        // check validaty of fields
        if (titleValue === '') {
            setTitleErrorValue('Veld verplicht')
            error = true;
        } else {
            setTitleErrorValue('')
        }

        if (contentValue === '') {
            setContentErrorValue('Veld verplicht')
            error = true;
        } else {
            setContentErrorValue('')
        }

        const signatureInfo = await handleImage(signatureValue);
        const mainInfo = await handleImage(imageValue);

        const blobs = { 'signature_image': signatureInfo['blob'], 'main_image': mainInfo['blob'] };
        const fileNames = { 'signature_image': signatureInfo['fileName'], 'main_image': mainInfo['fileName'] };

        let newReview = {
            'title': titleValue,
            'type': typeValue,
            'created': moment(dateValue).format("YYYY-MM-DD[T]HH:mm:ss"),
            'show_at': showValue,
            'show_at_full_branding_story': showAtFullBrandingStory,
            'content': contentValue,
            'company_name': companyValue,
            'signature_image': signatureInfo,
            'main_image': mainInfo
        };

        if (!error) {
            if (!post) {
                newReview['id'] = review.id;
                // PUT REVIEW
                dispatch(putReview(newReview, blobs, fileNames))
            } else {
                // POST REVIEW
                dispatch(postReview(newReview, blobs, fileNames))
            }
        }
    }

    const delReview = () => {
        setDialogOpen(false);
        dispatch(deleteReview(review.id)).then(history.push('/admin/reviews'))
    }

    return (
        <Box component='form' validate>
            <Field>
                <Typography fontWeight='500' style={{ width: '20vw'}}>Titel</Typography>
                <TextField
                    style={{ width: '50vw'}}
                    required
                    id="outlined-required"
                    value={titleValue}
                    error={!!titleErrorValue}
                    helperText={titleErrorValue}
                    onChange={(e) => setTitleValue(e.target.value)}
                    label='Titel'
                />
            </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Inhoud</Typography>
                    <Typography>{'Vul hier de tekst in, inclusief de enters.'}</Typography>
                </div>
                <TextField 
                    style={{ width: '50vw'}}
                    id="outlined-required"
                    value={contentValue}
                    error={!!contentErrorValue}
                    helperText={contentErrorValue}
                    onChange={(e) => setContentValue(e.target.value)}
                    required
                    multiline
                    label='Inhoud'
                />
            </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Aanmaakdatum</Typography>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label="Aanmaakdatum"
                        value={dayjs(dateValue)}
                        onChange={(e) => setDateValue(e.format('YYYY-MM-DDTHH:mm:ss'))}
                        renderInput={(params) => <TextField {...params} />}
                        ampm={false}
                        required
                    />
                </LocalizationProvider>
            </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Type</Typography>
                </div>
                <Select
                    value={typeValue}
                    label="Type"
                    onChange={(e) => setTypeValue(e.target.value)}
                    style={{ width: '50vw' }} 
                >
                    <MenuItem key={1} value='BR'>Branding</MenuItem>
                    <MenuItem key={2} value='TY'>Typografie</MenuItem>
                    <MenuItem key={3} value='BO'>Boekontwerp</MenuItem>
                </Select>
            </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Laten zien bij...</Typography>
                </div>
                <Select
                    value={showValue}
                    label="Laten zien bij..."
                    onChange={(e) => setShowValue(e.target.value)}
                    style={{ width: '50vw' }} 
                >
                    <MenuItem key={1} value='OV'>Overal</MenuItem>
                    <MenuItem key={2} value='PR'>Alleen bij project</MenuItem>
                </Select>
            </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Laten zien bij the full story branding</Typography>
                </div>
                <Checkbox
                    checked={showAtFullBrandingStory}
                    onChange={(e) => setShowAtFullBrandingStory(e.target.checked)}
                />
            </Field>
            <Field>
                <Typography fontWeight='500' style={{ width: '20vw'}}>Bedrijfsnaam</Typography>
                <TextField
                    style={{ width: '50vw'}}
                    required
                    id="outlined-required"
                    value={companyValue}
                    onChange={(e) => setCompanyValue(e.target.value)}
                    label='Bedrijfsnaam'
                />
            </Field>
            <Field>
                <Typography fontWeight='500' style={{ width: '20vw'}}>Klantennaam</Typography>
                <Container>
                    <input
                        accept='image/*'
                        style={{ display: 'none' }}
                        id={`fileUploaderSignature`}
                        onChange={(e) => setSignatureValue(e.target.files[0])}
                        type="file"
                        hidden
                    />
                    <ClickImg 
                        src={signatureValue && signatureValue.name ? URL.createObjectURL(signatureValue) : signatureValue} 
                        style={{ width: '10vw' }} 
                        alt='afbeelding'
                        onClick={() => document.getElementById(`fileUploaderSignature`).click()}
                    />
                </Container>
            </Field>
            <Field>
                <Typography fontWeight='500' style={{ width: '20vw'}}>Afbeelding</Typography>
                <Container>
                    <input
                        accept='image/*'
                        style={{ display: 'none' }}
                        id={`fileUploaderImage`}
                        onChange={(e) => setImageValue(e.target.files[0])}
                        type="file"
                        hidden
                    />
                    <ClickImg 
                        src={imageValue && imageValue.name ? URL.createObjectURL(imageValue) : imageValue} 
                        style={{ width: '10vw' }} 
                        alt='afbeelding'
                        onClick={() => document.getElementById(`fileUploaderImage`).click()}
                    />
                </Container>
            </Field>
            <div style={{ display: 'flex', width: '75vw', justifyContent: 'right', gap: '1vw'}}>
                {!post && <Button onClick={() => setDialogOpen(true)} variant='contained' startIcon={<DeleteIcon/>}>Verwijderen</Button>}
                <Button onClick={handleSubmit} variant='contained' startIcon={<EditIcon />}>{post ? 'Aanmaken' : 'Aanpassen'}</Button>
            </div>
            <Dialog
                open={dialogOpen}
                handleCancel={() => setDialogOpen(false)}
                handleOk={delReview}
                title={'Review verwijderen'}
                content={'Weet je zeker dat je deze review wil verwijderen?'}
            />
        </Box>
    )
}