import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button, Paper, Typography, Select, MenuItem } from '@mui/material';

import BlogGrid from './BlogGrid';

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { deleteBlogGrid } from '../../../actions/bloggrids';
import { getBlogs } from '../../../actions/blogs';

const Container = styled.div`
    width: ${props => props.width || 'calc(50vw - 30px)'};
    border: 1px solid #BFBEBD;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 1vw;
`;

const newVideo = {
    'id': null,
    'order': null,
    'blog_grid': null,
    'shape': 'SQUARE',
    'video': null,
}

const newImage = {
    'id': null,
    'order': null,
    'blog_grid': null,
    'shape': 'SQUARE',
    'file': null,
}

const newContent = {
    'id': null,
    'order': null,
    'blog_grid': null,
    'content': '',
}

const newHeader = {
    'id': null,
    'order': null,
    'blog_grid': null,
    'title': '',
}

const newGrid = {
    'id': null,
    'order': null,
    'grid': null,
    'images': [],
    'headers': [],
    'contents': [],
}

export default function BlogGrids(props) {
    const { grids, setGrids } = props;

    const dispatch = useDispatch();

    const [ isCreatingNewGrid, setIsCreatingNewGrid ] = useState(false);
    const [ isNewGrid, setNewGrid ] = useState('1');
    const [ gridValues, setGridValues ] = useState(['image']);
    const [ isEditing, setIsEditing ] = useState(false);

    const [, forceUpdate] = React.useState(0);

    const createGrid = () => {
        let newGrids = grids;
        const startOrder = grids.length > 0 ? grids.slice(-1)[0].order + 1 : 1;

        // const copy = Object.assign({}, obj)

        let images = [];
        let contents = [];
        let headers = [];
        let videos = [];
        for (let i = 0; i < gridValues.length; i += 1) {
            const value = gridValues[i];
            let object = null;
            if (value === 'image') {
                object = Object.assign({}, newImage);
                object.order = i + 1;
                images.push(object)
            } else if (value === 'content') {
                object = Object.assign({}, newContent);
                object.order = i + 1;
                contents.push(object);
            } else if (value === 'header') {
                object = Object.assign({}, newHeader);
                object.order = i + 1;
                headers.push(object);
            } else if (value === 'video') {
                object = Object.assign({}, newVideo);
                object.order = i + 1;
                videos.push(object);
            }
        }

        let grid = Object.assign({}, newGrid);
        grid.order = startOrder;
        grid.grid = isNewGrid;
        grid.images = images;
        grid.contents = contents;
        grid.headers = headers;
        grid.videos = videos;

        newGrids.push(grid);

        setGrids(newGrids);
        setIsCreatingNewGrid(false);
        setNewGrid('1');
        setGridValues(['image']);
        setIsEditing(true);
    }

    const setGridArray = (value) => {
        const newArray = Array(Number(value)).fill('image');
        setNewGrid(value);
        setGridValues(newArray);
    }

    const setGridArrayValues = (value, i) => {
        let newGridValues = gridValues;
        newGridValues[i] = value; 
        setGridValues(newGridValues);
        forceUpdate(n => !n);
    }

    const updateContent = (contentType, key, grid, id, value) => {
        let newGrids = grids;

        const gridId = grids.indexOf(grid);
        const gridContent = grid[contentType].filter(content => Number(content.order) === Number(id))[0];
        const gridContentId = grid[contentType].indexOf(gridContent);

        newGrids[gridId][contentType][gridContentId][key] = value;
        setGrids(newGrids);
        forceUpdate(n => !n);
    }

    const deleteGrid = (grid) => {
        dispatch(deleteBlogGrid(grid.id)).then(dispatch(getBlogs()));
        const newGrids = grids.filter(gr => Number(gr.id) !== Number(grid.id));
        setGrids(newGrids);
    }

    const cancelNewGrid = () => {
        const newGrids = grids.filter(gr => gr.id)
        setGrids(newGrids);

        setNewGrid('1');
        setGridValues(['image']);
    }

    return (
        <Container>
            {grids.map((grid, i) => (
                <Container width='46vw' key={i}>
                    <BlogGrid 
                        key={i} 
                        grid={grid} 
                        isEditing={isEditing}
                        updateContent={updateContent}
                        deleteGrid={deleteGrid}
                        cancelNewGrid={cancelNewGrid}
                    />
                </Container>
            ))}
            {isCreatingNewGrid ? 
                <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px', gap: '3vw' }}>
                    <Typography variant='h6'>Nieuw raster aanmaken</Typography>
                    <div>
                        <Typography>Raster</Typography>
                        <Select
                            value={isNewGrid}
                            label="Raster"
                            onChange={(e) => setGridArray(e.target.value)}
                            style={{ height: '3vw', width: '10vw'}}
                        >
                            <MenuItem key={1} value='1'>Raster van 1</MenuItem>
                            <MenuItem key={2} value='2'>Raster van 2</MenuItem>
                            <MenuItem key={3} value='3'>Raster van 3</MenuItem>
                        </Select>
                    </div>
                    <div>
                        <Typography>Types content</Typography>
                        {Array.apply(null, { length: Number(isNewGrid) }).map((e, i) => {
                            return (
                                <div key={i} style={{ display: 'flex', alignItems: 'center', gap: '5px'}}>
                                    <span>{i + 1}</span>
                                    <Select
                                        key={i}
                                        value={gridValues[i]}
                                        label="Raster"
                                        onChange={(e) => setGridArrayValues(e.target.value, i) }
                                        style={{ height: '3vw', width: '10vw'}}
                                    >
                                        <MenuItem key={4} value='header'>Tussenkop</MenuItem>
                                        <MenuItem key={5} value='content'>Tekst</MenuItem>
                                        <MenuItem key={6} value='image'>Afbeelding</MenuItem>
                                        <MenuItem key={7} value='video'>Video</MenuItem>
                                    </Select>
                                </div>
                            )
                        })}
                    </div>
                    <div>
                        <Button onClick={createGrid} variant='contained' startIcon={<AddIcon/>} style={{ marginBottom: '1vw'}}>Aanmaken</Button>
                        <Button onClick={() => setIsCreatingNewGrid(false)} variant='contained' startIcon={<CloseIcon/>}>Annuleren</Button>
                    </div>
                </Paper> 
            :
                <div style={{ display: 'flex', gap: '1vw', justifyContent: 'center'}}>
                    <Button onClick={() => setIsCreatingNewGrid(true)} variant='contained' startIcon={<AddIcon/>}>Nieuw raster aanmaken</Button>
                    {grids.length > 0 && !isEditing && <Button variant='contained' onClick={() => setIsEditing(true)} startIcon={<EditIcon/>}>Rasters aanpassen</Button>}
                    {grids.length > 0 && isEditing && <Button variant='contained' onClick={() => setIsEditing(false)} startIcon={<EditIcon/>}>Rasters bevestigen</Button>}
                </div>
            }
        </Container>
    )
}