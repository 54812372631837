import { Grid } from '@mui/material';
import React from 'react';
import { Typography, Button, ImageArch, PageTitle, LineButton } from '../../components';
import { Instagram, LieveWoorden, Match, Nieuwsgierig, RecenteProjecten, Werkwijze } from '../../subpages';

import strategie from '../../assets/images/diensten/strategie/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-strategie.jpg';
import branding from '../../assets/images/diensten/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding.jpg';
import branding1 from '../../assets/images/diensten/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-1.jpg';
import typografie1 from '../../assets/images/diensten/Studio-Valerie-Maas-grafisch-ontwerp-diensten-typografie-1.jpg';
import creationboost from '../../assets/images/diensten/Studio-Valerie-Maas-grafisch-ontwerp-diensten-creation-boost-sparren.jpg';
import designintensive from '../../assets/images/diensten/Studio-Valerie-Maas-grafisch-ontwerp-diensten-design-intensive.jpg';

import { getCsvDienstRow } from '../../helpers/diensten';


export default function Diensten(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    const creationBoostRow = getCsvDienstRow(props.dienstenCsv, 'creation boost');
    const designIntensiveRow = getCsvDienstRow(props.dienstenCsv, 'design intensive');

    return (
        <div>
            <Grid container justifyContent='center' alignItems='flex-start'>
                <div style={{ width: hasPhone ? '60vw' : hasTablet ? '30vw' : '18vw', textAlign: 'center', margin: hasPhone ? '0 0 80vw 0' : hasTablet ? '0 40vw 10vw 0' : '0 40vw 5vw 0'}}>
                    <Typography variant='h1' >strategische <br/>branding</Typography>
                    <Typography style={{ margin: hasPhone ? '4vw 0 2vw 0' : hasTablet ? '2vw 0' : '0'}}>Voor vrouwelijke ondernemers die impact willen maken met hun bedrijf, willen doen waar zij blij van worden én hun droomleven willen leiden</Typography>
                    <Typography variant='sub' style={{ margin: hasPhone ? '4vw 0 2vw 0' : hasTablet ? '2vw 0' : '3vw 0 0 0'}}>Pakketten en tarieven</Typography>
                    <Button link='/diensten/strategie' style={{ marginTop: hasPhone ? '3vw' : hasTablet ? '1vw' : '1vw'}}>Bekijk de strategie pakketten</Button>
                    <Button link='/diensten/branding' style={{ marginTop: hasPhone ? '3vw' : hasTablet ? '1vw' : '1vw'}}>Bekijk de branding pakketten</Button>
                </div>
                <div style={{ position: 'relative' }}>
                    <img src={branding1} alt='moodboard' style={{ position: 'absolute', width: hasPhone ? '35vw' : hasTablet ? '18vw' : '15vw', top: hasPhone ? '75vw' : hasTablet ? '8vw' : '2vw', right: hasPhone ? '32vw' : hasTablet ? '10vw' : '5vw', zIndex: '998' }} />
                    <img src={typografie1} alt='logo design' style={{ position: 'absolute', width: hasPhone ? '35vw' : hasTablet ? '18vw' : '15vw', top: hasPhone ? '100vw' : hasTablet ? '18vw' : '9vw', right: hasPhone ? '-10vw' : hasTablet ? '-10vw' : '-12vw', zIndex: '999' }} />
                </div>
            </Grid>
            <PageTitle title='strategie' left='-5px'>
                <Grid container justifyContent='space-evenly' alignItems='center' style={{ margin: '5vw 0'}}>
                    <div style={{ width: hasPhone ? '75vw' : hasTablet ? '32vw' : '25vw'}}>
                        <Typography variant='h2'>strategie</Typography>
                        <Typography>Met enkel een mooi plaatje ben je er niet. Je bent je bedrijf om een reden begonnen. Je wil impact maken met jouw merk en je dromen waarmaken. Je wil doen waar je blij van wordt en daarmee je doelgroep aantrekken.
                        <br/><br/>Door voor jouw merk een branding strategie te creëren, weet jij hoe je tekstueel en visueel jouw ideale klant aan kan trekken op een manier die bij jou past!</Typography>
                        <Button link='/diensten/strategie' style={{ marginTop: hasPhone ? '3vw' : '2vw'}}>Bekijk de strategie pakketten</Button>
                    </div>
                    <ImageArch src={strategie} alt='Strategie' width={hasPhone ? '75vw' : hasTablet ? '32vw' : '25vw'} style={{ margin: hasPhone ? '10vw 0' : '0' }} />
                </Grid>
            </PageTitle>
            <PageTitle title='branding' left='-5px'>
                <Grid container justifyContent='space-evenly' flexDirection={hasPhone ? 'row' : 'row-reverse'} alignItems='center' style={{ margin: '5vw 0'}}>
                    <div style={{ width: hasPhone ? '75vw' : hasTablet ? '32vw' : '25vw'}}>
                        <Typography variant='h2'>branding</Typography>
                        <Typography>Het is tijd voor de volgende stap. Jouw onderneming mag naar the next level, professionaliseren, door middel van een branding. Een volledige visuele identiteit, logo en huisstijl, gekoppeld aan een branding strategie. Deze kunnen we online, op je website en social media, maar ook in drukwerk uitwerken.</Typography>
                        <LineButton to='/diensten/fullbranding' style={{ margin: hasPhone ? '3vw 0' : '1vw 0'}}>Full branding</LineButton>
                        <LineButton to='/diensten/fullbrandingweb' style={{ margin: hasPhone ? '3vw 0' : '1vw 0'}}>Full branding en web</LineButton>
                        <LineButton to='/diensten/thefullstorybranding' style={{ margin: hasPhone ? '3vw 0' : '1vw 0'}}>The full story branding</LineButton>
                        <Button link='/diensten/branding' style={{ marginTop: hasPhone ? '3vw' : '2vw'}}>Bekijk de branding pakketten</Button>
                    </div>
                    <ImageArch src={branding} alt='Branding' width={hasPhone ? '75vw' : hasTablet ? '32vw' : '25vw'} style={{ margin: hasPhone ? '10vw 0' : '0' }} />
                </Grid>
            </PageTitle>
            <Grid container justifyContent='space-evenly' alignItems='center' style={{ margin: hasPhone ? '2vw 0 10vw 0' : hasTablet ? '0 0 7vw 0' : '5vw 0 10vw 0'}}>
                <div style={{ width: hasPhone ? '75vw' : hasTablet ? '50vw' : '25vw', textAlign: 'center'}}>
                    <Typography variant='h2'>{designIntensiveRow['dienst']}</Typography>
                    <Typography variant='sub' style={{ margin: hasPhone ? '1vw 0 2vw 0' : hasTablet ? '0 0 1vw 0' : '0 0 1vw 0'}}>Vanaf {designIntensiveRow['prijs']} excl. btw</Typography>
                    <Typography>
                    Heb jij inmiddels heel wat ontwerp-taken verzameld die al even op je wachten? Maar waar je steeds maar niet goed aan toe komt? <br/><br/>
                    Of heeft je nieuwe branding óf een ander design project veel haast? <br/><br/>
                    In een Design Intensive werken we (minimaal)  één dag samen aan jouw merk. Dit kan bij jou op locatie óf op afstand. In een korte tijd kunnen we, door de korte lijntjes, jouw design project snel afronden en jouw to-do lijstje eindelijk eens korter maken.
                    </Typography>
                    <Button link='/diensten/designintensive' style={{ marginTop: hasPhone ? '3vw' : '2vw'}}>Bekijk meer informatie</Button>
                </div>
            </Grid>
            <div style={{ display: 'flex' }}>
                <div style={{ width: hasPhone ? '85vw' : hasTablet ? '40vw' : '25vw', margin: hasPhone ? '0 0 75vw 7.5vw' : hasTablet ? '0 0 0 12vw' : '0 0 0 17vw'}}>
                    <Typography variant='h2'>ik wil alleen even met je sparren en van je kennis gebruik maken!</Typography>
                    <Typography variant='sub'>{creationBoostRow['prijs']} excl. BTW</Typography>
                    <Typography style={{ margin: '2vw 0 0 0'}}>In een Creation Boost kunnen we samen één uur lang over jouw vraagstuk.</Typography>
                    <Button link='/diensten/creationboost' style={{ marginTop: hasPhone ? '3vw' : hasTablet ? '2vw' : '1vw'}}>Ik wil samen sparren!</Button>
                </div>
                <div style={{ position: 'relative'}}>
                    <img src={creationboost} alt='Valerie' style={{ height: hasPhone ? '38vw' : hasTablet ? '18vw' : '16vw', width: hasPhone ? '38vw' : hasTablet ? '18vw' : '16vw', position: 'absolute', bottom: hasPhone ? '25vw' : hasTablet ? '3vw' : '0vw', right: hasPhone ? '45vw' : hasTablet ? '-25vw' : '-32vw'}} />
                    <ImageArch src={designintensive} alt='Design' width={hasPhone ? '38vw' : hasTablet ? '18vw' : '16vw'} style={{ position: 'absolute', right: hasPhone ? '0vw' : hasTablet ? '-45vw'  : '-50vw', top: hasPhone ? '70vw' : '-13vw'}} />    
                </div>
            </div>
            <Grid container justifyContent={hasPhone ? 'center' : 'space-evenly'} alignItems='center' style={{ margin: hasPhone ? '10vw 0' : '7vw 0'}}>
                <div style={{ width: hasPhone ? '80vw' : hasTablet ? '43vw' : '27vw', textAlign: 'center'}}>
                    <Typography variant='h2'>of kan ik je ergens anders mee helpen?</Typography>
                    <Typography>Naast de bestaande pakketten wil ik ook graag werken aan andere grafische projecten. Denk bijvoorbeeld aan boeken, magazines óf een geboortekaartje. Heb jij een fantastisch idee? En kan ik helpen jouw droom waar te maken? Plan dan even een kennismakingsgesprek in.</Typography>
                    <Button link='/contact' style={{ margin: hasPhone ? '5vw 0 3vw 0' : '2vw 0 1vw 0'}}>neem contact op</Button>
                    <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')}>Plan een gratis kennismakingsgesprek in</Button>
                </div>
            </Grid>
            <Werkwijze {...props} />
            <RecenteProjecten {...props} />
            <Match {...props} />
            <LieveWoorden {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}