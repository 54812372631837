import React, { useState } from 'react';
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import { Typography, TextField, useInput, Button } from '../../components';
import { Grid } from '@mui/material';
import { colors } from '../../assets/colors';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { postAdvent, postCalendlyEvent, postCalendlyInvitee } from '../../actions/advent';

const Container = styled.div`
    position: relative;
`;

const Widget = styled.div`
    height: 50vw;

    * #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        height: 300vw;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        height: 130vw; 
    }
`;

export default function AdventPlan(props) {
    const { hasPhone, hasTablet } = props;

    const [ status, setStatus ] = useState('form');

    const { value: valueFirstName, bind: bindFirstName } = useInput('');

    const [ valueName, setName ] = useState('');

    const { value: valueLastName, bind: bindLastName } = useInput('');
    const { value: valueEmail, bind: bindEmail } = useInput('');
    const { value: valueCompanyName, bind: bindCompanyName } = useInput('');

    const { value: valueAddress, bind: bindAddress } = useInput('');
    const { value: valueZipcode, bind: bindZipcode } = useInput('');
    const { value: valueCity, bind: bindCity } = useInput('');
    const { value: valueCountry, bind: bindCountry } = useInput('');

    const { value: valueTaxNumber, bind: bindTaxNumber } = useInput('');

    const dispatch = useDispatch();

    const pageWidth = hasPhone ? '60vw' : hasTablet ? '35vw' : '25vw';
    const textFieldStyle = { width: pageWidth, marginBottom: hasPhone ? '3vw' : hasTablet ? '1vw' : '1vw'};

    useCalendlyEventListener({
        // onProfilePageViewed: () => console.log("onProfilePageViewed"),
        onDateAndTimeSelected: () => {
            // props.setStatus('pay')
        },
        // onEventTypeViewed: () => console.log("onEventTypeViewed"),
        onEventScheduled: (e) => { 
            const eventUri = e.data.payload.event.uri.split('/').slice(-1)[0];
            const inviteeUri = e.data.payload.invitee.uri.split('/').slice(-1)[0];
                        
            const invitee = {
                uri: inviteeUri,
                first_name: valueFirstName,
                last_name: valueLastName,
                email: valueEmail,
                company_name: valueCompanyName,
                address: valueAddress,
                zip_code: valueZipcode,
                city: valueCity,
                country: valueCountry,
                tax_number: valueTaxNumber,
            }

            const event = {
                uri: eventUri,
                invitee: invitee,
            }

            const payment = {
                mollie_id: '',
                value: '60.50',
                description: 'Advent creation Boost'.concat(" ", valueCompanyName),
                redirect_url: process.env.REACT_APP_FRONTEND_URL.concat(`advent/${eventUri}`),
                webhook_url: process.env.REACT_APP_API_URL.concat('/webhook/mollie/'),
                checkout_url: process.env.REACT_APP_FRONTEND_URL.concat('advent'),
            }

            dispatch(postCalendlyInvitee(invitee)).then(invitee_resp => {
                dispatch(postCalendlyEvent(event)).then(event_resp => {
                    const advent = {
                        event: event_resp,
                        payment: payment
                    }

                    dispatch(postAdvent(advent)).then(advent_resp => {
                        window.location.replace(advent_resp['payment']['checkout_url'])
                    });
                });
            });
        },
    });

    const submitForm = (evt) => {
        evt.preventDefault();

        const name = valueFirstName.concat(" ", valueLastName);
        setName(name);

        setStatus('plan');  
    }

    return (
        <Container>
            {status === 'plan' ? 
                <Widget>
                    <InlineWidget 
                        url="https://calendly.com/studio-valeriemaas-creationboost/60min" 
                        styles={{ height: hasPhone ? '300vw' : hasTablet ? '130vw' : '45vw'}} 
                        prefill={{
                            firstName: valueFirstName,
                            lastName: valueLastName,
                            name: valueName,
                            email: valueEmail,
                        }}
                    />
                </Widget> : status === 'form' ?
                <form onSubmit={submitForm} autoComplete='on' style={{ margin: '5vw 0'}}>
                    <Grid container direction='column' alignItems='center'>
                        <Typography variant='h2' style={{ marginBottom: hasPhone ? '5vw' : '2vw'}}>Gegevens</Typography>
                        <TextField 
                            placeholder='Voornaam'
                            style={textFieldStyle}
                            required
                            {...bindFirstName}
                        />
                        <TextField 
                            placeholder='Achternaam'
                            style={textFieldStyle}
                            required
                            {...bindLastName}
                        />
                        <TextField 
                            placeholder='E-mailadres'
                            id='email'
                            type='email'
                            style={textFieldStyle}
                            required
                            {...bindEmail}
                        />
                        <TextField 
                            placeholder='Bedrijfsnaam'
                            style={textFieldStyle}
                            required
                            {...bindCompanyName}
                        />
                        <TextField 
                            placeholder='Adres'
                            style={textFieldStyle}
                            required
                            {...bindAddress}
                        />
                        <TextField 
                            placeholder='Postcode'
                            style={textFieldStyle}
                            required
                            {...bindZipcode}
                        />
                        <TextField 
                            placeholder='Stad'
                            style={textFieldStyle}
                            required
                            {...bindCity}
                        />
                        <TextField 
                            placeholder='Land'
                            style={textFieldStyle}
                            required
                            {...bindCountry}
                        />
                        <Typography style={{ color: colors.title, width: pageWidth, margin: hasPhone ? '2vw 0 1vw 0' : hasTablet ? '1vw 0 0.5vw 0' : '0.5vw 0 0.5vw 0'}}>
                            Alleen voor klanten uit België
                        </Typography>
                        <TextField 
                            placeholder='BTW-nummer'
                            style={textFieldStyle}
                            {...bindTaxNumber}
                        />
                        <Button type='submit' style={{ marginTop: hasPhone ? '5vw' : '2vw' }}>Verstuur en plan je afspraak</Button>
                    </Grid>
                </form> : <div>oeps</div>
            }
        </Container>
    )
}