import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Typography, ImageArch, Button, Accordion, PageTitle, LineButton, BulletPoint } from '../../components';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { getCsvDienstRow } from '../../helpers/diensten';

import brandingweb from '../../assets/images/diensten/branding/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-full-branding-en-web.jpg';
import fullbranding from '../../assets/images/diensten/branding/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-full-branding.jpg';
// import minibranding from '../../assets/images/diensten/branding/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-mini-branding.jpg';
import visitekaartje from '../../assets/images/diensten/branding/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-visitekaartje.jpg';
import moodboard from '../../assets/images/diensten/branding/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-moodboard.jpg';
import website from '../../assets/images/diensten/branding/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-website.jpg';
import fullstory from '../../assets/images/diensten/branding/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding.jpg';
import intensiveVal from '../../assets/images/diensten/logohuisstijl/Studio-Valerie-Maas-grafisch-ontwerp-diensten-design-intensive-Valerie.jpg';
import intensive from '../../assets/images/diensten/logohuisstijl/Studio-Valerie-Maas-grafisch-ontwerp-diensten-design-intensive.jpg';

import { ReactComponent as Monstera } from '../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_monstera.svg';
import { Blog, Instagram, LieveWoorden, Match, Nieuwsgierig, RecenteProjecten, Shop } from '../../subpages';

const CustomLineButton = styled.div`
    font-family: Mulish;
    font-size: 13px;
    font-weight: ${props => props.active ? '600' : '300'};
    text-transform: uppercase;
    text-decoration: none;
    color: ${colors.photo};
    letter-spacing: 1.4px;
    text-decoration: underline;
    text-underline-offset: 5px;
    margin: ${props => props.margin || '1vw'};

    &:hover {
        cursor: pointer;
        font-weight: 600;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 11px;
        padding-bottom: 2px;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 11px;
        padding-bottom: 1px;
    }
`;

const StyledLink = styled(Link)`
    display: inline;
    text-decoration: none;
    color: ${colors.black};
    position: relative;
    cursor: pointer;
    
    &:hover {
        font-style: italic;
    }
`;

export default function Branding(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;
    const dienstenCsv = props.dienstenCsv;

    const fullRef = useRef(null);
    const fullWebRef = useRef(null);
    const fullStoryRef = useRef(null);
    const implemRef = useRef(null);
    const intensiveRef = useRef(null);
    const linkRef = useRef(null);

    const fullBrandingRow = getCsvDienstRow(dienstenCsv, 'full branding');
    const fullBrandingWebRow = getCsvDienstRow(dienstenCsv, 'full branding en web');
    // const visitekaartjeRow = getCsvDienstRow(dienstenCsv, 'visitekaartje');
    // const socialMediaTemplatesRow = getCsvDienstRow(dienstenCsv, 'social media templates');
    // const strategieSessieRow = getCsvDienstRow(dienstenCsv, 'branding strategie');
    const fullStoryRow = getCsvDienstRow(dienstenCsv, 'the full story branding');
    const intensiveRow = getCsvDienstRow(dienstenCsv, 'design intensive');

    const executeScroll = (myRef, offSet=150) => {
        myRef && myRef.current && window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop - offSet })
    };
    
    function BrandingPage(props) {
        const { pageTitle, title, titleMargin, text, price, accordionText, bulletPoints, link, image, even } = props;

        return (
            <PageTitle title={pageTitle} left={titleMargin}>
                <Grid container justifyContent='space-evenly' alignItems='flex-start' style={{ flexDirection: even ? 'row-reverse' : 'row', margin: '8vw 0'}}>
                    <div style={{ width: hasPhone ? '85vw' : hasTablet ? '37vw' : '25vw', margin: even ? '3vw 0 0 3vw' : '3vw 0 0 5vw'}}>
                        <Typography variant='h2' >{title}</Typography>
                        <Typography variant='sub' style={{ fontSize: '12px', margin: hasPhone ? '3vw 0' : '0' }}>{price} excl. btw</Typography>
                        <Typography style={{ margin: hasPhone ? '4vw 0' : '2vw 0'}}>{text}</Typography>
                        <Accordion 
                            mainTextStyle={{ marginLeft: '0'}}
                            detailTextStyle={{ marginBottom: '0', marginLeft: '0' }}
                            textStyle={{ marginBottom: '0' }}
                            mainText='wat krijg je?'
                            detailText={accordionText}
                            bulletPoints={bulletPoints}
                        />
                        <Button link={link} style={{ marginTop: hasPhone ? '3vw' : '1vw'}}>Yes! Ik wil meer lezen</Button>
                    </div>
                    <ImageArch src={image} alt={title} width={hasPhone ? '80vw' : hasTablet ? '35vw' : '23vw'} style={{ marginTop: hasPhone ? '7vw' : '0', marginLeft: even ? '7vw' : '0' }} />
                </Grid>
            </PageTitle>
        )
    }

    return (
        <div>
            <Grid container justifyContent='space-evenly' alignItems='center' style={{ margin: '8vw 0 28vw 0'}}>
                <div style={{ width: hasPhone ? '85vw' : hasTablet ? '40vw' : '25vw', marginLeft: hasTablet ? '0' : '5vw'}}>
                    <Typography variant='h1'>branding</Typography>
                    <Typography>Wie ben jij? Wat doe je? En vooral: Waarom doe je wat je doet? We kijken samen naar jouw verhaal en jouw bedrijf. En ontwikkelen aan de hand van jouw verhaal een huisstijl die bij jou past. Op die manier trek je klanten aan die bij je passen en wek je nooit de verkeerde indruk.</Typography>
                    <Button onClick={() => executeScroll(linkRef, 350)} style={{ marginTop: '2vw'}}>Bekijk de branding pakketten</Button>
                </div>
                <div style={{ position: 'relative', width: hasPhone ? '100vw' : '25vw', height: hasPhone ? '80vw' : '100%'}}>
                    <img src={moodboard} alt='moodboard' style={{ position: 'absolute', width: hasPhone ? '35vw' : '15vw', top: hasPhone ? '10vw' : '-10vw', left: hasPhone ? '25vw' : '3vw', zIndex: '999' }} />
                    <img src={visitekaartje} alt='logo design' style={{ position: 'absolute', width: hasPhone ? '35vw' : '15vw', top: hasPhone ? '31vw' : '0', left: hasPhone ? '55vw' : '16vw', zIndex: '998' }} />
                    <ImageArch src={website} alt='Website voorbeeld' width={hasPhone ? '35vw' : '15vw'} style={{ position: 'absolute', top: hasPhone ? '58vw' : '12vw', left: hasPhone ? '12vw' : '-2vw' }} />
                </div>
            </Grid>
            <div ref={linkRef}>
                <Grid container direction='column' justifyContent='center' alignItems='center' style={{ position: 'relative'}}>
                    <Typography variant='h2'>Hoe kan jij mij helpen?</Typography>
                    <CustomLineButton onClick={() => executeScroll(fullRef)} margin={hasPhone ? '3vw 0' : '1vw 0'}>Full branding</CustomLineButton>
                    <CustomLineButton onClick={() => executeScroll(fullWebRef)} margin={hasPhone ? '3vw 0' : '1vw 0'}>Full branding en web</CustomLineButton>
                    <CustomLineButton onClick={() => executeScroll(fullStoryRef)} margin={hasPhone ? '3vw 0' : '1vw 0'}>The full story branding</CustomLineButton>
                    <CustomLineButton onClick={() => executeScroll(implemRef)} margin={hasPhone ? '3vw 0' : '1vw 0'}>Implementatie</CustomLineButton>
                    <CustomLineButton onClick={() => executeScroll(intensiveRef)} margin={hasPhone ? '3vw 0' : '1vw 0'}>Design intensive</CustomLineButton>
                    
                    {!hasPhone && <Monstera style={{ fill: colors.illustration, position: 'absolute', left: '8vw', top: '-13vw', width: hasTablet ? '10vw' : '8vw'}} />}
                </Grid>
            </div>
            <div ref={fullRef}>
                <BrandingPage 
                    pageTitle={fullBrandingRow['dienst']}
                    titleMargin='-20px'
                    title={fullBrandingRow['dienst']}
                    price={`vanaf ${fullBrandingRow['prijs']}`}
                    text='Het pakket ‘Full branding’ is ideaal als jij klaar bent om te groeien. Je hebt jouw verhaal en doelgroep duidelijk óf bent klaar om deze samen met mij duidelijker te maken in de strategie-sessie. Deze strategie staat centraal om logo’s, variaties daarop, emblemen, illustraties of iconen en patronen te creëren. Zodat jouw verhaal vertelt kan worden in een compleet en passend jasje, waarmee jij jouw ideale klant aantrekt.'
                    bulletPoints={
                        <div>
                            <BulletPoint>Inspiratie-gesprek (online)</BulletPoint>
                            <BulletPoint>
                                <div style={{ display: 'inline-block'}}>
                                    <div style={{ display: 'inline', marginRight: '5px'}}>Strategie-sessie (dagdeel op locatie)</div>
                                    <StyledLink to='/diensten/brandingstrategie'>Lees meer</StyledLink>
                                </div>
                            </BulletPoint>
                            <BulletPoint>Moodboard</BulletPoint>
                            <BulletPoint>2 correctierondes</BulletPoint><br/>
                            <BulletPoint>Conceptvoorstel (online)</BulletPoint>
                            <BulletPoint>Logo`s, beeldmerk, tekstmerk, embleem en variaties</BulletPoint>
                            <BulletPoint>Kleurenpalet</BulletPoint>
                            <BulletPoint>Typografie selectie</BulletPoint>
                            <BulletPoint>5 illustraties of iconen</BulletPoint>
                            <BulletPoint>2 patronen</BulletPoint>
                            <BulletPoint>Favicon</BulletPoint>
                            <BulletPoint>Brandbook incl. strategie</BulletPoint>
                            <BulletPoint>Ontwerp van visitekaartje</BulletPoint>
                            <BulletPoint>
                                <div style={{ display: 'inline-block'}}>
                                    <div style={{ display: 'inline', marginRight: '5px'}}>Ontwerp van 1 keuze uit de (online) zichtbaarheid-huisstijldragers</div>
                                    <StyledLink to='/diensten/zichtbaarheid'>Lees meer</StyledLink>
                                </div>
                            </BulletPoint>
                        </div>
                    }
                    link='/diensten/fullbranding'
                    image={fullbranding}
                />
            </div>
            <div ref={fullWebRef}>
                <BrandingPage 
                    pageTitle={fullBrandingWebRow['dienst']}
                    titleMargin='-45px'
                    title={fullBrandingWebRow['dienst']}
                    price={`vanaf ${fullBrandingWebRow['prijs']}`}
                    text='Het pakket ‘Full branding en web’ is een compleet pakket voor ondernemers die klaar zijn om het écht serieus aan te pakken. Naast de elementen uit het ‘Full branding’ pakket, creëer ik samen met een van de webbouwers waar ik vaak mee samenwerk een unieke website (zonder templates). Dit pakket is ideaal als jij naast het ‘Full branding’ pakket jouw ideale klant ook online wil bereiken met een website die perfect bij jou en jouw verhaal past.'
                    bulletPoints={
                        <div>
                            <BulletPoint>Brainstormsessie</BulletPoint>
                            <BulletPoint>Website ontwerp op maat</BulletPoint>
                            <BulletPoint>Responsive design</BulletPoint><br/>
                            <BulletPoint>6 gebouwde pagina’s (home, over mij, aanbod, realisaties, blog, contact, …)</BulletPoint>
                            <BulletPoint>Coming soon pagina</BulletPoint>
                            <BulletPoint>Koppeling e-mailmarketing</BulletPoint>
                            <BulletPoint>Koppeling Google Analytics</BulletPoint>
                            <BulletPoint>2 correctierondes</BulletPoint>
                        </div>
                    }
                    accordionText='Alles van ‘Full branding’ +'
                    link='/diensten/fullbrandingweb'
                    image={brandingweb}
                    even
                />
            </div>
            <div ref={fullStoryRef}>
                <BrandingPage 
                    pageTitle={fullStoryRow['dienst']}
                    titleMargin='-55px'
                    title={fullStoryRow['dienst']}
                    price={fullStoryRow['prijs']}
                    text='Ben jij klaar om het écht goed aan te pakken? Zowel online als offline jouw ideale klant aan te trekken? En wil jij volledig ontzorgt worden? Het pakket ‘The full story branding’ is een pakket voor ondernemers die het complete plaatje in een keer goed neer willen zetten, waardoor jij écht een waardevol merk in de markt zet. Ik zorg, samen met mijn team, voor het neerzetten van één sterk verhaal, met een sterke basis in de branding strategie. Waarin we gaan kijken naar jouw verhaal!'
                    bulletPoints={
                        <div>
                            <BulletPoint>Branding strategie</BulletPoint>
                            <BulletPoint>Full Branding</BulletPoint>
                            <BulletPoint>Website mét teksten</BulletPoint>
                            <BulletPoint>Drukwerk</BulletPoint>
                            <BulletPoint>Fotografie</BulletPoint>
                            <BulletPoint>Social media templates</BulletPoint>
                            <Typography>En nog veel meer...</Typography>
                        </div>
                    }
                    link='/diensten/thefullstorybranding'
                    image={fullstory}
                    even={false}
                />
            </div>
            <div  ref={implemRef}>
                <PageTitle title='Implementatie' left='-20px' >
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: hasPhone ? '15vw 0' : '3vw 0'}} >
                            <Typography variant='h2'>implementatie</Typography>
                            <Typography style={{ width: hasPhone ? '85vw' : hasTablet ? '37vw' : '25vw'}}>Je branding is klaar en nu? Consistentie is erg belangrijk en daarom wil ik je graag helpen jouw herkenbaar en betrouwbaar te maken door jouw nieuwe branding consistent toe te passen.<br/><br/>
                            Hiervoor heb ik een aantal vaste pakketten ontwikkeld, maar je kan uiteraard ook losse uitingen laten maken.</Typography>
                            <div style={{ display: 'flex', gap: '5vw', textAlign: 'center', justifyContent: 'center', margin: hasPhone ? '5vw 0' : hasTablet ? '4vw 0' : '2vw 0', flexWrap: 'wrap'}}>
                                <div style={{ display: 'flex', textAlign: 'center', flexDirection: 'column', alignItems: 'center', margin: hasPhone && '5vw 0'}}>
                                    <Typography variant='h2'>print ontwerp</Typography>
                                    <LineButton to='/diensten/visitekaartje' style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '0.5vw 0'}}>Visitekaartje</LineButton>
                                    <LineButton to='/contact' style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '0.5vw 0'}}>Ansichtkaart / cadeaubon</LineButton>
                                    <LineButton to='/contact' style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '0.5vw 0'}}>Flyers of ander promotiemateriaal</LineButton>
                                    <LineButton to='/contact' style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '0.5vw 0'}}>Product verpakkingen</LineButton>
                                    <LineButton to='/contact' style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '0.5vw 0'}}>Gevelbekleding / signing</LineButton>
                                    <LineButton to='/contact' style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '0.5vw 0'}}>(Werk)boek opmaak</LineButton>
                                </div>
                                <div style={{ display: 'flex', textAlign: 'center', flexDirection: 'column', alignItems: 'center'}}>
                                    <Typography variant='h2'>digitaal ontwerp</Typography>
                                    <LineButton to='/diensten/socialmediatemplate' style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '0.5vw 0' }}>Social media templates</LineButton>
                                    <LineButton to='/contact' style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '0.5vw 0'}}>E-book vormgeving</LineButton>
                                    <LineButton to='/diensten/emailhandtekening' style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '0.5vw 0'}}>E-mailhandtekening</LineButton>
                                    <LineButton to='/diensten/logoanimatie' style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '0.5vw 0'}}>Logo-animatie</LineButton>
                                    <LineButton to='/contact' style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '0.5vw 0'}}>Podcast / YouTube covers</LineButton>
                                    <LineButton to='/contact' style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '0.5vw 0'}}>Website / e-learning ontwerp</LineButton>
                                </div>
                            </div>
                    </div>
                </PageTitle>
            </div>
            <div ref={intensiveRef}>
                <PageTitle title='design intensive' left='-30px'>
                    <div style={{ display: 'flex', margin: '7vw 0', position: 'relative', flexWrap: 'wrap'}}>
                        <div style={{ width: hasPhone ? '85vw' : hasTablet ? '35vw' : '20vw', marginLeft: hasPhone ? '10vw' : hasTablet ? '15vw' : '20vw', marginBottom: hasPhone && '70vw'}}>
                            <Typography variant='h2'>{intensiveRow['dienst']}</Typography>
                            <Typography variant='sub' style={{ margin: '0.5vw 0'}}>Vanaf {intensiveRow['prijs']} excl. btw</Typography>
                            <Typography>
                                Heb jij inmiddels heel wat ontwerp-taken verzameld die al even op je wachten? Maar waar je steeds maar niet goed aan toe komt? <br/><br/>
                                Of heeft je nieuwe branding óf een ander design project veel haast? <br/><br/>
                                In een Design Intensive werken we (minimaal)  één dag samen aan jouw merk. Dit kan bij jou op locatie óf op afstand. In een korte tijd kunnen we, door de korte lijntjes, jouw design project snel afronden en jouw to-do lijstje eindelijk eens korter maken.
                            </Typography>
                            <Button link='/diensten/designintensive' style={{ margin: '2vw 0'}}>Bekijk meer informatie</Button>
                        </div>
                        <img src={intensiveVal} alt='Valerie' style={{ height: hasPhone ? '38vw' : hasTablet ? '18vw' : '16vw', width: hasPhone ? '38vw' : hasTablet ? '18vw' : '16vw', position: 'absolute', bottom: hasPhone ? '0vw' : hasTablet ? '3vw' : '-1vw', right: hasPhone ? '53vw' : hasTablet ? '25vw' : '27vw'}} />
                        <ImageArch src={intensive} alt='Design' width={hasPhone ? '38vw' : hasTablet ? '18vw' : '16vw'} style={{ position: 'absolute', right: hasPhone ? '5vw' : hasTablet ? '5vw' : '7vw', top: hasPhone ? '70vw' : '3vw'}} />    
                    </div>
                </PageTitle>
            </div>
            <RecenteProjecten {...props} />
            <Match {...props} />
            <LieveWoorden {...props} />
            <Shop {...props} />
            <Blog {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}