import axiosInstance from './axios';
import { GET_DOCUMENTS, GET_DOCUMENT, PUT_DOCUMENT, GET_ARTICLES, POST_DOCUMENT } from './types';
import { reportResponse } from './helpers';

// GET BOOKINGS
export const getDocuments = (type=null, subType=null) => async dispatch => {
    let url = `/api/documents/?`

    if (type) {
      url += `&type=${type}`
    }

    if (subType) {
      url += `&sub_type=${subType}`
    } 

    const res = await axiosInstance.get(url);
    dispatch({
      type: GET_DOCUMENTS,
      payload: res.data
    });
};

export const getDocument = (id) => async dispatch => {
  const res = await axiosInstance.get(`/api/documents/${id}/`)

  dispatch({
    type: GET_DOCUMENT,
    payload: res.data,
  })

  dispatch({
    type: GET_ARTICLES,
    payload: res.data.document_articles,
  })
}

// PUT DOCUMENT
export const putDocument = (document) => async dispatch => {
  const res = await axiosInstance.put(`/api/documents/${document.id}/`, document);
  
  return reportResponse(res, PUT_DOCUMENT, 'Document', dispatch);
};

// PUT DOCUMENT
export const postDocument = (document) => async dispatch => {
  const res = await axiosInstance.post(`/api/documents/`, document);
  
  return reportResponse(res, POST_DOCUMENT, 'Document', dispatch);
};