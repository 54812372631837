import React from 'react';
import { LieveWoorden, Match, Nieuwsgierig, Shop, Blog, Instagram } from '../../subpages';
import { Typography, Button, BulletPoint } from '../../components';

import boost1 from '../../assets/images/diensten/logoanimatie/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-logo-animatie.mp4';
import StartTextPhoto from './StartTextPhoto';
import { getCsvDienstRow } from '../../helpers/diensten';

export default function LogoAnimatie(props) {
    const dienstenCsv = props.dienstenCsv;

    const logoanimatieRow = getCsvDienstRow(dienstenCsv, 'logo animatie');

    return (
        <div>
            <StartTextPhoto
                video={boost1}
                title={logoanimatieRow['dienst']}
                subtitle={`Vanaf ${logoanimatieRow['prijs']} excl. btw`}
                {...props}
            >
                <Typography>Jouw uniek ontworpen logo in beweging brengen voor zowel social media als voor jouw website.<br/><br/></Typography>
                <BulletPoint>Inclusief 1 concept en 1 correctieronde.</BulletPoint>
                <BulletPoint>Aangeleverd als GIF en transparante video.</BulletPoint>
                <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')} style={{ marginTop: '1vw'}}>Plan een gratis kennismakingsgesprek in</Button>
            </StartTextPhoto>
            <Match {...props} />
            <LieveWoorden {...props} />
            <Shop {...props} />
            <Blog {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}