import React from 'react';
import { Blog, Instagram, LieveWoorden, Match, Nieuwsgierig, Shop } from '../../subpages';
import { Grid } from '@mui/material';
import { Typography, Button, PageTitle, Accordion, RotatingCircle, BulletPoint } from '../../components';
import { colors } from '../../assets/colors';

import boost1 from '../../assets/images/diensten/socialcontentboost/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-social-media-boost-1.jpg';
import boost2 from '../../assets/images/diensten/socialcontentboost/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-social-media-boost-2.jpg';
import boost3 from '../../assets/images/diensten/socialcontentboost/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-social-media-boost-3.jpg';

import { ReactComponent as Ontdekken } from '../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_ontdekken-creeren-ontwerpen.svg';
import StartTextPhoto from './StartTextPhoto';
import { getCsvDienstRow } from '../../helpers/diensten';

export default function SocialContentBoost(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    const dienstenCsv = props.dienstenCsv;

    const socialContentBoostRow = getCsvDienstRow(dienstenCsv, 'social content boost');
    const socialGrowRow = getCsvDienstRow(dienstenCsv, 'social grow');
    const socialImpactRow = getCsvDienstRow(dienstenCsv, 'social impact');

    return (
        <div>
            <StartTextPhoto
                image={boost1}
                title={socialContentBoostRow['dienst']}
                subtitle={`Vanaf ${socialContentBoostRow['prijs']} excl. btw per maand`}
                {...props}
            >
                <Typography>Consistent aanwezig zijn op een unieke manier, zonder elke week uren te besteden aan het uitzoeken van de goede afbeelding, de kleuren of het lettertype? Naast het maken van templates voor jouw social media, kan ik ook het visuele gedeelte van de social media uit handen nemen. Het voordeel hiervan is dat ik met je meedenk over de inhoud van jouw posts en stories, maar vooral dat ik ze op een unieke manier vormgeef die écht bij jou en jouw doelgroep past. Je hebt altijd voldoende content en kan goed vooruit plannen.</Typography>
                <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')} style={{ marginTop: hasPhone ? '3vw' : hasTablet ? '2vw' : '1vw'}}>Plan een gratis kennismakingsgesprek in</Button>
            </StartTextPhoto>
            <Grid container justifyContent='center' style={{ textAlign: 'center'}}>
                <Typography variant='h2'>je hebt keuze uit <br/>twee abonnementen</Typography>
            </Grid>
            <PageTitle title='Social content boost abonnementen' left='-105px'>
                <Grid container justifyContent='space-evenly' alignItems='flex-start'>
                    <div style={{ width: hasPhone ? '80vw' : hasTablet ? '35vw' : '27vw', margin: '3vw 0 8vw 0'}}>
                        <Typography variant='h2' >{socialGrowRow['dienst']}</Typography>
                        <Typography variant='sub' style={{ fontSize: hasPhone ? '10px' : '12px', marginBottom: hasPhone ? '4vw' : hasTablet ? '3vw' : '2vw'}}>{socialGrowRow['prijs']} excl. btw per maand</Typography>
                        <Typography variant='sub'>Wat krijg je?</Typography>
                        <BulletPoint>Ontwerp van 24 posts óf story’s per maand (zelf te  verdelen over posts of story’s), aangeleverd in bestandsformaat JPG.</BulletPoint>
                        <BulletPoint>Op basis van de reeds ontwikkelde branding (kleurenpallet, typografie, illustraties en patronen).</BulletPoint>
                        <BulletPoint>Op basis van aangeleverde tekstuele informatie en fotografie.</BulletPoint>
                        <BulletPoint>Elke maand een half uur overleg (digitaal).</BulletPoint>
                        <Typography style={{ marginTop: hasPhone ? '3vw' : hasTablet ? '1vw' : '0.5vw' }}>
                            Elke maand opzegbaar; minimaal 1 maand voor de start van de maand.
                        </Typography>
                        <Typography style={{ margin: hasPhone ? '2vw 0 4vw 0' : hasTablet ? '1.5vw 0' : '0.5vw 0 2vw 0' }}>
                            Bij het afsluiten van een abonnement van 
                            <Typography variant='sub' style={{ margin: '0', display: 'inline'}}> 6 maanden betaal je maar {socialGrowRow['prijs2']} excl. btw.</Typography> Betaling in 2 termijnen mogelijk.
                        </Typography>
                        <Accordion
                            mainText='wat moet ik zelf doen?' 
                            mainTextStyle={{ marginLeft: '-2px', color: colors.photo}} 
                            bulletPoints={
                                <div>
                                    <BulletPoint>Aanleveren tekstuele informatie én fotografie voor het ontwerpen van de posts en stories.</BulletPoint>
                                    <BulletPoint>Schrijven van de tekst voor de post.</BulletPoint>
                                    <BulletPoint>Posten!</BulletPoint>
                                </div>
                            }
                            detailTextStyle={{ margin: '0'}}
                            margin='0 0 2vw 0'
                        />
                        <Accordion
                            mainText='Bijkomende kosten' 
                            mainTextStyle={{ marginLeft: '-2px', color: colors.photo}} 
                            bulletPoints={
                                <div>
                                    <BulletPoint>Licenties voor Fonts en Mock-ups, indien van toepassing.</BulletPoint>
                                </div>
                            }
                            detailTextStyle={{ margin: '0'}}
                        />
                    </div>
                    <div style={{ width: hasPhone ? '80vw' : hasTablet ? '35vw' : '27vw', margin: '3vw 0 8vw 0'}}>
                        <Typography variant='h2' >{socialImpactRow['dienst']}</Typography>
                        <Typography variant='sub' style={{ fontSize: hasPhone ? '10px' : '12px', marginBottom: hasPhone ? '4vw' : hasTablet ? '3vw' : '2vw'}}>{socialImpactRow['prijs']} excl. btw per maand</Typography>
                        <Typography variant='sub'>Wat krijg je?</Typography>
                        <BulletPoint>Ontwerp van 36 posts óf story’s per maand (zelf te  verdelen over posts of story’s), aangeleverd in bestandsformaat JPG.</BulletPoint>
                        <BulletPoint>Op basis van de reeds ontwikkelde branding (kleurenpallet, typografie, illustraties en patronen).</BulletPoint>
                        <BulletPoint>Op basis van aangeleverde tekstuele informatie en fotografie.</BulletPoint>
                        <BulletPoint>Elke maand drie kwartier overleg (digitaal).</BulletPoint>
                        <Typography style={{ marginTop: hasPhone ? '3vw' : hasTablet ? '1vw' : '0.5vw' }}>
                            Elke maand opzegbaar; minimaal 1 maand voor de start van de maand.
                        </Typography>
                        <Typography style={{ margin: hasPhone ? '2vw 0 4vw 0' : hasTablet ? '1.5vw 0' : '0.5vw 0 2vw 0' }}>
                            Bij het afsluiten van een abonnement van 
                            <Typography variant='sub' style={{ margin: '0', display: 'inline'}}> 6 maanden betaal je maar {socialImpactRow['prijs2']} excl. btw.</Typography> Betaling in 2 termijnen mogelijk.
                        </Typography>
                        <Accordion
                            mainText='wat moet ik zelf doen?' 
                            mainTextStyle={{ marginLeft: '-2px', color: colors.photo}} 
                            bulletPoints={
                                <div>
                                    <BulletPoint>Aanleveren tekstuele informatie én fotografie voor het ontwerpen van de posts en stories.</BulletPoint>
                                    <BulletPoint>Schrijven van de tekst voor de post.</BulletPoint>
                                    <BulletPoint>Posten!</BulletPoint>
                                </div>
                            }
                            detailTextStyle={{ margin: '0'}}
                            margin='0 0 2vw 0'
                        />
                        <Accordion
                            mainText='Bijkomende kosten' 
                            mainTextStyle={{ marginLeft: '-2px', color: colors.photo}} 
                            bulletPoints={
                                <div>
                                    <BulletPoint>Licenties voor Fonts en Mock-ups, indien van toepassing.</BulletPoint>
                                </div>
                            }
                            detailTextStyle={{ margin: '0'}}
                        />
                    </div>
                </Grid>
            </PageTitle>
            <div style={{ position: 'relative', margin: hasPhone ? '5vw 0 20vw 0' : hasTablet ? '0 0 10vw 0' : '0 0 4vw 0'}}>
                {!hasPhone && <RotatingCircle style={{ position: 'absolute', left: hasTablet ? '7vw' : '10vw', top: hasTablet ? '0' : '-2vw'}}>
                    <Ontdekken style={{ fill: colors.illustration, width: hasTablet ? '13vw' : '10vw' }} />
                </RotatingCircle>}
                <Grid container justifyContent='center' style={{ textAlign: 'center'}}>
                    <div style={{ width: hasPhone ? '80vw' : hasTablet ? '50vw' : '30vw'}}>
                        <Typography variant='h2'>wat brengt het jou en<br/> jouw bedrijf?</Typography>
                        <Typography style={{ marginBottom: '10px'}}>
                            Geen stress over wat je nu weer moet plaatsen.
                        </Typography>
                        <Typography style={{ marginBottom: '10px'}}>
                            Niet meer uren prullen om te zorgen dat jouw posts er perfect uitzien én bij jouw huisstijl passen.
                        </Typography>
                        <Typography style={{ marginBottom: '10px'}}>
                            Een spar-moment, waardoor je ook over de inhoud kan brainstormen.
                        </Typography>
                        <Typography>
                            Consistent aanwezig zijn online.
                        </Typography>
                        <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')} style={{ marginTop: hasPhone ? '3vw' : hasTablet ? '2vw' : '1vw'}}>Plan een gratis kennismakingsgesprek in</Button>
                    </div>
                </Grid>
            </div>
            <PageTitle title='Werkwijze social content boost' left='-88px'>
                <div style={{ position: 'relative', margin: hasPhone ? '0 0 75vw 0' : '5vw 0'}}>
                    <div style={{ width: hasPhone ? '80vw' : hasTablet ? '35vw' : '24vw', marginLeft: hasPhone ? '10vw' : hasTablet ? '10vw' : '20vw'}}>
                        <Typography variant='h2'>werkwijze</Typography>
                        <Typography style={{ marginBottom: '10px'}}>
                            Twee weken voor het starten van de nieuwe maand: Jij levert tekstuele informatie én fotografie aan, zodat we deze tijdens een video-gesprek kunnen doornemen.
                        </Typography>
                        <Typography style={{ marginBottom: '10px'}}>
                            Je ontvangt een ontwerp binnen 1 week na het video-gesprek.
                        </Typography>
                        <Typography style={{ marginBottom: '10px'}}>
                            Je levert binnen 3 dagen feedback aan.
                        </Typography>
                        <Typography style={{ marginBottom: '10px'}}>
                            Je ontvangt JPG-bestanden in de laatste week voor de start van de nieuwe maand.
                        </Typography>
                        <Accordion
                            mainText='Bijvoorbeeld' 
                            mainTextStyle={{ marginLeft: '-2px', color: colors.photo}} 
                            detailText='Vóór 15 september lever jij tekstuele informatie én fotografie aan.<br/>
                            15 september: Video-gesprek.<br/>
                            Vóór 22 september ontvang jij de ontwerpen via mail.<br/>
                            Jij reageert binnen 3 dagen met feedback.<br/>
                            Vóór 29 september ontvang jij de JPG-bestanden per mail.' 
                            detailTextStyle={{ margin: '0 0 10px 0'}}
                        />
                    </div>
                    <img src={boost2} alt='boost design' style={{ position: 'absolute', width: hasPhone ? '37vw' : hasTablet ? '18vw' : '15vw', top: !(hasPhone || hasTablet) && '7vw', bottom: hasPhone ? '-60vw' : hasTablet && '3vw', right: hasPhone ? '52vw' : '27vw', zIndex: '998' }} />
                    <img src={boost3} alt='boost design' style={{ position: 'absolute', width: hasPhone ? '37vw' : hasTablet ? '18vw' : '15vw', top: !(hasPhone || hasTablet) && '0', bottom: hasPhone ? '-45vw' : hasTablet && '12vw', right: hasTablet ? '7vw' : '10vw', zIndex: '999' }} />
                </div>
            </PageTitle>
            <Match {...props} />
            <LieveWoorden {...props} />
            <Shop {...props} />
            <Blog {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}