import moment from 'moment';
import 'moment/locale/nl';

// expects a new Date object
export function getMonth(date) {
    const MONTHS = ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"];

    return MONTHS[date.getMonth()];
}

export function getYear(date) {
    return 1900 + date.getYear();
}

export function getDateTime(dateTime) {
    moment.locale('nl');
    return moment(new Date(dateTime)).format('LLL');
}