import axiosInstance from './axios';
import { GET_PROJECT_TYPES, POST_PROJECT_TYPE } from './types';
import { reportResponse } from './helpers';

// GET PROJECT TYPE
export const getProjectTypes = () => async dispatch => {
    const res = await axiosInstance.get(`/api/project_types/`);
    
    dispatch({
      type: GET_PROJECT_TYPES,
      payload: res.data
    });
};

// POST PROJECT TYPE
export const postProjectType = (projectType) => async dispatch => {
  const res = await axiosInstance.post(`/api/project_types/`, projectType);
  
  return reportResponse(res, POST_PROJECT_TYPE, 'Project type', dispatch);
};