import React from 'react';
import { LieveWoorden, Match, Nieuwsgierig, Shop, Blog, Instagram } from '../../subpages';
import { Button, BulletPoint } from '../../components';

import boost1 from '../../assets/images/diensten/emailhandtekening/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-e-mailhandtekening-1.jpg';
import StartTextPhoto from './StartTextPhoto';
import { getCsvDienstRow } from '../../helpers/diensten';

export default function EmailHandtekening(props) {
    const emailHandtekeningRow = getCsvDienstRow(props.dienstenCsv, 'e-mailhandtekening');

    return (
        <div>
            <StartTextPhoto
                image={boost1}
                title={emailHandtekeningRow['dienst']}
                subtitle={`Vanaf ${emailHandtekeningRow['prijs']} excl. btw`}
                {...props}
            >
                <BulletPoint>Inclusief 1 schets en 1 correctieronde.</BulletPoint>
                <BulletPoint>Inclusief bouw van een code voor gebruik.</BulletPoint>
                <BulletPoint>Pdf waarin staat hoe je deze toe kan voegen.</BulletPoint>
                <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')} style={{ marginTop: '1vw'}}>Plan een gratis kennismakingsgesprek in</Button>
            </StartTextPhoto>
            <Match {...props} />
            <LieveWoorden {...props}  />
            <Shop {...props} />
            <Blog {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}