import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';

const H1 = styled.h1`
    font-family: VM;
    text-transform: lowercase;
    font-weight: 300;
    color: ${colors.title};
    background: transparent;
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 0;
    white-space: pre-line;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 22px;
        margin-bottom: 10px;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 22px;
        margin-bottom: 10px;
    }
`;

const SubTitle = styled.div`
    font-family: Mulish;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    color: ${colors.photo};
    white-space: pre-line;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 11px;
        letter-spacing: 1.1px;
        margin: 0;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 11px;
        letter-spacing: 1.1px;
        margin: 0;
    }
`;

const Paragraph = styled.div`
    font-family: Chivo;
    font-weight: 300;
    color: ${colors.black};
    margin-bottom: 10px;
    line-height: 24px;
    white-space: pre-line;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 13px;
        line-height: 1.38;
        margin: 0;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 13px;
        line-height: 1.43;
        margin: 0;
    }
`;

export default function Typography(props) {
    const { variant } = props;

    if (variant === 'h1') {
        return <H1 style={props.style} {...props} >{props.children}</H1>
    } else if (variant === 'h2') {
        return <H1 as='h2' style={props.style} {...props} >{props.children}</H1>
    } else if (variant === 'sub') {
        return <SubTitle style={props.style} {...props}>{props.children}</SubTitle>
    } else {
        return <Paragraph style={props.style} {...props}>{props.children}</Paragraph>
    }
}