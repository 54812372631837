import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';
import { TextField, Typography, useInput, Checkbox, Button, ImageArch } from '../../components';
import { postMailerLite } from '../../actions/wachtlijst';


import boog from '../../assets/images/gratis/Studio-Valerie-Maas-grafisch-ontwerp-shop-ebook-droomklanten-boog.jpg';
import vierkant from '../../assets/images/gratis/Studio-Valerie-Maas-grafisch-ontwerp-shop-ebook-droomklanten-vierkant.jpg';

const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;

    &:hover {
        cursor: pointer;
        font-weight: 500;
    }
`;

export default function Droomklanten(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    const { value: valueName, bind: bindName } = useInput('');
    const { value: valueEmail, bind: bindEmail } = useInput('');

    const [ voorwaardenError, setVoorwaardenError ] = useState(false);
    const [ isVoorwaardenChecked, setVoorwaardenChecked ] = useState(false);
    const [ isWantsNewsChecked, setWantsNewsChecked ] = useState(false);
    const [ generalError, setGeneralError ] = useState(false);

    const textFieldStyle = { width: hasPhone ? '80vw' : hasTablet ? '35vw' : '20vw', margin: hasPhone ? '0 0 3vw 0' : hasTablet ? '1vw 0 1vw 0' : '0 0 1vw 0'};
    const checkBoxStyle = { maxWidth: hasPhone ? 'inherit' : hasTablet ? '30vw' : '20.5vw', margin: hasPhone ? '0 0 3vw 0' : '0 0 0.5vw 0' };

    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = (evt) => {
        evt.preventDefault();

        if (!isVoorwaardenChecked) {
            setVoorwaardenError(true)
        } else {
            setVoorwaardenError(false)

            const formValues = {
                'email': valueEmail,
                'name': valueName,
                'wantsNewsletter': isWantsNewsChecked,
                'groupName': 'Droomklanten',
            }

            dispatch(postMailerLite(formValues)).then(e => {
                if (e.constructor === Object && 'success' in e) {
                    setGeneralError(false);
                    history.push('/contact/bedankt');
                } else {
                    setGeneralError(true);
                }
            });
        }
    }

    return (
        <form onSubmit={handleSubmit} autoComplete='on' style={{ position: hasPhone ? 'flex' : 'relative', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{ display: 'flex', flexDirection: 'column', width: hasPhone ? '80vw' : hasTablet ? '40vw' : '30vw', margin: hasPhone ? '0 10vw 0 10vw' : hasTablet ? '0 0 0 7.5vw' : '0 0 0 17vw', justifyContent: 'center' }}>
                <Typography variant='sub'>Download het e-book</Typography>
                <Typography variant='h1'>Hoe herken je jouw droomklanten?</Typography>
                <Typography style={{ margin: hasPhone ? '0 0 3vw 0' : '0 0 1vw 0'}}>Vul hieronder je naam en e-mailadres in <br/> en ontvang het e-book!</Typography>
                <TextField
                    placeholder='Naam'
                    style={textFieldStyle}
                    required
                    {...bindName}
                />
                <TextField
                    placeholder='E-mailadres'
                    style={{ width: hasPhone ? '80vw' : hasTablet ? '35vw' : '20vw', margin: hasPhone ? '0 0 5vw 0' : hasTablet ? '0 0 3vw 0' : '0 0 2vw 0'}}
                    required
                    {...bindEmail}
                />
                <Checkbox
                    onClick={e => setVoorwaardenChecked(!isVoorwaardenChecked)}
                    isChecked={isVoorwaardenChecked}
                    required
                    style={checkBoxStyle}
                >
                    Ik heb de <StyledLink style={{ textDecoration: 'none', color: 'inherit' }} target='_blank' to='/voorwaarden'>algemene voorwaarden</StyledLink> van de site gelezen en ga hiermee akkoord
                </Checkbox>
                {voorwaardenError && <Typography style={{ color: 'red' }}>Je moet de voorwaarden accepteren om je aan te melden!</Typography>}
                <Checkbox
                    onClick={e => setWantsNewsChecked(!isWantsNewsChecked)}
                    isChecked={isWantsNewsChecked}
                    style={checkBoxStyle}
                >
                    Ik wil op de hoogte gehouden worden van ontwikkelingen via mail!
                </Checkbox>
                <Button type='submit' style={{ margin: hasPhone ? '2vw 0 10vw 0' : '1vw 0 5vw 0', maxWidth: hasPhone ? '60vw' : hasTablet ? '22vw' : '15vw'}}>Download het e-book</Button>
                {generalError && <Typography style={{ color: 'red' }}>Er is iets fout gegaan..</Typography>}
            </div>
            <div style={{ position: hasPhone ? 'relative' : 'absolute', display: hasPhone ? 'flex' : 'default', right: hasPhone ? '0' : hasTablet ? '7.5vw' : '10vw', top: '0', width: hasPhone ? '100vw' : hasTablet ? '38vw' : '32vw', height: hasPhone ? 'default' : hasTablet ? '32vw' : '28vw', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', gap: '10vw'}}>
                <ImageArch src={boog} alt='Foto van laptop met voorbeeld droomklanten' width={hasPhone ? '75vw' : hasTablet ? '17.5vw' : '15vw'} style={{ position: hasPhone ? 'relative' : 'absolute', right: '0', top: '0'}} />
                <img src={vierkant} alt='Foto van voorbeeld droomklanten' style={{ position: hasPhone ? 'relative' : 'absolute', width: hasPhone ? '75vw' : hasTablet ? '17.5vw' : '15vw', left: '0', bottom: '0'}} />
            </div>
        </form>
    )
}