import axiosInstance from './axios';
import { GET_AUTHENTICATED } from './types';
import { setCookie, getCookie } from './cookies';

// GET BOOKINGS
export const getAuthenticated = () => async dispatch => {
    try {
        const res = await axiosInstance.get(`/api/authenticated/`);

        if (res.data.message === 'OK') {
            // localStorage.setItem('isAuthenticated', true);
            if (getCookie('consent')) {
                setCookie('isAuthenticated', true, 30);
                dispatch({
                    type: GET_AUTHENTICATED,
                    payload: { 'status': true }
                });
            }
            
        }
    } catch (error) {
        // localStorage.setItem('isAuthenticated', false);
        setCookie('isAuthenticated', false, 100);
        dispatch({
            type: GET_AUTHENTICATED,
            payload: { 'status': false }
        });
    }
};