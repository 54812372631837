import axiosInstance from './axios';
import { getFilters } from './helpers';
import { GET_BLOG, GET_BLOGS, PUT_BLOG, POST_BLOG, DELETE_BLOG } from './types';
import { reportResponse } from './helpers';
import { getCookie } from './cookies';

const config = {
  headers: {
    'Authorization': getCookie('access_token') ? "Bearer " + getCookie('access_token') : null,
    'Content-Type': 'application/json',
  }
}

const getFormData = (object, blobs, fileNames) => Object.entries(object).reduce((formData, [ key, val ]) => {
  if (key === 'main_image' || key === 'oval_image') {
    formData.append(key, blobs[key], fileNames[key]);
  } else if (Array.isArray(val)) {
    val.forEach(v => formData.append(key, v))
  } else {
    formData.append(key, val)
  }
  
  return formData;
}, new FormData());

async function processGrids(grids) {
  let newGrids = [];
  for (let i = 0; i < grids.length; i++) {
    let currGrid = grids[i];
    if (currGrid.contents.length > 0) {
      let contents = currGrid.contents;
      let newContents = [];
      for (let j=0; j < contents.length; j++) {
        let newContent = contents[j];
        newContent.content = await processLink(contents[j].content);
        newContents.push(newContent);
      }
      currGrid.contents = newContents;
    }
    newGrids.push(currGrid);
  }
  return newGrids
}

async function processLink(content) {
  if (content.includes('<Link') || content.includes('<a')) {
      let linkType = null;
      if (content.includes('<Link')) {
          linkType = 'Link'
      } else if (content.includes('<a')) {
          linkType = 'a'
      }

      const leftString = '<' + linkType;
      const leftIndex = content.indexOf(leftString);
      const leftContent = [content.slice(0, leftIndex), content.slice(leftIndex + leftString.length)];

      const rightString = '</' + linkType + '>';
      const rightIndex = leftContent[1].indexOf(rightString);
      const rightContent = [leftContent[1].slice(0, rightIndex), leftContent[1].slice(rightIndex + rightString.length)];

      let urlContent = rightContent[0].split('>');

      let url = urlContent[0].split('=')[1];
      let text = urlContent[1];

      let newLinks = [await processLink(leftContent[0]), { url: url, text: text, linkType: linkType }, await processLink(rightContent[1])]

      return newLinks.reduce((a, b) => a.concat(b), []);
  }
  return [content];
}

// GET BLOG
export const getBlog = (id) => async dispatch => {
  let res = await axiosInstance.get(`/api/blog/${id}/`);

  res.data.grids = await processGrids(res.data.grids);

  dispatch({
    type: GET_BLOG,
    payload: res,
  });
};

// GET REVIEWS
export const getBlogs = (filters) => async dispatch => {
  const filter = getFilters(filters);

  let res = await axiosInstance.get(`/api/blog/?${filter}`);

  if (res && res.data.results.length === 1) {
    res.data.results[0].grids = await processGrids(res.data.results[0].grids);
  }

  return reportResponse(res, GET_BLOGS, 'Blogs', dispatch);
};


// PUT BLOG
export const putBlog = (blog, blobs, fileNames) => async dispatch => {
  const res = await axiosInstance.put(`/api/blogs/${blog.id}/`, getFormData(blog, blobs, fileNames), config);
  
  return reportResponse(res, PUT_BLOG, 'Blog', dispatch);
};

// POST BLOG
export const postBlog = (blog, blobs, fileNames) => async dispatch => {
  const res = await axiosInstance.post(`/api/blogs/`, getFormData(blog, blobs, fileNames), config);

  return reportResponse(res, POST_BLOG, 'Blog', dispatch);
};

export const deleteBlog = (id) => async dispatch => {
  const res = await axiosInstance.delete(`/api/blogs/${id}/`);

  return reportResponse(res, DELETE_BLOG, 'Blog', dispatch);
}