import React from 'react';

import { Typography, PageTitle, LineButton } from "../../components";
import { Instagram } from '../../subpages';
import { Grid } from "@mui/material";

import Document from './Document';

export default function Voorwaarden(props) {
    const documentName = 'Algemene voorwaarden'

    return (
        <div>
            <PageTitle title={documentName} left='-60px'>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography variant='h2'>{documentName}</Typography>
                    <Document documentName={documentName} {...props} />
                    <LineButton href='/files/VM_Algemene voorwaarden.pdf' download={true}>Algemene voorwaarden downloaden</LineButton>
                </Grid>
            </PageTitle>
            <Instagram {...props} />
        </div>
    )
}