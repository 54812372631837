// import axios from 'axios';
import { POST_CALENDLY_INVITEE, POST_CALENDLY_EVENT, GET_PAYMENT_STATUS, POST_ADVENT } from './types';
import { reportResponse } from './helpers';
import axiosInstance from './axios';

export const postCalendlyInvitee = (formValues) => async dispatch => {
    const res = await axiosInstance.post('api/calendly_invitees/', { ...formValues });
    return reportResponse(res, POST_CALENDLY_INVITEE, 'Calendly invitee', dispatch);
};

export const postCalendlyEvent = (formValues) => async dispatch => {
    const res = await axiosInstance.post('api/calendly_events/', { ...formValues });
    return reportResponse(res, POST_CALENDLY_EVENT, 'Calendly event', dispatch);
}

export const postAdvent = (formValues) => async dispatch => {
    const res = await axiosInstance.post('api/advents/', { ...formValues });
    return reportResponse(res, POST_ADVENT, 'Advent', dispatch);
}

export const getPaymentStatus = (event_uri) => async dispatch => {
    const res = await axiosInstance.get('mollie_payment_status/', {
        params: {
            event_uri: event_uri
        }
    });
    return reportResponse(res, GET_PAYMENT_STATUS, 'Betalingstatus', dispatch);
}