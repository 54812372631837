import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';

const StyledTextField = styled.input`
    background-color: ${colors.background};
    font-family: Chivo;
    font-weight: 300;
    color: ${colors.black};
    font-size: 16px;

    border: 1px solid ${colors.title};
    border-radius: 18px;

    padding-left: 10px;
    margin-right: 10px;

    min-height: 30px;

    ::placeholder {
        font-family: Mulish;
        font-weight: 400;
        letter-spacing: 1.4px;
        font-size: 14px;
        text-transform: uppercase;
        color: ${colors.title};
    }

    &:focus {
        outline: none;
        font-family: Chivo;
        text-transform: default;
        border: 2px solid ${colors.title};
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 13px;
        min-height: 25px;
        min-width: min-content;

        ::placeholder {
            font-size: 12px;
            letter-spacing: 1.1px;
        }
    } 

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 13px;
        min-height: 25px;
        min-width: min-content;

        ::placeholder {
            font-size: 12px;
            letter-spacing: 1.1px;
        }
    }
`;

export default function TextField(props) {
    return (
        <StyledTextField
            value={props.value} 
            placeholder={props.required ? props.placeholder.concat("*") : props.placeholder}
            onChange={props.onChange}
            style={props.style}
            required={props.required}
            type={props.type ? props.type : 'text'}
            id={props.id}
        />
    )
}