import axiosInstance from './axios';
import { GET_BLOG_OVERVIEW } from './types';

const getTypes = (blogs) => {
  const types = blogs.map(p => p.types).flat();
  return types;
}

const getNextPageNmb = (blogObject) => {
  const nextUrl = blogObject.next;
  if (nextUrl) {
    return Number(nextUrl.split('page=')[1][0]);
  }
  return null;
}

// GET PORTFOLIO OVERVIEW
export const getBlogOverview = (filters) => async dispatch => {
    let filter = ''

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
          if (value) {
            if (value instanceof Array) {
              for (let i = 1; i < value.length; i++) {
                filter = filter.concat(`&${key}=${value[i]}`)
              }
            } else {
              filter = filter.concat(`&${key}=${value}`)
            }
          }
      }
    }
    
    const res = await axiosInstance.get(`/api/blog_overview/?${filter}`);
    const types = getTypes(res.data.results);
    const nextPage = getNextPageNmb(res.data);

    dispatch({
      type: GET_BLOG_OVERVIEW,
      payload: res.data,
      payloadTypes: types,
      payloadNextPage: nextPage,
    });
};