import { Box, Button, TextField, Typography } from '@mui/material';
import { Dialog } from '../../../components';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
// import DateAdapter from '@mui/lab/AdapterMoment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentArticle, postDocumentArticle, putDocumentArticle, deleteDocumentArticle } from '../../../actions/articles';
import { getDocuments } from '../../../actions/documents';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const Field = styled.div`
    display: flex;
    align-items: center;
    gap: 5vw;
    margin-bottom: 1vw;
`;

export default function Article(props) {
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();

    const post = params.articleId === 'new';

    useEffect(() => {
        !post && dispatch(getDocumentArticle(params.articleId))
    }, [post, params.articleId, dispatch])

    const articles = useSelector((state) => state.articles).articles || [];

    let article;
    if (articles.length > 0 && !post) {
        article = articles.filter(a => Number(a.id) === Number(params.articleId))[0];
    } else {
        article = {
            'title': '',
            'article_number': null,
            'content': '',
        };
    }

    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ titleValue, setTitleValue ] = useState((article && article.title) || '');
    const [ titleErrorValue, setTitleErrorValue ] = useState('');
    const [ numberValue, setNumberValue ] = useState((article && article.article_number) || null);
    const [ contentValue, setContentValue ] = useState((article && article.content) || '');
    const [ contentErrorValue, setContentErrorValue ] = useState('');
    const [ dateValue, setDateValue ] = useState((article && article.created) || '');

    const deleteArticle = () => {
        setDialogOpen(false);
        dispatch(deleteDocumentArticle(params.articleId)).then(history.push(`/admin/documents/${params.documentId}`));
    }
    
    const handleSubmit = () => {
        let error = false;
        // check validaty of fields
        if (titleValue === '') {
            setTitleErrorValue('Veld verplicht')
            error = true;
        } else {
            setTitleErrorValue('')
        }

        if (contentValue === '') {
            setContentErrorValue('Veld verplicht')
            error = true;
        } else {
            setContentErrorValue('')
        }

        let newArticle = {
            'title': titleValue,
            'article_number': numberValue,
            'content': contentValue,
            'created': dateValue,
        }

        if (!error) {
            if (!post) {
                newArticle['id'] = article.id;
                newArticle['document'] = article.document;
                dispatch(putDocumentArticle(newArticle)).then(dispatch(getDocuments()))
            } else {
                newArticle['document'] = params.documentId;
                dispatch(postDocumentArticle(newArticle)).then(dispatch(getDocuments())).then(history.push(`/admin/documents/${params.documentId}`))
            }
        }
    }

    if (article) {
        return (
            <Box component='form' validate>
                <Field>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Titel</Typography>
                    <TextField
                        style={{ width: '50vw'}}
                        required
                        id="outlined-required"
                        value={titleValue}
                        error={!!titleErrorValue}
                        helperText={titleErrorValue}
                        onChange={(e) => setTitleValue(e.target.value)}
                        label='Titel'
                    />
                </Field>
                <Field>
                    <div style={{ width: '20vw'}}>
                        <Typography fontWeight='500'>Artikel nummer</Typography>
                        <Typography>Vul dit in als je het artikel wil laten beginnen met "Artikel X", waar X het nummer is.</Typography>
                    </div>
                    <TextField 
                        style={{ width: '50vw'}}
                        id="outlined-required"
                        value={numberValue}
                        onChange={(e) => setNumberValue(e.target.value)}
                        label='Artikel nummer'
                    />
                </Field>
                <Field>
                    <div style={{ width: '20vw'}}>
                        <Typography fontWeight='500' style={{ width: '20vw'}}>Inhoud</Typography>
                        <Typography>{'Vul hier de tekst in, inclusief de enters. Als je een link in de tekst wil, omring dan het klikwoord bijv. "klik hier" met <Link to=/diensten/branding>klik hier</Link>.'}</Typography>
                    </div>
                    <TextField 
                        style={{ width: '50vw'}}
                        id="outlined-required"
                        value={contentValue}
                        error={!!contentErrorValue}
                        helperText={contentErrorValue}
                        onChange={(e) => setContentValue(e.target.value)}
                        required
                        multiline
                        label='Inhoud'
                    />
                </Field>
                <Field>
                    <div style={{ width: '20vw'}}>
                        <Typography fontWeight='500' style={{ width: '20vw'}}>Aanmaakdatum</Typography>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="Aanmaakdatum"
                            value={dayjs(dateValue)}
                            onChange={(e) => setDateValue(e.format('YYYY-MM-DDTHH:mm:ss'))}
                            renderInput={(params) => <TextField {...params} />}
                            ampm={false}
                            required
                        />
                    </LocalizationProvider>
                </Field>
                <div style={{ display: 'flex', width: '75vw', justifyContent: 'right', gap: '1vw'}}>
                    {!post && <Button onClick={() => setDialogOpen(true)} variant='contained' startIcon={<DeleteIcon/>}>Verwijderen</Button>}
                    <Button onClick={handleSubmit} variant='contained' startIcon={<EditIcon />}>{post ? 'Aanmaken' : 'Aanpassen'}</Button>
                </div>
                <Dialog
                    open={dialogOpen}
                    handleCancel={() => setDialogOpen(false)}
                    handleOk={deleteArticle}
                    title={'Artikel verwijderen'}
                    content={'Weet je zeker dat je dit artikel wil verwijderen?'}
                />
            </Box>
        )
    } else {
        return null;
    }
}