import React from 'react';
import { colors } from '../../assets/colors';
import { Grid } from '@mui/material';
import { Instagram, LieveWoorden, Match, Nieuwsgierig, RecenteProjecten, Werkwijze, Blog, Shop } from '../../subpages';
import { Button, ImageArch, LineButton, PageTitle, Typography } from '../../components';

import portret from '../../assets/images/home/Studio-Valerie-Maas-grafisch-ontwerp-home-portret.jpg';
import moodboard from '../../assets/images/home/Studio-Valerie-Maas-grafisch-ontwerp-home-moodboard-branding.jpg';
import pressed from '../../assets/images/home/Studio-Valerie-Maas-grafisch-ontwerp-home-logo-pln.jpg';
import over from '../../assets/images/home/Studio-Valerie-Maas-grafisch-ontwerp-home-portret-over.jpg';
import sticker from '../../assets/images/home/VM_Logo_Cirkel_Diap.png';

import { ReactComponent as Monstera } from '../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_monstera.svg';

import Diensten from './Diensten';

export default function Home(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    return (
        <div>
            <Grid container justifyContent='space-evenly' alignItems='flex-start'>
                <div style={{ width: hasPhone ? '50vw' : hasTablet ? '25vw' : '18vw', textAlign: 'center', margin: '1vw 0 8vw 0'}}>
                    <Typography variant='h1' style={{ margin: hasPhone ? '3vw 0' : hasTablet ? '1vw 0' : '1vw 0'}}>studio valerie maas</Typography>
                    <Typography variant='sub' style={{ margin: hasPhone ? '4vw 0' : hasTablet ? '2vw 0' : '1vw 0', lineHeight: hasPhone ? '4vw' : hasTablet ? '2vw' : '1.3vw', fontWeight: '600'}}>Strategische <br/> branding studio</Typography>
                    <Typography>Voor vrouwelijke ondernemers die impact willen maken met hun bedrijf, willen doen waar zij blij van worden én hun droomleven willen leiden</Typography>
                    <Button link='/diensten' style={{ margin: hasPhone ? '5vw 0' : hasTablet ? '3vw 0' : '1vw 0 2vw 0'}}>Bekijk de diensten</Button>
                </div>
                <div style={{ width: hasPhone ? '80vw' : 'default', height: hasPhone ? '100vw' : 'default', position: 'relative' }}>
                    <ImageArch src={portret} alt='portret Valerie' width={hasPhone ? '35vw' : hasTablet ? '15vw' : '13vw'} />
                    <img src={moodboard} alt='moodboard' style={{ position: 'absolute', width: hasPhone ? '35vw' : hasTablet ? '15vw' : '13vw', top: hasPhone ? '30vw' : hasTablet ? '13vw' : '9vw', right: hasPhone ? '0' : hasTablet ? '-17vw' : '-15vw', zIndex: '998' }} />
                    <img src={pressed} alt='logo design' style={{ position: 'absolute', width: hasPhone ? '35vw' : hasTablet ? '15vw' : '13vw', top: hasPhone ? '55vw' : hasTablet ? '23vw' : '18vw', left: hasPhone ? '15vw' : hasTablet ? '5vw' : '4vw', zIndex: '999' }} />
                </div>
            </Grid>
            <PageTitle title='kijk eens rond' left='-25px'>
                <div style={{ position: 'relative', margin: hasPhone ? '15vw 0 35vw 0' : hasTablet ? '10vw 0 15vw 0' : '4vw 0'}}>
                    <Grid container direction='column' justifyContent='center' alignItems='center'>
                        <LineButton to='/diensten/strategie' margin={hasPhone ? '2vw 0' : '1vw 0'}>Strategie</LineButton>
                        <LineButton to='/diensten/branding' margin={hasPhone ? '2vw 0' : '1vw 0'}>Branding</LineButton>
                        <LineButton to='/over' margin={hasPhone ? '2vw 0' : '1vw 0'}>Over</LineButton>
                        <LineButton to='/werkwijze' margin={hasPhone ? '2vw 0' : '1vw 0'}>Werkwijze</LineButton>
                        <LineButton to='/blog' margin={hasPhone ? '2vw 0' : '1vw 0'}>Blog</LineButton>
                    </Grid>
                    {!hasPhone && <img src={sticker} alt='sticker' style={{ position: 'absolute', left: hasPhone ? '5vw' : '10vw', top: hasPhone ? '-18vw' : hasTablet ? '-8vw' : '-10vw', width: hasPhone ? '20vw' : hasTablet ? '11vw' : '10vw'}} />}
                    <Monstera style={{ fill: colors.illustration, position: 'absolute', right: hasPhone ? '5vw' : '8vw', bottom: hasPhone ? '-18vw' : hasTablet ? '-8vw' : '-5vw', width: hasPhone ? '20vw' : hasTablet ? '11vw' : '8vw'}} />
                </div>
            </PageTitle>
            <Diensten style={{ margin: '5vw 0 6vw 0' }} {...props} />
            <PageTitle title='over Valerie' left='-18px'>
                <div style={{ position: 'relative', margin: hasPhone ? '10vw 0' : hasTablet ? '7vw 0' : '5vw 0'}}>
                    <Grid container direction={hasPhone ? 'row-reverse': 'row'} justifyContent='space-evenly' alignItems={hasTablet ? 'flex-start' : 'flex-start'}>
                        {!hasPhone && <ImageArch src={over} alt='portret Valerie' width={hasTablet ? '33vw' : '25vw'} style={{ margin: hasTablet && '1vw 0 0 0'}} />}
                        <div style={{ width: hasPhone ? '80vw' : hasTablet ? '35vw' : '24vw'}}>
                            <Typography variant='h1'>studio valerie maas</Typography>
                            <Typography>
                            Binnen mijn <span style={{ fontStyle: 'italic' }}>brand design studio</span> ontwerp ik merken die voor een échte connectie zorgen met jouw droomklant. Merken die niet alleen mooi zijn, maar die jouw klant écht aanspreken. Waar ze zich in thuis voelen. <br/><br/>
                            Ik wil je, samen met mijn team, helpen meer impact te maken met jouw business door middel van strategische branding én art direction. We creëren magie from the inside out, startend bij de strategie, doorgevoerd in jouw uitstraling. Wil jij net als ik blijven doen waar je blij van wordt én je droomleven leiden?<br/><br/>
                            Creatief, Betrouwbaar, Open, Duidelijk, Persoonlijk, Passievol, Warm, Doortastend, Benaderbaar, Gestructureerd, Krachtig, Intuïtief en Confronterend. Dat zijn de woorden die Studio Valerie Maas omschrijven volgens onze klanten.
                            </Typography>
                            <LineButton to='/over' margin={hasPhone ? '5vw 0' : '2vw 0'}>Leer mij en mijn team beter kennen</LineButton>
                        </div>
                        {hasPhone && <ImageArch src={over} alt='portret Valerie' width='80vw' style={{ margin: '10vw 0'}} />}
                    </Grid>
                </div>
            </PageTitle>
            
            <Werkwijze {...props} />
            <RecenteProjecten {...props} />
            <Match {...props} />
            <LieveWoorden {...props}  />
            <Shop {...props} />
            <Blog {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}