import { colors } from "../../assets/colors";
import Typography from "../Typography/Typography";

export default function BulletPoint(props) {
    return (
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', gap: '5px', margin: '0vw 0', color: colors.black }}>
            <div>»</div>
            <Typography>{props.children}</Typography>
        </div>
    )
}