import React, { useState } from 'react';
import styled from 'styled-components';
import { PageTitle, Typography, ImageArch, LineButton, Button } from '../../components';
import { Grid } from '@mui/material';
import { colors } from '../../assets/colors';

import diensten from '../../assets/images/home/Studio-Valerie-Maas-grafisch-ontwerp-home-diensten.jpg';
import branding from '../../assets/images/home/Studio-Valerie-Maas-grafisch-ontwerp-home-diensten-branding.jpg';
import typography from '../../assets/images/home/Studio-Valerie-Maas-grafisch-ontwerp-home-diensten-typografie.jpg';

const Arch = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;

    width: ${props => props.width}vw;
    max-width: ${props => props.width}vw;
    height: ${props => props.width * 1.1}vw;
    border-radius: 50% 50% 0 0;
    border: 1px solid ${colors.photo};
    margin: 0 1vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        margin: 5vw 0;
        height: ${props => props.width * 1.3}vw;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        height: ${props => props.width * 1.3}vw;
    }
`;

function ArchText(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    return (
        <Arch onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut} width={props.archWidth}>
            <Typography variant='h2' style={{ marginTop: hasPhone ? '13vw' : hasTablet ? '5vw' : '4vw' }}>{props.title}</Typography>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 2vw', background: 'transparent'}}>
                <Typography style={{ maxWidth: hasPhone ? '63vw' : hasTablet ? '26vw' : '18vw', fontSize: '12pt'}}>{props.text}</Typography>
            </div>
            <LineButton to={props.link} style={{ margin: hasPhone ? '3vw 0 0 0' : hasTablet ? '1vw 0 0 0' : '0'}}>{props.linkText}</LineButton>
        </Arch>
    )
}

export default function Diensten(props) {
    const [ overBranding, setOverBranding ] = useState(false);
    const [ overTypography, setOverTypography ] = useState(false);

    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    const archWidth = hasPhone ? 85 : hasTablet ? 40 : 25;

    let image = diensten;
    if (overBranding) {
        image = branding;
    } else if (overTypography) {
        image = typography;
    }

    return (
        <div style={props.style}>
            <PageTitle title='diensten' left='-7px'>
                <div style={{ position: 'relative', margin: '0 0 2vw 0'}}>
                    <Grid container direction='column' justifyContent='center' alignItems='center'>
                        <div style={{ width: hasPhone ? '70vw' : hasTablet ? '35vw' : '32vw', textAlign: 'center'}}>
                            <Typography variant='h1'>diensten</Typography>
                            <Typography>Als strategisch grafisch ontwerper wil ik meer doen dan enkel een logo en een huisstijl maken. De basis ligt in jouw verhaal, jouw strategie en die werken we uit tot een passende branding, website mét passende teksten en fotografie.</Typography>
                        </div>
                    </Grid>
                </div>
                <Grid container direction='row' justifyContent='center' alignItems='flex-start' gap={hasTablet && '2vw'}>
                    <ArchText 
                        title='strategie' 
                        link='/diensten/strategie' 
                        linkText='Bekijk DE strategie pakketTEN' 
                        text={`Met enkel een mooi plaatje ben je er niet. Je bent je bedrijf om een reden begonnen én precies dat willen je klanten weten. \n
                        Door voor jouw merk een branding strategie te creëren, weet jij hoe je tekstueel en visueel jouw ideale klant aan kan trekken op een manier die bij jou past!`}
                        archWidth={archWidth}
                        onMouseOver={() => setOverTypography(true)}
                        onMouseOut={() => setOverTypography(false)}
                        {...props}
                    />
                    <ArchText 
                        title='branding' 
                        link='/diensten/branding' 
                        linkText='bekijk de branding pakketten' 
                        text='Het is tijd voor de volgende stap. Jouw onderneming mag naar the next level, professionaliseren, door middel van een branding. Een volledige visuele identiteit, logo en huisstijl, gekoppeld aan een branding strategie. Deze kunnen we online, op je website en social media, maar ook in drukwerk uitwerken.' 
                        archWidth={archWidth}
                        onMouseOver={() => setOverBranding(true)}
                        onMouseOut={() => setOverBranding(false)}
                        {...props}
                    />
                    
                    {!(hasPhone || hasTablet) && <ImageArch src={image} alt='portret Valerie' width={archWidth - 0.6 + 'vw'} height={(archWidth - 0.6) * 1.102 + 'vw'} style={{ margin: '0 1vw' }} />}
                </Grid>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: hasPhone ? '5vw' : hasTablet ? '4vw' : '2vw'}}>
                    <Button link='/diensten'>Bekijk alle pakketten</Button>
                </div>
            </PageTitle>
        </div>
    )
}