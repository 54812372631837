import { RESPONSE_OK, RESPONSE_ERROR } from './types';

export function getFilters(filters) {
    let filter = ''

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
          if (value) {
            if (value instanceof Array) {
              for (let i = 1; i < value.length; i++) {
                filter = filter.concat(`&${key}=${value[i]}`)
              }
            } else {
              filter = filter.concat(`&${key}=${value}`)
            }
          }
      }
    }

    return filter;
}

export function reportResponse(res, TYPE, type, dispatch) {
  if (!res) {
    dispatch({
      type: RESPONSE_ERROR,
      payload: { response: 'error', responseCode: 400, message: 'Geen data gevonden' }
    })
  } else if (res.status === 201) {
    const message = type + ' aangemaakt';
    dispatch({
      type: RESPONSE_OK,
      payload: { response: 'ok', responseCode: 201, message: message }
    });
    dispatch({
      type: TYPE,
      payload: res.data
    });
  } else if (res.status === 200) {
    let message = type + ' aangepast';
    if (TYPE.includes('GET')) {
      message = type + ' ingeladen';
    }
    dispatch({
      type: RESPONSE_OK,
      payload: { response: 'ok', responseCode: 200, message: message }
    });
    dispatch({
      type: TYPE,
      payload: res.data
    });
  } else if (res.status === 204) {
    const message = type + ' verwijderd';
    dispatch({
      type: RESPONSE_OK,
      payload: { response: 'ok', responseCode: 204, message: message }
    });
    dispatch({
      type: TYPE,
      payload: res.data,
    })
  } else {
    dispatch({
      type: RESPONSE_ERROR,
      payload: { response: 'error', responseCode: 400, message: res.data }
    })
  }

  return res.data;
}