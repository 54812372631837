import { GET_REVIEW, GET_REVIEWS } from '../actions/types';

const reviews = (state = {}, action) => {
  switch (action.type) {
    case GET_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
      };
    case GET_REVIEW:
      let newReviewObject = {};
      if (state.reviews && state.reviews.results) {
        const oldReviews = (state.reviews && state.reviews.results) || [];
        const oldReview = oldReviews.filter(s => Number(s.id) === Number(action.payload.id))[0];
        const idList = oldReviews.indexOf(oldReview);

        let newReviews = oldReviews;
        newReviews[idList] = action.payload;

        newReviewObject = state.reviews;
        newReviewObject['results'] = newReviews;
      } else {
        newReviewObject['results'] = [action.payload];
      }

      return {
        ...state,
        reviews: newReviewObject,
      }
    default:
      return state;
  }
};

export default reviews;