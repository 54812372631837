import { GET_BLOG_IDS } from '../actions/types';

const blogIds = (state = {}, action) => {
  switch (action.type) {
    case GET_BLOG_IDS:
      return {
        ...state,
        ...action.payload,
        blogIds: action.blogIds,
      };
    default:
      return state;
  }
};

export default blogIds;