import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';

const StyledImg = styled.img`
    width: ${props => props.width || 'auto'};
    height: ${props => props.height || 'auto'};
    border-radius: 95% 95% 0 0;
    border: 1px solid ${colors.photo};
    padding: 0.3vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 1vw;
    }
`;

export default function ImageArch(props) {
    if (props.video) {
        return (
            <div style={props.style}>
                <StyledImg as='video' playsInline autoPlay muted loop alt={props.alt} width={props.width} height={props.height}>
                    <source src={props.src} />
                </StyledImg>
            </div>
        )
    }

    return (
        <div style={props.style}>
            <StyledImg src={props.src} alt={props.alt} width={props.width} height={props.height} />
        </div>
    )
}