import React, { useEffect } from 'react';
import { Typography, Button, TextField, useInput } from '../../components';
import { Grid } from '@mui/material';

import { login } from '../../actions/login';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getAuthenticated } from '../../actions/authentication';

export default function Login(props) {
    const { value: valueUsername, bind: bindUsername } = useInput('');
    const { value: valuePassword, bind: bindPassword } = useInput('');

    const pageWidth = '25vw';
    const textFieldStyle = { width: pageWidth, marginBottom: '10px'};

    const dispatch = useDispatch();
    const response = useSelector((state) => state.responses);
    const authenticated = useSelector((state) => state.authenticated) || {};
    const status = response && response.status;

    const history = useHistory();

    useEffect(() => {
        Object.keys(authenticated).length === 0 && valueUsername.length === 0 && dispatch(getAuthenticated())
        
        if (status === 200 || authenticated.status) {
            history.push('/admin', { login: true })
        }
    })

    const handleSubmit = (evt) => {
        evt.preventDefault();

        const credentials = { 'username': valueUsername, 'password': valuePassword };
        dispatch(login(credentials))
        
    }

    return (
        <form onSubmit={handleSubmit} autoComplete='on' style={{ margin: '5vw 0'}}>
            <Grid container direction='column' alignItems='center'>
                <Typography variant='h2'>inloggen</Typography>
                <TextField 
                    placeholder='Gebruikersnaam'
                    style={textFieldStyle}
                    {...bindUsername}
                    required
                />
                <TextField 
                    placeholder='Wachtwoord'
                    style={textFieldStyle}
                    {...bindPassword}
                    required
                    type='password'
                />
                {response && response.status && <Typography>{response.statusText}</Typography>}
                <Button type='submit' style={{ marginTop: '1vw' }}>inloggen</Button>
            </Grid>
        </form>
    )
}