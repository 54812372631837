export const GET_REVIEWS = 'GET_REVIEWS';
export const GET_REVIEW = 'GET_REVIEW';
export const PUT_REVIEW = 'PUT_REVIEW';
export const POST_REVIEW = 'POST_REVIEW';
export const DELETE_REVIEW = 'DELETE_REVIEW';

export const GET_BOOKINGS = 'GET_BOOKINGS';
export const PUT_BOOKING = 'PUT_BOOKING';
export const POST_BOOKING = 'POST_BOOKING';

export const POST_CONTACTFORM = 'POST_CONTACTFORM';

export const GET_PROJECT_OVERVIEW = 'GET_PROJECT_OVERVIEW';

export const GET_PROJECT_TYPES = 'GET_PROJECT_TYPES';
export const POST_PROJECT_TYPE = 'POST_PROJECT_TYPE';

export const GET_SPECIFIC_PROJECT_TYPES = 'GET_SPECIFIC_PROJECT_TYPES';
export const POST_SPECIFIC_PROJECT_TYPE = 'POST_SPECIFIC_PROJECT_TYPE';

export const GET_KEYWORDS = 'GET_KEYWORDS';
export const POST_KEYWORD = 'POST_KEYWORD';

export const POST_PROJECT_GRID = 'POST_PROJECT_GRID';
export const PUT_PROJECT_GRID = 'PUT_PROJECT_GRID';
export const DELETE_PROJECT_GRID = 'DELETE_PROJECT_GRID';

export const PUT_PROJECT_IMAGE = 'PUT_PROJECT_IMAGE';
export const POST_PROJECT_IMAGE = 'POST_PROJECT_IMAGE';

export const GET_PROJECT = 'GET_PROJECT';
export const GET_PROJECTS = 'GET_PROJECTS';
export const PUT_PROJECT = 'PUT_PROJECT';
export const POST_PROJECT = 'POST_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

export const GET_PROJECT_IDS = 'GET_PROJECT_IDS';

export const GET_INSTA_PHOTOS = 'GET_INSTA_PHOTOS';
export const GET_INSTAGRAM_CODES = 'GET_INSTAGRAM_CODES';

export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const PUT_DOCUMENT = 'PUT_DOCUMENT';
export const POST_DOCUMENT = 'POST_DOCUMENT';

export const RESPONSE_OK = 'RESPONSE_OK';
export const RESPONSE_UNAUTHORISED = 'RESPONSE_UNAUTHORISED';
export const RESPONSE_UNKNOWN = 'RESPONSE_UNKNOWN';
export const RESPONSE_ERROR = 'RESPONSE_ERROR';

export const GET_AUTHENTICATED = 'GET_AUTHENTICATED';

export const GET_ARTICLES = 'GET_ARTICLES';
export const GET_ARTICLE = 'GET_ARTICLE';
export const PUT_ARTICLE = 'PUT_ARTICLE';
export const POST_ARTICLE = 'POST_ARTICLE';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';

export const GET_BLOG_OVERVIEW = 'GET_BLOG_OVERVIEW';
export const GET_BLOGS = 'GET_BLOGS';
export const GET_BLOG = 'GET_BLOG';
export const PUT_BLOG = 'PUT_BLOG';
export const POST_BLOG = 'POST_BLOG';
export const DELETE_BLOG = 'DELETE_BLOG';

export const GET_BLOG_TYPES = 'GET_BLOG_TYPES';
export const POST_BLOG_TYPES = 'POST_BLOG_TYPES';
export const GET_BLOG_IDS = 'GET_BLOG_IDS';

export const PUT_BLOG_IMAGE = 'PUT_BLOG_IMAGE';
export const POST_BLOG_IMAGE = 'POST_BLOG_IMAGE';

export const POST_BLOG_GRID = 'POST_BLOG_GRID';
export const PUT_BLOG_GRID = 'PUT_BLOG_GRID';
export const DELETE_BLOG_GRID = 'DELETE_BLOG_GRID';

export const PUT_BLOG_CONTENT = 'PUT_BLOG_CONTENT';
export const POST_BLOG_CONTENT = 'POST_BLOG_CONTENT';

export const PUT_BLOG_HEADER = 'PUT_BLOG_HEADER';
export const POST_BLOG_HEADER = 'POST_BLOG_HEADER';

export const PUT_BLOG_VIDEO = 'PUT_BLOG_VIDEO';
export const POST_BLOG_VIDEO = 'POST_BLOG_VIDEO';

export const POST_WACHTLIJST = 'POST_WACHTLIJST';

export const PUT_PROJECT_VIDEO = 'PUT_PROJECT_VIDEO';
export const POST_PROJECT_VIDEO = 'POST_PROJECT_VIDEO';

export const POST_CALENDLY_INVITEE = 'POST_CALENDLY_INVITEE';
export const POST_CALENDLY_EVENT = 'POST_CALENDLY_EVENT';
export const POST_ADVENT = 'POST_ADVENT';
export const GET_PAYMENT_STATUS = 'GET_PAYMENT_STATUS';