import axiosInstance from './axios';
import { PUT_PROJECT_IMAGE, POST_PROJECT_IMAGE } from './types';
import { reportResponse } from './helpers';
import { getCookie } from './cookies';

const getFormData = (object, blob, fileName) => Object.keys(object).reduce((formData, key) => {
    if (key === 'file') {
      formData.append(key, blob, fileName);
    } else {
      formData.append(key, object[key]);
    }
    
    return formData;
}, new FormData());

const config = {
  headers: {
    'Authorization': getCookie('access_token') ? "Bearer " + getCookie('access_token') : null,
  }
}

// PUT PROJECT IMAGE
export const putProjectImage = (projectImage, blob, fileName) => async dispatch => {
    const res = await axiosInstance.put(`/api/project_images/${projectImage.id}/`, getFormData(projectImage, blob, fileName), config);
    
    return reportResponse(res, PUT_PROJECT_IMAGE, 'Project image', dispatch);
};

// POST PROJECT IMAGE
export const postProjectImage = (projectImage, blob, fileName) => async dispatch => {
  const res = await axiosInstance.post(`/api/project_images/`, getFormData(projectImage, blob, fileName), config);
  
  return reportResponse(res, POST_PROJECT_IMAGE, 'Project image', dispatch);
};