import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { Grid } from '@mui/material';
import { PageTitle, Typography } from '../../components';

import { ReactComponent as Pencil } from '../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_penseel.svg';

const Border = styled.div`
    border: 1px solid ${colors.title};
    padding: 3vw 4vw;
    border-radius: 200px;
    position: relative;

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        padding: 4vw 4vw 6vw 4vw;
    }
`;

export default function Match(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    if (hasPhone) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: 'center', margin: hasPhone ? '10vw 0 30vw 0' : '5vw 0 15vw 0', position: 'relative'}}>
                <Typography variant='h2' style={{ margin: hasPhone ? '2vw 5vw' : '1vw 0'}}>Denk je dat wij een match zijn? <br/>Je bent een match als...</Typography>
                <Typography style={{ margin: hasPhone ? '3vw 0' : '1vw 0', width: hasPhone ? '55vw' : '25vw'}}>Je werkt vanuit een passie en bent gedreven en ambitieus</Typography>
                <Typography style={{ margin: hasPhone ? '3vw 0' : '1vw 0', width: hasPhone ? '55vw' : '25vw'}}>Je weet wie je bent en waar je voor staat óf bent klaar om dit samen te onderzoeken</Typography>
                <Typography style={{ margin: hasPhone ? '3vw 0' : '1vw 0', width: hasPhone ? '55vw' : '25vw'}}>Je ziet de waarde van goed ontwerp en wordt enthousiast als je mijn werk ziet</Typography>
                <Typography style={{ margin: hasPhone ? '3vw 0' : '1vw 0', width: hasPhone ? '55vw' : '25vw'}}>Je hebt zin om samen in het proces te duiken en aan jouw nieuwe uitstraling te werken</Typography>
                <Pencil style={{ fill: colors.latte, position: 'absolute', zIndex: '999', right: hasPhone ? '5vw' : '30vw', bottom: hasPhone ? '-15vw' : '-10vw', width: hasPhone ? '15vw' : '6vw', background: 'transparent' }} />
            </div>
        )
    } else {
        return (
            <div style={{ margin: '7vw 0'}}>
                <PageTitle title='zijn wij een match?' left='-40px'>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Border>
                            <Pencil style={{ fill: colors.latte, position: 'absolute', zIndex: '999', right: '6vw', top: '-4vw', width: hasTablet ? '6vw' : '4vw', background: 'transparent' }} />
                            <Typography variant='h2' style={{ textAlign: 'center', marginTop: '0'}}>
                                Denk je dat wij een match zijn? <br/>
                                Je bent een match als...
                            </Typography>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                style={{ gap: hasTablet ? '3vw' : '2vw', marginTop: hasTablet ? '5vw' : '3vw', textAlign: 'center'}}
                            >
                                <Typography style={{ width: hasTablet ? '17vw' : '13vw'}}>Je werkt vanuit een passie en bent gedreven en ambitieus</Typography>
                                <Typography style={{ width: hasTablet ? '17vw' : '13vw'}}>Je weet wie je bent en waar je voor staat óf bent klaar om dit samen te onderzoeken</Typography>
                                <Typography style={{ width: hasTablet ? '17vw' : '13vw'}}>Je ziet de waarde van goed ontwerp en wordt enthousiast als je mijn werk ziet</Typography>
                                <Typography style={{ width: hasTablet ? '17vw' : '13vw'}}>Je hebt zin om samen in het proces te duiken en aan jouw nieuwe uitstraling te werken</Typography>
                            </Grid>
                        </Border>
                    </Grid>
                </PageTitle>
            </div>
        )
    }
}