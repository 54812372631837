import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';

const StyledSelect = styled.select`
    font-family: Mulish;
    font-weight: 400;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    
    color: ${colors.photo};
    border: 1px solid ${colors.photo};
    border-radius: 18px;
    
    min-height: 30px;
    min-width: 200px;

    padding: 7px 5px 7px 10px;
    margin-right: 10px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:focus {
        outline: none;
        border: 1px solid ${colors.photo};
    }
`;

export default function Select(props) {
    return (
        <StyledSelect value={props.value} onChange={props.onChange} style={props.style}>
            {props.options && props.options.map((o,i) => {
                return <option key={o} value={o}>{o}</option>
            })}
        </StyledSelect>
    )
}