import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import AddIcon from '@mui/icons-material/Add';

import { TextField, Box, Button } from '@mui/material';
import { Typography } from "@mui/material";
import { getInstagramCodes, postInstagramCode, deleteInstagramCodes } from '../../actions/instagram';

const Field = styled.div`
    display: flex;
    align-items: center;
    gap: 5vw;
    margin: 1vw 0;
`;

export default function Instagram(props) {
    const [ instagramCode, setInstagramCode ] = useState('');

const dispatch = useDispatch();

    const instagramCodesObject = useSelector((state) => state.instagramCodes);
    const instagramCodes = (instagramCodesObject && instagramCodesObject.results) || [];

    const handleSubmit = () => {
        if (instagramCode) {
            const instagramObject = {
                "short_code": instagramCode,
                "short_live_token": '',
                "long_live_token": '',
                "photo_string": '',
                "expires": 5184000,
            }
    
            dispatch(postInstagramCode(instagramObject));
        }
    }

    const deleteOldCodes = () => {
        dispatch(deleteInstagramCodes());
    }

    useEffect(() => {
        dispatch(getInstagramCodes())
    }, [dispatch])

    return (
        <div>
            <Typography>Kan je de Instagram afbeeldingen niet zien op je website of krijg je een error als je pagina laadt? Of is het weer tijd om je code te vernieuwen?</Typography>
            <Typography>Klik <span/>
                <a target='_blank' rel='noreferrer' href='https://api.instagram.com/oauth/authorize?client_id=1083483132396619&redirect_uri=https://www.valeriemaas.nl/auth&scope=user_profile,user_media&response_type=code'>hier</a>
                <span/> en kopieër de code achter "code=" in de website balk nadat je de stappen hebt gevolgd. Negeer de #_ aan het einde van de code. Voer hem beneden in.
            </Typography>
            <Box component='form' validate>
                <Field>
                    <div style={{ width: '20vw'}}>
                        <Typography fontWeight='500' style={{ width: '20vw'}}>Instagram code</Typography>
                    </div>
                    <TextField
                        style={{ width: '50vw' }}
                        id="outlined-required"
                        value={instagramCode}
                        onChange={(e) => setInstagramCode(e.target.value)}
                        label='Instagram code'
                    />
                </Field>
                <div style={{ display: 'flex', width: '75vw', justifyContent: 'right', gap: '1vw'}}>
                    <Button onClick={handleSubmit} variant='contained' startIcon={<AddIcon />}>Aanmaken</Button>
                </div>
            </Box>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5vw'}}>
                Aantal codes: {instagramCodes.length}
                {instagramCodes.length > 0 && <Button onClick={deleteOldCodes} variant='contained'>Alle codes verwijderen</Button>}
            </div>
        </div>
    )
}