import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getInstaPhotos } from '../../actions/instagram';
import { colors } from '../../assets/colors';
import { Typography } from '../../components';

const InstagramFooter = styled.div`
    margin-top: 5.5vw;
    position: relative;
    height: auto;
`;

const Img = styled.img`
    width: 11vw;
    height: auto;
    margin-right: 0;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 28vw;
        height: auto;
        margin: 0;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        width: 14vw;
        height: auto;
        margin-right: 0;
    }
`;

const Container = styled.div`
    position: absolute;
    background: transparent;
    display: flex;
    align-items: center;
    z-index: 1;
    right: 0;
    top: calc(5.5vw - 29px);
`;

const OvalBorder = styled.div`
    border-radius: 25px 0% 0% 25px;
    background: ${colors.background};
    border: solid 1px ${colors.photo};
    border-right: 0px;
    height: 37px;
    padding: 11px 25px 11px 25px;
    text-align: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        margin-top: 5vw;
        width: max-content;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        gap: 1vw;
        padding: 7px 18px 5px 35px;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        margin-top: 2vw;
        padding: 7px 18px 3px 35px;
    }
`;

const Link = styled.div`
    display: flex;
    justify-content: right;
    text-decoration: none;

    &:hover {
        font-style: italic;
    }
`;

export default function Instagram(props) {

    const dispatch = useDispatch();
    const instaPhotos = useSelector((state) => state.instagramPhotos).photos || [];

    useEffect(() => {
        instaPhotos.length === 0 && dispatch(getInstaPhotos());
    }, [dispatch, instaPhotos.length]);
    
    if (instaPhotos.length === 0) {
        return null;
    } else if (props.hasPhone) {
        return (
            <div style={{ marginTop: '10vw'}}>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '2vw', flexWrap: 'wrap'}}>
                    {instaPhotos.map((m, i) => {
                        let url = m.media_type === 'VIDEO' ? m.thumbnail_url : m.media_url;
                        return (
                            <a href={m.permalink} key={`instagram-${i}`} target='_blank' rel="noreferrer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '28vw', height: '28vw', overflow: 'hidden', border: `1px solid ${colors.photo}` }}>
                                <Img src={url}/>
                            </a>
                        )
                    })}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                    <OvalBorder>
                        <Typography variant='sub' style={{ margin: '0'}}>Volg je me al op instagram?</Typography>
                        <Link as='a' href='https://www.instagram.com/studio.valeriemaas/' target='_blank' rel="noreferrer">
                            <Typography style={{ color: colors.photo }}>@studio.valeriemaas</Typography>
                        </Link>
                    </OvalBorder>
                </div>
                
            </div>
        )
    } else {
        return (
            <InstagramFooter>
                <Grid container justifyContent='center' alignItems='center' gap='1vw'>
                    {instaPhotos.map((m, i) => {
                        let url = m.media_type === 'VIDEO' ? m.thumbnail_url : m.media_url;
                        return (
                            <a href={m.permalink} key={`instagram-${i}`} target='_blank' rel="noreferrer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  width: props.hasTablet ? '14vw' : '11vw', height: props.hasTablet ? '14vw' : '11vw', overflow: 'hidden', border: `1px solid ${colors.photo}` }}>
                                <Img src={url}/>
                            </a>
                        )
                    })}
                </Grid>
                <Container>
                    <OvalBorder>
                        <Typography variant='sub' style={{ margin: '0'}}>Volg je me al op instagram?</Typography>
                        <Link as='a' href='https://www.instagram.com/studio.valeriemaas/' target='_blank' rel="noreferrer">
                            <Typography style={{ color: colors.photo }}>@studio.valeriemaas</Typography>
                        </Link>
                    </OvalBorder>
                </Container>
            </InstagramFooter>
        )
    }
}