import React, { useState } from 'react';
import styled from 'styled-components';
import { Arrow } from '..';

const StyledImg = styled.img`
    content: url(${props => props.file});
    width: ${props => props.width || '20vw'};
    height: ${props => props.height || '20vw'};

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 766px) and (max-width: 1024px){
        width: ${props => props.width || '50vw'};
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: ${props => props.width || '75vw'};
    } 
`;

export default function ImageCarousel(props) {
    const [ currentImage, setCurrentImage ] = useState(0);
    const [ isArrowHover, setArrowHover ] = useState(false);

    const { images } = props;

    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    const mouseEnter = (e) => {
        // animate arrows
        setArrowHover(true)
        setTimeout(() => {
            setArrowHover(false)
        }, 500)
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {currentImage > 0 ? 
                <Arrow onClick={() => setCurrentImage(currentImage - 1)} direction='left' hover={isArrowHover} style={{ position: 'absolute', left: hasPhone ? '5vw' : hasTablet ? '7vw' : '19vw'}} />
                 : <div style={{ position: 'absolute', width: '4vw'}} />}
            <StyledImg onMouseEnter={mouseEnter} width={hasPhone ? '75vw' : hasTablet ? '64vw' : '50vw'} height='default' file={images[currentImage].file} />
            {currentImage < images.length - 1 ? 
                <Arrow onClick={() => setCurrentImage(currentImage + 1)} direction='right' hover={isArrowHover} style={{ position: 'absolute', right: hasPhone ? '5vw' : hasTablet ? '7vw' : '19vw'}}  /> 
                : <div style={{ width: '4vw'}} />}
        </div>
    )
}