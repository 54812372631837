import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Typography, ImageArch, PageTitle, RotatingCircle, BulletPoint, Arrow } from '../../../components';
import { LieveWoorden } from '../../../subpages';
import Document from '../../voorwaarden/Document';

import { colors } from '../../../assets/colors';

import image1 from '../../../assets/images/diensten/fullstorybranding/Studio-Valerie-Maas-grafisch-ontwerp-The-Full-Story-Branding-Sharon-Holistic-Lifestyle.jpg';
import image2 from '../../../assets/images/diensten/fullstorybranding/Studio-Valerie-Maas-grafisch-ontwerp-The-Full-Story-Branding-Sharon-Holistic-Lifestyle-briefpapier.jpg';
import image3 from '../../../assets/images/diensten/fullstorybranding/Studio-Valerie-Maas-grafisch-ontwerp-The-Full-Story-Branding-Maaike-Kastrop.jpg';
import image4 from '../../../assets/images/diensten/fullstorybranding/Studio-Valerie-Maas-grafisch-ontwerp-The-Full-Story-Branding-Valerie.jpg';
import image5 from '../../../assets/images/diensten/fullstorybranding/Studio-Valerie-Maas-grafisch-ontwerp-The-Full-Story-Branding-Maaike-Kastrop-mobiel.jpg';

import { ReactComponent as Notitieboek } from '../../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_notitieboek.svg';
import { ReactComponent as Ontdekken } from '../../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_ontdekken-creeren-ontwerpen.svg';
import { ReactComponent as Latte } from '../../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_kopje-latte-macchiato.svg';
import { getCsvDienstRow } from '../../../helpers/diensten';

const Timeline = styled.div`
    width: 100%;
    position: relative;
    margin: 2vw 0 0 0;

    display: flex;
    align-items: flex-start;

    overflow-x: scroll;
    overflow-y: hidden;

    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
        display: none;
    }
`;

const TimelineItem = (props) => {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    return (
        <div style={{ minWidth: hasPhone ? '70vw' : hasTablet ? '40vw' : '27vw', marginLeft: props.first ? '5vw' : '0'}}>
            <div style={{ display: 'flex'}}>
                <div style={{ height: '1.5vw', background: colors.title, width: '0.5px'}} />
                {!props.last && <div style={{ height: '0.5px', background: colors.title, width: '100%', marginTop: '0.74vw' }} />}
            </div>
            <div style={{ display: 'flex'}}>
                <div>
                    <Typography variant='sub' style={{ margin: '0.5vw 0' }}>{props.title}</Typography>
                    <Typography>{props.text}</Typography>
                </div>
                <div style={{ width: hasPhone ? '10vw' : '10vw'}} />
            </div>
        </div>
    )
}

export default function FullStoryBranding(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;
    const dienstenCsv = props.dienstenCsv;

    const fullStoryBrandingRow = getCsvDienstRow(dienstenCsv, 'the full story branding');

    const [ isDown, setIsDown ] = useState(false);
    const [ isArrowHover, setArrowHover ] = useState(false);
    const [ startX, setStartX ] = useState(0);
    const [ scrollLeft, setScrollLeft ] = useState(0);

    const target = React.createRef();
    const walk = 300;
    const swipe = (e, walk) => {
        if (target && target.current) {
            const slider = target.current;
            slider.scrollLeft = slider.scrollLeft + walk;
        }
    }

    const mouseDown = (e) => {
        setIsDown(true);
        
        // calculate slider swipe
        const slider = target.current;
        setStartX(e.pageX - slider.offsetLeft);
        setScrollLeft(slider.scrollLeft);
    }

    const mouseLeave = (e) => {
        setIsDown(false);
    }

    const mouseMove = (e) => {
        if (!isDown) return;
        e.preventDefault();

        const slider = target.current;
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 2;
        slider.scrollLeft = scrollLeft - walk;
    }

    const mouseEnter = (e) => {
        // animate arrows
        setArrowHover(true)
        setTimeout(() => {
            setArrowHover(false)
        }, 500)
    }

    return (
        <div>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', margin: hasPhone ? '10vw 0 85vw 0' : '10vw 0 15vw 0' }}>
                <div style={{ width: hasPhone ? '80vw' : hasTablet ? '45vw' : '32vw', marginRight: hasPhone ? '0' : '25vw'}}>
                    <Typography variant='sub' style={{ marginBottom: hasPhone ? '2vw' : '0.5vw'}}>The full story branding</Typography>
                    <Typography variant='h2'>wil jij next level gaan met jouw business? <br/>zodat jij je ideale klant aantrekt met een branding die echt bij jou past</Typography>
                    <Typography style={{ width: hasPhone ? '75vw' : '25vw', margin: hasPhone ? '5vw 0' : '1.5vw 0'}}>Offline en online stralen met jouw branding, jouw website, goede fotografie en aansprekende teksten.</Typography>
                    <Button link='/diensten/thefullstorybrandingcontact'>Yes! Ik wil dit traject!</Button>
                </div>
                <ImageArch src={image2} alt='Sharon Holistic Lifestyle briefpapier' width={hasPhone ? '35vw' : '15vw'} style={{ position: 'absolute', right: hasPhone ? '10vw' : hasTablet ? '5vw' : '10vw', top: hasPhone ? '80vw' : '-3vw'}} />
                <img src={image1} alt='Sharon Holistic Lifestyle producten' style={{ position: 'absolute', width: hasPhone ? '35vw' : '15vw', top: hasPhone ? '105vw' : hasTablet ? '10vw' : '11vw', right: hasPhone ? '53vw' : hasTablet ? '23vw' : '27vw', zIndex: '998' }} />
            </div>
            <PageTitle title='Herken jij dit?' left='-1.3vw'>
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: hasPhone ? '15vw 0' : hasTablet ? '10vw 0' : '5vw 0' }}>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: hasPhone ? '80vw' : hasTablet ? '50vw' : '32vw', textAlign: 'center'}}>
                        <Typography variant='h2'>je bent al een tijdje ondernemer en je hebt een huisstijl laten maken of je hebt er zelf een in elkaar gezet, maar…</Typography>
                        <Typography style={{ width: hasPhone ? '75vw' : hasTablet ? '40vw' : '21vw', margin: hasTablet ? '1vw 0' : hasPhone ? '2vw 0' : '0.5vw 0'}}>Je voelt je niet meer 100% thuis <br/>in jouw huisstijl en website</Typography>
                        <Typography style={{ width: hasPhone ? '75vw' : hasTablet ? '40vw' : '21vw', margin: hasTablet ? '1vw 0' : hasPhone ? '2vw 0' : '0.5vw 0'}}>Je hebt een duidelijk verhaal, maar vind het moeilijk om dit onder woorden te brengen.</Typography>
                        <Typography style={{ width: hasPhone ? '75vw' : hasTablet ? '40vw' : '21vw', margin: hasTablet ? '1vw 0' : hasPhone ? '2vw 0' : '0.5vw 0'}}>Je vindt het moeilijk om één uitstraling te behouden in al je marketing.</Typography>
                        <Typography style={{ width: hasPhone ? '75vw' : hasTablet ? '40vw' : '21vw', margin: hasTablet ? '1vw 0' : hasPhone ? '2vw 0' : '0.5vw 0'}}>Je hebt al met verschillende ondernemers contact gehad, maar je wil één aanspreekpunt.</Typography>
                    </div>
                </div>
            </PageTitle>
            <PageTitle title='Het is tijd om te investeren in jou en jouw business' left={hasTablet ? '-18vw' : '-11.7vw'}>
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', margin: hasPhone ? '15vw 0' : '5vw 0', gap: '10vw', flexWrap: 'wrap' }}>
                    <div style={{ width: hasPhone ? '80vw' : hasTablet ? '32vw' : '23vw'}}>
                        <Typography variant='h2'>is het tijd om jouw ideale klant aan te trekken vanuit vertrouwen op een manier die bij jou past?</Typography>
                        <Typography style={{ margin: hasTablet || hasPhone ? '2vw 0' : '0.5vw 0'}}>Jouw verhaal op de goede manier neer te zetten, door middel van een branding strategie die ervoor zorgt dat we jouw verhaal onder woorden kunnen brengen?</Typography>
                        <Typography style={{ margin: hasTablet || hasPhone ? '2vw 0' : '0.5vw 0'}}>Niet alleen een nieuw logo? Maar een totaal pakket, waardoor jouw droomklant niet meer om jou en jouw business heen kan?</Typography>
                        <Typography style={{ margin: hasTablet || hasPhone ? '2vw 0' : '0.5vw 0'}}>Zowel online, op je website en social media, als in drukwerk knallen? Waardoor jij jouw droomklanten aantrekt én zij meteen denken: Yes! Dit wil ik!</Typography>
                        <Typography style={{ margin: hasTablet || hasPhone ? '2vw 0' : '0.5vw 0'}}>Alles bij één team neer kunnen leggen? En daardoor volledig ontzorgd worden?</Typography>
                        <Typography style={{ margin: hasTablet || hasPhone ? '2vw 0' : '0.5vw 0'}}>Een fijne langdurige samenwerking kunnen aangaan? Waardoor je niet na de lancering van jouw nieuwe branding verdrinkt in een diepe zee?</Typography>
                        <Button link='/diensten/thefullstorybrandingcontact' style={{ margin: hasPhone ? '3vw 0' : hasTablet ? '2vw 0' : '1vw 0'}}>Yes! Ik wil dit traject!</Button>
                    </div>
                    <ImageArch src={image3} width={hasPhone ? '75vw' : hasTablet ? '30vw' : '24vw'} style={{ margin: hasPhone ? '0' : '3vw 0 0 0'}} />
                </div>
            </PageTitle>
            <LieveWoorden showAtFullBrandingStory={true} />
            <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: hasPhone ? '30vw 0 100vw 0' : '5vw 0 15vw 0' }}>
                <div style={{ width: hasPhone ? '80vw' : hasTablet ? '40vw' : '24vw', textAlign: 'center'}}>
                    <Typography variant='h2'>al vijf jaar help ik ondernemers om hun ideale klant aan te kunnen trekken</Typography>
                    <Typography style={{ padding: '0 1.5vw', margin: hasPhone || hasTablet ? '2vw 0' : '0.5vw 0'}}>Alle onderdelen uit dit traject kwamen in de afgelopen vijf jaar al eens voorbij binnen mijn bedrijf of in de baan die ik parttime in loondienst had als ontwerper.</Typography>
                    <Typography style={{ padding: '0 1.5vw', margin: hasPhone || hasTablet ? '2vw 0' : '0.5vw 0'}}>Alle diensten los zijn super waardevol. Maar ik geloof erin dat jij past écht kan knallen, online en offline, als je ervoor zorgt dat je alle onderdelen in één keer goed neerzet.</Typography>
                    <Typography style={{ padding: '0 1.5vw', margin: hasPhone || hasTablet ? '2vw 0' : '0.5vw 0'}}>Branding strategie en huisstijl ontwerp. Gecombineerd met online en offline uitingen, zoals een website, social media en drukwerk. Én een fotoshoot met een fotograaf die past bij jouw verhaal en jouw visuele identiteit.</Typography>
                </div>
                <Notitieboek style={{ fill: colors.latte, transform: 'scaleX(-1)', position: 'absolute', zIndex: '100', left: hasPhone ? '5vw' : hasTablet ? '10vw' : '15vw', top: hasPhone ? '-23vw' : hasTablet ? '-5vw' : '-2vw', width: hasPhone ? '25vw' : hasTablet ? '13vw' : '10vw', background: 'transparent' }} />
                <img src={image4} alt='Valerie' style={{ position: 'absolute', width: hasPhone ? '70vw' : hasTablet ? '18vw' : '15vw', top: hasPhone ? '100vw' : '5vw', right: hasPhone ? '15vw' : hasTablet ? '5vw' : '10vw', zIndex: '998' }} />
                {!hasPhone && <RotatingCircle style={{ position: 'absolute', right: hasTablet ? '8vw' : '12.5vw', bottom: hasTablet ? '-5vw' : '-7vw'}}>
                    <Ontdekken style={{ fill: colors.illustration, width: hasTablet ? '15vw' : '10vw' }} />
                </RotatingCircle>}
            </div>
            <div>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '5vw', flexWrap: 'wrap'}}>
                    <div style={{ width: hasPhone ? '75vw' : hasTablet ? '35vw' : '27vw'}}>
                        <Typography variant='h2' style={{ margin: hasPhone ? '0 0 1vw 0' : '0' }}>the full story branding</Typography>
                        <Typography variant='sub' style={{ lineHeight: hasPhone  ? '4vw' : hasTablet ? '2vw' : '1.5vw', fontSize: hasPhone ? '3vw' : hasTablet ? '1vw' : '0.8vw', paddingRight: '6vw', margin: hasPhone ? '2vw 0 5vw 0' : '0.5vw 0 1vw 0' }}>In 4 maanden zetten we jouw merk neer als een huis, zodat deze volledig laat zien wie jij bent en Waarvan  jouw droomklant denkt: Yes! Dit wil ik!</Typography>
                        <Typography>In The Full Story Branding help ik jou jouw business als een sterk merk neer te zetten, op een manier die volledig bij jou en jouw business past. En waar zowel jij als je ideale klant heel blij van worden.</Typography>
                    </div>
                    <div style={{ width: hasPhone ? '75vw' : hasTablet ? '40vw' : '20vw'}}>
                        <Typography variant='sub' style={{ lineHeight: hasPhone ? '4vw' : hasTablet ? '2vw' : '1.5vw', fontSize: hasPhone ? '3vw' : hasTablet ? '1vw' : '0.8vw', margin: hasPhone ? '3vw 0 2vw 0' : '2.7vw 0 1vw 0' }}>het traject bestaat uit zeven fases, die we samen doorlopen</Typography>
                        <Typography>
                            Fase 1: Ontmoeten <br/>
                            Fase 2: Vastleggen <br/>
                            Fase 3: Ontdekken <br/>
                            Fase 4: Creëren en ontwerpen <br/>
                            Fase 5: Verfijnen <br/>
                            Fase 6: Exporteren <br/>
                            Fase 7: Lanceren
                        </Typography>
                    </div>
                </div>
                <div style={{ display: 'flex', columnGap: hasPhone ? '3vw' : '2vw', justifyContent: 'center', alignItems: 'center', marginTop: hasPhone ? '5vw' : '3vw' }}>
                    <Arrow direction='left' onClick={(e) => swipe(e, -walk)} hover={isArrowHover} long={hasPhone || hasTablet} />
                    <Typography variant='sub' style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '0', height: '2vw'}}>Een kijkje in het proces</Typography>
                    <Arrow direction='right' onClick={(e) => swipe(e, walk)} hover={isArrowHover} long={hasPhone || hasTablet} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Timeline
                        onMouseDown={mouseDown}
                        onMouseLeave={mouseLeave}
                        onMouseUp={mouseLeave}
                        onMouseMove={mouseMove}
                        onMouseEnter={mouseEnter}
                        ref={target}
                    >
                        <TimelineItem first={true} title='Week 1 en 2 - ontdekken' text='We trappen het traject af met de strategie-sessie op locatie! Hierin bekijken we wie jij bent en wat jouw verhaal is. Jij ontvangt jouw branding strategie na 2 weken in jouw mail!' {...props} />
                        <TimelineItem title='Week 3, 4 en 5 - creëren en ontwerpen' text='Aan de slag met het het creëren van het plaatje wat perfect bij jou én jouw ideale klant past. Hierin neem ik naast de branding, ook jouw website, drukwerk en een fotografie-stijl mee.' {...props} />
                        <TimelineItem title='Week 6 en 7 - ontwerpen en verfijnen' text='Na het geven van de concept-presentatie geef jij feedback, die ik ga verwerken. We gaan ook zoeken naar de fotograaf die pérfect bij jou past én starten het traject met de webbouwer en de copywriter op.'  {...props} />
                        <TimelineItem title='Week 8, 9, 10 en 11 - verfijnen' text='Je bent 100% tevreden met jouw branding, het is nu tijd om deze tot in de puntjes te verwerken in alle uitingen. Zoals jouw website, jouw social media én jouw drukwerk.' {...props} />
                        <TimelineItem title='Week 12, 13 en 14 - verfijnen en exporteren' text='Ik werk alle onderdelen van jouw branding uit en exporteer deze. Ondertussen zijn de webbouwer en de copywriter voor jou aan de slag. Ook is het nu tijd voor de shoot!' {...props} />
                        <TimelineItem title='Week 15 en 16 - exporteren' text='We zijn er bijna! Jouw branding, jouw website mét pakkende teksten, jouw social media en jouw fotografie staan bijna klaar. We werken samen een lanceerplan uit om jouw ideale klant meteen aan te spreken!' {...props} />
                        <TimelineItem last={true} title='Week 17 - Lanceren' text='Plop de champagne maar open! Het is tijd om een glaasje te drinken en te vieren dat jij en jouw bedrijf naar the next level zijn gegaan!' {...props} />
                    </Timeline>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', margin: hasPhone ? '15vw 0' : hasTablet ? '8vw 0' : '5vw 0', textAlign: 'center'}}>
                <Typography variant='h2' style={{ width: hasPhone ? '75vw' : hasTablet ? '50vw' : '25vw'}}>we werken niet alleen jouw branding uit, maar bouwen online én offline aan een merk wat volledig bij jou én jouw klanten past</Typography>
                <Button link='/diensten/thefullstorybrandingcontact' style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '0'}}>Yes! Ik wil dit traject!</Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '12vw', margin: hasPhone ? '15vw 0' : hasTablet ? '10vw 0' :  '2vw 0', flexWrap: 'wrap'}}>
                <div style={{ width: hasPhone ? '80vw' : hasTablet ? '40vw' : '28vw'}}>
                    <Typography variant='h2' style={{ margin: '0'}}>{fullStoryBrandingRow['dienst']}</Typography>
                    <Typography variant='sub' style={{ margin: hasPhone ? '1vw 0 3vw 0' : '0.5vw 0 1vw 0'}}>Vanaf {fullStoryBrandingRow['prijs']} excl. btw</Typography>
                    <Typography>Ben jij klaar om het écht goed aan te pakken? Zowel online als offline jouw ideale klant aan te trekken? En wil jij volledig ontzorgt worden? Het pakket ‘The full story branding’ is een pakket voor ondernemers die het complete plaatje in een keer goed neer willen zetten, waardoor jij écht een waardevol merk in de markt zet. Ik zorg, samen met mijn team, voor het neerzetten van één sterk verhaal, met een sterke basis in de branding strategie. Waarin we gaan kijken naar jouw verhaal!</Typography>
                    <Button link='/diensten/thefullstorybrandingcontact' style={{ margin: hasPhone ? '3vw 0' : hasTablet ? '2vw 0' : '1.5vw 0'}}>Yes! Ik wil dit traject!</Button>
                </div>
                <ImageArch src={image5} width={hasPhone ? '75vw' : hasTablet ? '30vw' : '25vw'} />
            </div>
            <div>
                <div style={{ display: 'flex', justifyContent: 'center', gap: hasPhone ? '5vw' : '12vw', margin: hasPhone ? '5vw 0 50vw 0' : '2vw 0', flexWrap: 'wrap'}}>
                    <div style={{ width: hasPhone ? '80vw' : hasTablet ? '33vw' : '26vw', margin: hasPhone ? '1vw 0' : hasTablet ? '3vw 0 7vw 0' : '3vw 0 3vw 0'}}>
                        <Typography variant='h2'>wat krijg je?</Typography>
                        <Typography variant='sub' style={{ margin: hasPhone ? '5vw 0 2vw 0' : hasTablet ? '2vw 0 1vw 0' : '1vw 0 0.5vw 0'}}>Branding</Typography>
                        <BulletPoint>1-op-1 strategie-sessie (dagdeel op locatie)</BulletPoint>
                        <BulletPoint>Logo’s, beeldmerk, tekstmerk, embleem en variaties</BulletPoint>
                        <BulletPoint>Kleurenpalet en Typografie selectie</BulletPoint>
                        <BulletPoint>10 Grafische elementen, zoals illustraties, iconen of patronen</BulletPoint>
                        <BulletPoint>Logo-animatie!</BulletPoint>
                        <BulletPoint>Brandbook, waarin staat hoe jij je branding én strategie kan toepassen.</BulletPoint>
                        <Typography variant='sub' style={{ margin: hasPhone ? '5vw 0 2vw 0' : hasTablet ? '2vw 0 1vw 0' : '1vw 0 0.5vw 0'}}>Drukwerk</Typography>
                        <BulletPoint>Drukwerk ontwerp: Ontwerp van bijvoorbeeld een visitekaartje, ansichtkaart, prijslijst, cadeaubon, briefpapier, stickers…</BulletPoint>
                        <BulletPoint>Je kan ook denken aan: Verpakkingen, boek- of magazine-ontwerp.</BulletPoint>
                        <BulletPoint>Drukwerk begeleiding.</BulletPoint>
                        <Typography variant='sub' style={{ margin: hasPhone ? '5vw 0 2vw 0' : hasTablet ? '2vw 0 1vw 0' : '1vw 0 0.5vw 0'}}>Website</Typography>
                        <BulletPoint>Unieke website in Wordpress met cursus, zodat jij jouw website na de lancering nog kan aanvullen.</BulletPoint>
                        <BulletPoint>Gevuld met teksten die jouw ideale klant aanspreken, geschreven door een copywriter. We denken daarbij ook aan goede vindbaarheid!</BulletPoint>
                        <Typography variant='sub' style={{ margin: hasPhone ? '5vw 0 2vw 0' : hasTablet ? '2vw 0 1vw 0' : '1vw 0 0.5vw 0' }}>Online</Typography>
                        <BulletPoint>Social media templates en highlights.</BulletPoint>
                        <BulletPoint>E-mailhandtekening mét handleiding hoe je deze toe kan voegen.</BulletPoint>
                        <Typography variant='sub' style={{ margin: hasPhone ? '5vw 0 2vw 0' : hasTablet ? '2vw 0 1vw 0' : '1vw 0 0.5vw 0' }}>Fotoshoot</Typography>
                        <BulletPoint>Branding fotoshoot mét begeleiding.</BulletPoint>
                    </div>
                    <div style={{ width: hasPhone ? '80vw' : hasTablet ? '33vw' : '26vw', margin: hasPhone ? '1vw 0' : '3vw 0 8vw 0', position: 'relative'}}>
                        <Typography variant='h2'>wat moet ik zelf doen?</Typography>
                        <BulletPoint>Vragen beantwoorden tijdens de branding strategie-sessie op locatie.</BulletPoint>
                        <BulletPoint>Een moodboard maken (op Pinterest) van bijvoorbeeld logo’s, lettertypes, kleuren en uitingen (online en offline) die jij mooi vind en bij jouw bedrijf passen.</BulletPoint>
                        <BulletPoint>Brainstormen in de strategie-sessie voor de website samen met de webbouwer en mij!</BulletPoint>
                        <BulletPoint>Call met de copywriter en mij, om de schrijfstijl en inhoud van jouw website definitief te bepalen.</BulletPoint>
                        <BulletPoint>Stralen op de dag van jouw fotoshoot! Geen zorgen, de fotograaf en ik zijn er voor je.</BulletPoint>
                        <BulletPoint>Feedback geven op ontwerpen.</BulletPoint>
                        <div style={{ height: hasPhone ? '5vw' : hasTablet ? '3vw' : '2vw'}} />
                        <div style={{ textAlign: 'center', position: 'absolute', bottom: hasPhone ? '-35vw' : '5vw'}}>
                            <Typography variant='h2'>een fijne samenwerking met om de week een 1-op-1 call over het proces en de planning!</Typography>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', width: '100%', margin: hasPhone ? '15vw 0' : hasTablet ? '10vw 0vw' : '2vw 0' }}>
                <Typography variant='h2'>veelgestelde vragen</Typography>
                <Typography>Ik heb de antwoorden!</Typography>
                <Document documentName='Veelgestelde vragen' typeName='The Full Story Branding' style={{ textAlign: 'left', margin: '1vw 0'}} {...props} />
            </div>
            <div style={{ position: 'relative'}}>
                <div style={{ display: 'flex', justifyContent: 'center', margin: hasPhone ? '7vw 0 30vw 0' : hasTablet ? '7vw 0' : '7vw 0' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: hasPhone ? '80vw' : hasTablet ? '60vw' : '48vw', textAlign: 'center' }}>
                            <div>
                                <Typography variant='h2'>ik ben er klaar voor om samen met mijn team en mét jou je business naar the next level te tillen</Typography>
                            </div>
                            <Typography style={{ padding: hasPhone ? '0 5vw' : '0 12vw'}}>Jij ook? Plan dan een gratis kennismakingsgesprek in, dan drinken we samen (digitaal) een latte en bekijken we wat we voor elkaar kunnen betekenen.</Typography>
                            <Button link='/diensten/thefullstorybrandingcontact' style={{ margin: hasPhone ? '5vw 0' : hasTablet ? '3vw 0' : '1vw 0'}}>Yes! Ik wil dit traject!</Button>
                        </div>
                </div>
                {hasPhone ? 
                    <Latte style={{ fill: colors.latte, position: 'absolute', left: '12vw', bottom: '-20vw', width: '12vw' }} /> :
                    hasTablet ?
                        <Latte style={{ fill: colors.latte, position: 'absolute', left: '7vw', top: '-7vw', width: '6vw' }} /> :
                        <Latte style={{ fill: colors.latte, position: 'absolute', left: '15vw', bottom: '0', width: '5vw' }} />
                }
            </div>
        </div>
    )
}