import React from 'react';
import { TextField, Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

export default function AutoComplete(props) {
    const { value, setValue, optionList, optionName, label, style } = props;
    // const [ value, setValue ] = useState([]);

    if (optionList && optionList.length > 0) {
        return (
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    // TODO: change such that it is a dict
                    let newValues = newValue;
                    for (let i=0; i < newValue.length; i++) {
                        if (typeof newValues[i] === 'string') {
                            let stringValue = newValues[i];
                            newValues[i] = Object.create(optionList[0]);
                            newValues[i]['id'] = null;
                            newValues[i][optionName] = stringValue;
                        }
                    }
                    setValue(newValues);
                }}
                multiple
                id="tags-filled"
                options={optionList}
                freeSolo
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={option[optionName] || option}
                            {...getTagProps({ index })}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={label || ''}
                        placeholder="Zoek of voeg iets nieuws toe met enter"
                    />
                )}
                renderOption={(props, option) => <li {...props}>{option[optionName]}</li>}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option[optionName];
                  }}
                style={style}
            />
        )
    }

    return null;
}