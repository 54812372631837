import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';

import { Typography } from '../../components';
import { Instagram, Nieuwsgierig } from '../../subpages';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getBlogOverview } from '../../actions/blogoverview';
import { getBlogTypes } from '../../actions/blogtypes';

const CustomLineButton = styled.div`
    font-family: Mulish;
    font-size: 13px;
    font-weight: ${props => props.active ? '600' : '300'};
    text-transform: uppercase;
    text-decoration: none;
    color: ${colors.photo};
    letter-spacing: 1.4px;
    text-decoration: underline;
    text-underline-offset: 5px;
    margin: ${props => props.margin || '0.5vw 1vw'};
    width: inherit;
    text-align: left;

    &:hover {
        cursor: pointer;
        font-weight: 600;
    }

    @media screen and (max-width: 766px) {
        width: 45%;
        text-align: ${props => props.alignRight ? 'right' : 'left'}; 
        font-size: 11px;
        min-width: 20vw;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 11px;
    }
`;

const BlogGrid = styled.div`
    display: flex;
    justify-content: left;
    gap: 3vw;
    flex-wrap: wrap;

    width: 66vw;
    margin: 3vw 0 0 0;

    @media screen and (max-width: 766px) {
        width: 95vw;
        justify-content: center;
        gap: 10vw;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        width: 87vw;
    }
`;

const StyledImg = styled.img`
    content: url(${props => props.img});
    object-position: center;
    object-fit: cover;
    max-width: 20vw;
    max-height: 20vw;

    @media screen and (max-width: 766px) {
        max-width: 85vw;
        max-height: 85vw;
    }

    @media screen and (min-width: 767px) and (max-width: 1024px) {
        max-width: 27vw;
        max-height: 27vw;
    }
`;

const CustomButton = styled.div`
    font-family: Mulish;
    font-weight: 400;
    text-decoration: none;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    font-size: 13px;
    color: ${colors.photo};
    border: 1px solid ${colors.photo};
    padding: 8px;
    border-radius: 18px;
    outline: none;
    margin-top: 2vw;

    &:hover {
        font-weight: 600;
        cursor: pointer;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 11px;
        letter-spacing: 1.1px;
        padding: 5px;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 11px;
        letter-spacing: 1.1px;
        padding: 8px;
    }
`;

const StyledTitle = styled(Typography)`
    margin-top: 10px;
`

const BlogContainer = styled.div`
    &:hover ${StyledTitle} {
        font-style: italic;
    }
`

export default function Blog(props) {
    const [ hasFetched, setHasFetched ] = useState(false);
    const [ isType, setType ] = useState([]);
    const [ isPage, setPage ] = useState(1);

    const dispatch = useDispatch();

    const blogsObject = useSelector((state) => state.blogOverviews);
    const blogs = blogsObject.results || [];

    const blogTypes = useSelector((state) => state.blogTypes).results;

    const getBlog = (blog, p) => {
        if (blog.main_image) {
            const img = blog.main_image;
            const width = hasPhone ? '80vw' : hasTablet ? '27vw' : '20vw';

            return (
                <Link key={p} to={`/blog/${blog.url_slug}`} style={{ textDecoration: 'none'}}>
                    <BlogContainer>
                        <StyledImg img={img} alt='blog foto' />
                        <StyledTitle>
                            <Typography style={{ width: width, marginBottom: hasPhone ? '0' : '5px'  }}>
                                {blog.title} 
                            </Typography>
                        </StyledTitle>
                        <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap', width: width }}>
                            {blog.types && blog.types.map((type, j) => {
                                return (
                                    <Typography variant='sub' key={j} style={{ margin: '0' }}>
                                        {j > 0 && <span> | </span>}
                                        <span>{type.content}</span>
                                    </Typography>
                                )
                            })}
                        </div>
                    </BlogContainer>
                </Link>
            )
        }
    }

    const changeType = (type) => {
        let filters = { page_size: 6 }
        if (isType === type) {
            setType([]);
            const typeNames = blogTypes.map(type => type.content).join(',');
            filters.types__content = typeNames;
            dispatch(getBlogOverview(filters));
        } else {
            setType(type);
            filters.types__content = type;
            filters.page = 1;
            // reset page to 1
            setPage(1);
            dispatch(getBlogOverview(filters))
        }
    }

    const changePage = (pageNumber) => {
        setPage(pageNumber);
        const filters = { page_size: 6, page: pageNumber, type: isType };
        dispatch(getBlogOverview(filters));
        window.scrollTo(0,0);
    }

    useEffect(() => {
        if (!blogTypes) {
            dispatch(getBlogTypes())
        }
        
        const noObject = Object.keys(blogsObject).length === 0;

        if (blogTypes && (noObject || !hasFetched)) {
            const typeNames = isType || blogTypes.map(type => type.content).join(',');
            const filters = { types__content: typeNames, page_size: 6, page: isPage, published: true };
           
            dispatch(getBlogOverview(filters)).then(setHasFetched(true))
        }

    }, [dispatch, isPage, isType, blogsObject, blogTypes, hasFetched]);
    
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;
    
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant='h1'>Blog</Typography>
                <div style={{ display: 'flex', justifyContent: 'left', margin: hasPhone ? '5%' : hasTablet ? '2%' : '0', gap: hasPhone ? '5vw' : hasTablet ? '2vw' : '0', flexWrap: 'wrap' }}>
                    <CustomLineButton key={'0'} onClick={() => changeType(isType)} active={isType && isType.length === 0} alignRight={true}>Alle</CustomLineButton>
                    {blogTypes && blogTypes.length > 0 && blogTypes.map((type, i) => <CustomLineButton key={i} onClick={() => changeType(type.content)} active={isType === type.content} alignRight={i % 2 === 1}>{type.content}</CustomLineButton>)}
                </div>
                {(blogs && blogs.length > 0) ? <BlogGrid>
                    {blogs.length > 0 && blogs.map((blog, p) => {
                        return getBlog(blog, p)
                    })}
                </BlogGrid> : <Typography style={{ margin: '3vw 0'}}>Geen blogs gevonden</Typography>}
            </div>
            <div style={{ position: 'relative', margin: hasPhone ? '2vw 0 10vw 0' : hasTablet ? '2vw 0 12vw 0' : '1vw 0 2vw 0', height: '5vw'}}>
                {blogsObject.previous && <div style={{ position: 'absolute', left: hasPhone ? '6vw' : hasTablet ? '9vw' : '23vw'}}>
                    <CustomButton onClick={() => changePage(isPage - 1)}>Bekijk vorige</CustomButton>
                </div>}
                {blogsObject.next && <div style={{ position: 'absolute', right: hasPhone ? '6vw' : hasTablet ? '9vw' : '23vw' }}>
                    <CustomButton onClick={() => changePage(isPage + 1)}>Bekijk volgende</CustomButton>
                </div>}
            </div>
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}