import React from 'react';
import styled from 'styled-components';

import { Typography, Checkbox } from '@mui/material';

const ClickImg = styled.img`
    &:hover {
        cursor: ${props => props.editing ? 'pointer' : 'default'};
    }

`;

const IMAGE_SIZES = [ '30vw', '15vw', '10vw' ];

export default function ProjectImage(props) {
    const { gridId, imageId, grid, image, updateImage, isEditing } = props;

    const customId = image.id || image.order;

    return (
        <div key={imageId} style={{ margin: '1vw', width: IMAGE_SIZES[Number(grid.grid) - 1] }}>
            <div>
                <input
                    accept='image/*'
                    style={{ display: 'none' }}
                    id={`fileUploader-${gridId}-${imageId}`}
                    onChange={(e) => updateImage('file', grid, customId, e.target.files[0])}
                    type="file"
                    hidden
                />
                <ClickImg 
                    src={image.file && image.file.name ? URL.createObjectURL(image.file) : image.file} 
                    style={{ width: IMAGE_SIZES[Number(grid.grid) - 1] }} 
                    alt='afbeelding' 
                    editing={isEditing}
                    onClick={() => isEditing && document.getElementById(`fileUploader-${gridId}-${imageId}`).click()}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography>Portfolio afb. 1</Typography>
                <Checkbox 
                    checked={image.primary} 
                    disabled={!isEditing} 
                    onChange={(e) => updateImage('primary', grid, customId, e.target.checked)}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography>Portfolio afb. 2</Typography>
                <Checkbox 
                    checked={image.secondary} 
                    disabled={!isEditing}
                    onChange={(e) => updateImage('secondary', grid, customId, e.target.checked)}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography>Scrollbaar</Typography>
                <Checkbox 
                    checked={image.scrollable} 
                    disabled={!isEditing}
                    onChange={(e) => updateImage('scrollable', grid, customId, e.target.checked)}
                />
            </div>
        </div>
    )
}