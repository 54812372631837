import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getProject, getProjects, putProject, postProject, deleteProject } from '../../../actions/projects';
import { getProjectTypes, postProjectType } from '../../../actions/projecttypes';
import { getSpecificProjectTypes, postSpecificProjectType } from '../../../actions/specificprojecttypes';
import { getKeywords, postKeyword } from '../../../actions/keywords';

import { AutoComplete, Dialog } from '../../../components';

import styled from 'styled-components';
import moment from 'moment';

import { Box, Button, TextField, Typography, Checkbox, Select, MenuItem } from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
// import DateAdapter from '@mui/lab/AdapterMoment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { isValidHttpUrl } from '../../../helpers/url';
import { getReviews } from '../../../actions/reviews';
import ProjectImages from './ProjectImages';
import { postProjectImage, putProjectImage } from '../../../actions/projectimages';
import { postProjectGrid } from '../../../actions/projectgrids';
import { postProjectVideo, putProjectVideo } from '../../../actions/projectvideos';

const Field = styled.div`
    display: flex;
    align-items: center;
    gap: 5vw;
    margin-bottom: 1vw;
`;

export default function Project(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();

    const post = params.projectId === 'new';

    const projectsObject = useSelector((state) => state.projects);
    const projects = (projectsObject && projectsObject.projects && projectsObject.projects.results) || [];


    let project;
    if (!post) {
        project = projects.filter(a => Number(a.id) === Number(params.projectId))[0];
    } else {
        project = {
            'title': '',
            'content': '',
            'website_link': '',
            'website_text': '',
            'created': moment(),
            'company_name': '',
            'is_recent': true,
            'types': [],
            'reviews': [],
            'specific_types': [],
            'keywords': [],
            'images': [],
            'videos': []
        };
    }

    const hasProject = project && Object.keys(project).length > 0;

    const projectTypes = useSelector((state) => state.projectTypes).results || [];
    const specificProjectTypes = useSelector((state) => state.specificProjectTypes).results || [];
    const keywords = useSelector((state) => state.keywords).results || [];
    const reviewObject = useSelector((state) => state.reviews).reviews || {};
    const reviews = (reviewObject && reviewObject.results) || [];

    const [ dialogOpen, setDialogOpen ] = useState(false);

    const [ titleValue, setTitleValue ] = useState((project && project.title) || '');
    const [ titleErrorValue, setTitleErrorValue ] = useState('');
    const [ contentValue, setContentValue ] = useState((project && project.content) || '');
    const [ contentErrorValue, setContentErrorValue ] = useState('');
    const [ companyValue, setCompanyValue ] = useState((project && project.company_name) || '');
    const [ websiteValue, setWebsiteValue ] = useState((project && project.website_link.split('//')[1]) || '');
    const [ websiteErrorValue, setWebsiteErrorValue ] = useState('');
    const [ websiteTextValue, setWebsiteTextValue ] = useState((project && project.website_text) || '');
    const [ recentValue, setRecentValue ] = useState((project && project.is_recent) || false);
    const [ dateValue, setDateValue ] = useState((project && project.created) || '');

    const [ typeValue, setTypeValue ] = useState((project && project.types) || []);
    const [ specificTypeValue, setSpecificTypeValue ] = useState((project && project.specific_types) || []);
    const [ keywordValue, setKeywordValue ] = useState((project && project.keywords) || []);
    const [ reviewValue, setReviewValue ] = useState((project && project.reviews) || ['']);

    const [ gridsValue, setGridsValue ] = useState((project && project.grids) || []);

    useEffect(() => {
        !post && dispatch(getProject(params.projectId))
        if (!post && hasProject && !titleValue) {
            setTitleValue(project.title);
            setContentValue(project.content);
            setCompanyValue(project.company_name);
            setWebsiteValue(project.website_link);
            setWebsiteTextValue(project.website_text);
            setRecentValue(project.is_recent);
            setDateValue(project.created);
            setTypeValue(project.types);
            setSpecificTypeValue(project.specific_types);
            setKeywordValue(project.keywords);
            setReviewValue(project.reviews);
            setGridsValue(project.grids);
        }
        dispatch(getProjectTypes())
        dispatch(getSpecificProjectTypes())
        dispatch(getKeywords())
        dispatch(getReviews({ page_size: 100 }))
    }, [hasProject, titleValue, post, project, params.projectId, dispatch])
    

    if (!project || Object.keys(project).length === 0) {
        return <div>Geen project gevonden</div>
    }

    const delProject = () => {
        setDialogOpen(false);
        dispatch(deleteProject(project.id)).then(history.push('/admin/projecten'))
    }

    async function updateRelation(oldValues, dispatchFunction) {
        let valueIds = oldValues.map(value => value.id);
        
        let newValues = oldValues;
        if (valueIds.includes(null)) {
            for (let i=0; i < oldValues.length; i++) {
                if (oldValues[i].id === null) {
                    let value = oldValues[i];
                    await dispatch(dispatchFunction(value)).then((response) =>
                        newValues[i] = response
                    )
                }
            };
        }

        return newValues;
    }

    async function updateNestedRelation(orgValues, newValues) {
        let returnValues = [];
        let isNewGrid = false;
        for (let i = 0; i < newValues.length; i++) {
            let newGrid = newValues[i];
            let newGridImages = newValues[i].images;
            let newGridVideos = newValues[i].videos;

            // POST A NEW GRID
            if (!newGrid.id) {
                isNewGrid = true;
                newGrid['images'] = [];
                newGrid['videos'] = [];
                await dispatch(postProjectGrid(newGrid)).then((response) => {
                    newGrid.id = response.id;
                    newGridImages = newGridImages.map(el => {
                        var img = Object.assign({}, el);
                        img.project_grid = response.id;
                        return img;
                    })
                    newGridVideos = newGridVideos.map(el => {
                        var video = Object.assign({}, el);
                        video.project_grid = response.id;
                        return video;
                    })
                })
            }

            for (let j = 0; j < newGridImages.length; j++) {
                let newImage = newGridImages[j];
                if (newImage.id && !isNewGrid) {
                    // FETCH THE FILE
                    if (typeof newImage.file === 'string') {
                        let fileName = newImage.file;
                        // fetch the existing file on the server related to filename
                        await fetch(newImage.file)
                            .then(res => res.blob())
                            .then(blob => {
                                // PUT IMAGE
                                dispatch(putProjectImage(newImage, blob, fileName))
                        })
                        .catch((error) => {
                            if (newImage.file) {
                                dispatch(postProjectImage(newImage, newImage.file, newImage.file.name)).then(response =>
                                    // ADD IMAGE TO GRID
                                    newGrid.images.push(response)
                                )
                            }
                            Promise.reject(error);
                        })
                    } else if (newImage.file && typeof newImage.file === 'object') {
                        dispatch(putProjectImage(newImage, newImage.file, newImage.file.name))
                    }
                } else {
                    if (newImage.file) {
                        // POST IMAGE
                        await dispatch(postProjectImage(newImage, newImage.file, newImage.file.name)).then(response =>
                            // ADD IMAGE TO GRID
                            newGrid.images.push(response)
                        )
                    }
                }
            }

            for (let j = 0; j < newGridVideos.length; j++) {
                let newVideo = newGridVideos[j];
                if (newVideo.id && !isNewGrid) {
                    // FETCH THE FILE
                    if (typeof newVideo.video === 'string') {
                        let fileName = newVideo.video;
                        // fetch the existing file on the server related to filename
                        await fetch(newVideo.video + '/')
                            .then(res => res.blob())
                            .then(blob => {
                                // PUT IMAGE
                                dispatch(putProjectVideo(newVideo, blob, fileName))
                        })
                        .catch((error) => {
                            if (newVideo.video) {
                                dispatch(postProjectVideo(newVideo, newVideo.video, newVideo.video.name)).then(response =>
                                    // ADD IMAGE TO GRID
                                    newGrid.videos.push(response)
                                )
                            }
                            Promise.reject(error);
                        })
                    } else if (newVideo.video && typeof newVideo.video === 'object') {
                        dispatch(putProjectVideo(newVideo, newVideo.video, newVideo.video.name))
                    }
                } else {
                    if (newVideo.video) {
                        // POST IMAGE
                        await dispatch(postProjectVideo(newVideo, newVideo.video, newVideo.video.name)).then(response =>
                            // ADD IMAGE TO GRID
                            newGrid.videos.push(response)
                        )
                    }
                }
            }
            
            returnValues.push(newGrid);
            isNewGrid = false;
        }

        setGridsValue(returnValues);
        return returnValues;
    }

    async function handleSubmit() {
        let error = false;
        // check validaty of fields
        if (titleValue === '') {
            setTitleErrorValue('Veld verplicht')
            error = true;
        } else {
            setTitleErrorValue('')
        }

        if (contentValue === '') {
            setContentErrorValue('Veld verplicht')
            error = true;
        } else {
            setContentErrorValue('')
        }

        const websiteLink = websiteValue.includes('https://') ? websiteValue : 'https://' + websiteValue;
        if (websiteValue && !isValidHttpUrl(websiteLink)) {
            setWebsiteErrorValue('Ongeldige link');
            error = true;
        } else {
            setWebsiteErrorValue('');
        }

        const newTypeValues = await updateRelation(typeValue, postProjectType);
        const newSpecificTypeValues = await updateRelation(specificTypeValue, postSpecificProjectType);
        const newKeywords = await updateRelation(keywordValue, postKeyword);
        const newGrids = await updateNestedRelation(project.grids, gridsValue);

        let newProject = {
            'title': titleValue,
            'content': contentValue,
            'website_link': websiteValue ? websiteLink : websiteValue,
            'website_text': websiteTextValue,
            'company_name': companyValue,
            'is_recent': recentValue,
            'created': dateValue,
            'types': newTypeValues.map(value => value.id),
            'specific_types': newSpecificTypeValues.map(value => value.id),
            'keywords': newKeywords.map(value => value.id),
            'grids': newGrids.map(value => value.id),
            'reviews': reviewValue.map(value => value && value.id),
        }

        if (!error) {
            if (!post) {
                newProject['id'] = project.id;
                await dispatch(putProject(newProject)).then((data) => {
                    if (data.id) {
                        dispatch(getProject(project.id))
                    }
                })
            } else {
                dispatch(postProject(newProject)).then(dispatch(getProjects()))
            }
        }
    }
    
    return (
        <Box component='form' validate>
            <Field>
                <Typography fontWeight='500' style={{ width: '20vw'}}>Project naam</Typography>
                <TextField
                    style={{ width: '50vw'}}
                    required
                    id="outlined-required"
                    value={titleValue}
                    error={!!titleErrorValue}
                    helperText={titleErrorValue}
                    onChange={(e) => setTitleValue(e.target.value)}
                    label='Project naam'
                />
            </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Inhoud</Typography>
                    <Typography>{'Vul hier de tekst in, inclusief de enters. Als je een link in de tekst wil, omring dan het klikwoord bijv. "klik hier" met <Link to=/diensten/branding>klik hier</Link>.'}</Typography>
                </div>
                <TextField 
                    style={{ width: '50vw'}}
                    id="outlined-required"
                    value={contentValue}
                    error={!!contentErrorValue}
                    helperText={contentErrorValue}
                    onChange={(e) => setContentValue(e.target.value)}
                    required
                    multiline
                    label='Inhoud'
                />
            </Field>
            <Field>
                <Typography fontWeight='500' style={{ width: '20vw'}}>Bedrijfsnaam</Typography>
                <TextField
                    style={{ width: '50vw'}}
                    id="outlined-required"
                    value={companyValue}
                    onChange={(e) => setCompanyValue(e.target.value)}
                    label='Bedrijfsnaam'
                />
            </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Website link</Typography>
                    <Typography>{'Bijv. www.valeriemaas.nl'}</Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '50vw'}}>
                    <Typography style={{ marginRight: '10px' }}>https://</Typography>
                    <TextField
                        style={{ width: '100%'}}
                        id="outlined-required"
                        value={websiteValue}
                        error={!!websiteErrorValue}
                        helperText={websiteErrorValue}
                        onChange={(e) => setWebsiteValue(e.target.value)}
                        label='Website link'
                    />
                </div>
            </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Website tekst</Typography>
                    <Typography>{'Bijv. Webdesign in samenwerking met ...'}</Typography>
                </div>
                <TextField
                    style={{ width: '50vw' }}
                    id="outlined-required"
                    value={websiteTextValue}
                    onChange={(e) => setWebsiteTextValue(e.target.value)}
                    label='Website tekst'
                />
            </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Recent</Typography>
                    <Typography>{'Vink dit aan als je dit project in de recente projecten lijst wil laten zien.'}</Typography>
                </div>
                <Checkbox
                    checked={recentValue}
                    onChange={(e) => setRecentValue(e.target.checked)}
                />
            </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Aanmaakdatum</Typography>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label="Aanmaakdatum"
                        value={dayjs(dateValue)}
                        onChange={(e) => setDateValue(e.format('YYYY-MM-DDTHH:mm:ss'))}
                        renderInput={(params) => <TextField {...params} />}
                        ampm={false}
                        required
                    />
                </LocalizationProvider>
            </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Types</Typography>
                </div>
                <AutoComplete
                    value={typeValue} 
                    setValue={(value) => setTypeValue(value)}
                    optionList={projectTypes} 
                    label='Types' 
                    optionName='content' 
                    style={{ width: '50vw' }} 
                />
            </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Specifieke types</Typography>
                </div>
                <AutoComplete
                    value={specificTypeValue} 
                    setValue={(value) => setSpecificTypeValue(value)}
                    optionList={specificProjectTypes} 
                    label='Specifieke types' 
                    optionName='content' 
                    style={{ width: '50vw' }} 
                />
            </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Keywords</Typography>
                </div>
                <AutoComplete
                    value={keywordValue} 
                    setValue={(value) => setKeywordValue(value)}
                    optionList={keywords} 
                    label='Keywords' 
                    optionName='content' 
                    style={{ width: '50vw' }} 
                />
            </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Reviews</Typography>
                </div>
                {reviews.length > 0 ? <Select
                    value={(reviewValue[0] && reviews.filter(r => Number(r.id) === reviewValue[0].id)[0]) || ''}
                    label="Review"
                    onChange={(e) => e.target.value ? setReviewValue([e.target.value]) : setReviewValue([])}
                    style={{ width: '50vw' }} 
                >
                    {reviews.map((review, i) => (
                        <MenuItem key={i} value={review}>{review.title}</MenuItem>
                    ))}
                    <MenuItem value={null}>Geen</MenuItem>
                </Select> : <Typography>Geen reviews gevonden om te selecteren</Typography>}
            </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Raster</Typography>
                </div>
                <ProjectImages 
                    grids={gridsValue}
                    setGrids={setGridsValue}
                />
            </Field>
            <div style={{ display: 'flex', width: '75vw', justifyContent: 'right', gap: '1vw'}}>
                {!post && <Button onClick={() => setDialogOpen(true)} variant='contained' startIcon={<DeleteIcon/>}>Verwijderen</Button>}
                <Button onClick={handleSubmit} variant='contained' startIcon={<EditIcon />}>{post ? 'Aanmaken' : 'Aanpassen'}</Button>
            </div>
            <Dialog
                open={dialogOpen}
                handleCancel={() => setDialogOpen(false)}
                handleOk={delProject}
                title={'Project verwijderen'}
                content={'Weet je zeker dat je dit project wil verwijderen?'}
            />
        </Box>
    )
}