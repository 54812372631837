import axiosInstance from './axios';
import { getFilters } from './helpers';
import { GET_PROJECT_OVERVIEW } from './types';

const getTypes = (projects) => {
  const types = projects.map(p => p.types).flat();
  return types;
}

const getNextPageNmb = (projectObject) => {
  const nextUrl = projectObject.next;
  if (nextUrl) {
    return Number(nextUrl.split('page=')[1][0]);
  }
  return null;
}

// GET PORTFOLIO OVERVIEW
export const getProjectOverview = (filters) => async dispatch => {
    const filter = getFilters(filters)
    
    const res = await axiosInstance.get(`/api/project_overview/?${filter}`);
    const types = getTypes(res.data.results);
    const nextPage = getNextPageNmb(res.data);
    dispatch({
      type: GET_PROJECT_OVERVIEW,
      payload: res.data,
      payloadTypes: types,
      payloadNextPage: nextPage,
    });
};