import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { PageTitle, LineButton, Typography } from '../../components';
import { useDispatch, useSelector } from 'react-redux';

import { colors } from '../../assets/colors';
import { getBlogs } from '../../actions/blogs';

const StyledImg = styled.img`
    position: absolute;
    width: 14vw;
    height: auto;
    top: 3vw;
    margin: 0 1vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 40vw;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        width: 18vw;
    }
`;

const Square = styled.div`
    position: absolute;
    width: 14vw;
    height: 14vw;
    top: 3vw;
    z-index: 10;
    margin: 0 1vw;
    overflow: hidden;
    background: transparent;

    :before {
        content: '';
        position: absolute;
        bottom: 0%;
        width: 100%;
        height: 100%;
        border-radius: 100% 100% 0 0;
        box-shadow: 0px 5vw 0px 15vw ${colors.background};
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 40vw;
        height: 40vw;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        width: 18vw;
        height: 18vw;
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    position: relative;
    cursor: pointer;
    
    &:hover {
        font-style: italic;
    }
`;

export default function Blog(props) {
    const dispatch = useDispatch();

    const blogsObject = useSelector((state) => state.blogs) || {};
    const blogs = (blogsObject && blogsObject.blogs && blogsObject.blogs.results) || [];
    const showBlogs = blogs.slice(0, 2);

    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    useEffect(() => {
        const filters = { published: true } 
        dispatch(getBlogs(filters))
    }, [dispatch])

    if (hasPhone) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100vw', alignItems: 'center', margin: '10vw 0', position: 'relative'}}>
                <Typography variant='h2'>Blog</Typography>
                {showBlogs[1] && <div style={{ position: 'absolute', right: '20vw', top: '10vw' }}>
                    <StyledLink to={`/blog/${showBlogs[1].id}`}>
                        <div style={{ position: 'absolute' }}>
                            <Square />
                            <StyledImg src={showBlogs[1].oval_image} alt='blog artikel foto' />
                        </div>
                        <Typography style={{ position: 'absolute', zIndex: '1', bottom: '-52vw', maxWidth: '40vw', margin: '0 0 0 1vw', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{showBlogs[1].title}</Typography>
                    </StyledLink>
                    <LineButton to='/blog' style={{ margin: '5vw 0 0 1vw'}}>Bekijk de blog</LineButton>
                </div>}
                {showBlogs[0] && <div style={{ position: 'absolute', left: '10vw', top: '40vw' }}>
                    <StyledLink to={`/blog/${showBlogs[0].id}`} style={{ textDecoration: 'none'}}>
                        <img src={showBlogs[0].main_image} alt='blog artikel foto' style={{ width: '40vw', height: '40vw'}} />
                        <Typography style={{ margin: '0.5vw 0 0 0', maxWidth: '40vw', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{showBlogs[0].title}</Typography>
                    </StyledLink>
                </div>}
                <LineButton to='/blog' style={{ margin: '85vw 0 0'}}>Bekijk de blog</LineButton>
            </div>
        )
    }

    return (
        <PageTitle title='blog' left='15px'>
            <div style={{ height: hasTablet ? '20vw' : '15vw', position: 'relative'}}>
                {showBlogs[1] && <div style={{ position: 'absolute', height: '20vw', right: hasTablet ? '12vw' : '18vw', top: hasTablet ? '-16vw' : '-12vw' }}>
                    <StyledLink to={`/blog/${showBlogs[1].id}`}>
                        <div style={{ position: 'absolute' }}>
                            <Square />
                            <StyledImg src={showBlogs[1].oval_image} alt='blog artikel foto' />
                        </div>
                        <Typography style={{ position: 'absolute', zIndex: '1', bottom: hasTablet ? '-25vw' : '-20vw', maxWidth: hasTablet ? '18vw' : '14vw', margin: '0 0 0 1vw', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{showBlogs[1].title}</Typography>
                    </StyledLink>
                    <LineButton to='/blog' style={{ margin: '5vw 0 0 1vw'}}>Bekijk de blog</LineButton>
                </div>}
                {showBlogs[0] && <div style={{ position: 'absolute', right: '27vw', top: '-1vw' }}>
                    <StyledLink to={`/blog/${showBlogs[0].id}`} style={{ textDecoration: 'none'}}>
                        <img src={showBlogs[0].main_image} alt='blog artikel foto' style={{ width: hasTablet ? '18vw' : '15vw', height: hasTablet ? '18vw' : '15vw'}} />
                        <Typography style={{ margin: '0.5vw 0 0 0', maxWidth: hasTablet ? '18vw' : '15vw', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{showBlogs[0].title}</Typography>
                    </StyledLink>
                </div>}
                <div style={{ position: 'absolute', right: hasTablet ? '10vw' : '16vw', bottom: hasTablet ? '1vw' : '-1vw' }}>
                    <LineButton to='/blog'>Bekijk de blog</LineButton>
                </div>
            </div>
        </PageTitle>
    )
    
}