import { GET_DOCUMENT, GET_DOCUMENTS } from '../actions/types';

const documents = (state = {}, action) => {
  switch (action.type) {
    case GET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    case GET_DOCUMENT:
      let newDocumentObject = {};
      if (state.documents && state.documents.results) {
        const oldDocuments = (state.documents && state.documents.results) || [];
        const oldDocument = oldDocuments.filter(s => Number(s.id) === Number(action.payload.id))[0];
        const idList = oldDocuments.indexOf(oldDocument);

        let newDocuments = oldDocuments;
        newDocuments[idList] = action.payload;

        newDocumentObject = state.documents;
        newDocumentObject['results'] = newDocuments;
      } else {
        newDocumentObject['results'] = [action.payload];
      }

      return {
        ...state,
        documents: newDocumentObject,
      }
    default:
      return state;
  }
};

export default documents;