import React from 'react';
import { Typography, Button, PageTitle, LineButton, BulletPoint } from '../../components';
import { Grid } from '@mui/material';
import { colors } from '../../assets/colors';
import StartTextPhoto from './StartTextPhoto';

import image from '../../assets/images/diensten/branding/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-full-branding-en-web.jpg';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Blog, Instagram, LieveWoorden, Match, Nieuwsgierig, RecenteProjecten, Shop } from '../../subpages';
import Document from '../voorwaarden/Document';
import { getCsvDienstRow } from '../../helpers/diensten';

const StyledLink = styled(Link)`
    display: inline;
    text-decoration: none;
    color: ${colors.black};
    position: relative;
    cursor: pointer;
    
    &:hover {
        font-style: italic;
    }
`;

export default function FullBrandingWeb(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;
    const dienstenCsv = props.dienstenCsv;

    const fullBrandingWebRow = getCsvDienstRow(dienstenCsv, 'full branding en web');
    const ansichtkaartRow = getCsvDienstRow(dienstenCsv, 'ansichtkaart');
    const flyerRow = getCsvDienstRow(dienstenCsv, 'flyer');
    const productverpakkingRow = getCsvDienstRow(dienstenCsv, 'productverpakking');
    const socialMediaTemplateRow = getCsvDienstRow(dienstenCsv, 'social media templates');
    const socialContentBoostRow = getCsvDienstRow(dienstenCsv, 'social content boost');
    const emailHandtekeningRow = getCsvDienstRow(dienstenCsv, 'e-mailhandtekening');
    const logoanimatieRow = getCsvDienstRow(dienstenCsv, 'logo animatie');
    const fotografieRow = getCsvDienstRow(dienstenCsv, 'fotografie');
    const juridischNLRow = getCsvDienstRow(dienstenCsv, 'juridische documenten NL');
    const juridischBERow = getCsvDienstRow(dienstenCsv, 'juridische documenten BE');

    function DienstenType(props) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: hasPhone ? '10vw' : hasTablet ? '7vw' : '5vw' }}>
                <Typography variant='h2'>{props.title}</Typography>
                <Typography style={{ width: hasPhone ? '80vw' : hasTablet ? '45vw' : '30vw', marginBottom: '3vw'}}>
                   {props.content}
                </Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: hasTablet ? '5vw' : '3vw' }}>
                    {props.types && props.types.map((type, i) => {
                        return (
                            <div key={i} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexWrap: 'wrap', width: hasPhone ? '75vw' : hasTablet ? '25vw' : '20vw'}}>
                                <Typography variant='h2'>{type}</Typography>
                                {props.typesText && props.typesText[i] && <Typography>{props.typesText[i]}</Typography>}
                                <Typography variant='sub'>{props.typePrices[i]}</Typography>
                                {props.links && props.links[i] && <LineButton to={props.links[i]}>Meer weten?</LineButton>}
                            </div>
                        )
                    })}
                </div>
                <Button link='/contact' style={{ width: hasPhone ? '80vw' : hasTablet ? '45vw' : '30vw', marginTop: hasPhone ? '5vw' : hasTablet ? '3vw' : '1vw'}}>Yes! Dit wil ik bij mijn full branding en web pakket</Button>
                {props.children}
            </div>
        )
    }

    return (
        <div>
            <StartTextPhoto
                title={fullBrandingWebRow['dienst']}
                subtitle={`vanaf ${fullBrandingWebRow['prijs']} excl. btw`}
                image={image}
                {...props}
            >
                <Typography>
                Het pakket ‘Full branding en web’ is een compleet pakket voor ondernemers die klaar zijn om het écht serieus aan te pakken. Naast de elementen uit het ‘Full branding’ pakket, creëer ik samen met een van de webbouwers waar ik vaak mee samenwerk een unieke website (zonder templates). Dit pakket is ideaal als jij naast het ‘Full branding’ pakket jouw ideale klant ook online wil bereiken met een website die perfect bij jou en jouw verhaal past.
                </Typography>
                <Button link='/contact' style={{ marginTop: '2vw'}}>Yes! Dit wil ik</Button>
            </StartTextPhoto>
            <PageTitle title='wat krijg je?'>
                <Grid container justifyContent='space-evenly' alignItems='flex-start'>
                    <div style={{ width: hasPhone ? '80vw' : hasTablet ? '33vw' : '26vw', margin: hasTablet ? '3vw 0 7vw 0' : '3vw 0 5vw 0'}}>
                        <Typography variant='h2'>wat krijg je?</Typography>
                        <BulletPoint>Inspiratie-gesprek (online)</BulletPoint>
                        <BulletPoint>
                            <div style={{ display: 'inline-block'}}>
                                <div style={{ display: 'inline', marginRight: '5px'}}>Strategie-sessie (dagdeel op locatie)</div>
                                <StyledLink to='/diensten/brandingstrategie'>Lees meer</StyledLink>
                            </div>
                        </BulletPoint>
                        <BulletPoint>Moodboard</BulletPoint>
                        <BulletPoint>2 correctierondes</BulletPoint><br/>
                        <BulletPoint>Conceptvoorstel (online)</BulletPoint>
                        <BulletPoint>Logo`s, beeldmerk, tekstmerk, embleem en variaties</BulletPoint>
                        <BulletPoint>Kleurenpalet</BulletPoint>
                        <BulletPoint>Typografie selectie</BulletPoint>
                        <BulletPoint>5 illustraties of iconen</BulletPoint>
                        <BulletPoint>2 patronen</BulletPoint>
                        <BulletPoint>Favicon</BulletPoint>
                        <BulletPoint>Brandbook incl. strategie</BulletPoint>
                        <BulletPoint>Ontwerp van visitekaartje</BulletPoint>
                        <BulletPoint>
                            <div style={{ display: 'inline-block'}}>
                                <div style={{ display: 'inline', marginRight: '5px'}}>Ontwerp van 1 keuze uit de (online) zichtbaarheid-huisstijldragers</div>
                                <StyledLink to='/diensten/zichtbaarheid'>Lees meer</StyledLink>
                            </div>
                        </BulletPoint>
                        <BulletPoint>Brainstormsessie</BulletPoint>
                        <BulletPoint>Website ontwerp op maat</BulletPoint>
                        <BulletPoint>Responsive design</BulletPoint><br/>
                        <BulletPoint>6 gebouwde pagina’s (home, over mij, aanbod, realisaties, blog, contact, …)</BulletPoint>
                        <BulletPoint>Coming soon pagina</BulletPoint>
                        <BulletPoint>Koppeling e-mailmarketing</BulletPoint>
                        <BulletPoint>Koppeling Google Analytics</BulletPoint>
                        <BulletPoint>2 correctierondes</BulletPoint>
                    </div>
                    <div style={{ width: hasPhone ? '80vw' : hasTablet ? '33vw' : '26vw', margin: '3vw 0 8vw 0'}}>
                        <Typography variant='h2'>wat moet ik zelf doen?</Typography>
                        <BulletPoint>Vragen beantwoorden tijdens de strategie-sessie op locatie.</BulletPoint>
                        <BulletPoint>Een moodboard maken (op Pinterest) van bijvoorbeeld logo’s, lettertypes en kleuren die jij mooi vind en bij jouw bedrijf passen.</BulletPoint>
                        <BulletPoint>Teksten en fotografie aanleveren. Je kan ook kiezen voor een samenwerking met een van de ondernemers waar ik vaker mee samenwerk, zoals Studio Annemarije.</BulletPoint>
                        <BulletPoint>Feedback geven op ontwerpen.</BulletPoint>
                        <Typography variant='h2' style={{ marginTop: hasPhone ? '7vw' : '2vw'}}>bijkomende kosten</Typography>
                        <BulletPoint>Reiskosten (€0,19 per km)</BulletPoint>
                        <BulletPoint>Licenties voor Fonts / Web, indien van toepassing.</BulletPoint>
                    </div>
                </Grid>
            </PageTitle>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: hasPhone ? '10vw' : hasTablet ? '7vw' : '5vw' }}>
                <div style={{ width: hasPhone && '80vw'}}>
                    <Typography variant='h2'>wil jij de juiste klanten aantrekken op {!hasPhone && <br/>}een manier die écht bij jou past?</Typography>
                    <Button link='/contact' style={{ marginTop: hasTablet ? '2vw' : '1vw'}}>Yes! Dit wil ik</Button>
                </div>
            </div>
            <PageTitle title='Frequently asked questions' left='-60px'>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', width: '100%', margin: hasTablet ? '7vw 0vw' : '5vw 0' }}>
                    <Typography variant='h2'>veelgestelde vragen</Typography>
                    <Typography variant='h2'>branding</Typography>
                    <Document documentName='Veelgestelde vragen' typeName='Branding' style={{ textAlign: 'left', margin: '1vw 0'}} {...props} />
                    <Typography variant='h2'>website</Typography>
                    <Document documentName='Veelgestelde vragen' typeName='Website' style={{ textAlign: 'left', margin: '1vw 0'}} {...props} />
                    <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')}>Plan een gratis kennismakingsgesprek in</Button>
                </div>
            </PageTitle>
            <RecenteProjecten {...props} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: hasPhone ? '10vw' : hasTablet ? '7vw' : '5vw' }}>
                <Typography variant='h2' style={{  width: hasPhone ? '75vw' : hasTablet ? '45vw' : '30vw'}}>je kan je full branding en web uitbreiden {!hasPhone && <br/>}met bijvoorbeeld…</Typography>
                <Typography style={{  width: hasPhone ? '75vw' : hasTablet ? '45vw' : '30vw', marginBottom: '3vw'}}>Wil jij een mooie extra toevoegen aan je huisstijl, zodat je zowel fysiek als digitaal de juiste klanten aan kan trekken?</Typography>
            </div>
            <DienstenType
                title='drukwerk'
                content={
                    <div><span style={{ fontStyle: 'italic' }}>Print is dead.</span> Zeker niet! Wil jij naast een visitekaartje jouw klanten een ansichtkaart opsturen om ze te bedanken? Cadeaubonnen verkopen? Of een te gek product op de markt zetten waar ook een unieke verpakking om heen moet?<br/><br/>
                        Ik help je graag bij het ontwerpen van jouw drukwerk! Hieronder geef ik een aantal voorbeelden, maar ik help je graag met al jouw dromen waar maken! Daarnaast kijk ik graag met je mee naar passend papier en een goede (betaalbare) drukkerij.</div>}
                types={['ansichtkaart of \ncadeaubon', 'flyers of ander \npromotiemateriaal', 'product\nverpakkingen']}
                typePrices={[`${ansichtkaartRow['prijs']} excl. btw`, `Vanaf ${flyerRow['prijs']} excl. btw`, `Vanaf ${productverpakkingRow['prijs']} excl. btw`]}
            />
            <DienstenType
                title='social media'
                content={
                    'Consistent aanwezig zijn op een unieke manier, zonder elke week uren te besteden aan het uitzoeken van de goede afbeelding, de kleuren of het lettertype? Naast het maken van templates voor jouw social media, kan ik ook het visuele gedeelte van de social media uit handen nemen in de vorm van het maand-abonnement Social Grow of Social Impact.'}
                types={[socialMediaTemplateRow['dienst'], socialContentBoostRow['dienst']]}
                typePrices={[`Vanaf ${socialMediaTemplateRow['prijs']} excl. btw`, `Vanaf ${socialContentBoostRow['prijs']} excl. btw`]}
                links={['/diensten/socialmediatemplate', '/diensten/socialcontentboost']}
            />
            <DienstenType
                title='digitaal zichtbaar zijn'
                content={
                    'Ook als mensen je in de digitale wereld tegenkomen wil je een goede indruk maken. Dit kan bijvoorbeeld op je website zijn, maar denk ook aan een online presentatie. Hiervoor kunnen we bijvoorbeeld een template ontwikkelen.'}
                types={[emailHandtekeningRow['dienst'], logoanimatieRow['dienst']]}
                typePrices={[`Vanaf ${emailHandtekeningRow['prijs']} excl. btw`, `Vanaf ${logoanimatieRow['prijs']} excl btw`]}
                links={['/diensten/emailhandtekening', '/diensten/logoanimatie']}
            >
                <Button link='/contact' style={{ marginTop: hasPhone ? '3vw' : '1vw', maxWidth: hasPhone && '86vw'}}>Ik heb nog een ander te gek idee! Laten we brainstormen</Button>
            </DienstenType>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: hasPhone ? '10vw' : hasTablet ? '7vw' : '5vw' }}>
                <Typography variant='h2'>denk je ook aan...</Typography>
                <Typography style={{ width: hasPhone ? '75vw' : hasTablet ? '45vw' : '30vw', marginBottom: hasPhone ? '4vw' : hasTablet ? '3vw' : '2vw'}}>Om jouw branding compleet te maken zijn een paar dingen heel essentieel, denk bijvoorbeeld aan goede fotografie. Ook dien je juridisch in orde te zijn met je business. Aangezien ik graag samenwerk met andere ondernemers, link ik je hieronder naar een aantal mensen waar ik graag mee samenwerk!</Typography>
                <Grid container justifyContent='center' flexWrap='wrap' alignItems='flex-start' gap={hasTablet ? '5vw' : '3vw'} style={{ marginBottom: hasTablet ? '3vw' : '1vw'}}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', alignItems: 'center', width: hasPhone ? '80vw' : hasTablet ? '35vw' : '20vw'}}>
                        <Typography variant='h2'>{fotografieRow['dienst']}</Typography>
                        <Typography>Voor een te gekke branding shoot samen<br/> met Studio Annemarije</Typography>
                        <Typography variant='sub'>Vanaf {fotografieRow['prijs']} excl. btw</Typography>
                        <LineButton to='/contact'>Meer weten?</LineButton>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: hasPhone ? '80vw' : hasTablet ? '35vw' : '20vw'}}>
                        <Typography variant='h2'>juridische documenten</Typography>
                        <Typography>Voor Nederlandse ondernemers samen <br/>met Schmitz Juridische Dienstverlening</Typography>
                        <Typography variant='sub'>Vanaf {juridischNLRow['prijs']} excl. btw</Typography>
                        <LineButton to='/contact' style={{ marginBottom: hasPhone ? '5vw' : '2vw'}}>Meer weten?</LineButton>
                    
                        <Typography>Voor Belgische ondernemers samen <br/>met The Legal House</Typography>
                        <Typography variant='sub'>Vanaf {juridischBERow['prijs']} excl. btw</Typography>
                        <LineButton to='/contact'>Meer weten?</LineButton>
                    </div>
                </Grid>
                <Button style={{ marginTop: hasPhone && '2vw' }} onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')}>Plan een gratis kennismakingsgesprek in</Button>
            </div>
            <Match {...props} />
            <LieveWoorden {...props} />
            <Shop {...props}/>
            <Blog {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}