import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Typography } from "../../components";
import { Grid } from "@mui/material";
import styled from 'styled-components';

import { getDocuments } from '../../actions/documents';
import { getMonth, getYear } from '../../helpers/datetime';

const ListAccordion = styled.div`
    margin-bottom: 20px;
    text-align: left;
`;

export default function Document(props) {
    const { documentName, typeName, titleHeight } = props;

    const dispatch = useDispatch();
    const documentObject = useSelector((state) => state.documents).documents || {};
    const documentHasContent = Object.keys(documentObject).length === 0;

    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    let document = null;
    if (typeName) {
        document = (documentObject && documentObject.results) || [];
        if (document.length > 0) {
            document = document.filter(v => v.sub_type === typeName)[0];
        }
    } else {
        document = (documentObject && documentObject.results && documentObject.results[0]) || [];
    }

    useEffect(() => {
        documentName && documentHasContent && dispatch(getDocuments(documentName));
    }, [dispatch, documentHasContent, documentName]);

    const plotAccordions = (list) => {
        return (
            list.map((artikel, i) => 
                <ListAccordion key={i}>
                    <Accordion key={ `1${i}`} articleText={artikel.article_number} mainText={artikel.title} detailText={artikel.content} />
                </ListAccordion>
            )
        )
    }

    if ((!document || Object.keys(document).length === 0) || (document && document.document_articles.length === 0)) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', height: titleHeight}}>
                <Typography>Geen document gevonden</Typography>
            </div>
        );
    } else if (hasPhone || hasTablet) {
        const articles = document && document.document_articles;
        return (
            <div style={props.style}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {document.created ?
                    <Typography variant='sub' style={{ marginBottom: '5vw'}}>{getMonth(new Date(document.created))} {getYear(new Date(document.created))}</Typography> :
                    <div style={{ marginBottom: '3vw'}} />}
                </div>
                <div style={{ width: '80vw', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', textAlign: 'left'}}>
                    {plotAccordions(articles)}
                </div>
            </div>
        )
    } else {
        const articles = document && document.document_articles;
        const m = Math.ceil(articles.length / 2);
        const [leftArticles, rightArticles] = [articles.slice(0, m), articles.slice(m, articles.length)];

        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', height: titleHeight }}>
                {document.created ?
                 <Typography variant='sub' style={{ marginBottom: '1vw'}}>{getMonth(new Date(document.created))} {getYear(new Date(document.created))}</Typography> :
                 <div style={{ marginBottom: '1vw'}} />}
                <Grid
                    container
                    justifyContent='center'
                    columnSpacing={6}
                >
                    <Grid item xs={3}>
                        {plotAccordions(leftArticles)}
                    </Grid>
                    <Grid item xs={3}>
                        {plotAccordions(rightArticles)}
                    </Grid>
                </Grid>
            </div>
        )
    } 
}