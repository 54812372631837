import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Typography, ImageArch, Button, Accordion, PageTitle, LineButton, BulletPoint } from '../../components';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../assets/colors';

import stella from '../../assets/images/diensten/typografie/Studio-Valerie-Maas-grafisch-ontwerp-diensten-typografie-1.jpg';
import holistic from '../../assets/images/diensten/typografie/Studio-Valerie-Maas-grafisch-ontwerp-diensten-typografie-2.jpg';
import letter from '../../assets/images/diensten/typografie/Studio-Valerie-Maas-grafisch-ontwerp-diensten-typografie-3.jpg';
import handschrift from '../../assets/images/diensten/typografie/Studio-Valerie-Maas-grafisch-ontwerp-diensten-typografie-handschrift.jpg';
import lettertype from '../../assets/images/diensten/typografie/Studio-Valerie-Maas-grafisch-ontwerp-diensten-typografie-lettertype-op-maat.jpg';

import { ReactComponent as Ipad } from '../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_ipad.svg';
import { Blog, Instagram, LieveWoorden, Match, Nieuwsgierig, RecenteProjecten, Shop } from '../../subpages';
import { getCsvDienstRow } from '../../helpers/diensten';

const StyledLink = styled(Link)`
    display: inline;
    text-decoration: none;
    color: ${colors.black};
    position: relative;
    cursor: pointer;
    
    &:hover {
        font-style: italic;
    }
`;

const CustomLineButton = styled.div`
    font-family: Mulish;
    font-size: 13px;
    font-weight: ${props => props.active ? '600' : '300'};
    text-transform: uppercase;
    text-decoration: none;
    color: ${colors.photo};
    letter-spacing: 1.4px;
    text-decoration: underline;
    text-underline-offset: 5px;
    margin: ${props => props.margin || '1vw'};

    &:hover {
        cursor: pointer;
        font-weight: 600;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 11px;
        padding-bottom: 2px;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 11px;
        padding-bottom: 1px;
    }
`;

export default function Typografie(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;
    const dienstenCsv = props.dienstenCsv;

    const lettertypeRow = getCsvDienstRow(dienstenCsv, 'lettertype op maat');
    const handschriftRow = getCsvDienstRow(dienstenCsv, 'handschrift');
    const handgeschrevenRow = getCsvDienstRow(dienstenCsv, 'handgeschreven');
    const glyphsRow = getCsvDienstRow(dienstenCsv, 'missende glyphs');

    const opMaatRef = useRef(null);
    const handschriftRef = useRef(null);
    const linkRef = useRef(null);

    const executeScroll = (myRef, offSet=150) => {
        myRef && myRef.current && window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop - offSet })
    };
    
    function BrandingPage(props) {
        const { pageTitle, title, titleMargin, text, price, accordionText, bulletPoints, link, image, even } = props;

        return (
            <PageTitle title={pageTitle} left={titleMargin}>
                <Grid container justifyContent='space-evenly' alignItems='flex-start' style={{ flexDirection: even ? 'row-reverse' : 'row', margin: '8vw 0'}}>
                    <div style={{ width: hasPhone ? '85vw' : hasTablet ? '37vw' : '25vw', margin: even ? '3vw 0 0 3vw' : '3vw 0 0 5vw'}}>
                        <Typography variant='h2' >{title}</Typography>
                        <Typography variant='sub' style={{ fontSize: '12px', margin: hasPhone ? '3vw 0' : '0' }}>{price} excl. btw</Typography>
                        <Typography style={{ margin: hasPhone ? '4vw 0' : '2vw 0'}}>{text}</Typography>
                        <Accordion 
                            mainTextStyle={{ marginLeft: '0'}}
                            detailTextStyle={{ marginBottom: '0', marginLeft: '0' }}
                            textStyle={{ marginBottom: '0' }}
                            mainText='wat krijg je?'
                            detailText={accordionText}
                            bulletPoints={bulletPoints}
                        />
                        <Button link={link} style={{ marginTop: hasPhone ? '3vw' : '1vw'}}>Yes! Ik wil meer lezen</Button>
                    </div>
                    <ImageArch src={image} alt={title} width={hasPhone ? '80vw' : hasTablet ? '35vw' : '23vw'} style={{ marginTop: hasPhone ? '7vw' : '0', marginLeft: even && !hasPhone ? '7vw' : '0' }} />
                </Grid>
            </PageTitle>
        )
    }

    return (
        <div>
            <Grid container justifyContent='space-evenly' alignItems='center' style={{ margin: '8vw 0 28vw 0'}}>
                <div style={{ width: hasPhone ? '85vw' : hasTablet ? '40vw' : '25vw', marginLeft: hasTablet ? '0' : '5vw'}}>
                    <Typography variant='h2'>typografie</Typography>
                    <Typography>Letters zijn overal. Maar welk lettertype is naast leesbaar ook passend voor jou? Door je eigen lettertype te laten ontwerpen, wat aansluit bij je huisstijl, zorg je ervoor dat je één verhaal naar buiten kan brengen. Jouw lettertype kan op den duur een herkenningspunt worden, net als je logo.</Typography>
                    <Button onClick={() => executeScroll(linkRef, 350)} style={{ marginTop: '2vw'}}>Bekijk de typografie pakketten</Button>
                </div>
                <div style={{ position: 'relative', width: hasPhone ? '99vw' : '25vw', height: hasPhone ? '85vw' : '100%'}}>
                    <img src={letter} alt='moodboard' style={{ position: 'absolute', width: hasPhone ? '35vw' : '15vw', top: hasPhone ? '65vw' : '12vw', left: hasPhone ? '25vw' : '3vw', zIndex: '999' }} />
                    <img src={holistic} alt='logo design' style={{ position: 'absolute', width: hasPhone ? '35vw' : '15vw', top: hasPhone ? '40vw' : '0', left: hasPhone ? '55vw' : '16vw', zIndex: '998' }} />
                    <ImageArch src={stella} alt='Website voorbeeld' width={hasPhone ? '35vw' : '15vw'} style={{ position: 'absolute', top: hasPhone ? '10vw' : '-12vw', left: hasPhone ? '12.5vw' : '-2vw' }} />
                </div>
            </Grid>
            <div ref={linkRef}>
                <Grid container direction='column' justifyContent='center' alignItems='center' style={{ position: 'relative'}}>
                    <CustomLineButton onClick={() => executeScroll(opMaatRef)} margin={hasPhone ? '3vw 0' : '1vw 0'}>Lettertype op maat</CustomLineButton>
                    <CustomLineButton onClick={() => executeScroll(handschriftRef)} margin={hasPhone ? '3vw 0' : '1vw 0'}>Handschrift</CustomLineButton>
                    {!hasPhone && <Ipad style={{ fill: colors.illustration, position: 'absolute', left: '8vw', top: '-13vw', width: hasTablet ? '10vw' : '8vw'}} />}
                </Grid>
            </div>
            <div ref={opMaatRef}>
                <BrandingPage 
                    pageTitle={lettertypeRow['dienst']}
                    titleMargin='-45px'
                    title={lettertypeRow['dienst']}
                    price={`Vanaf ${lettertypeRow['prijs']}`}
                    text='Een lettertype geheel vormgegeven naar jouw eigen wensen. Alleen cijfers of kapitalen? Een ringel-s of andere talen? Verschillende diktes en een italic? We overleggen tijdens het inspiratie-gesprek de mogelijkheden en creëren op die manier een lettertype wat perfect bij jou en jouw bedrijf past. '
                    bulletPoints={
                        <div>
                            <BulletPoint>Inspiratie-gesprek (online)</BulletPoint>
                            <BulletPoint>2 correctierondes</BulletPoint><br/>
                            <BulletPoint>Conceptvoorstel (online)</BulletPoint>
                            <BulletPoint>Jouw lettertype als TTF, OTF, WOFF2, WOFF</BulletPoint>
                            <BulletPoint>Inspiratie-gids, waarin je een idee krijgt hoe jij jouw lettertype het beste kan gebruiken.</BulletPoint>
                        </div>
                    }
                    link='/diensten/lettertype'
                    image={lettertype}
                />
            </div>
            <div ref={handschriftRef}>
                <BrandingPage 
                    pageTitle={`Vanaf ${lettertypeRow['prijs']}`}
                    titleMargin='-20px'
                    title={handschriftRow['dienst']}
                    price={`Vanaf ${handschriftRow['prijs']}`}
                    text={`Een lettertype gebaseerd op basis van een (eigen) handschrift. Hoe kan je jouw verhaal nu nog meer eigen maken?\n\nAan de hand van enkele voorbeelden van jouw handschrift maak ik een lettertype wat er perfect op lijkt. Ik let daarbij op leesbaarheid en geef je tips hoe je deze het beste kan gebruiken.`}
                    bulletPoints={
                        <div>
                            <BulletPoint>Inspiratie-gesprek (online)</BulletPoint>
                            <BulletPoint>
                                <div style={{ display: 'inline-block'}}>
                                    <div style={{ display: 'inline', marginRight: '5px'}}>Strategie-sessie (dagdeel op locatie)</div>
                                    <StyledLink to='/diensten/brandingstrategie'>Lees meer</StyledLink>
                                </div>
                            </BulletPoint>
                            <BulletPoint>Moodboard</BulletPoint>
                            <BulletPoint>2 correctierondes</BulletPoint><br/>
                            <BulletPoint>Conceptvoorstel (online)</BulletPoint>
                            <BulletPoint>Jouw lettertype als TTF, OTF, WOFF2, WOFF</BulletPoint>
                            <BulletPoint>Inspiratie-gids, waarin je een idee krijgt hoe jij jouw lettertype het beste kan gebruiken.</BulletPoint>
                        </div>
                    }
                    link='/diensten/handschrift'
                    image={handschrift}
                    even
                />
            </div>
            <PageTitle title='extra opties' left='-17px'>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: hasPhone ? '15vw 0' : '0 0 5vw 0'}}>
                    <div style={{ textAlign: 'center', width: hasPhone ? '75vw' : hasTablet ? '45vw' : '30vw', marginBottom: hasTablet ? '3vw' : '2vw'  }}>
                        <Typography variant='h2'>extra opties</Typography> 
                        <Typography style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '0'}}>Wil jij naast je pakket een mooie toevoeging aan je huisstijl?</Typography>
                        <Typography>Of wil jij geen gebruik maken van pakket, maar losse ontwerpen laten maken?</Typography>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: hasTablet ? '2vw' : '8vw', margin: hasPhone ? '5vw 0' : '0 0 1vw 1vw'}}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: hasPhone ? '100vw' : 'default'}}>
                            <Typography variant='h2'>{handgeschrevenRow['dienst']}</Typography> 
                            <Typography variant='sub' style={{ fontSize: '12px', margin: hasPhone ? '2vw 0' : '0' }}>{handgeschrevenRow['prijs']} excl. btw</Typography>
                            <LineButton to='/diensten/handgeschreven'>Meer weten?</LineButton>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: hasPhone ? '100vw' : 'default'}}>
                            <Typography variant='h2'>{glyphsRow['dienst']}</Typography> 
                            <Typography variant='sub' style={{ fontSize: '12px', margin: hasPhone ? '2vw 0' : '0' }}>Vanaf {glyphsRow['prijs']} excl. btw</Typography>
                            <LineButton to='/diensten/glyphs'>Meer weten?</LineButton>
                        </div>
                    </div>
                    <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')}>Plan een gratis kennismakingsgesprek in</Button>
                </div>
            </PageTitle>
            <RecenteProjecten {...props} />
            <Match {...props} />
            <LieveWoorden {...props} />
            <Shop {...props} />
            <Blog {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}