import axiosInstance from './axios';
import { GET_KEYWORDS, POST_KEYWORD } from './types';
import { reportResponse } from './helpers';

// GET PROJECT TYPE
export const getKeywords = () => async dispatch => {
    const res = await axiosInstance.get(`/api/keywords/`);
    
    dispatch({
      type: GET_KEYWORDS,
      payload: res.data
    });
};

// POST PROJECT TYPE
export const postKeyword = (keyword) => async dispatch => {
  const res = await axiosInstance.post(`/api/keywords/`, keyword);
  
  return reportResponse(res, POST_KEYWORD, 'Keyword', dispatch);
};