import axiosInstance from './axios';
import { GET_BLOG_IDS } from './types';

const findAdjacentBlogs = (blogs, blogId) => {
  if (blogs.length > 0) {
    const blogIndex = blogs.findIndex(x => Number(x.id) === Number(blogId));
    const leftBlogId = blogs[blogIndex-1] || blogs[blogs.length - 1];
    const rightBlogId = blogs[blogIndex+1] || blogs[0];
    return [leftBlogId, rightBlogId]
  } else {
    return []
  }
}

// GET BLOG
export const getBlogIds = (blogId, filters) => async dispatch => {
  let filter = ''

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
          if (value) {
            if (value instanceof Array) {
              for (let i = 1; i < value.length; i++) {
                filter = filter = filter.concat(`&${key}=${value[i]}`)
              }
            } else {
              filter = filter.concat(`&${key}=${value}`)
            }
          }
      }
    }

  const res = await axiosInstance.get(`/api/blog_ids/?${filter}`);
  const blogIds = findAdjacentBlogs(res.data.results, blogId);

  dispatch({
    type: GET_BLOG_IDS,
    payload: res.data,
    blogIds: blogIds,
  });
};