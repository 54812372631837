
import styled from 'styled-components';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { colors } from '../../assets/colors';

const RotatedTitle = styled.div`
    font-family: Mulish;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1.4px;
    color: ${colors.title};
    position: absolute;
    z-index: 999;
    left: ${props => props.left || '0' };
    top: ${props => props.height > 0 ? props.height/2 + 'px' : '8vw'};
    transform: rotate(-90deg);
    text-align: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        visibility: hidden;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 10px;
        line-height: 1.3;
    }
`;

export default function PageTitle(props) {
    const [ height, setHeight ] = useState(null);
    const [ domNode, setDomNode ] = useState(null);
    const ref = useRef(null);

    const onRefChange = useCallback(node => {
        const oldHeight = node && domNode && domNode.getBoundingClientRect().height;
        const newHeight = node && node.getBoundingClientRect().height;
        if (oldHeight !== newHeight) {
            node && setDomNode(node);
            node && setHeight(newHeight);
        }
    }, [domNode]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setTimeout(() => { setHeight(domNode && domNode.getBoundingClientRect().height) }, 300)
        }
        return () => { isMounted = false };
    }, [domNode]);

    return (
        <div style={{ position: 'relative', height: 'auto' }} ref={ref}>
            <RotatedTitle height={height} left={props.left}>{props.title}</RotatedTitle>
            <div ref={onRefChange}>{props.children}</div>
        </div>
    )
}