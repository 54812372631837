import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';

const Spinner = styled.div`
    animation: ${props => props.isInView && 'spin 5000ms linear 1'};

    @keyframes spin {
        from {transform:rotate(0deg);}
        to {transform:rotate(360deg);}
    }
`;

export default function RotatingCircle(props) {
    const [ isInView, setInView ] = useState(false);
    const ref = useRef(null);
    const [ hasRotated, setRotated ] = useState(false);

    const handleScroll = useCallback(() => {
        const rect = ref.current && ref.current.getBoundingClientRect();
        const inView = rect && rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth) && !hasRotated;
                
        if (isInView && !inView && !hasRotated) {
            setRotated(true);
        }
        
        setInView(inView);
    }, [hasRotated, isInView]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return (
        <Spinner style={props.style} isInView={isInView}>
            <div ref={ref}>{props.children}</div>
        </Spinner>
    )
}