import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '..';
import { colors } from '../../assets/colors';

const Summary = styled.div`
    font-family: Mulish;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: ${colors.title};
    cursor: pointer;
    overflow-wrap: anywhere;

    &:hover {
        font-weight: 500;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 11px;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 11px;
    }
`;

const Arrow = styled(Summary)`
    margin: 0 10px 5px 10px;
    transform: ${props => props.isOpen ? props.variant === 'small' ? 'rotate(-90deg)' : 'rotate(90deg)' : 'none'};
    align-items: center;
`;

const SummaryText = styled(Summary)`
    font-family: Mulish;
    font-weight: 400;
    font-size: ${props => props.variant === 'small' ? '14px' : '16px'};
    text-transform: uppercase;
    color: ${colors.title};
    margin-bottom: 5px;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 12px;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 12px;
    }
`;

const DetailText = styled.div`
    font-family: Chivo;
    font-weight: 300;
    color: ${colors.black};
    margin-left: 10px;
    margin-bottom: 15px;
`;

const StyledLink = styled.div`
    display: inline;
    text-decoration: none;
    color: ${colors.black};
    position: relative;
    cursor: pointer;
    
    &:hover {
        font-style: italic;
    }
`;

export default function Accordion(props) {
    const { variant, open } = props;
    const [ isOpen, setOpen ] = useState(open);

    function processLink(content) {
        if (content.includes('<Link') || content.includes('<a')) {
            let linkType = null;
            if (content.includes('<Link')) {
                linkType = 'Link'
            } else if (content.includes('<a')) {
                linkType = 'a'
            }
      
            const leftString = '<' + linkType;
            const leftIndex = content.indexOf(leftString);
            const leftContent = [content.slice(0, leftIndex), content.slice(leftIndex + leftString.length)];
      
            const rightString = '</' + linkType + '>';
            const rightIndex = leftContent[1].indexOf(rightString);
            const rightContent = [leftContent[1].slice(0, rightIndex), leftContent[1].slice(rightIndex + rightString.length)];
      
            let urlContent = rightContent[0].split('>');
      
            let url = urlContent[0].split('=')[1];
            let text = urlContent[1];
      
            let newLinks = [processLink(leftContent[0]), { url: url, text: text, linkType: linkType }, processLink(rightContent[1])]
      
            return newLinks.reduce((a, b) => a.concat(b), []);
        }
        return [content];
    }

    return (
        <div onClick={() => setOpen(!isOpen)} style={{ margin: props.margin || '0 0 1vw 0', textAlign: props.variant === 'small' ? 'center' : 'default' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: variant === 'small' ? 'center' : 'flex-start'}}>
                {variant !== 'small' && <Arrow isOpen={isOpen} style={props.mainTextStyle}>{'>'}</Arrow>}
                <SummaryText variant={variant} style={props.mainTextStyle}>
                    {props.articleText && 'Artikel'} {props.articleText} 
                    <span style={{ fontFamily: 'Mulish', fontWeight: '500', marginLeft: props.articleText ? '5px' : '0'}}>{props.mainText}</span>
                </SummaryText>
                {variant === 'small' && <Arrow isOpen={isOpen} style={props.mainTextStyle} variant={variant}>{'<'}</Arrow>}
            </div>
            <DetailText style={props.detailTextStyle} key={props.mainText}>
                {isOpen && props.detailText && props.detailText.length > 0 && processLink(props.detailText).map((a, i) => {
                    if (typeof a === 'object' && a !== null) {
                        if (a.linkType === 'Link') {
                            return <StyledLink to={a.url} as={Link} target="_blank">{a.text}</StyledLink>
                        } else if (a.linkType === 'a') {
                            return <StyledLink href={a.url} as='a' target="_blank">{a.text}</StyledLink>
                        }
                        return null;
                    } else if (a.includes('<br/>')) {
                        return a.split('<br/>').map((t, j) => {
                            return <Typography key={j}>{t}</Typography>
                        })
                    } else {
                        return <Typography style={{ display: 'inline' }}>{a}</Typography>
                    }
                })}
            </DetailText>
            {isOpen && props.bulletPoints}
        </div>
    );
}