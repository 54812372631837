import axios from 'axios';
import { setCookie, getCookie, eraseCookie } from './cookies';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 5000,
    headers: {
        'Authorization': getCookie('access_token') ? "Bearer " + getCookie('access_token') : null,
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.response.use(
    response => response,
    error => {
      const originalRequest = error.config;
      
      if (error.response && error.response.status) {
        if (error.response.status === 401 && error.response.statusText === "Unauthorized") {
          // const refresh_token = localStorage.getItem('refresh_token');
          const refresh_token = getCookie('refresh_token');

          if (refresh_token && refresh_token !== 'undefined') {
            return axiosInstance
              .post('api/token/refresh/', {refresh: refresh_token})
              .then((response) => {
                  if (response['code'] === 'token_not_valid') {
                    eraseCookie('access_token');
                    eraseCookie('refresh_token');
                    setCookie('isAuthenticated', false, 30);

                    // localStorage.removeItem('access_token');
                    // localStorage.removeItem('refresh_token');
                    axiosInstance.defaults.headers['Authorization'] = null;
                    // localStorage.setItem('isAuthenticated', false);
                  }

                  if (localStorage.getItem('consent') !== false) {
                    setCookie('access_token', response.data.access, 30);
                    setCookie('refresh_token', refresh_token, 30);
                  } else {
                    return null;
                  }
                  
                  // localStorage.setItem('access_token', response.data.access);
                  // localStorage.setItem('refresh_token', refresh_token);

                  axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
                  originalRequest.headers['Authorization'] = "Bearer " + response.data.access;

                  // debugger;

                  return axiosInstance(originalRequest);
              })
              .catch(err => {
                // localStorage.removeItem('access_token');
                // localStorage.removeItem('refresh_token');
                eraseCookie('access_token');
                eraseCookie('refresh_token');
                setCookie('isAuthenticated', false, 30);
                axiosInstance.defaults.headers['Authorization'] = null;
                // localStorage.setItem('isAuthenticated', false);
              });
              
            } else {
                eraseCookie('access_token');
                eraseCookie('refresh_token');
                setCookie('isAuthenticated', false, 30);
                axiosInstance.defaults.headers['Authorization'] = null;
            }
         }
          
      }
      return error.response;
  }
);

export default axiosInstance