import axiosInstance from './axios';
import { PUT_PROJECT_VIDEO, POST_PROJECT_VIDEO } from './types';
import { reportResponse } from './helpers';
import { getCookie } from './cookies';

const getFormData = (object, blob, fileName) => Object.keys(object).reduce((formData, key) => {
    if (key === 'video') {
      formData.append(key, blob, fileName);
    } else {
      formData.append(key, object[key]);
    }
    
    return formData;
}, new FormData());

const config = {
  headers: {
    'Authorization': getCookie('access_token') ? "Bearer " + getCookie('access_token') : null,
  }
}

// PUT PROJECT IMAGE
export const putProjectVideo = (projectVideo, blob, fileName) => async dispatch => {
    const res = await axiosInstance.put(`/api/project_videos/${projectVideo.id}/`, getFormData(projectVideo, blob, fileName), config);
    
    return reportResponse(res, PUT_PROJECT_VIDEO, 'Project video', dispatch);
};

// POST PROJECT IMAGE
export const postProjectVideo = (projectVideo, blob, fileName) => async dispatch => {
  const res = await axiosInstance.post(`/api/project_videos/`, getFormData(projectVideo, blob, fileName), config);
  
  return reportResponse(res, POST_PROJECT_VIDEO, 'Project video', dispatch);
};