import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { Link } from 'react-router-dom';

const OvalHover = styled.button`
    font-family: Mulish;
    text-decoration: none;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    font-size: 13px;
    color: ${props => props.color || colors.photo};
    background-color: ${colors.button};
    border: none;
    padding: 8px;
    border-radius: 18px;
    outline: none;

    &:hover {
        color: ${props => props.hovercolor || 'white'};
        background-color: ${colors.photo};
        cursor: pointer;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 11px;
        letter-spacing: 1.1px;
        padding: 5px;
        width: max-content;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 11px;
        padding: 5px;
        letter-spacing: 1.1px;
    }
`;

export default function Button(props) {
    if (props.link) {
        return (
            <Link to={props.link}>
                <OvalHover rel="noreferrer" style={props.style} {...props}>{props.children}</OvalHover>
            </Link>
        )
    } else {
        return (
            <OvalHover href={props.href} target='_blank' rel="noreferrer" style={props.style} {...props}>{props.children}</OvalHover>
        )
    }
    
}