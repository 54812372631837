import { GET_PROJECT_IDS } from '../actions/types';

const projectIds = (state = {}, action) => {
  switch (action.type) {
    case GET_PROJECT_IDS:
      return {
        ...state,
        ...action.payload,
        projectIds: action.projectIds,
      };
    default:
      return state;
  }
};

export default projectIds;