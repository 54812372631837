import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Typography, Dialog } from '../../../components';
import { MenuItem, Select, TextField, Button } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

const FlexContainer = styled.div`
    display: flex;
    flex-direction: ${props => props.gridNmb === 1 ? 'column' : 'row'};
    gap: 2vw;
`;

const Container = styled.div`
    width: ${props => props.gridNmb === 1 ? '70%' : '31%'};
    border: 1px solid #BFBEBD;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 1vw;
`;

const ClickImg = styled.img`
    &:hover {
        cursor: ${props => props.editing ? 'pointer' : 'default'};
    }
`;

export default function BlogGrid(props) {
    const { grid, isEditing, updateContent, deleteGrid, cancelNewGrid } = props;

    const [ dialogOpen, setDialogOpen ] = useState(false);

    const contents = grid.images.concat(grid.contents).concat(grid.headers).concat(grid.videos).sort((a, b) => a.order - b.order);

    const gridNmb = Number(grid.grid);

    console.log(grid);

    return (
        <FlexContainer gridNmb={gridNmb}>
            {contents.map((content, i) => {
                if ('file' in content) {
                    return (
                        <Container key={i} gridNmb={gridNmb}>
                            <input
                                accept='image/*'
                                style={{ display: 'none' }}
                                id={`fileUploader-${grid.id}-${content.order}`}
                                onChange={(e) => updateContent('images', 'file', grid, content.order, e.target.files[0])}
                                type="file"
                                hidden
                            />
                            <ClickImg 
                                src={content.file && content.file.name ? URL.createObjectURL(content.file) : content.file} 
                                style={{ width: '100%' }} 
                                alt='afbeelding' 
                                editing={isEditing}
                                onClick={() => isEditing && document.getElementById(`fileUploader-${grid.id}-${content.order}`).click()}
                            />
                            <Select
                                value={content.shape}
                                label="Vorm"
                                onChange={(e) => updateContent('images', 'shape', grid, content.order, e.target.value) }
                                style={{ width: '100%'}}
                                disabled={!isEditing}
                            >
                                <MenuItem key={4} value='SQUARE'>Vierkant</MenuItem>
                                <MenuItem key={5} value='ARCH'>Boog</MenuItem>
                            </Select>
                        </Container>
                    )
                } else if ('title' in content) {
                    if (isEditing) {
                        return (
                            <Container key={i} gridNmb={gridNmb}>
                                <div>Tussenkop</div>
                                <TextField
                                    style={{ width: '90%'}}
                                    required
                                    id="outlined-required"
                                    value={content['title']}
                                    onChange={(e) => updateContent('headers', 'title', grid, content.order, e.target.value)}
                                    label='Titel'
                                />
                            </Container>
                        )
                    }
                    return (
                        <Container key={i} gridNmb={gridNmb}>
                            <div>Tussenkop</div>
                            <Typography variant='sub' margin='0' style={{ color: '#000'}}>{content.title}</Typography>
                        </Container>
                    )
                } else if ('content' in content) {
                    if (isEditing) {
                        return (
                            <Container key={i} gridNmb={gridNmb}>
                                <div>Tekst</div>
                                <TextField 
                                    style={{ width: '90%'}}
                                    id="outlined-required"
                                    value={content['content']}
                                    onChange={(e) => updateContent('contents', 'content', grid, content.order, e.target.value)}
                                    required
                                    multiline
                                    label='Tekst'
                                />
                            </Container>
                        )
                    }
                    
                    if (Array.isArray(content.content)) {
                        return content.content.map((value, i) => {
                            if (typeof value === 'object') {
                                return <Link to={value.url}>{value.text}</Link>
                            } else {
                                return <Typography>{value}</Typography>
                            }
                        })
                    }

                    return (
                        <Container key={i} gridNmb={gridNmb}>
                            <div>Tekst</div>
                            <Typography>{content.content}</Typography>
                        </Container>
                    )
                } else if ('video' in content) {
                    return (
                        <Container key={i} gridNmb={gridNmb}>
                            <div>Video</div>
                            <input
                                accept="video/mp4,video/x-m4v,video/*"
                                style={{ display: 'none' }}
                                id={`fileUploader-${grid.id}-${content.order}`}
                                onChange={(e) => updateContent('videos', 'video', grid, content.order, e.target.files[0])}
                                type="file"
                                hidden
                            />
                            <ClickImg 
                                as='video'
                                src={content.video && content.video.name ? URL.createObjectURL(content.video) : content.video} 
                                style={{ width: '100%' }} 
                                alt='video' 
                                editing={isEditing}
                                onClick={() => isEditing && document.getElementById(`fileUploader-${grid.id}-${content.order}`).click()}
                            />
                            <Select
                                value={content.shape}
                                label="Vorm"
                                onChange={(e) => updateContent('videos', 'shape', grid, content.order, e.target.value) }
                                style={{ width: '100%'}}
                                disabled={!isEditing}
                            >
                                <MenuItem key={4} value='SQUARE'>Vierkant</MenuItem>
                                <MenuItem key={5} value='ARCH'>Boog</MenuItem>
                            </Select>
                        </Container>
                    )
                }
                
                return null;
                
            })}
            <Dialog
                open={dialogOpen}
                handleCancel={() => setDialogOpen(false)}
                handleOk={() => { deleteGrid(grid); setDialogOpen(false) }}
                title={'Raster verwijderen'}
                content={`Weet je zeker dat je dit raster wil verwijderen?`}
            />
            {isEditing && <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1vw' }}>
                {grid.id ? <Button variant='contained' onClick={() => setDialogOpen(true)} startIcon={<DeleteIcon/>}>Verwijderen</Button>
                    : <Button variant='contained' onClick={cancelNewGrid} startIcon={<CloseIcon/>}>Annuleren</Button>
                }
            </div>}
        </FlexContainer>
    )
}