import React, { useState, useLayoutEffect, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import Papa from "papaparse";

import { Footer, Menu } from './components';
import styled from 'styled-components';
import { Artikel, BrandingStrategie, Boekontwerp, MiniBranding, FullBrandingWeb, LogoAnimatie, 
  Contact, ContactBedankt, NoMatch, Diensten, SocialContentBoost, Branding, 
  EmailHandtekening, Glyphs, Handgeschreven, Zichtbaarheid, FullBranding, Visitekaartje, CreationBoost, 
  Portfolio, Project, Typografie, Lettertype, Handschrift, SocialMediaTemplates, Blog as BlogMain, 
  Shop as ShopMain, FullStoryBranding, FullStoryBrandingContact, Droomklanten, Advent, AdventPaid, LogoHuisstijl, Strategie, DesignIntensive } from "./pages";
import './App.css';
import { ScrollToTop } from "./helpers";
import Cookie from "./components/Cookie/Cookie";
import ProtectedRoute from "./helpers/ProtectedRoute";
import { isPhone, isTablet } from "./helpers/layout";

const MainContent = styled.div`
  position: relative;
  z-index: 0;
  margin-top: 5vw;

  /* #### Mobile Phones Portrait or Landscape #### */
  @media screen and (max-width: 766px) {
      margin: 0;
  }  

  /* #### Tablets Portrait or Landscape #### */
  @media screen and (min-width: 767px) and (max-width: 1024px) {
      margin: 0;
  }
`;

export function App(props) {
  const [ respMenuOpen, setRespMenuOpen ] = useState(false);
  const [ hasPhone, setHasPhone ] = useState(isPhone(window.screen));
  const [ hasTablet, setHasTablet ] = useState(isTablet(window.screen));

  const  [ parsedData, setParsedData ] = useState([]);

  useLayoutEffect(() => {
    function updateResponsive() {
      setHasPhone(isPhone(window.screen));
      setHasTablet(isTablet(window.screen));
    }
    window.addEventListener('resize', updateResponsive);
    updateResponsive();

    // laad csv file in voor prijzen + diensten
    if (parsedData.length === 0) {
      Papa.parse('/files/diensten.csv', {
        download: true,
        header: true,
        delimiter: ";",
        skipEmptyLines: true,
        complete: function (results) {
          setParsedData(results.data);
        },
      });
    }
    
    return () => window.removeEventListener('resize', updateResponsive);

  }, [parsedData]);

  const RespProps = { hasPhone: hasPhone, hasTablet: hasTablet, dienstenCsv: parsedData };

  const Home = lazy(() => import('./pages/home/Home'));
  const Insta = lazy(() => import('./pages/insta/Insta'));
  const Over = lazy(() => import('./pages/over/Over'));
  const Reviews = lazy(() => import('./pages/reviews/Reviews'));
  const Voorwaarden = lazy(() => import('./pages/voorwaarden/Voorwaarden'));
  const Cookies = lazy(() => import('./pages/voorwaarden/Cookies'));
  const Privacy = lazy(() => import('./pages/voorwaarden/Privacy'));
  const Vragen = lazy(() => import('./pages/voorwaarden/Vragen'));
  const Werkwijze = lazy(() => import('./pages/werkwijze/Werkwijze'));
  const Login = lazy(() => import('./pages/login/Login'));
  const Admin = lazy(() => import('./pages/admin/Admin'));
  const SocialContentAbonnement = lazy(() => import('./pages/documents/SocialContentAbonnement'));
  const LicentieOvereenkomst = lazy(() => import('./pages/documents/LicentieOvereenkomst'));
  const LakzegelsPrijslijst = lazy(() => import('./pages/documents/LakzegelsPrijslijst'));
  const BrandingChecklist = lazy(() => import('./pages/documents/BrandingChecklist'));

  return (
    <Router>
      <div style={{ position: 'relative', height: '100%' }}>
          <nav style={{ position: 'relative' }}>
            <Menu winkelwagenCount={0} isOpen={respMenuOpen} setOpen={setRespMenuOpen} {...RespProps} />
          </nav>
          <Cookie {...RespProps} />

          {!respMenuOpen && <MainContent>
            <Suspense fallback={<div>Aan het laden...</div>}>
              <ScrollToTop>
                <Switch>
                  <Route exact path='/'>
                    <Home {...RespProps} />
                  </Route>
                  <Route path='/blog'>
                    <Blog {...RespProps} />
                  </Route>
                  <Route path='/contact'>
                    <ContactRoute {...RespProps} />
                  </Route>
                  <Route path='/diensten'>
                    <DienstenRoute {...RespProps} />
                  </Route>
                  <Route path='/insta'>
                    <Insta {...RespProps}/>
                  </Route>
                  <Route path='/over'>
                    <Over {...RespProps} />
                  </Route>
                  <Route path='/portfolio'>
                    <PortfolioRoute {...RespProps} />
                  </Route>
                  <Route path='/reviews'>
                    <Reviews {...RespProps} />
                  </Route>
                  <Route path='/shop'>
                    <Shop {...RespProps} />
                  </Route>
                  <Route path='/voorwaarden'>
                    <Voorwaarden {...RespProps} />
                  </Route>
                  <Route path='/cookies'>
                    <Cookies {...RespProps} />
                  </Route>
                  <Route path='/privacy'>
                    <Privacy {...RespProps} />
                  </Route>
                  <Route path='/vragen'>
                    <Vragen {...RespProps} />
                  </Route>
                  <Route path='/werkwijze'>
                    <Werkwijze {...RespProps} />
                  </Route>
                  <Route path='/login'>
                    <Login {...RespProps} />
                  </Route>
                  <ProtectedRoute path='/admin'>
                    <Admin {...RespProps} />
                  </ProtectedRoute>
                  <Route path='/documents/socialcontentabonnement'>
                    <SocialContentAbonnement {...RespProps} />
                  </Route>
                  <Route path='/documents/licentieovereenkomst'>
                    <LicentieOvereenkomst {...RespProps} />
                  </Route>
                  <Route path='/documents/lakzegelsprijslijst'>
                    <LakzegelsPrijslijst {...RespProps} />
                  </Route>
                  <Route path='/documents/thebrandingchecklist'>
                    <BrandingChecklist {...RespProps} />
                  </Route>
                  <Route path='/gratis/droomklanten'>
                    <Droomklanten {...RespProps} />
                  </Route>
                  <Route path='/advent'>
                    <AdventRoute {...RespProps} />
                  </Route>
                  <Route path='*'>
                    <NoMatch {...RespProps} />
                  </Route>
                </Switch>
              </ScrollToTop>
            </Suspense>
          </MainContent>}

          {!respMenuOpen && <Footer {...RespProps} />}
      </div>
    </Router>
  );
}

function AdventRoute(props) {
  let match = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={`${match.path}/:eventUri`}>
          <AdventPaid {...props} />
        </Route>
        <Route path={match.path}>
          <Advent {...props} />
        </Route>
      </Switch>
    </div>
  );
}

function Blog(props) {
  let match = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={`${match.path}/:artikelId`}>
          <Artikel {...props} />
        </Route>
        <Route path={match.path}>
          <BlogMain {...props} />
        </Route>
      </Switch>
    </div>
  );
}

function ContactRoute(props) {
  let match = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={`${match.path}/bedankt`}>
          <ContactBedankt {...props}/>
        </Route>
        <Route path={match.path}>
          <Contact {...props} />
        </Route>
      </Switch>
    </div>
  )
}

function DienstenRoute(props) {
  let match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={match.path}>
          <Diensten {...props} />
        </Route>
        <Route path={`${match.path}/strategie`}>
          <Strategie {...props}/>
        </Route>
        <Route path={`${match.path}/branding`}>
          <Branding {...props}/>
        </Route>
        <Route path={`${match.path}/designintensive`}>
          <DesignIntensive {...props}/>
        </Route>
        <Route path={`${match.path}/fullbranding`}>
          <FullBranding {...props}/>
        </Route>
        <Route path={`${match.path}/fullbrandingweb`}>
          <FullBrandingWeb {...props}/>
        </Route>
        <Route path={`${match.path}/brandingstrategie`}>
          <BrandingStrategie {...props}/>
        </Route>
        <Route path={`${match.path}/minibranding`}>
          <MiniBranding {...props} />
        </Route>
        <Route path={`${match.path}/glyphs`}>
          <Glyphs {...props} />
        </Route>
        <Route path={`${match.path}/handgeschreven`}>
          <Handgeschreven {...props}/>
        </Route>
        <Route path={`${match.path}/socialmediatemplate`}>
          <SocialMediaTemplates {...props}/>
        </Route>
        <Route path={`${match.path}/typografie`}>
          <Typografie {...props} />
        </Route>
        <Route path={`${match.path}/visitekaartje`}>
          <Visitekaartje {...props}/>
        </Route>
        <Route path={`${match.path}/zichtbaarheid`}>
          <Zichtbaarheid {...props}/>
        </Route>
        <Route path={`${match.path}/socialcontentboost`}>
          <SocialContentBoost {...props} />
        </Route>
        <Route path={`${match.path}/emailhandtekening`}>
          <EmailHandtekening {...props} />
        </Route>
        <Route path={`${match.path}/creationboost`}>
          <CreationBoost {...props} />
        </Route>
        <Route path={`${match.path}/lettertype`}>
          <Lettertype {...props} />
        </Route>
        <Route path={`${match.path}/handschrift`}>
          <Handschrift {...props} />
        </Route>
        <Route path={`${match.path}/logoanimatie`}>
          <LogoAnimatie {...props} />
        </Route>
        <Route path={`${match.path}/thefullstorybranding`}>
          <FullStoryBranding {...props} />
        </Route>
        <Route path={`${match.path}/thefullstorybrandingcontact`}>
          <FullStoryBrandingContact {...props} />
        </Route>
        <Route path={`${match.path}/logohuisstijl`}>
          <LogoHuisstijl {...props} />
        </Route>
        <Route path={`${match.path}/*`}>
          <NoMatch {...props} />
        </Route>
      </Switch>
    </div>
  )
}

function PortfolioRoute(props) {
  let match = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={`${match.path}/boekontwerp`}>
          <Boekontwerp {...props} />
        </Route>
        <Route path={`${match.path}/:projectId`}>
          <Project {...props} />
        </Route>
        <Route path={match.path}>
          <Portfolio {...props} />
        </Route>
      </Switch>
    </div>
  );
}

function Shop(props) {
  let match = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={match.path} component={() => {
            window.location.href = 'https://shop.valeriemaas.nl/product/radiant-evolving/';
            return null;
        }}/>
        <Route path='*' component={() => {
            window.location.href = 'https://shop.valeriemaas.nl/product/radiant-evolving/';
            return null;
        }}/>
      </Switch>
    </div>
  );
}


export default App;
