import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Arrow, PageTitle, LineButton, Typography } from '../../components';
import Review from '../../pages/reviews/Review';

import { useDispatch, useSelector } from 'react-redux';
import { getReviews } from '../../actions/reviews';

export default function LieveWoorden(props) {
    const [ isPage, setPage ] = useState(1);
    const [ isArrowHover, setArrowHover ] = useState(false);

    const dispatch = useDispatch();
    const reviewObject = useSelector((state) => state.reviews).reviews || {};
    const review = reviewObject && reviewObject.results && reviewObject.results[0];

    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    const changePage = (pageNumber) => {
        setPage(pageNumber);
        const filters = { page: pageNumber, page_size : 1 }
        dispatch(getReviews(filters));
    }

    const mouseEnter = (e) => {
        // animate arrows
        setArrowHover(true)
        setTimeout(() => {
            setArrowHover(false)
        }, 500)
    }

    useEffect(() => {
        let filters = { page: isPage, page_size : 1 };
        if (props.showAtFullBrandingStory) {
            filters['show_at_full_branding_story'] = 'true'
        } else {
            filters['show_at'] = 'OV'
        }
        dispatch(getReviews(filters))
    }, [props.showAtFullBrandingStory, dispatch, isPage])

    if (review) {
        if (hasPhone) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', margin: hasPhone ? '15vw 0' : '5vw 0'}}>
                    <Typography variant='sub'>Lieve woorden</Typography>
                    {review && 
                            <Review review={review} {...props} />
                    }
                    {!props.showAtFullBrandingStory && <LineButton to='/reviews' margin='1vw 0 0 0'>Meer reviews lezen?</LineButton>}
                </div>
            )
        }
        return (
            <div>
                <PageTitle title='lieve woorden' left='-23px'>
                    <Grid 
                        container
                        justifyContent='center'
                        alignItems='flex-start' 
                    >
                        {reviewObject.previous ? 
                            <Arrow onClick={() => changePage(isPage - 1)} direction='left' hover={isArrowHover} style={{ marginTop: hasTablet ? '17vw' : '10vw', marginRight: '5vw'}} /> 
                            : <div style={{ width: '4vw', marginRight: '5vw'}} />}
                        {review && 
                            <Review review={review} isPage={isPage} index={isPage+1} onMouseEnter={mouseEnter} {...props} />
                        }
                        {reviewObject.next ? <Arrow onClick={() => changePage(isPage + 1)} direction='right' hover={isArrowHover} style={{ marginTop: hasTablet ? '17vw' : '10vw', marginLeft: '5vw'}}  /> : <div style={{ width: '4vw', marginLeft: '5vw'}} />}
                    </Grid>
                </PageTitle>
                {!props.showAtFullBrandingStory && <div style={{ display: 'flex', justifyContent: 'center'}}>
                    <LineButton to='/reviews' margin='1vw 0 0 0'>Meer reviews lezen?</LineButton>
                </div>}
            </div>
        )
    }

    return null;
}