import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { getBookings, putBooking, postBooking } from '../../actions/bookings';

import { Box, TextField, Typography, Button } from '@mui/material';
// import { DateTimePicker } from '@mui/lab';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import EditIcon from '@mui/icons-material/Edit';

const Field = styled.div`
    display: flex;
    align-items: center;
    gap: 5vw;
    margin-bottom: 1vw;
`;

export default function Boeking(props) {
    const dispatch = useDispatch();

    const bookingsObject = useSelector((state) => state.bookings);
    const bookings = (bookingsObject && bookingsObject.results) || [];
    const booking = (bookings.length > 0 && bookings[0]);

    const [ dateValue, setDateValue ] = useState((booking && booking.date) || '');

    useEffect(() => {
        bookings.length === 0 && !booking && dispatch(getBookings());
        if (bookings.length > 0 && !dateValue) {
            setDateValue(booking.date);
        }
    }, [dispatch, dateValue, bookings.length, booking]);

    const handleSubmit = () => {
        const newBooking = {
            id: booking && booking.id,
            date: moment(dateValue).format('YYYY-MM-DD'),
        }

        if (booking) {
            dispatch(putBooking(newBooking)).then(dispatch(getBookings()));
        } else {
            dispatch(postBooking(newBooking)).then(dispatch(getBookings()));
        }
    }

    return (
        <Box component='form' validate>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Boeking datum</Typography>
                    <Typography>Boekingen open voor ...</Typography>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label="Datum"
                        value={dayjs(dateValue)}
                        onChange={(e) => { setDateValue(e.format('YYYY-MM-DD')) }}
                        renderInput={(params) => <TextField {...params} />}
                        ampm={false}
                        required
                    />
                </LocalizationProvider>
            </Field>
            <div style={{ display: 'flex', width: '75vw', justifyContent: 'right', gap: '1vw'}}>
                <Button onClick={handleSubmit} variant='contained' startIcon={<EditIcon />}>{'Aanpassen'}</Button>
            </div>
        </Box>
    )
}