import { Typography, PageTitle, Button } from "../../components";
import { colors } from '../../assets/colors';

import { ReactComponent as Sleutel } from '../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_sleutel.svg';

export default function Shop(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    return (
        <PageTitle title='shop' left='15px'>
            <div style={{ textAlign: 'center', width: hasPhone ? '45vw' : hasTablet ? '20vw' : '14vw', margin: hasPhone ? '15vw 27.5vw' : hasTablet ? '10vw 0 0 18vw' : '10vw 0 0 25vw', position: 'relative'}}>
                <Typography variant='h2'>nog niet klaar om te investeren?</Typography>
                <Typography style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '0'}}>Uniek ontworpen items om te creëren én leren.</Typography>
                <Typography>Een gratis e-book, lettertypes & templates.</Typography>
                <Button link='/shop' style={{ marginTop: hasPhone ? '3vw' : hasTablet ? '2vw' : '1vw'}}>Bekijk de shop</Button>
                <Sleutel style={{ fill: colors.illustration, position: 'absolute', width: hasPhone ? '12vw' : hasTablet ? '7vw' : '5vw', top: hasPhone ? '-15vw' : hasTablet ? '-10vw' : '-8vw', left: hasPhone ? '-20vw' : hasTablet ? '-13vw' : '-13vw' }} />
            </div>
        </PageTitle>
    )
}