import React from 'react';
import { LieveWoorden, Match, Nieuwsgierig, Shop, Blog, Instagram } from '../../subpages';
import { Typography, Button, BulletPoint } from '../../components';
import styled from 'styled-components';
import StartTextPhoto from '../diensten/StartTextPhoto';

import boost1 from '../../assets/images/diensten/creationboost/Studio-Valerie-Maas-grafisch-ontwerp-diensten-creation-boost-1.jpg';
import boost2 from '../../assets/images/diensten/creationboost/Studio-Valerie-Maas-grafisch-ontwerp-diensten-creation-boost-2.jpg';
import { colors } from '../../assets/colors';


const StyledImg = styled.img`
    margin-top: 2vw;
    width: 18vw;
    border-radius: 50% 50% 0 0;
    margin-left: ${props => props.marginLeft};
    margin-right: ${props => props.marginRight};

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 60vw;
        margin: 10vw 0 0 0;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        width: 25vw;
    }
`;

export default function CreationBoostAdvent(props) {
    const { hasPhone, hasTablet, setStatus } = props;

    return (
        <div>
            <Typography variant='sub' style={{ marginLeft: hasPhone ? '10vw' : hasTablet ? '8vw' : '15vw', position: 'absolute', top: hasPhone ? '-3vw' : '1.5vw', fontSize: hasPhone ? '10px' : hasTablet ? '12px' : '13px' }}>Adventskalender actie</Typography>
            <StartTextPhoto
                image={boost1}
                title={'creation boost'}
                {...props}
            >
                <Typography>
                Samen sparren! Een manier om de 100 ideeën die in jouw hoofd ronddwalen te bespreken en een plan van aanpak te creëren.<br/><br/>
                    <span style={{ color: colors.photo }}>
                    “Ik heb zo veel ideeën in mijn hoofd, maar het lukt niet om te beginnen.”<br/>
                    “Ik zie gewoon beren op de weg, hoe dan?”
                    </span><br/><br/>
                    Als we een sessie plannen bellen we één uur via Zoom. Als jij zorgt dat van te voren jouw vragen duidelijk zijn, kan ik me al daarop inlezen en me voorbereiden. Op die manier kunnen we meteen beginnen met brainstormen!
                </Typography>
                <Button onClick={() => setStatus('plan')} style={{ marginTop: hasPhone ? '5vw' : hasTablet ? '2vw' : '1vw', backgroundColor: '#caa58e'}} color='black' hovercolor='white'>Plan nu jouw creation boost in</Button>
                <Typography variant='sub' style={{ fontSize: hasPhone ? '10px' : hasTablet ? '12px' : '13px', margin: '1vw 0 0 2vw' }}>Voor maar €50,- excl. btw</Typography>
            </StartTextPhoto>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', marginBottom: hasPhone ? '10vw' : '5vw'}}>
                <Typography variant='h2'>wat brengt het jou en <br/> jouw bedrijf?</Typography>
                <Typography style={{ marginBottom: hasPhone ? '5vw' : '2vw'}}>Een gezellig gesprek met mij.</Typography>
                <Typography variant='h2'>maar belangrijker:</Typography>
                <Typography>Een stappenplan, waardoor jij weet waar je kan <br/>
                beginnen met jouw idee of het oplossen van jouw <br/> probleem. En daarnaast een bak met energie! </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: hasPhone ? 'center' : 'flex-start', marginBottom: hasPhone ? '0' : '5vw'}}>
                <div style={{ width: hasPhone ? '80vw' : hasTablet ? '40vw' : '28vw'}}>
                    <Typography variant='h2'>aan welke vragen kan je <br/>denken?</Typography>
                    <BulletPoint>Wie is mijn ideale klant? En hoe spreek ik deze aan?</BulletPoint>
                    <BulletPoint>Ik heb een nieuw product bedacht, wat is er allemaal mogelijk?</BulletPoint>
                    <BulletPoint>Ik wil mijn aanbod (diensten) omgooien, kan je een frisse blik bieden?</BulletPoint>
                    <BulletPoint>Hoe maak ik mijn ideeën concreet?</BulletPoint>
                    <Typography>
                    Of we pakken een moment om door een aantal kleine dingen te gaan, zodat jij na ons gesprek weer vol goede energie zit om zelf door te pakken mét de goede handvaten!
                    </Typography>
                    <Button onClick={() => setStatus('plan')} style={{ marginTop: hasPhone ? '5vw' : hasTablet ? '2vw' : '1vw', backgroundColor: '#caa58e'}} color='black' hovercolor='white'>Plan nu jouw creation boost in</Button>
                    <Typography variant='sub' style={{ fontSize: hasPhone ? '10px' : hasTablet ? '12px' : '13px', margin: '1vw 0 0 2vw' }}>Voor maar €50,- excl. btw</Typography>
                </div>
                <StyledImg 
                    src={boost2}
                    alt='Valerie met een kopje koffie'
                    marginLeft={props.even ? '0' : '10vw'}
                    marginRight={props.even ? '10vw' : '0'}
                />
            </div>
            <Match {...props} />
            <LieveWoorden {...props}  />
            <Shop {...props} />
            <Blog {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}