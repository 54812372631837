import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Typography, Button, TextField, useInput, Checkbox, ImageArch } from '../../../components';
import { Grid } from '@mui/material';
import { colors } from '../../../assets/colors';
import { ReactComponent as Latte } from '../../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_kopje-latte-macchiato.svg';
import { useDispatch } from 'react-redux';
import { postContactForm } from '../../../actions/contactforms';

import image1 from '../../../assets/images/diensten/fullstorybranding/Studio-Valerie-Maas-grafisch-ontwerp-The-Full-Story-Branding-Valerie-Contact.jpg';
import image2 from '../../../assets/images/diensten/fullstorybranding/Studio-Valerie-Maas-grafisch-ontwerp-The-Full-Story-Branding-Maaike-Kastrop-Contact.jpg';

export default function FullStoryBrandingContact(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    const { value: valueFirstName, bind: bindFirstName } = useInput('');
    const { value: valueLastName, bind: bindLastName } = useInput('');
    const { value: valueEmail, bind: bindEmail } = useInput('');
    const { value: valueSubject, bind: bindSubject } = useInput('');
    const { value: valueCompanyName, bind: bindCompanyName } = useInput('');
    const { value: valueLink, bind: bindLink } = useInput('');
    const { value: valueHelp } = useInput('');
    const { value: valueVerder } = useInput('');
    const { value: valueMaand, bind: bindMaand } = useInput('');
    const { value: valueJaar, bind: bindJaar } = useInput('');
    const [ isTarievenChecked, setTarievenChecked ] = useState(false);

    let history = useHistory();
    const dispatch = useDispatch();

    const handleSubmit = (evt) => {
        evt.preventDefault();

        const formValues = {
            'first_name': valueFirstName,
            'last_name': valueLastName,
            'email': valueEmail,
            'subject': valueSubject,
            'type': 'full story branding contact',
            'company_name': valueCompanyName,
            'link': valueLink,
            'help': valueHelp,
            'verder': valueVerder,
            'knows_tarieven': isTarievenChecked,
            'deadline_month': valueMaand,
            'deadline_year': valueJaar
        }

        dispatch(postContactForm(formValues)).then(history.push('/contact/bedankt'));
    }

    const pageWidth = hasPhone ? '60vw' : hasTablet ? '35vw' : '25vw';
    const textFieldStyle = { width: pageWidth, marginBottom: hasPhone ? '2vw' : hasTablet ? '1vw' : '0.5vw'};

    return (
        <form onSubmit={handleSubmit} autoComplete='on' style={{ margin: '5vw 0'}}>
            <div style={{ position: 'relative' }}>
                <Grid container direction='column' alignItems='center' style={{ position: 'relative'}}>
                    <Typography variant='h2' style={{ width: hasPhone ? '80vw' : hasTablet ? '45vw' : '25vw', textAlign: 'center', margin: '0 0 1vw 0 '}}>gaan we samen jouw business naar the next level tillen?</Typography>
                    <Typography variant='sub' style={{ marginBottom: hasPhone ? '80vw' : '3vw'}}>Contact</Typography>
                    <Typography variant='h1'>Contact</Typography>
                    {!hasTablet && <ImageArch src={image1} width={hasPhone ? '37vw' : hasTablet ? '12vw' : '15vw'} style={{ position: 'absolute', top: hasPhone ? '26vw' : '10vw', right: hasPhone ? '7.5vw' : '5vw' }} />}
                    {!hasTablet && <img src={image2} alt='Voorbeeld van werk' style={{ position: 'absolute', width: hasPhone ? '37vw' :  '15vw', top: hasPhone ? '54vw' : '29vw', right: hasPhone ? '55vw' : '15vw' }} />}

                    <TextField 
                        placeholder='Voornaam'
                        style={textFieldStyle}
                        required
                        {...bindFirstName}
                    />
                    <TextField 
                        placeholder='Achternaam'
                        style={textFieldStyle}
                        required
                        {...bindLastName}
                    />
                    <TextField 
                        placeholder='E-mailadres'
                        id='email'
                        type='email'
                        style={textFieldStyle}
                        required
                        {...bindEmail}
                    />
                    <TextField 
                        placeholder='Onderwerp'
                        style={textFieldStyle}
                        required
                        {...bindSubject}
                    />
                    <TextField 
                        placeholder='Bedrijfsnaam'
                        style={textFieldStyle}
                        {...bindCompanyName}
                    />
                    <Typography style={{ color: colors.title, width: pageWidth, display: 'inline', margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '1vw 0 0.5vw 0'}}>
                        Deel een link waar ik een kijkje kan nemen bij jouw bedrijf. 
                        <span style={{ fontSize: hasPhone ? '11px' : hasTablet ? '12px' : '14px'}}> Je website of instagram-pagina.</span>
                    </Typography>
                    <TextField 
                        placeholder='Link'
                        style={textFieldStyle}
                        type='url'
                        {...bindLink}
                    />
                    <span style={{ height: '10px', width: '100%'}} />
                    <Typography style={{ color: colors.title, width: pageWidth, margin: hasPhone ? '2vw 0 1vw 0' : '1vw 0 0 0'}}>
                        Ben je op de hoogte van mijn tarieven?
                    </Typography>
                    <div style={{ position: 'relative', width: pageWidth, height: '1.5vw', margin: hasPhone ? '1vw 0 5vw 0' : hasTablet ? '0.5vw 0 2vw 0' : '0.5vw 0 0 0'}} >
                        <div style={{ position: 'absolute', left: hasPhone ? '-3vw' : hasTablet ? '-1.5vw' : '-0.5vw' }}>
                            <Checkbox
                                    onClick={e => setTarievenChecked(!isTarievenChecked)}
                                    isChecked={isTarievenChecked}
                                >
                                    Zeker!
                            </Checkbox>
                        </div>
                    </div>
                    <Typography style={{ color: colors.title, width: pageWidth, margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '1vw 0 0.5vw 0' }}>
                        Heb je een deadline voor jouw project?
                    </Typography>
                    <div style={{ position: 'relative', height: hasPhone ? '25vw' : hasTablet ? '11vw' : '5vw', width: pageWidth }}>
                        <div style={{ position: 'absolute', left: hasPhone ? '-3vw' : hasTablet ? '-1.5vw' : '-0.5vw', top: '0', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: hasPhone ? '2vw' : hasTablet ? '1vw' : '0vw', margin: '0 0 2vw 0'}}>
                            <TextField 
                                placeholder='Maand'
                                style={{ width: hasPhone ? '10vw' : '6vw'}}
                                {...bindMaand}
                            />
                            <TextField 
                                placeholder='Jaar'
                                style={{ width: '3vw' }}
                                {...bindJaar}
                            />
                        </div>
                    </div>
                    <Button type='submit' onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')} style={{ width: pageWidth, margin: hasTablet && '2vw 0' }}>Verstuur en plan meteen een gratis kennismakingsgesprek in</Button>
                </Grid>
                {!hasPhone && <Latte style={{ fill: colors.latte, position: 'absolute', left: '12vw', top: '27vw', width: hasTablet ? '7vw' : '5vw' }} />}
            </div>
        </form>
    )
}