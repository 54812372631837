import React from "react";
import { Link } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { LineButton, Typography } from "..";
import styled from "styled-components";
import { colors } from "../../assets/colors";

const CookieBanner = styled.div`
    display: flex;
    justify-content: center;

    position: fixed;
    top: 90vh;
    z-index: 1001;
    width: 100%;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    background: transparent;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        top: 88vh;
    }    

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        top: 91vh;
    }
`;

const Oval = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 89vw;
    background: white;

    border-radius: 24px;
    border: solid 1px ${colors.photo};

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        position: relative;
        width: 90vw;
        height: auto;
        display: inline-block;
        text-align: left;
        box-sizing: border-box;
        padding: 1.5vw 3vw 2vw 3vw;
    }   

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        position: relative;
        width: 90vw;
        height: 5vw;
        text-align: left;
        box-sizing: border-box;
        padding: 0 1vw;
    }
`

const StyledLink = styled(Link)`
    display: inline;
    text-decoration: none;
    background: none;
    position: relative;
    cursor: pointer;
    
    &:hover {
        font-style: italic;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        margin: 0 0 0 2vw;
    } 
    
    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        margin-bottom: 1px;
    }

`;

export default function Cookie(props) {
    const [ cookies, setCookie ] = useCookies(['consent']);
    
    const handleCookie = () => {
        setCookie('consent', true, { path: '/' });
    }

    if (cookies.consent) {
        return null;
    } else if (props.hasPhone) {
        return (
            <CookieBanner visible={!cookies.consent}>
                <Oval>
                    <Typography variant='sub' style={{ background: 'none', borderBox: 'border-box', display: 'inline', width: '100%', fontSize: '10px', lineHeight: '50%' }}>
                        Wij gebruiken cookies om onze website en onze service te optimaliseren
                    </Typography>
                    <StyledLink to='/cookies'>
                        <Typography style={{ color: colors.photo, background: 'none', display: 'inline', margin: '0vw 0.5vw 0 0', fontSize: '11px'}}>
                            Lees meer
                        </Typography>
                    </StyledLink>
                    <LineButton onClick={handleCookie} style={{ position: 'absolute', letterSpacing: 'normal', fontSize: '10px', right: '3.1vw', margin: '0', bottom: '2vw' }}>Accepteren</LineButton>
                </Oval>
            </CookieBanner>
        )
    } else if (props.hasTablet) {
        return (
            <CookieBanner visible={!cookies.consent}>
                <Oval>
                    <Typography variant='sub' style={{ background: 'none', fontSize: '10px'}}>
                        Wij gebruiken cookies om onze website en onze service te optimaliseren
                    </Typography>
                    <StyledLink to='/cookies'>
                        <Typography style={{ color: colors.photo, background: 'none', margin: '2px 2vw 0 1vw', fontSize: '11px' }}>
                            Lees meer
                        </Typography>
                    </StyledLink>
                    <LineButton onClick={handleCookie} style={{ fontSize: '9px', margin: '0'}}>Accepteren</LineButton>
                </Oval>
            </CookieBanner>
        )
    } else {
        return (
            <CookieBanner visible={!cookies.consent}>
                <Oval>
                    <Typography variant='sub' style={{ background: 'none', margin: '0vw 0.5vw 0 0', fontSize: '13px'}}>
                        Wij gebruiken cookies om onze website en onze service te optimaliseren
                    </Typography>
                    <StyledLink to='/cookies'>
                        <Typography style={{ color: colors.photo, background: 'none', margin: '0.1vw 10vw 0 0.5vw', fontSize: '14px' }}>
                            Lees meer
                        </Typography>
                    </StyledLink>
                    <LineButton onClick={handleCookie} style={{ margin: '0.5vw 0'}}>Accepteren</LineButton>
                </Oval>
            </CookieBanner>
        )
    }
}