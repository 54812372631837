import React from 'react';
import styled from 'styled-components';
import { Typography, LineButton } from '../../components';
import { colors } from '../../assets/colors';

const Signature = styled.img`
    background: none;
    filter: invert(92%) sepia(10%) saturate(2909%) hue-rotate(306deg) brightness(105%) contrast(58%);
    width: 5vw;
    margin-bottom: 5px;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 17vw;
        margin: 2vw 0;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        width: 7vw;
    }
`;

const StyledImg = styled.img`
    height: 18vw;
    border-radius: 50% 50% 0 0;
    margin: 1vw 1vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        height: 60vw;
        margin: 2vw 0 5vw 0;
    } 

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        height: 30vw;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
    justify-content: center;
    align-items: flex-start;
    margin: 2vw 0;
    gap: 5vw;
    width: 46vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        flex-direction: column-reverse;
        align-items: center;
        width: 75vw;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        width: 65vw;
    }
`;

export default function Review(props) {
    const { review, isPage, index, onMouseEnter, reverse, showAll } = props;

    const text = showAll ? review.content : review.shortened_content;

    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    return (
        <Content key={index} reverse={reverse && (index + 1 + isPage) % 2 !== 0} onMouseEnter={onMouseEnter}>
            {review.main_image && <StyledImg src={review.main_image} alt='Foto van werk' />}
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <div>
                    <Typography variant='h2'>{review.title}</Typography>
                    {text.split("\r\n\r\n").map((a, i) => {
                        return <Typography key={i} style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '1vw 0'}}>{a}</Typography>
                    })}
                    {!showAll && review.shortened_content.length < review.content.length && 
                        <div style={{ display: 'flex', justifyContent: hasPhone ? 'center' : 'left'}}>
                            <LineButton to='/reviews' margin='1vw 0 0 0'>Lees meer</LineButton>
                        </div>
                        }
                </div>
                <div style={{ marginTop: '1vw'}}>
                    {review.signature_image && <Signature src={review.signature_image}/>}
                    <Typography variant='sub' style={{ color: `${colors.black} !important` }}>{review.company_name}</Typography>
                </div>
            </div>
        </Content>
    )
}