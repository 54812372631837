import axiosInstance from './axios';
import { reportResponse } from './helpers';
import { PUT_BLOG_GRID, POST_BLOG_GRID, DELETE_BLOG_GRID } from './types';

// PUT BLOG GRID
export const putBlogGrid = (blogGrid) => async dispatch => {
  const res = await axiosInstance.put(`/api/blog_grids/${blogGrid.id}/`, blogGrid);

  return reportResponse(res, PUT_BLOG_GRID, 'Blog grid', dispatch);
}

// POST BLOG GRID
export const postBlogGrid = (blogGrid) => async dispatch => {
  const res = await axiosInstance.post(`/api/blog_grids/`, blogGrid);
  
  return reportResponse(res, POST_BLOG_GRID, 'Blog grid', dispatch);
};

// DELETE PROJECT GRID
export const deleteBlogGrid = (id) => async dispatch => {
  const res = await axiosInstance.delete(`/api/blog_grids/${id}/`)

  return reportResponse(res, DELETE_BLOG_GRID, 'Blog grid', dispatch);
}
