import React, { useState } from 'react';
import CreationBoostAdvent from './CreationBoostAdvent';
import AdventPlan from './AdventPlan';

export default function Advent(props) {
    const [ status, setStatus ] = useState(null);

    if (status === 'plan') {
        return <AdventPlan setStatus={setStatus} {...props} />
    }

    return (
        <CreationBoostAdvent setStatus={setStatus} {...props} />
    )
}