import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import styled from 'styled-components';

import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Fab } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { getDateTime } from '../../../helpers/datetime';
import { getBlogs } from '../../../actions/blogs';

const StyledLink = styled(Link)`
    position: absolute;
    bottom: 2vw;
    right: 5vw;
    text-decoration: none;
`;

export default function Blogs(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const blogsObject = useSelector((state) => state.blogs) || {};
    const blogs = (blogsObject && blogsObject.blogs && blogsObject.blogs.results) || [];

    useEffect(() => {
        dispatch(getBlogs())
    }, [dispatch])

    if (blogs.length === 0) {
        return (
            <div>Geen blogs gevonden
                <StyledLink to={{ pathname: `/admin/blogs/new`, post: true }}>
                    <Fab color='primary' aria-label='add'>
                        <AddIcon />
                    </Fab>
                </StyledLink>
            </div>
        )
    }

    return (
        <div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Titel</TableCell>
                            <TableCell>Types</TableCell>
                            <TableCell>Gepubliceerd</TableCell>
                            <TableCell>Aanmaakdatum</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {blogs.map((blog, i) => (
                            <TableRow key={i} onClick={() => history.push(`/admin/blogs/${blog.id}`, { blog: blog })} hover>
                                <TableCell>{blog.title}</TableCell>
                                <TableCell>
                                    {blog.types.map((type, j) => (
                                        <div key={j}>{type.content}</div>
                                    ))}
                                </TableCell>
                                <TableCell>
                                    <Checkbox checked={blog.published} disabled />
                                </TableCell>
                                <TableCell>{getDateTime(blog.created)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <StyledLink to={{ pathname: `/admin/blogs/new`, post: true }}>
                <Fab color='primary' aria-label='add'>
                    <AddIcon />
                </Fab>
            </StyledLink>
        </div>
    )
}