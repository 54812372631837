import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from "react-router-dom";
import { getDocument, getDocuments, putDocument } from '../../../actions/documents';

import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
// import DateAdapter from '@mui/lab/AdapterMoment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { Fab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TextField, Button } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import styled from 'styled-components';
import { getDateTime } from '../../../helpers/datetime';

const StyledLink = styled(Link)`
    position: absolute;
    bottom: 2vw;
    right: 5vw;
    text-decoration: none;
`;

const Field = styled.div`
    display: flex;
    align-items: center;
    gap: 5vw;
    margin-left: 1vw;
    margin-bottom: 5vw;
`;

export default function Document(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();

    const documentsObject = useSelector((state) => state.documents) || {};
    const documents = (documentsObject && documentsObject.documents && documentsObject.documents.results) || [];

    

    useEffect(() => {
        dispatch(getDocument(params.documentId))
    }, [params.documentId, dispatch])

    let document = documents.filter(a => Number(a.id) === Number(params.documentId));
    const [ dateValue, setDateValue ] = useState((document && document[0] && document[0].created) || null);

    if (document.length === 0) {
        return (
            <div>Geen document gevonden</div>   
        )
    }

    document = document[0];

    const articles = document.document_articles;

    const handleSubmit = () => {
        let newDocument = Object.assign({}, document);
        newDocument.created = dateValue;

        dispatch(putDocument(newDocument)).then(dispatch(getDocuments()))
    }

    return (
        <div style={{ height: '100%', position: 'relative' }}>
            <Typography style={{ marginLeft: '15px', marginBottom: '2vw' }} variant='h5'>{document.type} {document.sub_type}</Typography>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Datum</Typography>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label="Datum"
                        value={dayjs(dateValue)}
                        onChange={(e) => setDateValue(e.format('YYYY-MM-DDTHH:mm:ss'))}
                        inputFormat='DD/MM/yyyy HH:mm'
                        renderInput={(params) => <TextField {...params} />}
                        ampm={false}
                        required
                    />
                </LocalizationProvider>
                <Button onClick={handleSubmit} variant='contained' startIcon={<EditIcon />}>Aanpassen</Button>
            </Field>
            <Typography style={{ marginLeft: '15px', marginBottom: '2vw', fontSize: '16pt' }}>Artikelen</Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Artikel nummer</TableCell>
                            <TableCell>Titel</TableCell>
                            <TableCell>Aanmaakdatum</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {articles.map((article, i) => (
                            <TableRow key={i} onClick={() => history.push(`/admin/documents/${document.id}/articles/${article.id}`, { 'article': article })} hover>
                                <TableCell>{article.article_number}</TableCell>
                                <TableCell>{article.title}</TableCell>
                                <TableCell>{getDateTime(article.created)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            
            <StyledLink to={{ pathname: `/admin/documents/${document.id}/articles/new`, post: true }}>
                <Fab color='primary' aria-label='add'>
                    <AddIcon />
                </Fab>
            </StyledLink>
            
        </div>
    )
}