import { GET_KEYWORDS } from '../actions/types';

const keywords = (state = {}, action) => {
  switch (action.type) {
    case GET_KEYWORDS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default keywords;