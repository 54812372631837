export const containsCurrentURL = (location, url) => {
    return location.pathname.toLowerCase().includes(url.toLowerCase());
}

export const showContent = (location) => {
    if (containsCurrentURL(location, '/admin')) {
        return false;
    } else if (containsCurrentURL(location, '/insta')) {
        return false;
    }

    return true;
}