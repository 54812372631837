import React from 'react';
import styled from 'styled-components';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(fab);

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: ${props => props.color};
    background: transparent;
    font-size: 20px;
    margin: ${props => props.margin || 'default'}; 

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
       font-size: 17px;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 17px;
    }
`;

const IconCircle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: ${props => props.color};
    border-radius: 50%;

    &:hover {
        background-color: ${props => props.hoverColor};
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 25px;
        height: 25px;
    }  
    
    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        width: 25px;
        height: 25px;
    }
`;

export default function Icon(props) {
    return (
        <IconCircle color={props.backgroundColor} hoverColor={props.backgroundHoverColor}>
            <StyledFontAwesomeIcon icon={['fab', props.name]} color={props.textColor} margin={props.iconMargin} />
        </IconCircle>
    );
}