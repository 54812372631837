import { BulletPoint, Button, LineButton, Typography } from "../../components";
import StartTextPhoto from "./StartTextPhoto";

import image from '../../assets/images/diensten/socialmediatemplates/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-social-media-templates-1.jpg';
import image2 from '../../assets/images/diensten/socialmediatemplates/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-social-media-templates-2.jpg';
import image3 from '../../assets/images/diensten/socialmediatemplates/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-social-media-templates-3.jpg';
import { Grid } from "@mui/material";
import { Blog, Instagram, LieveWoorden, Match, Nieuwsgierig, Shop } from '../../subpages';
import { getCsvDienstRow } from "../../helpers/diensten";

export default function SocialMediaTemplates(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;
    const dienstenCsv = props.dienstenCsv;

    const socialMediaTemplatesRow = getCsvDienstRow(dienstenCsv, 'social media templates');

    return (
        <div>
            <StartTextPhoto
                title={socialMediaTemplatesRow['dienst']}
                subtitle={`Vanaf ${socialMediaTemplatesRow['prijs']} excl. btw`}
                image={image}
                {...props}
            >
                <BulletPoint>8 gepersonaliseerde templates</BulletPoint>
                <BulletPoint>In Canva of Adobe Indesign</BulletPoint>
                <BulletPoint>Stories en post formaat</BulletPoint>
                <BulletPoint>Zelf aan te passen</BulletPoint><br/>
                <Typography>Wat kan ik zelf doen?</Typography>
                <BulletPoint>Heb je al een huisstijl? Lever dan al je huisstijl elementen aan, die ik moet verwerken in de templates.</BulletPoint>
                <BulletPoint>Kies de thema’s van je templates</BulletPoint>
                <Button link='/contact' style={{ marginTop: hasPhone ? '5vw' : '2vw'}}>Yes! Dit wil ik</Button>
            </StartTextPhoto>
            <div style={{ position: 'relative', marginBottom: hasPhone ? '75vw' : '10vw'}}>
                <div style={{ marginLeft: hasPhone ? '10vw' : hasTablet ? '55vw' : '50vw'}}>
                    <Typography variant='h2'>onderwerpen voor <br/>social media templates</Typography>
                    <Typography>Je kan hier wat inspiratie opdoen:</Typography>
                    <BulletPoint>Een quote</BulletPoint>
                    <BulletPoint>Een foto en een tekst</BulletPoint>
                    <BulletPoint>Een nieuwe blog</BulletPoint>
                    <BulletPoint>Gratis weggever</BulletPoint>
                    <BulletPoint>Reviews</BulletPoint>
                    <BulletPoint>Speciale actie</BulletPoint>
                    <BulletPoint>Vacature</BulletPoint>
                    <BulletPoint>Werkwijze</BulletPoint>
                    <BulletPoint>Diensten</BulletPoint>
                    <Button link='/contact' style={{ marginTop: '2vw'}}>Yes! Dit wil ik</Button>
                </div>
                <img src={image2} alt='moodboard' style={{ position: 'absolute', bottom: hasPhone ? '-65vw' : hasTablet ? '0vw' : '0vw', left: hasPhone ? '10vw' : hasTablet ? '-7vw' : '-7vw', width: hasPhone ? '35vw' : hasTablet ? '18vw' : '15vw', margin: hasPhone ? '0' : '0 2vw 0 15vw' }} />
                <img src={image3} alt='moodboard' style={{ position: 'absolute', top: hasPhone ? '85vw' : hasTablet ? '3vw' : '3vw', left: hasPhone ? '50vw' : hasTablet ? '28vw' : '25vw', width: hasPhone ? '35vw' : hasTablet ? '18vw' : '15vw' }} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: hasPhone ? '0' : '5vw' }}>
                <div style={{ width: hasPhone ? '80vw' : '100vw'}}>
                    <Typography variant='h2'>naast templates voor social media kan <br/> ik je ook nog helpen met…</Typography>
                    <Grid container justifyContent='center' alignItems='center' gap={hasPhone ? '20vw' : '10vw'} style={{ margin: hasPhone ? '5vw 0 0 0' : '0 0 1vw 0'}}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: hasPhone ? '80vw' : hasTablet ? '47vw' : '30vw', textAlign: 'center'}}>
                            <Typography variant='h2'>social content boost</Typography>
                            <Typography variant='sub' style={{ marginBottom: '1vw'}}>Vanaf €175,- excl. btw per maand</Typography>
                            <Typography>Consistent aanwezig zijn op een unieke manier, zonder elke week uren te besteden aan het uitzoeken van de goede afbeelding, de kleuren of het lettertype? Naast het maken van templates voor jouw social media, kan ik ook het visuele gedeelte van de social media uit handen nemen. Het voordeel hiervan is dat ik met je meedenk over de inhoud van jouw posts en stories, maar vooral dat ik ze op een unieke manier vormgeef die écht bij jou en jouw doelgroep past. Je hebt altijd voldoende content en kan goed vooruit plannen.</Typography>
                            
                            <LineButton to='/diensten/socialcontentboost' style={{ margin: hasPhone ? '3vw 0 0 0' : hasTablet ? '2vw 0 0 0' : '0'}}>Meer weten?</LineButton>
                        </div>
                    </Grid>
                </div>
            </div>
            <Match {...props} />
            <LieveWoorden {...props} />
            <Shop {...props} />
            <Blog {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}