import React from 'react';

import { Typography, PageTitle } from "../../components";
import { Instagram } from '../../subpages';
import { Grid } from "@mui/material";

import Document from './Document';

export default function Cookies(props) {
    const documentName = 'Cookiestatement'

    return (
        <div>
            <PageTitle title={documentName} left='-35px'>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography variant='h2'>{documentName}</Typography>
                    <Document documentName={documentName} {...props} />
                </Grid>
            </PageTitle>
            <Instagram {...props} />
        </div>
    )
}