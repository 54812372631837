
import { GET_INSTAGRAM_CODES } from "../actions/types";

const instagramCodes = (state = {}, action) => {
    switch (action.type) {
      case GET_INSTAGRAM_CODES:
        return {
          ...state,
          ...action.payload,
        };
      default:
        return state;
    }
  };

export default instagramCodes;