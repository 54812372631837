import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import styled from 'styled-components';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Fab } from '@mui/material';
import { getReviews } from '../../../actions/reviews';

import AddIcon from '@mui/icons-material/Add';
import { getDateTime } from '../../../helpers/datetime';

const StyledLink = styled(Link)`
    position: absolute;
    bottom: 2vw;
    right: 5vw;
    text-decoration: none;
`;

const TYPES = { 'TY': 'Typografie', 'BR': 'Branding', 'BO': 'Boekontwerp'}

export default function Reviews(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const reviewsObject = useSelector((state) => state.reviews) || {};
    const reviews = (reviewsObject && reviewsObject.reviews && reviewsObject.reviews.results) || [];

    useEffect(() => {
        dispatch(getReviews())
    }, [dispatch])

    if (reviews.length === 0) {
        return (
            <div>Geen reviews gevonden
                <StyledLink to={{ pathname: `/admin/reviews/new`, post: true }}>
                    <Fab color='primary' aria-label='add'>
                        <AddIcon />
                    </Fab>
                </StyledLink>
            </div>
        )
    }

    return (
        <div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Titel</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Laten zien bij</TableCell>
                            <TableCell>Bedrijf</TableCell>
                            <TableCell>Aanmaakdatum</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reviews.map((review, i) => (
                            <TableRow key={i} onClick={() => history.push(`/admin/reviews/${review.id}`, { review: review })} hover>
                                <TableCell>{review.title}</TableCell>
                                <TableCell>{TYPES[review.type]}</TableCell>
                                <TableCell>{review.show_at === 'OV' ? 'Overal' : 'Alleen bij project'}</TableCell>
                                <TableCell>{review.company_name}</TableCell>
                                <TableCell>{getDateTime(review.created)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <StyledLink to={{ pathname: `/admin/reviews/new`, post: true }}>
                <Fab color='primary' aria-label='add'>
                    <AddIcon />
                </Fab>
            </StyledLink>
        </div>
    )
}