import React, { useState } from 'react';
import { Button, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useDispatch } from 'react-redux';

import { logout } from '../../actions/logout';
import { Switch, useHistory, Link } from 'react-router-dom';
import ProtectedRoute from '../../helpers/ProtectedRoute';
import styled from 'styled-components';

import InstagramIcon from '@mui/icons-material/Instagram';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CreateIcon from '@mui/icons-material/Create';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ImageIcon from '@mui/icons-material/Image';
import ReviewsIcon from '@mui/icons-material/Reviews';

import { colors } from '../../assets/colors';

import { Documenten, Document, Article, NewDocument } from './Documenten';
import { Projecten, Project } from './Projecten';
import { Blogs, Blog } from './Blogs';
import Boeking from './Boeking';
import Instagram from './Instagram';
import { Review, Reviews } from './Reviews';

import { Alert } from '../../components';

const Body = styled.div`
    display: flex;
    min-height: 80vh;
`;

const Drawer = styled.div`
    width: 15vw;
    height: 100%;
    background-color: ${colors.button};
`;

const Content = styled.div`
    width: 100%;
    height: calc(100% - 2vw);
    padding: 1vw;
`

export default function Admin(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const [ currentLocation, setCurrentLocation ] = useState('/admin');

    const drawerItems = [ 
        { name: 'Portfolio', icon: <ImageIcon/>, route: '/projecten' },
        { name: 'Reviews', icon: <ReviewsIcon/>, route: '/reviews' }, 
        { name: 'Blog', icon: <CreateIcon/>, route: '/blogs' }, 
        { name: 'Boeking', icon: <CalendarTodayIcon/>, route: '/boeking' }, 
        { name: 'Documenten', icon: <HistoryEduIcon/>, route: '/documents' }, 
        { name: 'Instagram', icon: <InstagramIcon/>, route: '/instagram' },
    ];

    const getLogout = () => {
        dispatch(logout()).then(history.push('/'))
    }

    const getDocumentsRoute = () => {
        const matchPath = '/admin/documents';

        return (
            <Switch>
                <ProtectedRoute path={`${matchPath}/:documentId`}>
                    <Switch>
                        <ProtectedRoute path={`${matchPath}/:documentId/articles/:articleId`}>
                            <Article />
                        </ProtectedRoute>
                        <ProtectedRoute path={`${matchPath}/new`}>
                            <NewDocument />
                        </ProtectedRoute>
                        <ProtectedRoute path={`${matchPath}/:documentId`}>
                            <Document />
                        </ProtectedRoute>
                    </Switch>
                </ProtectedRoute>
                <ProtectedRoute path={matchPath}>
                    <Documenten />
                </ProtectedRoute>
            </Switch>
        )
    }

    const getProjectenRoute = () => {
        const matchPath = '/admin/projecten';

        return (
            <Switch>
                <ProtectedRoute path={`${matchPath}/:projectId`}>
                    <Project />
                </ProtectedRoute>
                <ProtectedRoute path={matchPath}>
                    <Projecten />
                </ProtectedRoute>
            </Switch>
        )
    }

    const getReviewsRoute = () => {
        const matchPath = '/admin/reviews';

        return (
            <Switch>
                <ProtectedRoute path={`${matchPath}/:reviewId`}>
                    <Review />
                </ProtectedRoute>
                <ProtectedRoute path={matchPath}>
                    <Reviews />
                </ProtectedRoute>
            </Switch>
        )
    }

    const getBlogsRoute = () => {
        const matchPath = '/admin/blogs';

        return (
            <Switch>
                <ProtectedRoute path={`${matchPath}/:blogId`}>
                    <Blog {...props} />
                </ProtectedRoute>
                <ProtectedRoute path={matchPath}>
                    <Blogs />
                </ProtectedRoute>
            </Switch>
        )
    }

    return (
        <div style={{ position: 'relative', height: '100%' }}>
            <div id='header' style={{ position: 'relative', height: '5vh', borderBottom: '1px solid grey' }}>
                <div style={{ position: 'absolute', top: '1vh', right: '1vh' }}>
                    <Button variant='contained' onClick={getLogout}>Uitloggen</Button>
                </div>
            </div>
            <Body>
                <Drawer>
                    <List
                        component='nav'
                    >
                        {drawerItems.map((item, i) => {
                            const newLocation = `/admin${item.route}`;
                            return (
                                <Link key={i} to={newLocation} onClick={() => setCurrentLocation(newLocation)} style={{ textDecoration: 'none', color: 'black' }}>
                                    <ListItemButton
                                        key={i}
                                        selected={currentLocation.includes(item.route)}
                                    >
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.name}/>
                                    </ListItemButton>
                                </Link>
                            )
                        })}
                    </List>
                </Drawer>
                <Content>
                    <Switch>
                        <ProtectedRoute path={`/admin/documents`}>
                            {getDocumentsRoute()}
                        </ProtectedRoute>
                        <ProtectedRoute path={`/admin/projecten`}>
                            {getProjectenRoute()}
                        </ProtectedRoute>
                        <ProtectedRoute path={`/admin/reviews`}>
                            {getReviewsRoute()}
                        </ProtectedRoute>
                        <ProtectedRoute path={`/admin/blogs`}>
                            {getBlogsRoute()}
                        </ProtectedRoute>
                        <ProtectedRoute path={`/admin/instagram`}>
                            <Instagram {...props} />
                        </ProtectedRoute>
                        <ProtectedRoute path={`/admin/boeking`}>
                            <Boeking />
                        </ProtectedRoute>
                    </Switch>
                </Content>
            </Body>
            <Alert />
        </div>
    )
}