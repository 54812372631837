import { GET_PROJECT, GET_PROJECTS } from '../actions/types';

const projects = (state = {}, action) => {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };
    case GET_PROJECT:
      let newProjectsObject = {};
      if (state.projects) {
        const oldProjects = (state.projects && state.projects.results) || [];
        const oldProject = oldProjects.filter(s => Number(s.id) === Number(action.payload.data))[0];
        const idList = oldProjects.indexOf(oldProject);

        let newProjects = oldProjects;
        if (idList < 0) {
          newProjects.push(action.payload.data);
        } else {
          newProjects[idList] = action.payload.data;
        }

        newProjectsObject = action;
        newProjectsObject['results'] = newProjects;
      } else {
        newProjectsObject['results'] = [action.payload.data];
      }

      return {
        ...state,
        projects: newProjectsObject,
      };
    default:
      return state;
  }
};

export default projects;