import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';

const Line = styled.div`
    position: absolute;
    left: ${props => props.direction === 'right' ? '0' : 'default'};
    right: ${props => props.direction === 'left' ? '0' : 'default'};
    border-bottom: solid ${colors.photo} 1px;
    width: ${props => props.hover ? '2vw' : '3vw'};
    height: 0;

    &:hover {
        cursor: pointer;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        width: ${props => props.long && '12vw'};
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        width: ${props => props.long && '5vw'};
    }
`;

const StyledArrow = styled.div`
    position: absolute;
    left: ${props => props.direction === 'right' ? '2.6vw' : 'default'};
    left: ${props => (props.direction === 'right' && props.hover) ? '1.6vw' : 'default'};
    right: ${props => props.direction === 'left' ? '2.6vw' : 'default'};
    right: ${props => (props.direction === 'left' && props.hover) ? '1.6vw' : 'default'};
    border: solid ${colors.photo};
    border-width: 0 1px 1px 0;
    width: 5px;
    height: 5px;

    display: inline-block;

    transform: rotate(${props => props.direction === 'left' ? '135deg' : '-45deg'});

    &:hover {
        cursor: pointer;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        left: ${props => props.direction === 'right' ? '2.3vw' : 'default'};
        left: ${props => (props.direction === 'right' && props.hover) ? '1.3vw' : 'default'};
        right: ${props => props.direction === 'left' ? '2.3vw' : 'default'};
        right: ${props => (props.direction === 'left' && props.hover) ? '1.3vw' : 'default'};

        left: ${props => (props.direction === 'right' && props.long) ? '4.2vw' : 'default'};
        left: ${props => (props.direction === 'right' && props.long && props.hover) ? '4.2vw' : 'default'};
        right: ${props => (props.direction === 'left' && props.long) ? '4.2vw' : 'default'};
        right: ${props => (props.direction === 'left' && props.long && props.hover) ? '4.2vw' : 'default'};
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        left: ${props => props.direction === 'right' ? '1.5vw' : 'default'};
        left: ${props => (props.direction === 'right' && props.hover) ? '0.5vw' : 'default'};
        right: ${props => props.direction === 'left' ? '1.5vw' : 'default'};
        right: ${props => (props.direction === 'left' && props.hover) ? '0.5vw' : 'default'};

        left: ${props => (props.direction === 'right' && props.long) ? '10.2vw' : 'default'};
        left: ${props => (props.direction === 'right' && props.long && props.hover) ? '10.2vw' : 'default'};
        right: ${props => (props.direction === 'left' && props.long) ? '10.2vw' : 'default'};
        right: ${props => (props.direction === 'left' && props.long && props.hover) ? '10.2vw' : 'default'};
    }
`;

const ArrowLine = styled.div`
    display: flex;
    position: relative;
    flex-direction: ${props => props.direction === 'right' ? 'row-reverse' : 'default'};
    justify-content: flex-start;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    &:hover ${Line} {
        width: 2vw;
    }

    &:hover ${StyledArrow} {
        left: ${props => props.direction === 'right' ? '1.6vw' : 'default'};
        right: ${props => props.direction === 'left' ? '1.6vw' : 'default'};
    }
`;

const Container = styled.div`
    width: 4vw;
    height: 1vw;

    &:hover {
        cursor: pointer;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        width: ${props => props.long && '12vw'};
        margin-top: 1.5vw;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        width: ${props => props.long && '5vw'};
        margin-top: 0.5vw;
    }
`;

export default function Arrow(props) {
    return (
        <Container onClick={props.onClick} style={props.style} long={props.long}>
            <ArrowLine direction={props.direction} hover={props.hover} long={props.long}>
                <StyledArrow direction={props.direction} hover={props.hover} long={props.long} />
                <Line direction={props.direction} hover={props.hover} long={props.long}/>
            </ArrowLine>
        </Container>
    )
}