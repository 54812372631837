import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { Button, Typography, LineButton, RotatingCircle } from '../../components';
import { Blog, Instagram, Nieuwsgierig } from '../../subpages';
import Review from './Review';

import { ReactComponent as Sleutel } from '../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_sleutel.svg';
import { ReactComponent as Branding } from '../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_branding-typografie-boekontwerp.svg';

import { useDispatch, useSelector } from 'react-redux';
import { getReviews } from '../../actions/reviews';

const CustomLineButton = styled.div`
    font-family: Mulish;
    font-size: 13px;
    font-weight: ${props => props.active ? '600' : '300'};
    text-transform: uppercase;
    text-decoration: none;
    color: ${colors.photo};
    letter-spacing: 1.4px;
    text-decoration: underline;
    text-underline-offset: 5px;
    margin: ${props => props.margin || '0.5vw 1vw'};
    width: inherit;
    text-align: left;

    &:hover {
        cursor: pointer;
        font-weight: 600;
    }

    @media screen and (max-width: 766px) {
        width: 45%;
        text-align: ${props => props.alignRight ? 'right' : 'left'}; 
        font-size: 11px;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 11px;
    }
`;

const CustomButton = styled.div`
    font-family: Mulish;
    font-weight: 400;
    text-decoration: none;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    font-size: 13px;
    color: ${colors.photo};
    border: 1px solid ${colors.photo};
    padding: 8px;
    border-radius: 18px;
    outline: none;
    margin-top: 2vw;

    &:hover {
        font-weight: 600;
        cursor: pointer;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 11px;
        letter-spacing: 1.1px;
        padding: 5px;
        margin-bottom: 4vw;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 11px;
        letter-spacing: 1.1px;
        padding: 8px;
    }
`;

export default function Reviews(props) {
    const [ isPage, setPage ] = useState(1);
    const [ isType, setType ] = useState(null);

    const dispatch = useDispatch();
    const reviewObject = useSelector((state) => state.reviews).reviews || {};
    const reviews = (reviewObject && reviewObject.results) || [];

    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    const changeType = (type) => {
        let filters = { page_size: 3 }
        if (isType === type) {
            setType(null);
            filters.page = isPage;
            dispatch(getReviews(filters));

        } else {
            setType(type);
            filters.type = type;
            // reset page to 1
            setPage(1);
            filters.page = 1;
            dispatch(getReviews(filters))
        }
    }

    const changePage = (pageNumber) => {
        setPage(pageNumber);
        const filters = { page_size: 3, page: pageNumber, type: isType };
        dispatch(getReviews(filters));
        window.scrollTo(0,0);
    }

    useEffect(() => {
        const filters = { page_size: 3, page: isPage, type: isType, show_at: 'OV' };
        dispatch(getReviews(filters))
    }, [dispatch, isPage, isType])

    return (
        <div>
            <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: hasPhone ? 'center' : 'left', marginBottom: hasPhone ? '25vw' : hasTablet ? '15vw' :  '5vw' }}>
                <Typography variant='h1'>lieve woorden</Typography>
                <div style={{ display: 'flex', justifyContent: 'left', maxWidth: hasPhone ? '75vw' : '100vw', gap: hasPhone ? '3vw' : hasTablet ? '2vw' : '0', flexWrap: 'wrap', margin: hasPhone ? '2vw 0 10vw' : hasTablet ? '2vw 0 5vw' : '0' }}>
                    <CustomLineButton onClick={() => changeType('BR')} active={isType === 'BR'}>Branding</CustomLineButton>
                    <CustomLineButton onClick={() => changeType('TY')} active={isType === 'TY'} alignRight={true}>Typografie</CustomLineButton>
                    <CustomLineButton onClick={() => changeType('BO')} active={isType === 'BO'}>Boekontwerp</CustomLineButton>
                </div>
                {reviews && reviews.map((review, i) => 
                    <div style={{ margin: hasPhone ? '5vw 0' : hasTablet ? '3vw 0' : '0'}}>
                        <Review index={i} review={review} isPage={isPage} reverse showAll {...props} />
                    </div>
                )}
                {(reviewObject.next || reviewObject.previous) && 
                    <div style={{ position: 'relative', height: '5vw', width: '100%', marginTop: '1vw' }}>
                        {reviewObject.previous && 
                            <div style={{ position: 'absolute', left: hasPhone ? '7vw' : '20vw'}}>
                                <CustomButton onClick={() => changePage(isPage - 1)}>Bekijk vorige</CustomButton>
                            </div> 
                        }
                        {reviewObject.next && 
                            <div style={{ position: 'absolute', right: hasPhone ? '7vw' : '20vw'}}>
                                <CustomButton onClick={() => changePage(isPage + 1)}>Bekijk volgende</CustomButton>
                            </div>
                        }
                    </div>
                }
                {!(hasPhone || hasTablet) && <RotatingCircle style={{ position: 'absolute', right: '5vw', top: '40vw'}}>
                    <Branding style={{ fill: colors.grey, width: '13vw' }} />
                </RotatingCircle>}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: hasPhone ? '100vw' : hasTablet ? '30vw' : '20vw', margin: hasPhone ? '20vw 0' : hasTablet ? '0 0 0 17vw' : '10vw 0 0 20vw', position: 'relative'}}>
                <Typography variant='h2'>waar kan ik jou <br/>mee helpen?</Typography>
                <LineButton to='/diensten' style={{ margin: hasPhone ? '2vw' : '0.5vw'}}>Diensten</LineButton>
                <LineButton to='/werkwijze' style={{ margin: hasPhone ? '2vw 0 5vw' : '0.5vw 0 2vw'}}>Werkwijze</LineButton>
                <Button link='/portfolio'>Neem een kijkje in mijn portfolio</Button>
                <Sleutel style={{ fill: colors.illustration, position: 'absolute', width: hasTablet ? '6vw' : '5vw', top: hasTablet ? '-9vw' : '-8vw', left: hasTablet ? '-8vw' : '-13vw' }} />
            </div>
            <Blog {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}