import axiosInstance from './axios';
import { reportResponse } from './helpers';
import { PUT_BLOG_CONTENT, POST_BLOG_CONTENT } from './types';

// PUT BLOG CONTENT
export const putBlogContent = (blogContent) => async dispatch => {
  const res = await axiosInstance.put(`/api/blog_contents/${blogContent.id}/`, blogContent);

  return reportResponse(res, PUT_BLOG_CONTENT, 'Blog content', dispatch);
}

// POST BLOG CONTENT
export const postBlogContent = (blogContent) => async dispatch => {
  const res = await axiosInstance.post(`/api/blog_contents/`, blogContent);
  
  return reportResponse(res, POST_BLOG_CONTENT, 'Blog content', dispatch);
};
