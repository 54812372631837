import axiosInstance from './axios';
import { GET_BOOKINGS, PUT_BOOKING, POST_BOOKING } from './types';
import { reportResponse } from './helpers';

// GET BOOKINGS
export const getBookings = () => async dispatch => {
  const res = await axiosInstance.get(`/api/bookings/`);
  dispatch({
    type: GET_BOOKINGS,
    payload: res.data
  });
};

export const putBooking = (booking) => async dispatch => {
  const res = await axiosInstance.put(`/api/bookings/${booking.id}/`, booking);

  return reportResponse(res, PUT_BOOKING, 'Boeking', dispatch);
}

export const postBooking = (booking) => async dispatch => {
  const res = await axiosInstance.post(`/api/bookings/`, booking);

  return reportResponse(res, POST_BOOKING, 'Boeking', dispatch);
}