import axiosInstance from './axios';
import { GET_BLOG_TYPES, POST_BLOG_TYPES } from './types';
import { reportResponse } from './helpers';

// GET PROJECT TYPE
export const getBlogTypes = () => async dispatch => {
    const res = await axiosInstance.get(`/api/blog_types/`);
    
    dispatch({
      type: GET_BLOG_TYPES,
      payload: res.data
    });
};

// POST PROJECT TYPE
export const postBlogType = (blogType) => async dispatch => {
  const res = await axiosInstance.post(`/api/blog_types/`, blogType);
  
  return reportResponse(res, POST_BLOG_TYPES, 'Blog type', dispatch);
};