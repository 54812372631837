import axiosInstance from './axios';
import { DELETE_PROJECT_GRID, PUT_PROJECT_GRID, POST_PROJECT_GRID } from './types';
import { reportResponse } from './helpers';

// PUT PROJECT GRID
export const putProjectGrid = (projectGrid) => async dispatch => {
  const res = await axiosInstance.put(`/api/project_grids/${projectGrid.id}`, projectGrid);

  return reportResponse(res, PUT_PROJECT_GRID, 'Project grid', dispatch);
}

// POST PROJECT GRID
export const postProjectGrid = (projectGrid) => async dispatch => {
  const res = await axiosInstance.post(`/api/project_grids/`, projectGrid);
  
  return reportResponse(res, POST_PROJECT_GRID, 'Project grid', dispatch);
};

// DELETE PROJECT GRID
export const deleteProjectGrid = (id) => async dispatch => {
  const res = await axiosInstance.delete(`/api/project_grids/${id}/`)

  return reportResponse(res, DELETE_PROJECT_GRID, 'Project grid', dispatch);
}
