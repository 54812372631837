import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Alert as AlertMUI } from '@mui/material';

export default function Alert(props) {
    const [ show, setShow ] = useState(false);

    const responseObject = useSelector((state) => state.responses);

    useEffect(() => {
        let timer1 = setTimeout(() => setShow(true), 1000);
        return () => { clearTimeout(timer1); };
    }, [responseObject]);

    if (show) {
        let code = responseObject.responseCode;
        let severity = 'success';
        if (code === 400 || code === 401) {
            severity = 'error'
        }

        return (
            <div style={{ position: 'absolute', bottom: '10vw', right: '5vw'}}>
                <AlertMUI severity={severity} onClose={() => {setShow(false)}}>
                    {JSON.stringify(responseObject.message)}
                </AlertMUI>
            </div>
        )
    }

    return null;
}