import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Fab } from '@mui/material';
import { getProjects } from '../../../actions/projects';

import AddIcon from '@mui/icons-material/Add';

import styled from 'styled-components';
import { getDateTime } from '../../../helpers/datetime';

const StyledLink = styled(Link)`
    position: absolute;
    bottom: 2vw;
    right: 5vw;
    text-decoration: none;
`;

export default function Projecten(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const projectObject = useSelector((state) => state.projects) || {};
    const projects = (projectObject && projectObject.projects && projectObject.projects.results) || [];

    useEffect(() => {
        dispatch(getProjects())
    }, [projects.length, dispatch])

    if (projects.length === 0) {
        return (
            <div>Geen projecten gevonden
                <StyledLink to={{ pathname: `/admin/projecten/new`, post: true }}>
                    <Fab color='primary' aria-label='add'>
                        <AddIcon />
                    </Fab>
                </StyledLink>
            </div>
        )
    }

    // render the list of projects page
    return (
        <div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Project naam</TableCell>
                            <TableCell>Recent</TableCell>
                            <TableCell>Bedrijf</TableCell>
                            <TableCell>Categorie</TableCell>
                            <TableCell>Aanmaakdatum</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projects.map((project, i) => (
                            <TableRow key={i} onClick={() => history.push(`/admin/projecten/${project.id}`, { project: project })} hover>
                                <TableCell>{project.title}</TableCell>
                                <TableCell>
                                    <Checkbox checked={project.is_recent} disabled />
                                </TableCell>
                                <TableCell>{project.company_name}</TableCell>
                                <TableCell>
                                    {project.types.map((type, j) => (
                                        <div key={j}>{type.content}</div>
                                    ))}
                                </TableCell>
                                <TableCell>{getDateTime(project.created)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <StyledLink to={{ pathname: `/admin/projecten/new`, post: true }}>
                <Fab color='primary' aria-label='add'>
                    <AddIcon />
                </Fab>
            </StyledLink>
        </div>
    )
}