import { GET_PROJECT_OVERVIEW } from '../actions/types';

const projectOverviews = (state = {}, action) => {
  switch (action.type) {
    case GET_PROJECT_OVERVIEW:
      return {
        ...state,
        ...action.payload,
        types: action.payloadTypes,
        nextPage: action.payloadNextPage,
      };
    default:
      return state;
  }
};

export default projectOverviews;