import React from 'react';
import { Grid } from '@mui/material';
import { Typography, ImageArch, Accordion, PageTitle } from '../../components';
import { LieveWoorden, Nieuwsgierig, Instagram } from '../../subpages';
import { colors } from '../../assets/colors';

import portret1 from '../../assets/images/over/Studio-Valerie-Maas-grafisch-ontwerp-over-portret-1.jpg';
import portret2 from '../../assets/images/over/Studio-Valerie-Maas-grafisch-ontwerp-over-portret-2.jpg';
import hannecharlie from '../../assets/images/over/Studio-Valerie-Maas-grafisch-ontwerp-over-hanne-en-charlie.jpg';
import paalsport from '../../assets/images/over/Studio-Valerie-Maas-grafisch-ontwerp-over-portret-3.jpg';
import planten from '../../assets/images/over/Studio-Valerie-Maas-grafisch-ontwerp-over-planten.jpg';
import joyce from '../../assets/images/over/Studio-Valerie-Maas-grafisch-ontwerp-over-Joyce-Bastiaans-Content-Marketeer-Copywriter.jpg';
import elien from '../../assets/images/over/Studio-Valerie-Maas-grafisch-ontwerp-over-Elien-Alentijns-webdesigner.jpg';

import { ReactComponent as Bananenplant } from '../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_bananenplant.svg';
import { ReactComponent as Notitieboek } from '../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_notitieboek.svg';
import { ReactComponent as Latte } from '../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_kopje-latte-macchiato.svg';

export default function Over(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    return (
        <div>
            <div style={{ position: 'relative', marginBottom: '7vw' }}>
                <Grid container justifyContent='center' alignItems='flex-start' gap={!hasPhone && '20vw'}>
                    <div style={{ textAlign: 'center', width: hasPhone ? '85vw' : hasTablet ? '35vw' : '24vw', margin: hasPhone ? '1vw 0 5vw 0' : '3vw 0 0 0'}}>
                        <Typography variant='sub' as='h1'>Over studio Valerie maas</Typography>
                        <Typography variant='h2'>strategische <br/>branding studio</Typography>
                        <Typography>Binnen mijn <span style={{ fontStyle: 'italic' }}>brand design studio</span> ontwerp ik merken die voor een échte connectie zorgen met jouw droomklant. Merken die niet alleen mooi zijn, maar die jouw klant écht aanspreken. Waar ze zich in thuis voelen.<br/><br/>

                        Ik wil je, samen met mijn team, helpen meer impact te maken met jouw business door middel van strategische branding én art direction. We creëren magie from the inside out, startend bij de strategie, doorgevoerd in jouw uitstraling. Wil jij net als ik blijven doen waar je blij van wordt én je droomleven leiden?<br/><br/>

                        Creatief, Betrouwbaar, Open, Duidelijk, Persoonlijk, Passievol, Warm, Doortastend, Benaderbaar, Gestructureerd, Krachtig, Intuïtief en Confronterend. Dat zijn de woorden die Studio Valerie Maas omschrijven volgens onze klanten.
                        </Typography>
                    </div>
                    <ImageArch src={portret1} alt='Valerie lachend' width={hasPhone ? '80vw' : '25vw'} />
                    {!hasPhone && <Bananenplant style={{ fill: colors.illustration, position: 'absolute', zIndex: '1000', left: hasTablet ? '3vw' : '6vw', bottom: hasTablet ? '-17vw' : '-3vw', width: hasTablet ? '10vw' : '8vw', background: 'transparent' }} />}
                </Grid>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', margin: hasPhone ? '10vw 0' : '5vw 0'}}>
                <Typography variant='h2' style={{ width: hasPhone ? '85vw' : hasTablet ? '65vw' : '45vw' }}>het is mijn missie niet enkel een mooi plaatje te maken, maar jou te helpen een merk te bouwen waar je verliefd op bent en waar je je in thuis voelt. door jouw verhaal, jouw doelen en dromen te vertalen naar een passende branding, kan jij doen waar jij echt blij van wordt. Ik help je graag jouw dromen waar te maken.
                </Typography>
            </div>
            <div style={{ position: 'relative', marginBottom: '7vw' }}>
                <div style={{ position: 'relative', height: hasPhone ? '280vw' : hasTablet ? '85vw' : '50vw' }}>
                    <div style={{ width: hasPhone ? '80vw' : hasTablet ? '42vw' : '30vw', margin: hasPhone ? '5vw 0 10vw 0' : hasTablet ? '2vw 0 0 0' : '1vw 0 0 0', position: 'absolute', left: hasPhone ? '10vw' : hasTablet ? '10vw' : '15vw'}}>
                        <Typography variant='sub'>Over valerie</Typography>
                        <Typography variant='h2'>brand designer, latte-lover <br/>en katten-mama</Typography>
                        <Typography style={{ margin: hasPhone ? '2vw 0' : '1vw 0'}}>Al toen ik klein was tekende ik eindeloos. Ik verzon zelf verhaaltjes, maakte er tekeningen bij en maakte er vervolgens een boekje van. Ik heb een passie voor verhalen in combinatie met een creatief en passend grafisch ontwerp.<br/><br/>
                        Na de middelbare school ging ik naar de kunstacademie, waar ik met olieverf en keramiek aan de slag ging. Ik kon creatief bezig zijn, iets wat ik het allerliefste doe, maar toch zag ik daar mijn droomleven uiteindelijk niet…<br/><br/>
                        Mijn echte passie vond ik toen ik grafisch ontwerp ging studeren. Niet enkel een mooi plaatje maken, maar ontwerpen met een doel én verhaal.<br/><br/>
                        Na mijn master werkte ik een aantal jaar als grafisch ontwerper bij een ontwerpbureau wat tentoonstellingen ontwerpt, ik werkte daar onder andere voor Rijksmuseum van Oudheden, Van Gogh en het Openlucht Museum. <br/><br/>
                        Mijn liefde voor verhalen in combinatie met grafisch ontwerp, zorgde ervoor dat ik ondertussen, in 2017, mijn eigen studio opzette, om ondernemers te kunnen helpen met het neerzetten van hun merk in mijn stijl.<br/><br/>
                        Een paar feitjes over mij: Ik kan geen dag zonder een latte (met caramel-siroop). Ik start de dag altijd met journalen en mediteren. Een échte katten-mama, maar ooit hoop ik echt mama te mogen worden. Ik ben planten-lover. En ik train mijn kracht, lichamelijk en mentaal, door minimaal twee keer in de week aan paalsport te doen.</Typography>
                    </div>
                    <div style={{ display: 'flex', justifyContent: hasPhone ? 'flex-end' : 'default', position: 'absolute', margin: hasPhone ? '0 0 25vw 0' : hasTablet ? '5vw 0 0 0' : '0', width: hasPhone ? '80vw' : hasTablet ? '43vw' : '34vw', height: hasPhone ? '30vw' : hasTablet ? '35vw' : '29vw', right: hasTablet ? '5vw' : '10vw', bottom: hasPhone && '0'}}>
                        <img src={hannecharlie} alt='Hanne en Charlie' style={{ height: hasPhone ? '35vw' : hasTablet ? '20vw' : '16vw', width: hasPhone ? '35vw' : hasTablet ? '20vw' : '16vw', position: 'absolute', bottom: hasPhone ? '-35vw' : hasTablet ? '-15vw' : '-12vw', left: hasPhone ? '15vw' : hasTablet ? '8vw':  '4vw'}} />
                        <ImageArch src={portret2} alt='Valerie' width={hasPhone ? '35vw' : hasTablet ? '20vw' : '16vw'} style={{ position: 'absolute', left: hasTablet ? '3vw' : '0', bottom: hasPhone && '10vw'}} />
                        <img src={paalsport} alt='Paalsport' style={{ height: hasPhone ? '35vw' : hasTablet ? '20vw' : '16vw', width: hasPhone ? '35vw' : hasTablet ? '20vw' : '16vw', position: 'absolute', bottom: hasPhone ? '-15vw' : '-3vw', left: hasPhone ? '45vw' : hasTablet ? '25vw' : '18vw', zIndex: '100'}} />
                    </div>
                </div>
            </div>
            <div style={{ position: 'relative', margin: hasPhone ? '30vw 0 7vw 0' : '10vw 0 7vw 0' }}>
                <Grid container justifyContent={hasPhone ? 'center' : 'flex-end'} alignItems='center'>
                    <div style={{ textAlign: 'center', width: hasPhone ? '80vw' : hasTablet ? '35vw' : '25vw', marginRight: hasPhone ? '-2vw' : hasTablet ? '30vw' : '40vw'}}>
                        <Typography variant='h2'>kernwaarden</Typography>
                        <Accordion variant='small' mainText='creativiteit & authenticiteit' detailText='Als klein meisje tekende ik eindeloos en schreef ik veel verhalen. Het kunnen en mogen creëren is iets wat erg belangrijk voor mij is. Dit doen op een manier die bij mij past, maar ook 100% authentiek is, maakt het proces alleen nog maar mooier.'/>    
                        <Accordion variant='small' mainText='Storytelling in beeld' detailText='Verhalen vertellen is iets wat altijd centraal staat in alles wat ik doe en waar ik van houd. Het is mijn passie om jouw verhaal op een manier te vertellen, in tekst en in beeld, die het beste bij jou past én jouw droomklant aantrekt.'/>
                        <Accordion variant='small' mainText='Verbinden & vertrouwen' detailText='Goede relaties op basis van liefde en vertrouwen zijn voor mij erg belangrijk. Ik sta altijd klaar voor anderen. Ik geloof erin dat je een goede relatie aan kan gaan door elkaar oprecht te vertrouwen en daardoor de verbinding open en eerlijk aan te gaan.'/>
                        <Accordion variant='small' mainText='Vrijheid' detailText='Ik begon mijn bedrijf, in 2017, om de vrijheid van het creëren te ervaren. Ik voel me het beste als ik in een creatieve flow kan werken, dan vergeet de tijd en ben ik écht gelukkig.' />
                    </div>
                </Grid>
                <Notitieboek style={{ fill: colors.latte, transform: 'scaleX(-1)', position: 'absolute', zIndex: '100', right: hasPhone ? '7vw' : '18vw', top: hasPhone ? 'inherit' : hasTablet ? '-7vw' : '5vw', bottom: hasPhone && '-18vw', width: hasPhone ? '23vw' : hasTablet ? '13vw' : '12vw', background: 'transparent' }} />
                {!hasPhone && <Latte style={{ fill: colors.latte, transform: 'scaleX(1)', position: 'absolute', zIndex: '100', right: hasPhone ? '60vw' : '13vw', top: hasPhone ? '-22vw' : hasTablet ? '-20vw' : '-5vw', width: hasPhone ? '12vw' : hasTablet ? '6.5vw' : '5vw', background: 'transparent' }} />}
            </div>
            <div style={{ position: 'relative', margin: hasPhone ? '20vw 0 10vw 0' : '5vw 0' }}>
                <Grid container justifyContent='space-evenly' alignItems='flex-end'>
                    <div style={{ textAlign: 'center', width: hasPhone ? '80vw' : hasTablet ? '35vw' : '20vw', marginLeft: hasPhone ? '0' : hasTablet ? '45vw' : '40vw'}}>
                        <Typography variant='h2'>de stijl van valerie is te herkennen aan...</Typography>
                        <Typography variant='sub' style={{ margin: hasPhone ? '3vw 0' : hasTablet ? '2vw 0' : '0 0 1vw 0' }}>modern & een tikje eigenwijs</Typography>
                        <Typography variant='sub' style={{ margin: hasPhone ? '3vw 0' : hasTablet ? '2vw 0' : '0 0 1vw 0' }}>Authentiek & strategisch</Typography>
                        <Typography variant='sub' style={{ margin: hasPhone ? '3vw 0' : hasTablet ? '2vw 0' : '0 0 1vw 0' }}>Rustgevend & warm</Typography>
                        <Typography variant='sub' style={{ margin: hasPhone ? '3vw 0' : hasTablet ? '2vw 0' : '0 0 1vw 0' }}>Verfijnd & beweeglijk</Typography>
                    </div>
                    {!hasPhone && <img src={planten} alt='Planten' style={{ height: hasPhone ? '35vw' : hasTablet ? '20vw' : '16vw', width: hasPhone ? '35vw' : hasTablet ? '20vw' : '16vw', position: 'absolute', top: hasPhone ? '-22vw' : hasTablet ? '-5vw' : '-5vw', left: hasPhone ? '0vw' : hasTablet ? '5vw' : '10vw'}} />}
                </Grid>
            </div>
            <PageTitle title='het team' left='0px'>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column', gap: hasTablet ? '4vw' : '3vw', margin: hasPhone ? '3vw 0' : hasTablet ? '5vw 0' : '5vw 3vw 5vw 0' }}>
                    <div style={{ display: 'flex', columnGap: hasTablet ? '5vw' : '5vw', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'flex-end' }}>
                        <div style={{ width: hasPhone ? '85vw' : hasTablet ? '40vw' : '27.5vw'}}>
                            <Typography variant='sub'>het team</Typography>
                            <Typography variant='h2'>iedereen moet doen waar zij goed in is, ieder een eigen specialisme</Typography>
                            <Typography variant='sub' style={{ margin: hasPhone ? '5vw 0 2vw 0' : hasTablet ? '1vw 0' : '0.5vw 0'}}>Elien Alentijns <br/>strategisch webdesigner</Typography>
                            <Typography>Al van jongs af aan is Elien creatief bezig. Maar voor haar geen canvas of vellen papier. Zij is liever creatief met computers. Dat is haar manier van mensen helpen: jouw ideeën omzetten in een verlengstuk van jouw business. <br/><br/>
                            Wij maken samen websites die mensen graag gebruiken en waar jij als ondernemer ook echt iets aan hebt. Websites waar jouw bezoekers en jij als business-owner blij van worden. Daar worden wij namelijk ook blij van. Win-win!</Typography>
                        </div>
                        <div style={{ width: hasPhone ? '85vw' : hasTablet ? '40vw' : '27.5vw', position: 'relative'}}>
                            <img src={elien} alt='Elien Alentijns' style={{ height: hasPhone ? '75vw' : hasTablet ? '25vw' : '16vw', width: hasPhone ? '75vw' : hasTablet ? '25vw' : '16vw', marginBottom: hasTablet ? '1vw' : '2vw', margin: hasPhone && '4vw 4vw 4vw 4vw' }} />
                            <Typography 
                                variant='sub' 
                                onClick={() => window.open('https://www.elirastudio.be', '_blank')}
                                style={{ position: 'absolute', right: hasPhone ? '-17vw' : hasTablet ? '3vw' : '4vw', top: hasPhone ? '40vw' : hasTablet ? '12.5vw' : '8vw', transform: 'rotate(-90deg)'}}
                            >
                                www.elirastudio.be
                            </Typography>
                        </div>
                    </div>
                    <div style={{ display: 'flex', columnGap: '5vw', justifyContent: 'center', flexWrap: 'wrap', flexDirection: 'row-reverse' }}>
                        <div style={{ width: hasPhone ? '85vw' : hasTablet ? '40vw' : '27.5vw'}}>
                            <Typography variant='sub' style={{ margin: hasPhone ? '0 0 2vw 0' : hasTablet ? '1vw 0' : '0.5vw 0'}}>Joyce Bastiaans <br/> Content marketeer | copywriter</Typography>
                            <Typography>Joyce is een enthousiaste strategische contentmarketeer voor ambitieuze, creatieve ondernemers. Zij is dol op content maken. Urenlang bezig zijn met het typen van gave blogs of compleet verdwalen op Instagram en Pinterest. Maar, wel met een strategie.<br/><br/>
Joyce kan je niet alleen helpen met het schrijven van pakkende teksten, maar houdt ook nog eens rekening met goede vindbaarheid.</Typography>
                        </div>
                        <div style={{ width: hasPhone ? '85vw' : hasTablet ? '40vw' : '27.5vw', display: 'flex', justifyContent: hasPhone ? 'flex-start' : 'flex-end', position: 'relative' }}>
                            <img src={joyce} alt='Joyce Bastiaans' style={{ height: hasPhone ? '75vw' : hasTablet ? '25vw' : '16vw', width: hasPhone ? '75vw' : hasTablet ? '25vw' : '16vw', marginBottom: '2vw', margin: hasPhone && '4vw 4vw 4vw 4vw' }} />
                            <Typography 
                                variant='sub' 
                                onClick={() => window.open('https://www.joycebastiaans.nl', '_blank')}
                                style={{ position: 'absolute', left: hasPhone ? '-23vw' : hasTablet ? '2vw' : '3vw', top: hasPhone ? '40vw' : hasTablet ? '11.5vw' : '8vw', transform: 'rotate(-90deg)'}}
                            >
                                www.joycebastiaans.nl
                            </Typography>
                        </div>
                    </div>
                </div>
            </PageTitle>
            <LieveWoorden {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}