import React from 'react';
import { LieveWoorden, Match, Nieuwsgierig, Shop, Blog, Instagram } from '../../subpages';
import { Typography, Button } from '../../components';
import StartTextPhoto from './StartTextPhoto';

import boost1 from '../../assets/images/diensten/handgeschreven/Studio-Valerie-Maas-grafisch-ontwerp-diensten-typografie-handgeschreven.jpg';
import { getCsvDienstRow } from '../../helpers/diensten';

export default function Handgeschreven(props) {
    const dienstenCsv = props.dienstenCsv;

    const handRow = getCsvDienstRow(dienstenCsv, 'handgeschreven');

    return (
        <div>
            <StartTextPhoto
                image={boost1}
                title={handRow['dienst']}
                subtitle={`Vanaf ${handRow['prijs']} excl. btw`}
                {...props}
            >
                <Typography style={{ margin: props.hasPhone ? '2vw 0' : '0'}}>
                    Wil jij nét dat stukje eigenheid aan jouw website, drukwerk of branding toevoegen? Door gebruik te maken van handgeschreven woorden of zinnen wordt het echt uniek.
                </Typography>
                <Typography style={{ margin: props.hasPhone ? '2vw 0' : '0'}}>
                Wil jij enkele woorden of een naam uitgeschreven hebben in een mooi en passend handschrift? Denk bijvoorbeeld aan de naam van je kindje op een geboortekaartje óf wat woorden voor in je branding.
                </Typography>
                <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')} style={{ marginTop: '1vw'}}>Plan een gratis kennismakingsgesprek in</Button>
            </StartTextPhoto>
            <Match {...props} />
            <LieveWoorden {...props}  />
            <Shop {...props} />
            <Blog {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}