import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { TextField, Select, useInput } from '..';
import styled from 'styled-components';
import { colors } from '../../assets/colors';

const StyledText = styled.div`
    font-family: ${props => props.fontName || 'Amelie'};
    color: ${colors.title};
    font-size: ${props => props.fontSize || '72pt'};
    word-wrap: break-word;
    width: 65vw;
`;

const FontName = styled.div`
    font-family: Chivo;
    font-weight: 300;
    margin: 30px 0 5px 0;
    color: ${colors.black};
`;

const FontSizeOption = styled.div`
    font-family: Mulish;
    font-weight: ${props => props.active ? '700' : '400'};
    text-transform: uppercase;
    color: ${colors.photo};
    margin-left: 10px;

    &:hover {
        cursor: pointer;
    }
`;

const fontOptions = ['24pt', '36pt', '48pt', '72pt'];
const selectOptions = ['The quick brown fox jumps over the lazy dog', 'Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt Uu Vv Ww Xx Yy Zz', 'Ontdekken, creëren en ontwerpen']

export default function FontTester(props) {
    const { value: text, bind: bindText } = useInput('Type hier jouw tekst');
    const { value: option, bind: bindOption } = useInput(selectOptions[0]);
    const [ fontSize, setSize ] = useState('48pt');

    return (
        <form>
            <Grid 
                container
                alignItems='center'
            >
                <TextField
                    placeholder='Type hier jouw tekst'
                    style={{ width: '20vw'}}
                    {...bindText}
                />
                <Select
                    options={selectOptions}
                    style={{ width: '20vw'}}
                    {...bindOption}
                />
                {fontOptions.map((f,i) => {
                    return (
                        <FontSizeOption key={f} active={fontSize === f} onClick={e => setSize(f)}>{f}</FontSizeOption>
                    )
                })}
            </Grid>
            <FontName>{props.fontDisplayName}</FontName>
            <StyledText
                fontName={props.fontName}
                fontSize={fontSize}
            >
                { text || option }
            </StyledText>
        </form>
    )
}