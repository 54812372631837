import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Instagram, LieveWoorden, Match, Nieuwsgierig } from '../../subpages';
import { Typography, Button, LineButton } from '../../components';
import { colors } from '../../assets/colors';

const CustomLineButton = styled.div`
    font-family: Mulish;
    font-size: 13px;
    font-weight: ${props => props.active ? '600' : '300'};
    text-transform: uppercase;
    text-decoration: none;
    color: ${colors.photo};
    letter-spacing: 1.4px;
    text-decoration: underline;
    text-underline-offset: 5px;
    margin: ${props => props.margin || '0.5vw 1vw'};
    width: inherit;
    text-align: left;

    &:hover {
        cursor: pointer;
        font-weight: 600;
    }

    @media screen and (max-width: 766px) {
        width: 45%;
        text-align: ${props => props.alignRight ? 'right' : 'left'}; 
        font-size: 11px;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 11px;
    }
`;

const StyledImg = styled.img`
    content: url(${props => props.primaryImg});
    object-position: center;
    object-fit: cover;
    min-width: 20vw;
    max-width: 20vw;
    min-height: 20vw;
    max-height: 20vw;

    &:hover {
        content: url(${props => props.secondaryImg});
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        min-width: 85vw;
        max-width: 85vw;
        min-height: 85vw;
        max-height: 85vw;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        min-width: 35vw;
        max-width: 35vw;
        min-height: 35vw;
        max-height: 35vw;
    }
`;

const ProjectGrid = styled.div`
    display: flex;
    justify-content: left;
    gap: 3vw;
    flex-wrap: wrap;

    width: 43vw;
    margin: 5vw 0 2vw 0;

    @media screen and (max-width: 766px) {
        width: 95vw;
        justify-content: center;
        gap: 10vw;
        margin: 5vw 0;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        width: 75vw;
        gap: 5vw;
    }
`;

const CustomButton = styled.div`
    font-family: Mulish;
    font-weight: 400;
    text-decoration: none;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    font-size: 13px;
    color: ${colors.photo};
    border: 1px solid ${colors.photo};
    padding: 8px;
    border-radius: 18px;
    outline: none;
    margin-top: 2vw;

    &:hover {
        font-weight: 600;
        cursor: pointer;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 11px;
        letter-spacing: 1.1px;
        padding: 5px;
        margin-bottom: 4vw;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 11px;
        letter-spacing: 1.1px;
        padding: 8px;
    }
`;

export default function Overview(props) {
    const { projectsObject, text, projectsTypes, isType, changeType, isPage, setPage, children, type } = props;
    const projects = projectsObject.results || [];

    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    const getProject = (project, p) => {
        if (project.overview_images.length === 2) {
            const primaryImg = project.overview_images.filter(a => a.primary === true)[0].file;
            const secondaryImg = project.overview_images.filter(a => a.secondary === true)[0].file;

            return (
                <Link key={p} to={`/portfolio/${project.id}`} style={{ textDecoration: 'none'}}>
                    <StyledImg primaryImg={primaryImg} secondaryImg={secondaryImg} alt='portfolio foto' />
                    <Typography style={{ marginTop: '10px', width: hasPhone ? '85vw' : hasTablet ? '30vw' : '20vw'}}>
                        {project.title} 
                        {project.types && project.types.map((type, j) => {
                            return (
                                <span key={j}>
                                    {j === 1 && <span style={{ fontStyle: 'italic'}}> & </span>}
                                    {j > 2 && <span style={{ fontStyle: 'italic'}}> , </span>}
                                    <span style={{ fontStyle: 'italic'}}> {type.content}</span>
                                </span>
                            )
                        })}
                    </Typography>
                </Link>
            )
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Typography variant='h1'>Portfolio</Typography>
                <Typography style={{ textAlign: 'center', width: hasPhone ? '50vw' : hasTablet ? '30vw' : '17vw'}}>{text}</Typography>
                {type === 'portfolio' ? 
                    <div style={{ display: 'flex', justifyContent: 'left', margin: hasPhone ? '5%' : hasTablet ? '2%' : '0', gap: hasPhone ? '5vw' : hasTablet ? '2vw' : '0', flexWrap: 'wrap' }}>
                        <CustomLineButton key={'0'} style={{ margin: hasTablet ? '2vw' : '1vw' }} onClick={() => changeType(isType)} active={!isType || isType.length === 0} alignRight={true}>Alle</CustomLineButton>
                        {projectsTypes && projectsTypes.length > 0 && projectsTypes.map((type, i) => <CustomLineButton key={i} style={{ margin: hasTablet ? '2vw' : '1vw' }} onClick={() => changeType(type.content)} active={isType === type.content} alignRight={i % 2 === 1}>{type.content}</CustomLineButton>)}
                    </div>
                : 
                    <LineButton to='/portfolio'>Terug naar portfolio</LineButton>
                }
                <ProjectGrid>
                    {projects.length > 0 && projects.map((project, p) => {
                        return getProject(project, p)
                    })}
                </ProjectGrid>
                {projectsObject.next &&
                    <CustomButton onClick={() => setPage(isPage + 1)}>
                        Ik wil 
                        {isPage > 1 ? ' nog ' : ' '} 
                            meer zien!
                    </CustomButton>
                }
                {children}
            </div>
            <div style={{ position: 'relative', margin: hasPhone ? '20vw 0': '7vw 0' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <Button link='/diensten' style={{ marginBottom: hasPhone ? '3vw' : '1vw'}}>Bekijk de diensten</Button>
                    <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')}>Plan een gratis kennismakingsgesprek in</Button>
                </div>
            </div>
            <Match {...props} />
            <LieveWoorden {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}