import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';

const StyledLabel = styled.div`
    font-family: Mulish;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    color: ${colors.title};

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 766px) and (max-width: 1024px){
        font-size: 11px;
        letter-spacing: 1.1px;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 11px;
        letter-spacing: 1.1px;
    } 
`;

const Circle = styled.div`
    width: 18px;
    height: 18px;
    border: solid 1px ${colors.title};
    border-radius: 18px;
    background-color: ${props => props.isChecked ? colors.title : 'default'};
`;

const StyledCheckbox = styled.div`
    position: relative;
    cursor: pointer;
    margin-right: 10px;
`;

const Container = styled.div`
    display: flex;
    margin: 0;
    justify-content: center;

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 766px) and (max-device-width: 1024px){
        margin: 0.3vw 0;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        margin: 0.5vw 0;
    } 
`;

export default function Checkbox(props) {
    return (
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
            <StyledCheckbox onClick={props.onClick}>
                <Circle isChecked={props.isChecked} />
            </StyledCheckbox>
            <Container>
                <StyledLabel style={props.style}>{props.children}{props.required ? "*" : ""}</StyledLabel>
            </Container>
        </div>
    );
}