import axiosInstance from './axios';
import { POST_WACHTLIJST } from './types';
import { reportResponse } from './helpers';

// POST CONTACT FORM
export const postWachtlijst = (formValues) => async dispatch => {
    const res = await axiosInstance.post('/wachtlijst/', { ...formValues });
    
    return reportResponse(res, POST_WACHTLIJST, 'Wachtlijst', dispatch);
};

export const postMailerLite = (formValues) => async dispatch => {
    const res = await axiosInstance.post('/mailerlite/', { ...formValues });

    return reportResponse(res, POST_WACHTLIJST, formValues['groupName'], dispatch);
}