import React, { useState } from 'react';
import styled from 'styled-components';

import { Dialog } from '../../../components';

import { Typography, Button } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import ProjectImage from './ProjectImage';
import ProjectVideo from './ProjectVideo';

const Container = styled.div`
    width: ${props => props.width || 'calc(50vw - 30px)'};
    border: 1px solid #BFBEBD;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 1vw;
`;

export default function ProjectGrid(props) {
    const { grids, grid, gridId, updateImage, updateVideo, isEditing, deleteGrid, setGrids, addImage } = props;

    const [ dialogOpen, setDialogOpen ] = useState(false);

    function CustomAccordion(props) {
        return (
            <Container width='46vw'>
                <Typography>{props.title}</Typography>
                {props.children}
            </Container>
        )
    }

    const cancelNewGrid = () => {
        const newGrids = grids.filter(gr => gr.id)
        setGrids(newGrids);
    }

    const gridItems = grid && grid.images.concat(grid.videos).sort((a, b) => a.order - b.order);

    return (
        <CustomAccordion key={gridId} title={`Afbeeldingen met raster ${grid.grid}, groep: ${grid.group ? 'ja' : 'nee'}`}>
            <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: grid.group ? 'column' : 'row'}}>
                        {gridItems.map((item, j) => {
                            let customId = item.id || item.order;
                            if (item["video"] !== undefined) {
                                return <ProjectVideo
                                    key={j}
                                    gridId={gridId}
                                    videoId={customId}
                                    grid={grid}
                                    video={item}
                                    updateVideo={updateVideo}
                                    isEditing={isEditing}
                                />
                            } else {
                                return <ProjectImage 
                                    key={j}
                                    gridId={gridId}
                                    imageId={customId}
                                    grid={grid}
                                    image={item}
                                    updateImage={updateImage}
                                    isEditing={isEditing}
                                />
                            }
                        })}
                    </div>
                    <Dialog
                        open={dialogOpen}
                        handleCancel={() => setDialogOpen(false)}
                        handleOk={() => { deleteGrid(grid); setDialogOpen(false) }}
                        title={'Raster verwijderen'}
                        content={`Weet je zeker dat je dit raster wil verwijderen?`}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw', margin: '1vw'}}>
                        {grid.id ? isEditing && <Button variant='contained' onClick={() => setDialogOpen(true)} startIcon={<DeleteIcon/>}>Verwijderen</Button>
                            : isEditing && <Button variant='contained' onClick={cancelNewGrid} startIcon={<CloseIcon/>}>Annuleren</Button>
                        }
                        {grid.group && isEditing &&  
                            <Button variant='contained' onClick={() => addImage(grid)} startIcon={<AddIcon/>}>Afbeelding toevoegen</Button>
                        }
                    </div>
                    
                </div>
            </div>
        </CustomAccordion>
    )
}