import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { getPaymentStatus } from '../../actions/advent';

import { Typography, Button, ImageArch } from '../../components';
import { Grid } from '@mui/material';
import contactBedankt from '../../assets/images/contact/Studio-Valerie-Maas-grafisch-ontwerp-contact-bedankt.jpg';

export default function AdventPaid(props) {
    const { hasPhone, hasTablet } = props;

    let { eventUri } = useParams();

    const [ paymentStatus, setPaymentStatus ] = useState(null);
    const [ name, setName ] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!paymentStatus) {
            dispatch(getPaymentStatus(eventUri)).then(response => {
                setPaymentStatus(response['status']);
                setName(response['name'])
            })
        }
    })

    if (paymentStatus === 'paid') {
        return (
            <Grid container justifyContent='space-evenly' alignItems='center' style={{ margin: hasPhone ? '0 0 5vw 0' : '5vw 0'}}>
                <div style={{ textAlign: 'center', width: hasPhone ? '80vw' : hasTablet ? '35vw' : '25vw', margin: hasPhone && '10vw 0' }}>
                    <Typography variant='h1' style={{ margin: hasPhone ? '0 0 5vw 0' : '0 0 2vw 0'}}>Bedankt voor <br/>het betalen <br/> {name}!</Typography>
                    <Button link='/'>Terug naar de home-pagina</Button>
                </div>
                <div>
                    <ImageArch src={contactBedankt} alt='Foto' width={hasPhone ? '80vw' : hasTablet ? '35vw' : '25vw'} />
                </div>
            </Grid>
        )
    }

    return (
        <Grid container justifyContent='space-evenly' alignItems='center' style={{ margin: hasPhone ? '0 0 5vw 0' : '5vw 0'}}>
            <Typography>Er is iets mislukt... Probeer het opnieuw!</Typography>
        </Grid>
    );
}
