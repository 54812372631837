import React, { useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getProject } from '../../actions/projects';
import { getProjectIds } from '../../actions/projectids';
import { getProjectTypes } from '../../actions/projecttypes';
import { ImageCarousel, Typography, ImageArch } from '../../components';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { Instagram, Nieuwsgierig } from '../../subpages';
import Review from '../reviews/Review';


const ImageGrid = styled.div`
    display: flex;
    justify-content: left;
    gap: 4vw;
    flex-wrap: wrap;

    width: 50vw;
    margin: 3vw 0;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        margin-top: 5vw;
        width: 75vw;
        gap: 6vw;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        width: 65vw;
    }
`;

const StyledImg = styled.img`
    content: url(${props => props.file});
    width: ${props => props.width || '20vw'};
    height: ${props => props.height || '20vw'};
`;

const OverflowDiv = styled.div`
    overflow: auto;
    width: ${props => props.width || '50vw'};
    height: ${props => props.height || '25vw'};

    ::-webkit-scrollbar {
        display: none;
    }
`;

const CustomButton = styled.div`
    font-family: Mulish;
    font-weight: 400;
    text-decoration: none;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    font-size: 13px;
    color: ${colors.photo};
    border: 1px solid ${colors.photo};
    padding: 8px;
    border-radius: 18px;
    outline: none;
    margin-top: 2vw;

    &:hover {
        font-weight: 600;
        cursor: pointer;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 11px;
        letter-spacing: 1.1px;
        padding: 5px;
        margin-bottom: 4vw;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 11px;
        letter-spacing: 1.1px;
        padding: 8px;
    }
`;

const StyledLink = styled(Link)`
    display: inline;
    text-decoration: none;
    color: ${colors.black};
    position: relative;
    cursor: pointer;
    
    &:hover {
        font-style: italic;
    }
`;

export default function Project(props) {
    let { projectId } = useParams();

    const dispatch = useDispatch();

    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    const projectsObject = useSelector((state) => state.projects);
    const projectIds = useSelector((state) => state.projectIds).projectIds || [];
    const projectNumbers = projectIds.map(id => Number(id.id));
    const projects = (projectsObject && projectsObject.projects && projectsObject.projects.results) || [];
    const projectsTypes = useSelector((state) => state.projectTypes).results;

    let project = projects.filter(a => Number(a.id) === Number(projectId));

    useEffect(() => {
        if (!projectsTypes) {
            dispatch(getProjectTypes())
        }

        if (Object.keys(project).length === 0 || Number(project.id) !== Number(projectId)) {
            dispatch(getProject(projectId))
        }

        if (Object.keys(project).length > 0 && (!projectIds || projectIds.length === 0 || projectNumbers.includes(Number(projectId)))) {
            const projectTypes = project.types.map(type => type.content);
            let filters = {}
            if (projectTypes.includes('Boekontwerp')) {
                filters.types__content = 'Boekontwerp'
            } else {
                const typeNames = projectsTypes.map(type => type.content).join(',')
                filters.types__content = typeNames
            }
            dispatch(getProjectIds(projectId, filters))
        }
        
    }, [dispatch, projectId, project, projectIds.length, projectNumbers, projectsTypes])

    if (project.length === 0) {
        return (
            <div>Geen project gevonden</div>
        )
    }

    project = project[0];
    
    const getContent = (content) => {
        // preprocess links in content
        let newContent = [];
        if (content.includes('<Link') || content.includes('<a')) {
            let linkType = null;
            if (content.includes('<Link')) {
                linkType = 'Link'
            } else if (content.includes('<a')) {
                linkType = 'a'
            }
            let leftContent = content.split('<' + linkType);
            let rightContent = leftContent[1].split('</' + linkType + '>');
            let urlContent = rightContent[0].split('>');

            let url = urlContent[0].split('=')[1];
            let text = urlContent[1];

            newContent.push(leftContent[0]);
            newContent.push({ url: url, text: text, linkType: linkType });
            newContent.push(rightContent[1]);
        } else {
            newContent.push(content);
        }

        return (
            <div style={{ display: 'inline-block', width: hasPhone ? '75vw' : hasTablet ? '50vw' : '25vw', textAlign: 'center', margin: hasPhone ? '3vw 0' : hasTablet ? '0 0 1vw 0' : '0.5vw 0' }}>
                {newContent.map((subContent, i) => {
                    if (typeof subContent === 'string') {
                        return (
                            <Typography key={i} style={{ display: 'inline' }}>{subContent}</Typography>
                        )
                    } else {
                        if (subContent.linkType === 'Link') {
                            return (
                                <StyledLink key={i} to={`${subContent.url}`}>{subContent.text}</StyledLink>
                            )
                        } else if (subContent.linkType === 'a') {
                            return <StyledLink key={i} as='a' href={`${subContent.url}`}>{subContent.text}</StyledLink>
                        } else {
                            return null;
                        }
                    }
                })}
            </div>
        )
    }

    const getGridImages = (grid) => {
        if (grid.group) {
            return grid.images.length > 0 && <ImageCarousel key={grid.id} images={grid.images} {...props} />
        } else {
            let width = '';
            let height = '';
            if (grid.grid === '1') {
                width = hasPhone ? '75vw' : hasTablet ? '64vw' : '50vw';
                height = 'default';
            } else if (grid.grid === '2') {
                width = hasPhone ? '75vw' : hasTablet ? '30vw' : '23vw';
                height = hasPhone ? '75vw' : hasTablet ? '30vw' : '23vw';
            } else if (grid.grid === '3') {
                width = hasPhone ? '75vw' : hasTablet ? '19vw' : '14vw';
                height = hasPhone ? '75vw' : hasTablet ? '19vw' : '14vw';
            }

            const gridItems = grid.images.concat(grid.videos).sort((a, b) => a.order - b.order)

            return gridItems.map((item, i) => {
                if (item['video'] !== undefined) {
                    if (item.shape === 'ARCH') {
                        return (
                            <ImageArch key={i} video={true} width={width} height={height} src={item.video} />
                        )
                    } else {
                        return (
                            <video key={i} alt='blog foto' loop='true' autoplay='autoplay' muted style={{ width: width, height: height }} src={item.video} />
                        )
                    }
                } else if (item.scrollable) {
                    return (
                        <OverflowDiv key={i} width={width} height={hasPhone ? '40vw' : hasTablet ? '35vw' : '20vw'}>
                            <StyledImg key={i} overflow='auto' width={width} height='default' file={item.file} />
                        </OverflowDiv>
                    )
                } else {
                    return (
                        <StyledImg key={i} overflow='auto' width={width} height={height} file={item.file} />
                    )
                }
            })
        }
    }
    
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginBottom: hasPhone ? '20vw' : hasTablet ? '15vw' : '0'}}>
                <Typography variant='h2'>{project.title}</Typography>
                {project.specific_types && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', maxWidth: '75vw', flexWrap: 'wrap'}}>
                    {project.specific_types.length > 0 && project.specific_types.map((sp, j) => {
                        return (
                            <div key={j} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', margin: hasTablet ? '1vw 0' : '0'}}>
                                <Typography variant='sub'>{sp.content}</Typography>
                                {project.specific_types.length > 2 && j < project.specific_types.length - 2 &&
                                    <Typography variant='sub' style={{ marginRight: '5px'}}>{` , `}</Typography>
                                }
                                {project.specific_types.length > 1 && j === project.specific_types.length - 2 
                                    && <Typography variant='sub' style={{ margin: '0 5px'}}>{` & `}</Typography>}
                            </div>
                        )
                    })}
                </div>}
                {project.content && getContent(project.content)}
                {project.website_link && 
                    <Typography variant='sub' as='a' href={project.website_link} style={{ textDecoration: 'none', margin: hasTablet ? '1vw 0 0 0' : '0.5vw 0 0 0'}}>{project.website_link.split("://")[1]}</Typography>
                }
                {project.website_text && 
                    <Typography style={{ margin: hasPhone ? '0 0 2vw 0' : hasTablet ? '0 0 1vw 0' : '0 0 0.5vw 0', maxWidth: hasTablet ? '50vw' : '75vw', textAlign: 'center' }}>{getContent(project.website_text)}</Typography>
                }
                {project.keywords && project.keywords.length > 0 && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '0.5vw', maxWidth: hasPhone && '75vw', flexFlow: 'wrap'}}>
                    {project.keywords.length > 0 && project.keywords.map((kw, i) => {
                        return (
                            <div key={i} style={{ display: 'flex', flexDirection: 'row'}}>
                                <Typography variant='sub'>{kw.content}</Typography>
                                {i < project.keywords.length - 1 && <Typography variant='sub' style={{ margin: '0 5px'}}> - </Typography>}
                            </div>
                        )
                    })}
                </div>}
                {project.grids && project.grids.length > 0 && project.grids.map((grid, i) => (
                    <ImageGrid key={i}>
                        {getGridImages(grid)}
                    </ImageGrid>
                ))}
                {project.reviews && project.reviews.length > 0 && <Review index={1} review={project.reviews[0]} isPage={0} showAll={true} />}
                {projectIds && projectIds.length > 0 && 
                    <div style={{ position: 'relative', margin: '3vw 0'}}>
                        {projectIds[0] && <div style={{ position: 'absolute', left: hasPhone ? '-40vw' : hasTablet ? '-35vw' : '-23vw'}}>
                            <CustomButton as={Link} to={`/portfolio/${projectIds[0].id}`}>Bekijk vorige</CustomButton>
                        </div>}
                        {projectIds[1] && <div style={{ position: 'absolute', right: hasPhone ? '-40vw' : hasTablet ? '-35vw' : '-23vw'}}>
                            <CustomButton as={Link} to={`/portfolio/${projectIds[1].id}`}>Bekijk volgende</CustomButton>
                        </div>}
                    </div>
                }
            </div>
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
    
}