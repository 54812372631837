import { Box, Button, TextField, Typography, Select, MenuItem } from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
// import DateAdapter from '@mui/lab/AdapterMoment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getDocuments, postDocument } from '../../../actions/documents';

import EditIcon from '@mui/icons-material/Edit';

const Field = styled.div`
    display: flex;
    align-items: center;
    gap: 5vw;
    margin-bottom: 1vw;
`;

export default function NewDocument(props) {
    const dispatch = useDispatch();

    const documentsObject = useSelector((state) => state.documents) || {};
    const documents = (documentsObject && documentsObject.documents && documentsObject.documents.results) || [];
    const documentTypes = (documents && [...new Set(documents.map(d => d.type))]);

    const [ typeValue, setTypeValue ] = useState((documentTypes && documentTypes[documentTypes.length - 1]) || 'Veelgestelde vragen');
    const [ subTypeValue, setSubTypeValue ] = useState('');
    const [ dateValue, setDateValue ] = useState(null);

    useEffect(() => {
        dispatch(getDocuments())
    }, [dispatch])

    const handleSubmit = () => {
        let newDocument = {
            'type': typeValue,
            'sub_type': subTypeValue,
            'created': dateValue,
        }
        dispatch(postDocument(newDocument));
    }

    return (
        <Box component='form' validate>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Type</Typography>
                </div>
                <Select
                    value={typeValue}
                    label="Type"
                    onChange={(e) => setTypeValue(e.target.value)}
                    style={{ width: '50vw' }} 
                >
                    {documentTypes && documentTypes.map((d, i) => <MenuItem key={i} value={d}>{d}</MenuItem>)}
                </Select>
            </Field>
            <Field>
                    <div style={{ width: '20vw'}}>
                        <Typography fontWeight='500' style={{ width: '20vw'}}>Subtype</Typography>
                    </div>
                    <TextField 
                        style={{ width: '50vw'}}
                        id="outlined-required"
                        value={subTypeValue}
                        onChange={(e) => setSubTypeValue(e.target.value)}
                        label='Subtype'
                    />
                </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Datum</Typography>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label="Aanmaakdatum"
                        value={dayjs(dateValue)}
                        onChange={(e) => setDateValue(e.format('YYYY-MM-DDTHH:mm:ss'))}
                        renderInput={(params) => <TextField {...params} />}
                        ampm={false}
                        required
                    />
                </LocalizationProvider>
            </Field>
            <div style={{ display: 'flex', width: '75vw', justifyContent: 'right', gap: '1vw'}}>
                <Button onClick={handleSubmit} variant='contained' startIcon={<EditIcon />}>Aanmaken</Button>
            </div>
        </Box>
    )
}