import React, { useState, useEffect } from 'react';

import Overview from './Overview';

import { useDispatch, useSelector } from 'react-redux';
import { getProjectOverview } from '../../actions/projectoverview';

export default function Boekontwerp(props) {
    const [ isPage, setPage ] = useState(1);

    const dispatch = useDispatch();

    const projectsObject = useSelector((state) => state.projectOverviews);

    useEffect(() => {
        const filters = { types__content: 'Boekontwerp', page_size: isPage * 6 };
        dispatch(getProjectOverview(filters))

    }, [dispatch, isPage])

    return (
        <Overview 
            projectsObject={projectsObject} 
            text={`Boekontwerp`}
            isPage={isPage}
            setPage={setPage}
            {...props}
        />
    )
}