import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Typography, Arrow, Button } from '../../components';
import { Link } from 'react-router-dom';

import { getProjectOverview } from '../../actions/projectoverview';

const CarouselContainer = styled.div`
    position: relative;
    margin: 2vw;
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;

    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
        display: none;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        margin: 5vw 0;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
    }
`;

const StyledImg = styled.img`
    content: url(${props => props.primaryImg});
    object-position: center;
    object-fit: cover;
    min-width: 18vw;
    max-width: 18vw;
    min-height: 18vw;
    max-height: 18vw;

    &:hover {
        content: url(${props => props.secondaryImg});
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        min-width: 50vw;
        max-width: 50vw;
        min-height: 50vw;
        max-height: 50vw;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        min-width: 25vw;
        max-width: 25vw;
        min-height: 25vw;
        max-height: 25vw;
    }
`;

export default function RecenteProjecten(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    const target = React.createRef();
    
    const [ isDown, setIsDown ] = useState(false);
    const [ isArrowHover, setArrowHover ] = useState(false);
    const [ startX, setStartX ] = useState(0);
    const [ scrollLeft, setScrollLeft ] = useState(0);

    const walk = 300;

    const dispatch = useDispatch();
    const projects = useSelector((state) => state.projectOverviews).results || [];

    const mouseDown = (e) => {
        setIsDown(true);
        
        // calculate slider swipe
        const slider = target.current;
        setStartX(e.pageX - slider.offsetLeft);
        setScrollLeft(slider.scrollLeft);
    }

    const mouseLeave = (e) => {
        setIsDown(false);
    }

    const mouseMove = (e) => {
        if (!isDown) return;
        e.preventDefault();

        const slider = target.current;
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 2;
        slider.scrollLeft = scrollLeft - walk;
    }

    const mouseEnter = (e) => {
        // animate arrows
        setArrowHover(true)
        setTimeout(() => {
            setArrowHover(false)
        }, 500)
    }

    const swipe = (e, walk) => {
        if (target && target.current) {
            const slider = target.current;
            slider.scrollLeft = slider.scrollLeft + walk;
        }
    }

    const getProject = (project, p) => {
        if (project.overview_images.length === 2) {
            const primaryImg = project.overview_images.filter(a => a.primary === true)[0].file;
            const secondaryImg = project.overview_images.filter(a => a.secondary === true)[0].file;

            return (
                <Link key={p} to={`/portfolio/${project.id}`} style={{ textDecoration: 'none', margin: hasPhone ? '0 3vw' : hasTablet ? '0 2.5vw' : '0 2vw'}}>
                    <StyledImg primaryImg={primaryImg} secondaryImg={secondaryImg} alt='portfolio foto' />
                    <Typography style={{ marginTop: '10px', fontSize: '14px'}}>
                        {project.title} 
                        {project.types && project.types.map((type, j) => {
                            return (
                                <span key={j}>
                                    {j > 0 && <span style={{ fontStyle: 'italic'}}> & </span>}
                                    <span style={{ fontStyle: 'italic'}}> {type.content}</span>
                                </span>
                            )
                        })}
                    </Typography>
                </Link>
            )
        }
    }

    useEffect(() => {
        if (projects.length === 0) {
            const filters = { page_size: 6, is_recent: true }
            dispatch(getProjectOverview(filters))
        }
    }, [dispatch, projects.length])

    if (projects.length > 0) {
        return (
            <div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography variant='h2'>recente projecten</Typography>
                    <Typography>Branding & typografie</Typography>
                    <div style={{ display: 'flex', columnGap: hasPhone ? '3vw' : '2vw', justifyContent: 'center', alignItems: 'center' }}>
                        <Arrow direction='left' onClick={(e) => swipe(e, -walk)} hover={isArrowHover} long={hasPhone || hasTablet} />
                        <Typography variant='sub' style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '0', height: '2vw'}}>Bekijk projecten</Typography>
                        <Arrow direction='right' onClick={(e) => swipe(e, walk)} hover={isArrowHover} long={hasPhone || hasTablet} />
                    </div>
                    <CarouselContainer
                        onMouseDown={mouseDown}
                        onMouseLeave={mouseLeave}
                        onMouseUp={mouseLeave}
                        onMouseMove={mouseMove}
                        onMouseEnter={mouseEnter}
                        ref={target}
                    >
                        {projects.map((p, i) => {
                            return getProject(p, i)
                        })}
                    </CarouselContainer>
                    <Button link='/portfolio'>Neem een kijkje in mijn portfolio</Button>
                </div>
            </div>
        )
    }

    return null;
}