import axiosInstance from './axios';
import { GET_SPECIFIC_PROJECT_TYPES, POST_SPECIFIC_PROJECT_TYPE } from './types';
import { reportResponse } from './helpers';

// GET PROJECT TYPE
export const getSpecificProjectTypes = () => async dispatch => {
    const res = await axiosInstance.get(`/api/project_specific_types/`);
    
    dispatch({
      type: GET_SPECIFIC_PROJECT_TYPES,
      payload: res.data
    });
};

// POST PROJECT TYPE
export const postSpecificProjectType = (specificProjectType) => async dispatch => {
  const res = await axiosInstance.post(`/api/project_specific_types/`, specificProjectType);
  
  return reportResponse(res, POST_SPECIFIC_PROJECT_TYPE, 'Specifieke project type', dispatch);
};