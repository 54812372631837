import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { getDocuments } from '../../../actions/documents';

const StyledLink = styled(Link)`
    position: absolute;
    bottom: 2vw;
    right: 5vw;
    text-decoration: none;
`;

export default function Documenten(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const documentsObject = useSelector((state) => state.documents) || {};
    const documents = (documentsObject && documentsObject.documents && documentsObject.documents.results) || [];

    useEffect(() => {
        dispatch(getDocuments())
    }, [dispatch])

    if (documents.length === 0) {
        return <div>Geen documenten gevonden</div>
    }

    // render the list of documents page
    return (
        <div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Document naam</TableCell>
                            <TableCell>Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documents.map((document, i) => (
                            <TableRow key={i} onClick={() => history.push(`/admin/documents/${document.id}`, { document: document })} hover>
                                <TableCell>{document.type}</TableCell>
                                <TableCell>{document.sub_type}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <StyledLink to={{ pathname: `/admin/documents/new`, post: true }}>
                <Fab color='primary' aria-label='add'>
                    <AddIcon />
                </Fab>
            </StyledLink>
        </div>
    )
}