import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Checkbox, Button, TextField, Typography, useInput, ImageArch } from "../../components";

import { Instagram } from "../../subpages";

import styled from 'styled-components';

import image1 from '../../assets/images/shop/Studio-Valerie-Maas-grafisch-ontwerp-shop-ebook-droomklanten-1.jpg';
import image2 from '../../assets/images/shop/Studio-Valerie-Maas-grafisch-ontwerp-shop-lettertype-amelie-slider-1.jpg';
import { useDispatch } from 'react-redux';

import { postWachtlijst } from '../../actions/wachtlijst';


const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;

    &:hover {
        cursor: pointer;
        font-weight: 500;
    }
`;

export default function Shop(props) {
    const { value: valueName, bind: bindName } = useInput('');
    const { value: valueEmail, bind: bindEmail } = useInput('');
    const [ voorwaardenChecked, setVoorwaardenChecked ] = useState(false);
    const [ voorwaardenError, setVoorwaardenError ] = useState(false);

    const dispatch = useDispatch();
    let history = useHistory();

    const handleSubmit = (evt) => {
        evt.preventDefault();

        if (!voorwaardenChecked) {
            setVoorwaardenError(true)
        } else {
            setVoorwaardenError(false)
            // submit

            const form = {
                'name': valueName,
                'email': valueEmail,
            }

            dispatch(postWachtlijst(form)).then(history.push('/contact/bedankt'));
        }
    }

    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;
    const imgWidth = hasPhone ? '32vw' : hasTablet ? '17vw' : '15vw';

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <Typography variant='h2'>de shop is in <br/> ontwikkeling</Typography>
                <Typography variant='sub'>uniek ontworpen items om <br/>te creëren én leren</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', margin: hasPhone ? '20vw 0' : hasTablet ? '5vw 0' : '2vw 0'}}>
                <div style={{ width: hasPhone ? '100%' : '10vw', height: hasPhone && '60vw', marginRight: hasPhone ? '0' : hasTablet ? '30vw' : '25vw', position: 'relative'}}>
                    {hasPhone ? 
                        <img src={image1} alt='droomklanten' style={{ width: imgWidth, height: imgWidth, position: 'absolute', left: '15vw', bottom: '10vw'}} />
                    : hasTablet ? <img src={image1} alt='droomklanten' style={{ width: imgWidth, height: imgWidth, position: 'absolute', left: '-9vw', top: '15vw'}} /> 
                    : <img src={image1} alt='droomklanten' style={{ width: imgWidth, height: imgWidth, position: 'absolute', left: '-12vw', top: '12vw'}} />}
                    {hasPhone ? 
                        <ImageArch src={image2} width={imgWidth} style={{ position: 'absolute', right: '15vw', top: '0'}} />
                    : hasTablet ? <ImageArch src={image2} width={imgWidth} style={{ position: 'absolute', right: '-18vw', top: '1vw'}} />
                    : <ImageArch src={image2} width={imgWidth} style={{ position: 'absolute', right: '-12vw', top: '2vw'}} />
                    }
                </div>
                <div style={{ textAlign: 'center', margin: hasPhone ? '10vw 0' : hasTablet ? '2vw 0 3vw 0' : '3vw 0 5vw 0'}}>
                    <Typography variant='h2'>wachtlijst</Typography>
                    <Typography>Meld je aan voor de wachtlijst. <br/>Dan ben jij de eerste die te horen<br/> krijgt als de shop opent.</Typography>
                    <form onSubmit={handleSubmit} autoComplete="on" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ margin: hasPhone ? '5vw 0' : hasTablet ? '2vw 0' : '1vw 0', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField 
                                placeholder='Naam'
                                required
                                style={{ width: hasPhone ? '50vw' : hasTablet ? '30vw' : '22vw', marginBottom: hasPhone ? '2vw' : hasTablet ? '1vw' : '0.5vw' }}
                                {...bindName}
                            />
                            <TextField 
                                placeholder='E-mailadres'
                                id='email'
                                type='email'
                                required
                                style={{ width: hasPhone ? '50vw' : hasTablet ? '30vw' : '22vw', marginBottom: hasPhone ? '2vw' : hasTablet ? '1vw' : '0.5vw' }}
                                {...bindEmail}
                            />
                        </div>
                        <Checkbox
                            onClick={e => setVoorwaardenChecked(!voorwaardenChecked)}
                            isChecked={voorwaardenChecked}
                            style={{ textAlign: 'left', width: hasPhone ? '60vw' : hasTablet ? '25vw' : '20vw', marginBottom: hasPhone ? '5vw' : hasTablet ? '2vw' : '0' }}
                            required
                        >
                            Ik heb de <StyledLink style={{ textDecoration: 'none', color: 'inherit' }} target='_blank' to='/voorwaarden'>algemene voorwaarden</StyledLink> van de site gelezen en ga hiermee akkoord
                        </Checkbox>
                        {voorwaardenError && <Typography style={{ color: 'red' }}>Je moet de voorwaarden accepteren om je aan te melden!</Typography>}
                        <Button type='submit' style={{ marginTop: '1vw'}}>Aanmelden</Button>
                    </form>
                </div>
            </div>
            <Instagram {...props} />
        </div>
    )
}