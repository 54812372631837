import axiosInstance from './axios';
import { reportResponse } from './helpers';
import { GET_ARTICLE, PUT_ARTICLE, POST_ARTICLE, DELETE_ARTICLE } from './types';

// GET ARTICLE
export const getDocumentArticle = (id) => async dispatch => {
  const res = await axiosInstance.get(`/api/document_articles/${id}/`)
  dispatch({
    type: GET_ARTICLE,
    payload: res.data,
  })
}

// PUT ARTICLE
export const putDocumentArticle = (article) => async dispatch => {
    const res = await axiosInstance.put(`/api/document_articles/${article.id}/`, article);
    
    return reportResponse(res, PUT_ARTICLE, 'Artikel', dispatch);
};

// POST ARTICLE
export const postDocumentArticle = (article) => async dispatch => {
  const res = await axiosInstance.post(`/api/document_articles/`, article);
  
  return reportResponse(res, POST_ARTICLE, 'Artikel', dispatch);
};

// DELETE ARTICLE
export const deleteDocumentArticle = (id) => async dispatch => {
  const res = await axiosInstance.delete(`/api/document_articles/${id}/`);

  return reportResponse(res, DELETE_ARTICLE, 'Artikel', dispatch);
}