import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

// import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
// import DateAdapter from '@mui/lab/AdapterMoment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { AutoComplete } from '../../../components';
import { Box, Button, TextField, Typography } from '@mui/material';
import { Dialog } from '../../../components';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import BlogGrids from './BlogGrids';

import styled from 'styled-components';
import moment from 'moment';

import { deleteBlog, getBlog, getBlogs, postBlog, putBlog } from '../../../actions/blogs';
import { getBlogTypes, postBlogType } from '../../../actions/blogtypes';
import { postBlogImage, putBlogImage } from '../../../actions/blogimages';
import { postBlogGrid } from '../../../actions/bloggrids';
import { postBlogContent, putBlogContent } from '../../../actions/blogcontents';
import { postBlogHeader, putBlogHeader } from '../../../actions/blogheaders';
import { postBlogVideo, putBlogVideo } from '../../../actions/blogvideos';

const Field = styled.div`
    display: flex;
    align-items: center;
    gap: 5vw;
    margin-bottom: 1vw;
`;

const Container = styled.div`
    width: ${props => props.width || 'calc(50vw - 30px)'};
    border: 1px solid #BFBEBD;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 1vw;
`;

const ClickImg = styled.img`
    &:hover {
        cursor: pointer;
    }
`;

export default function Blog(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();

    const post = params.blogId === 'new';

    const blogsObject = useSelector((state) => state.blogs);
    const blogs = (blogsObject && blogsObject.blogs && blogsObject.blogs.results) || [];

    const blogTypes = useSelector((state) => state.blogTypes).results;

    let blog;
    if (!post) {
        blog = blogs.filter(a => Number(a.id) === Number(params.blogId))[0];
    } else {
        blog = {
            'title': '',
            'types': [],
            'grids': [],
            'main_image': null,
            'oval_image': null,
        };
    }

    const hasBlog = blog && Object.keys(blog).length > 0;

    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ titleValue, setTitleValue ] = useState((blog && blog.title) || '');
    const [ titleErrorValue, setTitleErrorValue ] = useState('');
    const [ slugValue, setSlugValue ] = useState((blog && blog.url_slug) || '');
    const [ typesValue, setTypesValue ] = useState((blog && blog.types) || []);
    const [ gridsValue, setGridsValue ] = useState((blog && blog.grids) || []);
    const [ dateValue, setDateValue ] = useState((blog && blog.created) || null);
    const [ imageValue, setImageValue ] = useState((blog && blog.main_image) || null);
    const [ ovalValue, setOvalValue ] = useState((blog && blog.oval_image) || null);

    useEffect(() => {
        !post && dispatch(getBlog(params.blogId))
        if (!post && hasBlog && !titleValue) {
            setTitleValue(blog.title);
            setSlugValue(blog.url_slug);
            setDateValue(blog.created);
            setTypesValue(blog.types);
            setGridsValue(blog.grids);
            setImageValue(blog.main_image);
            setOvalValue(blog.oval_image);
        }
        if (!blogTypes) {
            dispatch(getBlogTypes())
        }
    }, [post, dispatch, params.blogId, hasBlog, titleValue, blog, blogTypes]);

    async function updateRelation(oldValues, dispatchFunction) {
        let valueIds = oldValues.map(value => value.id);
        
        let newValues = oldValues;
        if (valueIds.includes(null)) {
            for (let i=0; i < oldValues.length; i++) {
                if (oldValues[i].id === null) {
                    let value = oldValues[i];
                    await dispatch(dispatchFunction(value)).then((response) =>
                        newValues[i] = response
                    )
                }
            };
        }

        return newValues;
    }

    async function updateNestedRelation(orgValues, newValues) {
        let returnValues = [];
        let isNewGrid = false;
        for (let i = 0; i < newValues.length; i++) {
            let newGrid = newValues[i];
            let newGridImages = newValues[i].images;
            let newGridContents = newValues[i].contents;
            let newGridHeaders = newValues[i].headers;
            let newGridVideos = newValues[i].videos;

            // POST A NEW GRID
            if (!newGrid.id) {
                isNewGrid = true;
                newGrid['images'] = [];
                newGrid['contents'] = [];
                newGrid['headers'] = [];
                newGrid['videos'] = [];
                await dispatch(postBlogGrid(newGrid)).then((response) => {
                    newGrid.id = response.id;
                    newGridImages = newGridImages.map(el => {
                        var img = Object.assign({}, el);
                        img.blog_grid = response.id;
                        return img;
                    })

                    newGridContents = newGridContents.map(el => {
                        var cont = Object.assign({}, el);
                        cont.blog_grid = response.id;
                        return cont;
                    })

                    newGridHeaders = newGridHeaders.map(el => {
                        var hea = Object.assign({}, el);
                        hea.blog_grid = response.id;
                        return hea;
                    })

                    newGridVideos = newGridVideos.map(el => {
                        var hea = Object.assign({}, el);
                        hea.blog_grid = response.id;
                        return hea;
                    })
                })
            }

            for (let j = 0; j < newGridImages.length; j++) {
                let newImage = newGridImages[j];
                if (newImage.id && !isNewGrid) {
                    // FETCH THE FILE
                    if (typeof newImage.file === 'string') {
                        let fileName = newImage.file;
                        // fetch the existing file on the server related to filename
                        await fetch(newImage.file)
                            .then(res => res.blob())
                            .then(blob => {
                                // PUT IMAGE
                                dispatch(putBlogImage(newImage, blob, fileName))
                        })
                    } else if (newImage.file && typeof newImage.file === 'object') {
                        await dispatch(putBlogImage(newImage, newImage.file, newImage.file.name))
                    }
                } else {
                    if (newImage.file) {
                        // POST IMAGE
                        await dispatch(postBlogImage(newImage, newImage.file, newImage.file.name)).then(response =>
                            // ADD IMAGE TO GRID
                            newGrid.images.push(response)
                        )
                    }
                }
            }

            for (let k = 0; k < newGridContents.length; k++) {
                let newContent = newGridContents[k];
                
                if (Array.isArray(newContent.content)) {
                    newContent.content = newContent.content[0]
                }

                if (newContent.id && !isNewGrid) {
                    await dispatch(putBlogContent(newContent))
                } else {
                    await dispatch(postBlogContent(newContent)).then(response => { newGrid.contents.push(response)})
                }
            }

            for (let k = 0; k < newGridHeaders.length; k++) {
                let newHeader = newGridHeaders[k];

                if (newHeader.id && !isNewGrid) {
                    await dispatch(putBlogHeader(newHeader))
                } else {
                    await dispatch(postBlogHeader(newHeader)).then(response => newGrid.headers.push(response))
                }
            }

            for (let k = 0; k < newGridVideos.length; k++) {
                let newVideo = newGridVideos[k];
                if (newVideo.id && !isNewGrid) {
                    // FETCH THE FILE
                    if (typeof newVideo.video === 'string') {
                        let fileName = newVideo.video;
                        // fetch the existing file on the server related to filename
                        await fetch(newVideo.video)
                            .then(res => res.blob())
                            .then(blob => {
                                // PUT IMAGE
                                dispatch(putBlogVideo(newVideo, blob, fileName))
                        })
                    } else if (newVideo.video && typeof newVideo.video === 'object') {
                        await dispatch(putBlogVideo(newVideo, newVideo.video, newVideo.video.name))
                    }
                } else {
                    if (newVideo.video) {
                        // POST IMAGE
                        await dispatch(postBlogVideo(newVideo, newVideo.video, newVideo.video.name)).then(response =>
                            // ADD IMAGE TO GRID
                            newGrid.videos.push(response)
                        )
                    }
                }
            }
            
            returnValues.push(newGrid);
            isNewGrid = false;
        }

        setGridsValue(returnValues);
        return returnValues;
    }

    async function handleImage(image) {
        let newImage = null;
        let fileName = '';
        if (typeof image === 'string') {
            fileName = image;
            await fetch(image)
                .then(res => res.blob())
                .then(blob => newImage = blob)
        } else if (image && typeof image === 'object') {
            newImage = image;
            fileName = image.name;
        }

        return { 'blob': newImage, 'fileName': fileName };
    }

    async function handleSubmit() {
        let error = false;
        // check validaty of fields
        if (titleValue === '') {
            setTitleErrorValue('Veld verplicht')
            error = true;
        } else {
            setTitleErrorValue('')
        }

        const mainInfo = await handleImage(imageValue);
        const ovalInfo = await handleImage(ovalValue);

        const blobs = { 'main_image': mainInfo['blob'], 'oval_image': ovalInfo['blob'] };
        const fileNames = { 'main_image': mainInfo['fileName'], 'oval_image': ovalInfo['fileName'] };

        const newTypeValues = await updateRelation(typesValue, postBlogType);
        const newGrids = await updateNestedRelation(blog.grids, gridsValue);

        let newBlog = {
            'title': titleValue,
            'url_slug': slugValue,
            'types': newTypeValues.map(value => value.id),
            'grids': newGrids.map(value => value.id),
            'created': moment(dateValue).format("YYYY-MM-DD[T]HH:mm:ss"),
            'main_image': mainInfo,
            'oval_image': ovalInfo,
        };

        if (!error) {
            if (!post) {
                newBlog['id'] = blog.id;
                // PUT BLOG
                dispatch(putBlog(newBlog, blobs, fileNames))
            } else {
                // POST BLOG
                dispatch(postBlog(newBlog, blobs, fileNames)).then(dispatch(getBlogs())).then(history.push('/admin/blogs'));
            }
        }


    }

    const delBlog = () => {
        setDialogOpen(false);
        dispatch(deleteBlog(blog.id)).then(history.push('/admin/blogs'))
    }

    return (
        <Box component='form' validate>
            <Field>
                <Typography fontWeight='500' style={{ width: '20vw'}}>Titel</Typography>
                <TextField
                    style={{ width: '50vw'}}
                    required
                    id="outlined-required"
                    value={titleValue}
                    error={!!titleErrorValue}
                    helperText={titleErrorValue}
                    onChange={(e) => setTitleValue(e.target.value)}
                    label='Titel'
                />
            </Field>
            <Field>
                <div>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Link naam</Typography>
                    <div>Bijv. bestandsformaten-voor-fonts</div>
                </div>
                <TextField
                    style={{ width: '50vw'}}
                    required
                    id="outlined-required"
                    value={slugValue}
                    onChange={(e) => setSlugValue(e.target.value)}
                    label='Link naam'
                />
            </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Aanmaakdatum</Typography>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label="Aanmaakdatum"
                        value={dayjs(dateValue)}
                        onChange={(e) => setDateValue(e.format('YYYY-MM-DDTHH:mm:ss'))}
                        renderInput={(params) => <TextField {...params} />}
                        ampm={false}
                        required
                    />
                </LocalizationProvider>
            </Field>
            <Field>
                <Typography fontWeight='500' style={{ width: '20vw'}}>Afbeelding</Typography>
                <Container>
                    <input
                        accept='image/*'
                        style={{ display: 'none' }}
                        id={`fileUploaderImage`}
                        onChange={(e) => setImageValue(e.target.files[0])}
                        type="file"
                        hidden
                    />
                    <ClickImg 
                        src={imageValue && imageValue.name ? URL.createObjectURL(imageValue) : imageValue} 
                        style={{ width: '10vw' }} 
                        alt='afbeelding'
                        onClick={() => document.getElementById(`fileUploaderImage`).click()}
                    />
                </Container>
            </Field>
            <Field>
                <Typography fontWeight='500' style={{ width: '20vw'}}>Boog afbeelding</Typography>
                <Container>
                    <input
                        accept='image/*'
                        style={{ display: 'none' }}
                        id={`fileUploaderOvalImage`}
                        onChange={(e) => setOvalValue(e.target.files[0])}
                        type="file"
                        hidden
                    />
                    <ClickImg 
                        src={ovalValue && ovalValue.name ? URL.createObjectURL(ovalValue) : ovalValue} 
                        style={{ width: '10vw' }} 
                        alt='afbeelding'
                        onClick={() => document.getElementById(`fileUploaderOvalImage`).click()}
                    />
                </Container>
            </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Types</Typography>
                </div>
                <AutoComplete
                    value={typesValue} 
                    setValue={(value) => setTypesValue(value)}
                    optionList={blogTypes} 
                    label='Types' 
                    optionName='content' 
                    style={{ width: '50vw' }} 
                />
            </Field>
            <Field>
                <div style={{ width: '20vw'}}>
                    <Typography fontWeight='500' style={{ width: '20vw'}}>Rasters</Typography>
                </div>
                <BlogGrids
                    grids={gridsValue}
                    setGrids={setGridsValue}
                />
            </Field>
            <div style={{ display: 'flex', width: '75vw', justifyContent: 'right', gap: '1vw'}}>
                {!post && <Button onClick={() => setDialogOpen(true)} variant='contained' startIcon={<DeleteIcon/>}>Verwijderen</Button>}
                <Button onClick={handleSubmit} variant='contained' startIcon={<EditIcon />}>{post ? 'Aanmaken' : 'Aanpassen'}</Button>
            </div>
            <Dialog
                open={dialogOpen}
                handleCancel={() => setDialogOpen(false)}
                handleOk={delBlog}
                title={'Blog verwijderen'}
                content={'Weet je zeker dat je deze blog wil verwijderen?'}
            />
        </Box>
    )
}