import React from 'react';
import { Typography, Button, PageTitle, BulletPoint } from '../../components';
import { Grid } from '@mui/material';
import StartTextPhoto from './StartTextPhoto';

import image from '../../assets/images/diensten/typografie/Studio-Valerie-Maas-grafisch-ontwerp-diensten-typografie-handschrift.jpg';
import amelie from '../../assets/images/diensten/lettertype/Studio-Valerie-Maas-grafisch-ontwerp-shop-lettertype-amelie-in-gebruik-1.jpg';
import charlie from '../../assets/images/diensten/lettertype/Studio-Valerie-Maas-grafisch-ontwerp-shop-lettertype-charlie-in-gebruik-1.jpg';

import { Blog, Instagram, LieveWoorden, Match, Nieuwsgierig, RecenteProjecten, Shop } from '../../subpages';
import Document from '../voorwaarden/Document';
import { getCsvDienstRow } from '../../helpers/diensten';

export default function Handschrift(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;
    const dienstenCsv = props.dienstenCsv;

    const handRow = getCsvDienstRow(dienstenCsv, 'handschrift');

    return (
        <div>
            <StartTextPhoto
                title={handRow['dienst']}
                subtitle={`Vanaf ${handRow['prijs']} excl. btw`}
                image={image}
                {...props}
            >
                <Typography>
                    Een lettertype gebaseerd op basis van een (eigen) handschrift. Hoe kan je jouw verhaal nu nog meer eigen maken?<br/><br/>
                    Aan de hand van enkele voorbeelden van jouw handschrift maak ik een lettertype wat er perfect op lijkt. Ik let daarbij op leesbaarheid en geef je tips hoe je deze het beste kan gebruiken.
                </Typography>
                <Button link='/contact' style={{ marginTop: hasPhone ? '4vw' : '2vw'}}>Yes! Dit wil ik</Button>
            </StartTextPhoto>
            <PageTitle title='wat krijg je?' left='-15px'>
                <Grid container justifyContent='space-evenly' alignItems='flex-start'>
                    <div style={{ width: hasPhone ? '80vw' : hasTablet ? '45vw' : '26vw', margin: hasPhone ? '3vw 0 3vw 0' : '3vw 0 5vw 0'}}>
                        <Typography variant='h2'>wat krijg je?</Typography>
                        <BulletPoint>Inspiratie-gesprek (online)</BulletPoint>
                        <BulletPoint>Moodboard</BulletPoint>
                        <BulletPoint>2 correctierondes</BulletPoint><br/>
                        <BulletPoint>Conceptvoorstel (online)</BulletPoint>
                        <BulletPoint>Jouw lettertype als TTF, OTF, WOFF2, WOFF</BulletPoint>
                        <BulletPoint>Inspiratie-gids, waarin je een idee krijgt hoe jij jouw lettertype het beste kan gebruiken.</BulletPoint>
                    </div>
                    <div style={{ width: hasPhone ? '80vw' : '26vw', margin: hasPhone ? '0 0 8vw 0' : '3vw 0 8vw 0'}}>
                        <Typography variant='h2'>wat moet ik zelf doen?</Typography>
                        <BulletPoint>Een moodboard maken (op Pinterest) van bijvoorbeeld logo’s, lettertypes en kleuren die jij mooi vind en bij jouw bedrijf passen.</BulletPoint>
                        <BulletPoint>Duidelijke foto’s van jouw handschrift maken, zowel losse letters als geschreven woorden of zinnen.</BulletPoint>
                        <BulletPoint>Feedback geven op ontwerpen.</BulletPoint>
                        <Typography variant='h2' style={{ marginTop: hasPhone ? '4vw' : '2vw'}}>bijkomende kosten</Typography>
                        <BulletPoint>Reiskosten (€0,19 per km)</BulletPoint>
                    </div>
                </Grid>
            </PageTitle>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: hasPhone ? '10vw' : '5vw' }}>
                <div style={{ width: hasPhone ? '80vw' : '100vw'}}>
                    <Typography variant='h2'>wil jij een écht unieke uitstraling die de<br/> juiste klanten aantrekt?</Typography>
                    <Button link='/contact' style={{ marginTop: '1vw'}}>Yes! Dit wil ik</Button>
                </div>
            </div>
            <div style={{ position: 'relative', flexDirection: hasPhone ? 'column' : 'row', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: hasPhone ? 'center' : 'default', margin: hasPhone ? '0 0 10vw 0' : hasTablet ? '10vw 0' : '0'}}>
                <div style={{ textAlign: 'center', width: hasPhone ? '70vw' : hasTablet ? '30vw' : '15vw' }}>
                    <Typography variant='h2'>nog niet klaar om te investeren?</Typography>
                    <Typography>Uniek ontworpen <br/>lettertypes voor jou en<br/> jouw business.</Typography>
                    <Button link='/shop' style={{ marginTop: '1vw'}}>Bekijk de shop</Button>
                </div>
                <div>
                    <img src={amelie} alt='moodboard' style={{ width: hasPhone ? '35vw' : hasTablet ? '18vw' : '15vw', margin: hasPhone ? '5vw 3vw 0 0' : hasTablet ? '0 2vw 0 10vw' : '0 2vw 0 15vw' }} />
                    <img src={charlie} alt='moodboard' style={{ width: hasPhone ? '35vw' : hasTablet ? '18vw' : '15vw' }} />
                </div>
                <Typography variant='sub'
                        style={{ fontSize: hasPhone ? '8px' : hasTablet ? '9px' : 'default', position: 'absolute', right: hasPhone ? '-7vw' : hasTablet ? '1vw' : '9vw', top: hasPhone ? '60vw' : hasTablet ? '8vw' : '7vw', transform: 'rotate(-90deg)'}}
                >
                    In gebruik door klanten
                </Typography>
            </div>
            <PageTitle title='Frequently asked questions' left='-70px'>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', maxWidth: '100%', margin: '5vw 0' }}>
                    <Typography variant='h2'>veelgestelde vragen</Typography>
                    <Typography>Ik heb de antwoorden!</Typography>
                    <Document documentName='Veelgestelde vragen' typeName='Typografie' style={{ textAlign: 'left', margin: '1vw 0'}} {...props} />
                    <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')}>Plan een gratis kennismakingsgesprek in</Button>
                </div>
            </PageTitle>
            <RecenteProjecten {...props} />
            <Match {...props} />
            <LieveWoorden {...props} />
            <Shop {...props} />
            <Blog {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}