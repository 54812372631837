import React from 'react';
import { Grid } from '@mui/material';
import { Typography, Button, ImageArch } from '../../components';
import { Instagram } from '../../subpages';
import ContactForm from './ContactForm';
import contact from '../../assets/images/contact/Studio-Valerie-Maas-grafisch-ontwerp-contact.jpg';
import { Match } from '../../subpages';

export default function Contact(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    return (
        <div>
            <Grid container justifyContent='center' alignItems='center' gap='13vw'>
                <div style={{ textAlign: 'center', width: hasPhone ? '80vw' : hasTablet ? '35vw' : '25vw'}}>
                    <Typography variant='h2'>Wat mag ik voor jou ontwerpen?</Typography>
                    <Typography variant='sub' style={{ margin: hasPhone && '2vw 0 5vw 0'}}>contact</Typography>
                    <Typography style={{ margin: hasPhone ? '2vw 0' : hasTablet ? '1vw 0' : '0.5vw 0'}}>Heb je een te gek uniek verhaal, een vraag of wil je gewoon graag samen een kopje koffie drinken?</Typography>
                    <Typography style={{ margin: hasPhone ? '2vw 0 5vw 0' : '0 0 3vw 0'}}>Vul het contactformulier hieronder in en ik neem zo snel mogelijk contact met je op. Je kan ook direct een gratis kennismakingsgesprek aanvragen hieronder.</Typography>
                    <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')} style={{ width: hasPhone && '80vw'}}>Plan een gratis kennismakingsgesprek in</Button>
                </div>
                <ImageArch src={contact} alt='Foto' width={hasPhone ? '80vw' : hasTablet ? '35vw' : '25vw'} style={{ margin: hasPhone ? '0 0 15vw 0' : hasTablet ? '5vw 0' : '0'}} />
            </Grid>
            <ContactForm {...props} />
            <Match {...props} />
            <Instagram {...props} />
        </div>
    )
}