import React, { useState, useEffect } from 'react';

import Overview from './Overview';

import { useDispatch, useSelector } from 'react-redux';
import { getProjectOverview } from '../../actions/projectoverview';
import { getProjectTypes } from '../../actions/projecttypes';
import { LineButton } from '../../components';

export default function Portfolio(props) {
    const [ hasFetched, setHasFetched ] = useState(false);
    const [ isType, setType ] = useState(null);
    const [ isPage, setPage ] = useState(1);

    const hasPhone = props.hasPhone;

    const dispatch = useDispatch();

    const projectsObject = useSelector((state) => state.projectOverviews);

    const projectsTypes = useSelector((state) => state.projectTypes).results;

    const changeType = (type) => {
        let filters = { page_size: isPage * 6 }
        if (isType === type) {
            setType([]);
            const typeNames = projectsTypes.map(type => type.content).join(',')
            filters.types__content = typeNames
            dispatch(getProjectOverview(filters));
        } else {
            setType(type);
            filters.types__content = type;
            // reset page to 1
            setPage(1);
            dispatch(getProjectOverview(filters))
        }
    }

    useEffect(() => {
        if (!projectsTypes || projectsTypes.length === 0) {
            dispatch(getProjectTypes())
        }
        
        const noObject = Object.keys(projectsObject).length === 0;
        const paginationFetch = projectsObject.results && projectsObject.results.length < isPage * 6 && projectsObject.results.length < projectsObject.count;

        if (projectsTypes && (noObject || paginationFetch || !hasFetched)) {
            const typeNames = isType || projectsTypes.map(type => type.content).join(',');
            const filters = { types__content: typeNames, page_size: isPage * 6 };
           
            dispatch(getProjectOverview(filters)).then(setHasFetched(true))
        }

    }, [dispatch, isPage, isType, projectsObject, projectsTypes, hasFetched])

    return (
        <Overview 
            type='portfolio'
            projectsObject={projectsObject} 
            text={`Branding en typografie voor creatieve en passievolle ondernemers`}
            projectsTypes={projectsTypes}
            isType={isType}
            changeType={changeType}
            isPage={isPage}
            setPage={setPage}
            {...props}
        >
            <LineButton to='/portfolio/boekontwerp' margin='2vw 0 0 0' style={{ fontSize: hasPhone && '10px'}}>Ik wil naar het portfolio met boekontwerpen</LineButton>
        </Overview>
    )
}