import React from 'react';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { colors } from '../../assets/colors';
import logo from '../../assets/images/VM_LOGO.png';
import { Icon, Typography } from '..';
import { showContent } from '../../helpers/routes';
import { useLocation } from 'react-router-dom';

const Rechten = styled.div`
    font-family: Mulish;
    font-size: 14px;
    text-transform: uppercase;
    color: ${colors.photo};
    border: solid 1px ${colors.title};
    text-align: center;
    padding: 5px 0;
    border-radius: 195px;
    margin: 0 5vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 12px;
    }
`;

const SimpleGrid = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    margin: 2vw 0;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        gap: 3vw;
        margin-top: 5vw;
        margin-bottom: 10vw;
    }  
`;

const ChivoText = styled.div`
    font-family: Chivo;
    color: ${colors.title};
    text-align: center;
    line-height: 24px;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        line-height: 20px;
        font-size: 12px;
    }    

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        line-height: 22px;
        font-size: 13px;
    }
`

const ChivoBoldText = styled.div`
    font-family: Chivo;
    font-weight: 400;
    color: ${colors.black};
    text-align: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        font-size: 12px;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        font-size: 13px;
    }
`;

const CursiveLink = styled.a`
    text-decoration: none;
    color: inherit;
    display: block;
    &:hover {
        font-style: italic;
        cursor: pointer;
    }
`;

export default function Footer(props) {
    const location = useLocation();

    const currentDate = new Date();

    if (!showContent(location)) {
        return null;
    } else if (props.hasPhone) {
        return (
            <div style={{ marginTop: '20vw', marginBottom: '30px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', marginLeft: '2vw', textAlign: 'center'}}>
                    <img src={logo} alt='logo' style={{height: '8vw', marginBottom: '2vw'}}/>
                    <ChivoBoldText>
                        Studio <br/>
                        Valerie Maas
                    </ChivoBoldText>
                </div>
                <SimpleGrid>
                    <a href='https://www.instagram.com/studio.valeriemaas/' target='_blank' rel="noreferrer">
                        <Icon name='instagram' textColor={colors.background} backgroundColor={colors.latte} backgroundHoverColor={colors.grey} />
                    </a>
                    <a href='https://www.facebook.com/valeriemaasgraphicdesign/' target='_blank' rel="noreferrer">
                        <Icon name='facebook-f' textColor={colors.background} backgroundColor={colors.latte} backgroundHoverColor={colors.grey} />
                    </a>
                    <a href='https://nl.pinterest.com/valeriemaas_graphicdesign/' target='_blank' rel="noreferrer">
                        <Icon name='pinterest-p' textColor={colors.background} backgroundColor={colors.latte} backgroundHoverColor={colors.grey} />
                    </a>
                </SimpleGrid>
                <ChivoText>
                    <div>Valerie Maas</div>
                    <div>Fazantlaan 78</div>
                    <div>6021ZH Budel</div>
                    <CursiveLink href='mailto:info@valeriemaas.nl'>info@valeriemaas.nl</CursiveLink>
                    <CursiveLink href='tel:+31651231248'>06 51231248</CursiveLink>
                </ChivoText>
                <div style={{ margin: '10vw 0'}}>
                    <ChivoText>
                        <CursiveLink href='/blog'>Blog</CursiveLink>
                        <CursiveLink href='/privacy'>Privacyverklaring</CursiveLink>
                        <CursiveLink href='/cookies'>Cookiestatement</CursiveLink>
                        <CursiveLink href='/voorwaarden'>Algemene voorwaarden</CursiveLink>
                        <CursiveLink href='/vragen'>Veelgestelde vragen</CursiveLink>
                    </ChivoText>
                </div>
                <Rechten>
                    Copyright 2017-2023 Valerie Maas <br/>alle rechten voorbehouden
                </Rechten>
            </div>
        )
    } else {
        return (
            <div style={{ marginTop: '5vw', marginBottom: '30px' }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="stretch"
                >
                    <ChivoText>
                        <div>Valerie Maas</div>
                        <div>Fazantlaan 78</div>
                        <div>6021ZH Budel</div>
                        <CursiveLink href='mailto:info@valeriemaas.nl'>info@valeriemaas.nl</CursiveLink>
                        <CursiveLink href='tel:+31651231248'>06 51231248</CursiveLink>
                    </ChivoText>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', marginLeft: '2vw', textAlign: 'center'}}>
                        <img src={logo} alt='logo' style={{ width: props.hasTablet ? '70px' : '90px' }}/>
                        <ChivoBoldText>
                            Studio <br/>
                            Valerie Maas
                        </ChivoBoldText>
                    </div>
                    <ChivoText>
                        <CursiveLink href='/blog'>Blog</CursiveLink>
                        <CursiveLink href='/privacy'>Privacyverklaring</CursiveLink>
                        <CursiveLink href='/cookies'>Cookiestatement</CursiveLink>
                        <CursiveLink href='/voorwaarden'>Algemene voorwaarden</CursiveLink>
                        <CursiveLink href='/vragen'>Veelgestelde vragen</CursiveLink>
                    </ChivoText>
                </Grid>
                <SimpleGrid>
                    <a href='https://www.instagram.com/studio.valeriemaas/' target='_blank' rel="noreferrer">
                        <Icon name='instagram' textColor={colors.background} backgroundColor={colors.latte} backgroundHoverColor={colors.grey} />
                    </a>
                    <a href='https://www.facebook.com/valeriemaasgraphicdesign/' target='_blank' rel="noreferrer">
                        <Icon name='facebook-f' textColor={colors.background} backgroundColor={colors.latte} backgroundHoverColor={colors.grey} />
                    </a>
                    <a href='https://nl.pinterest.com/studiovaleriemaas/' target='_blank' rel="noreferrer">
                        <Icon name='pinterest-p' textColor={colors.background} backgroundColor={colors.latte} backgroundHoverColor={colors.grey} />
                    </a>
                </SimpleGrid>
                <Rechten>
                    <Typography variant='sub' style={{ margin: '0'}}>Copyright 2017-{currentDate.getFullYear()} Valerie Maas, alle rechten voorbehouden.</Typography>
                </Rechten>
            </div>
        );
    }
}