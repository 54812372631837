import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router';

export default function DownloadFile(props) {
    const linkRef = useRef(null);
    let history = useHistory();

    useEffect(() => {
        linkRef.current.click();
        props.push && history.push('/');
    });

    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%'}}>
            <a ref={linkRef} href={props.filePath} target='_blank' rel="noreferrer" download>{props.fileName}</a>
        </div>
    )
}