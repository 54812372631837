import axiosInstance from './axios';
import { reportResponse } from './helpers';
import { GET_PROJECTS, GET_PROJECT, PUT_PROJECT, POST_PROJECT, DELETE_PROJECT } from './types';

// GET PROJECTS
export const getProjects = (id) => async dispatch => {
  const res = await axiosInstance.get(`/api/project/`);

  dispatch({
    type: GET_PROJECTS,
    payload: res.data,
  });
};

// GET PROJECT
export const getProject = (id) => async dispatch => {
  const res = await axiosInstance.get(`/api/project/${id}/`);

  dispatch({
    type: GET_PROJECT,
    payload: res,
  });
};

// PUT PROJECT
// Different endpoint API from GET, because relationships are send only with pk
export const putProject = (project) => async dispatch => {
  const res = await axiosInstance.put(`/api/projects/${project.id}/`, project);

  return reportResponse(res, PUT_PROJECT, 'Project', dispatch);
};

export const postProject = (project) => async dispatch => {
  const res = await axiosInstance.post(`/api/projects/`, project);

  return reportResponse(res, POST_PROJECT, 'Project', dispatch);
}

// DELETE PROJECT
export const deleteProject = (id) => async dispatch => {
  const res = await axiosInstance.delete(`/api/projects/${id}/`);
  
  return reportResponse(res, DELETE_PROJECT, 'Project', dispatch);
}