import axiosInstance from './axios';
import { PUT_BLOG_VIDEO, POST_BLOG_VIDEO } from './types';
import { reportResponse } from './helpers';
import { getCookie } from './cookies';

const getFormData = (object, blob, fileName) => Object.keys(object).reduce((formData, key) => {
  if (key === 'video') {
    formData.append(key, blob, fileName);
  } else {
    formData.append(key, object[key]);
  }
  
  return formData;
}, new FormData());

const config = {
headers: {
  'Authorization': getCookie('access_token') ? "Bearer " + getCookie('access_token') : null,
}
}

// PUT BLOG IMAGE
export const putBlogVideo = (blogVideo, blob, fileName) => async dispatch => {
  const res = await axiosInstance.put(`/api/blog_videos/${blogVideo.id}/`, getFormData(blogVideo, blob, fileName), config);
  
  return reportResponse(res, PUT_BLOG_VIDEO, 'Blog video', dispatch);
};

// POST BLOG IMAGE
export const postBlogVideo = (blogVideo, blob, fileName) => async dispatch => {
  const res = await axiosInstance.post(`/api/blog_videos/`, getFormData(blogVideo, blob, fileName), config);

  return reportResponse(res, POST_BLOG_VIDEO, 'Blog video', dispatch);
};