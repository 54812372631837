import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';

import { Typography, ImageArch } from '../../components';

const Video = styled.video`
    width: 28vw;
    border-radius: 95% 95% 0 0;
    border: 1px solid ${colors.photo};
    padding: 0.3vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 80vw;
    }  

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        width: 35vw;
    }
`; 

export default function StartTextPhoto(props) {
    const { hasPhone, hasTablet, titleVariant } = props;

    return (
        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'flex-start', flexWrap: 'wrap', margin: hasPhone ? '0 0 10vw 0' : '0'}}>
            <div style={{ width: hasPhone ? '80vw' : hasTablet ? '40vw' : '25vw', margin: hasPhone ? '0 0 10vw 0' : '3vw 0 8vw 0'}}>
                {props.title && <Typography variant={titleVariant ? titleVariant : 'h2'} >{props.title}</Typography>}
                {props.subtitle && <Typography variant='sub' style={{ fontSize: '12px', margin: hasPhone ? '0 0 2vw 0' : hasTablet ? '0 0 1vw 0' : '0 0 0.5vw 0'}}>{props.subtitle}</Typography>}
                {props.children}
            </div>
            {props.image && <div style={{ position: 'relative', margin: hasPhone ? '1vw 0 0 0' : '0 0 5vw 0' }}>
                <ImageArch src={props.image} alt='portret Valerie' width={hasPhone ? '80vw' : hasTablet ? '35vw' : '28vw'} />
            </div>}
            {props.video && 
                <Video playsInline autoPlay muted loop>
                    <source src={props.video} type="video/mp4"/>
               </Video>
            }
        </div>
    )
}