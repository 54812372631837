import axiosInstance from './axios';
import { RESPONSE_OK, RESPONSE_UNAUTHORISED, RESPONSE_UNKNOWN } from './types';
import { setCookie, eraseCookie } from './cookies';

// GET BOOKINGS
export const login = (credentials) => async dispatch => {
    try {
        const res = await axiosInstance.post('api/token/', credentials);
        axiosInstance.defaults.headers['Authorization'] = "Bearer " + res.data.access;
        // localStorage.setItem('access_token', res.data.access);
        // localStorage.setItem('refresh_token', res.data.refresh);
        // localStorage.setItem('isAuthenticated', true);

        if (localStorage.getItem('consent') !== false) {
            setCookie('access_token', res.data.access, 30);
            setCookie('refresh_token', res.data.refresh, 30);
            setCookie('isAuthenticated', true, 30);

            dispatch({
                type: RESPONSE_OK,
                payload: { 'status': res.status, 'statusText': res.statusText }
            });
        } else {
            dispatch({
                type: RESPONSE_UNAUTHORISED,
                payload: { 'status': 401, 'statusText': 'Je moet de cookies accepteren' }
            });
        }
    } catch (error) {
        if (error.response) {
            const res = error.response;
            if (res.status === 401) {
                // localStorage.removeItem('access_token');
                eraseCookie('access_token');
                // localStorage.removeItem('refresh_token');
                // localStorage.setItem('isAuthenticated', false);
                setCookie('isAuthenticated', false, 30);
                axiosInstance.defaults.headers['Authorization'] = null;
                dispatch({
                    type: RESPONSE_UNAUTHORISED,
                    payload: { 'status': res.status, 'statusText': 'Gebruikersnaam en/of wachtwoord onjuist' }
                });
            } else {
                // localStorage.removeItem('access_token');
                eraseCookie('access_token');
                // localStorage.removeItem('refresh_token');
                eraseCookie('refresh_token');
                // localStorage.setItem('isAuthenticated', false);
                setCookie('isAuthenticated', false, 30);
                axiosInstance.defaults.headers['Authorization'] = null;
                dispatch({
                    type: RESPONSE_UNKNOWN,
                    payload: { 'status': res.status, 'statusText': 'Onbekende fout opgetreden' }
                });
            }
        }
    }
};