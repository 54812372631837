import axiosInstance from './axios';
import { reportResponse } from './helpers';
import { PUT_BLOG_HEADER, POST_BLOG_HEADER } from './types';

// PUT BLOG HEADER
export const putBlogHeader = (blogHeader) => async dispatch => {
  const res = await axiosInstance.put(`/api/blog_headers/${blogHeader.id}/`, blogHeader);

  return reportResponse(res, PUT_BLOG_HEADER, 'Blog tussenkop', dispatch);
}

// POST BLOG CONTENT
export const postBlogHeader = (blogHeader) => async dispatch => {
  const res = await axiosInstance.post(`/api/blog_headers/`, blogHeader);
  
  return reportResponse(res, POST_BLOG_HEADER, 'Blog tussenkop', dispatch);
};
