import React from 'react';
import { LieveWoorden, Match, Nieuwsgierig, Shop, Blog, Instagram } from '../../subpages';
import { Typography, Button } from '../../components';
import StartTextPhoto from './StartTextPhoto';

import boost1 from '../../assets/images/diensten/glyphs/Studio-Valerie-Maas-grafisch-ontwerp-diensten-typografie-missende-glyphs.jpg';
import { getCsvDienstRow } from '../../helpers/diensten';


export default function Glyphs(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;
    const dienstenCsv = props.dienstenCsv;

    const glyphsRow = getCsvDienstRow(dienstenCsv, 'missende glyphs');

    return (
        <div>
            <StartTextPhoto
                image={boost1}
                title={glyphsRow['dienst']}
                subtitle={`Vanaf ${glyphsRow['prijs']} excl. btw`}
                {...props}
            >
                <Typography style={{ width: hasPhone ? '75vw' : 'default', margin: hasPhone ? '5vw 0 2vw 0' : hasTablet ? '2vw 0' : '0'}}>
                    Je hebt een lettertype gekocht en nu kom je er achter dat je accenten of speciale tekens mist. 
                </Typography>
                <Typography style={{ width: hasPhone ? '75vw' : 'default', margin: hasPhone ? '0 0 2vw 0' : hasTablet ? '2vw 0' : '0'}}>
                    Afhankelijk van de licentie van de maker, kan ik de missende tekens voor je ontwerpen en maken. 
                </Typography>
                <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')} style={{ margin: hasPhone ? '2vw 0 0 0' : '1vw 0 0 0'}}>Plan een gratis kennismakingsgesprek in</Button>
            </StartTextPhoto>
            <Match {...props} />
            <LieveWoorden {...props}  />
            <Shop {...props} />
            <Blog {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}