import React from 'react';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { Typography, ImageArch, Button, RotatingCircle } from '../../components';
import { colors } from '../../assets/colors';

import portret from '../../assets/images/werkwijze/Studio-Valerie-Maas-grafisch-ontwerp-werkwijze-1.jpg';
import werkwijze from '../../assets/images/werkwijze/Studio-Valerie-Maas-grafisch-ontwerp-werkwijze-2.jpg';

import { ReactComponent as Ontdekken } from '../../assets/illustrations/Studio-Valerie-Maas-grafisch-ontwerp-illustraties_ontdekken-creeren-ontwerpen.svg';
import { Instagram, LieveWoorden, Match, Nieuwsgierig } from '../../subpages';

const StyledImg = styled.img`
    width: 15vw;
    border-radius: 50% 50% 0 0;

    @media screen and (max-width: 766px){
        margin: 5vw 0;
        width: 60vw;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        width: 25vw;
    }
`;

export default function Werkwijze(props) {
    const hasPhone = props.hasPhone;
    const hasTablet = props.hasTablet;

    const getFase = (number, title, text) => {
        return (
            <div style={{ width: hasPhone ? '75vw' : hasTablet ? '35vw' : '26vw'}}>
                <Typography variant='sub' style={{ margin: '0'}}>fase {number}</Typography>
                <Typography variant='h2'>{title}</Typography>
                <Typography>{text}</Typography>
            </div>
        )
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20vw', flexWrap: 'wrap', alignItems: 'center', marginBottom: '5vw'}}>
                <div style={{ textAlign: 'center', width: hasPhone ? '75vw' : hasTablet ? '22vw' : '15vw'}}>
                    <Typography variant='h1'>werkwijze</Typography>
                    <Typography>Ik doe elke dag wat ik leuk vind om te doen</Typography>
                    <Typography variant='sub'>Ontdekken, creëren en ontwerpen</Typography>
                </div>
                <ImageArch src={portret} alt='Foto' width={hasPhone ? '75vw' : hasTablet ? '28vw' : '25vw'} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                <Typography variant='h2' style={{ textAlign: 'center', width: hasPhone ? '75vw' : '25vw', margin: hasPhone ? '5vw 0' : '0'}}>we doorlopen samen zeven fases naar het ontwerp wat volledig bij jou past!</Typography>
                {!hasPhone && <RotatingCircle style={{ position: 'absolute', left: hasTablet ? '9vw' : '10vw', top: hasTablet ? '-1vw' : '-2vw'}}>
                    <Ontdekken style={{ fill: colors.illustration, width: hasTablet ? '15vw' : '10vw' }} />
                </RotatingCircle>}
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: hasPhone ? '7vw' : hasTablet ? '10vw' : '5vw', alignItems: 'flex-start', margin: hasPhone ? '7vw 0' : '7vw 0 3vw 0' }}>
                {getFase(1, 'ontmoeten', 'Nadat je via de website, een e-mail of een privé berichtje op social media contact met mij hebt gezocht, plannen we een kennismakingsgesprek in. We drinken een digitale koffie met elkaar. Ik vertel je wie ik ben en wat ik doe in mijn bedrijf. Jij vertelt ook wie je bent, maar ook wat je wensen zijn.')}
                {getFase(2, 'vastleggen', 'Na ons gesprek stuur ik je een offerte op met een voorstel. Zodra jij deze goedkeurt, ontvang je de eerste factuur voor de aanbetaling. Zodra de administratieve zaken afgerond zijn, kan je een e-mail van mij verwachten met daarin een welkoms-gids en een link naar ons gezamenlijke moodboard.')}
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: hasPhone ? '7vw' : hasTablet ? '10vw' : '5vw', alignItems: 'center' }}>
                {getFase(3, 'ontdekken', 'We zijn nu oficieel begonnen aan het project. Yes! In deze fase duiken we diep in het verhaal achter jou en/of jouw bedrijf. Jij vertelt tijdens de inspiratie- en/of strategie-sessie wie je bent, wat je doet, wie je doelgroep is, hoe jij opvalt en nog veel meer. We zorgen er samen voor dat jouw verhaal, jouw strategie duidelijk wordt. Daarnaast bekijken we het gezamelijke moodboard en bespreken we wat je wél en niet aanspreekt. Aan het einde van deze fase ontvang jij een document, waarin al goed de richting te zien is die we op gaan. Natuurlijk is de inhoud afhankelijk van het project, bij een branding ontvang je natuurlijk jouw branding strategie, maar ook een compleet moodboard en een kleurenpalet.')}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: hasTablet ? '35vw' : '26vw' }}>
                    <StyledImg src={werkwijze} alt='Foto van werkwijze' />
                </div>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap:hasPhone ? '7vw' : hasTablet ? '10vw' : '5vw', alignItems: 'flex-start', margin: hasPhone ? '7vw 0' : '3vw 0' }}>
                {getFase(4, 'creëren en ontwerpen', 'Nadat we bekeken hebben wie jij bent, wat je doet en vooral waarom je dat doet, kunnen we beginnen met creëren! Jouw verhaal (de branding-strategie) is hierin leidend. Ik creëer een concept met jou, jouw bedrijf en jouw doelgroep in gedachten. Ik presenteer je aan het einde van deze fase een concept, met daarin kleuren, letters, illustraties, logo’s en nog veel meer!')}
                {getFase(5, 'verfijnen', 'Na het geven van je feedback, wordt deze verwerkt. Het verfijnen van het ontwerp is een normaal onderdeel van het proces. Het ontwerp klopt natuurlijk pas écht, als ook jij je er 100% goed bij voelt. Aan het einde van deze fase heb je een definitief ontwerp wat goed voelt, perfect bij jouw verhaal past, maar ook de ideale klant aantrekt.')}
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap:hasPhone ? '7vw' : hasTablet ? '10vw' : '5vw', alignItems: 'flex-start', margin: hasPhone ? '7vw 0' : '3vw 0' }}>
                {getFase(6, 'exporteren', 'Tijdens deze fase ga ik alle definitieve ontwerpen exporteren. Ook zorg ik ervoor dat alle drukbestanden opgemaakt worden en in productie kunnen. Bij een branding-pakket start ik met een brandbook, waarin jij kan zien hoe je jouw branding kan implementeren. Wat jij moet doen? Eigenlijk niet zo veel meer, neem vooral de tijd om jouw doelgroep al warm te maken en wordt zelf verliefd op jouw nieuwe ontwerp!')}
                {getFase(7, 'lanceren', 'We kunnen nu eindelijk jouw nieuwe ontwerp lanceren! Yes! Ik kan niet wachten om te zien wat anderen van jouw nieuwe branding vinden. Het is nu tijd voor een glas champagne of natuurlijk een latte, want dat verdien je. Proost!')}
            </div>
            <div style={{ position: 'relative', margin: hasPhone ? '20vw 0' : hasTablet ? '10vw 0' : '5vw 0' }}>
                <Grid container justifyContent='space-evenly' alignItems='center'>
                    <div style={{ textAlign: 'center', width: hasPhone ? '75vw' : hasTablet ? '50vw' : '30vw'}}>
                        <Button link='/diensten' style={{ marginBottom: '1vw'}}>Bekijk de diensten</Button>
                        <Button onClick={(e) => window.open('https://calendly.com/valeriemaas/kennismaking')}>Plan een gratis kennismakingsgesprek in</Button>
                    </div>
                </Grid>
            </div>
            <Match {...props} />
            <LieveWoorden {...props} />
            <Nieuwsgierig {...props} />
            <Instagram {...props} />
        </div>
    )
}