import React from 'react';

import styled from 'styled-components';

import { Select, MenuItem } from '@mui/material';

const ClickImg = styled.img`
    &:hover {
        cursor: ${props => props.editing ? 'pointer' : 'default'};
    }

`;

export default function ProjectVideo(props) {
    const { grid, video, updateVideo, isEditing } = props;

    const customId = video.id || video.order;

    return (
        <div>
            <div>Video</div>
            <input
                accept="video/mp4,video/x-m4v,video/*"
                style={{ display: 'none' }}
                id={`fileUploader-${grid.id}-${customId}`}
                onChange={(e) => updateVideo('video', grid, customId, e.target.files[0])}
                type="file"
                hidden
            />
            <ClickImg 
                as='video'
                src={video.video && video.video.name ? URL.createObjectURL(video.video) : video.video} 
                style={{ width: '100%' }} 
                alt='video' 
                editing={isEditing}
                onClick={() => isEditing && document.getElementById(`fileUploader-${grid.id}-${customId}`).click()}
            />
            <Select
                value={video.shape}
                label="Vorm"
                onChange={(e) => updateVideo('shape', grid, customId, e.target.value) }
                style={{ width: '100%'}}
                disabled={!isEditing}
            >
                <MenuItem key={4} value='SQUARE'>Vierkant</MenuItem>
                <MenuItem key={5} value='ARCH'>Boog</MenuItem>
            </Select>
        </div>
    )
}