import axiosInstance from './axios';
import { GET_PROJECT_IDS } from './types';

const findAdjacentProjects = (projects, projectId) => {
  const projectIndex = projects.findIndex(x => Number(x.id) === Number(projectId));
  const leftProjectId = projects[projectIndex-1] || projects[projects.length - 1];
  const rightProjectId = projects[projectIndex+1] || projects[0];
  return [leftProjectId, rightProjectId]
}

// GET PROJECT
export const getProjectIds = (projectId, filters) => async dispatch => {
  let filter = ''

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
          if (value) {
            if (value instanceof Array) {
              for (let i = 1; i < value.length; i++) {
                filter = filter = filter.concat(`&${key}=${value[i]}`)
              }
            } else {
              filter = filter.concat(`&${key}=${value}`)
            }
          }
      }
    }

  const res = await axiosInstance.get(`/api/project_ids/?${filter}`);
  const projectIds = findAdjacentProjects(res.data.results, projectId);

  dispatch({
    type: GET_PROJECT_IDS,
    payload: res.data,
    projectIds: projectIds,
  });
};