import React, { useState } from 'react';
import { Typography, Checkbox, Button, Paper, Select, MenuItem } from '@mui/material';

import styled from 'styled-components';

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ProjectGrid from './ProjectGrid';

import { useDispatch } from 'react-redux';
import { getProjects } from '../../../actions/projects';
import { deleteProjectGrid } from '../../../actions/projectgrids';

const Container = styled.div`
    width: ${props => props.width || 'calc(50vw - 30px)'};
    border: 1px solid #BFBEBD;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 1vw;
`;

const defaultVideo = {
    'id': null,
    'order': null,
    'shape': 'SQUARE',
    'video': null,
    'project_grid': null,
}

const defaultImage = {
    'id': null,
    'file': null,
    'primary': false,
    'secondary': false,
    'scrollable': false,
    'project_grid': null,
}

export default function ProjectImages(props) {
    const { grids, setGrids } = props;

    const [ isCreatingNewGrid, setIsCreatingNewGrid ] = useState(false);
    const [ isNewGroup, setNewGroup ] = useState(false);
    const [ isNewGrid, setNewGrid ] = useState('1');
    const [ gridValues, setGridValues ] = useState(['image']);

    const [ isEditing, setIsEditing ] = useState(false);

    const [, forceUpdate] = React.useState(0);

    const dispatch = useDispatch();

    const createGrid = () => {
        let newGrids = grids;
        const startOrder = grids.length > 0 ? grids.slice(-1)[0].order + 1 : 1;

        let images = [];
        let videos = [];
        for (let i = 0; i < gridValues.length; i += 1) {
            const value = gridValues[i];
            let object = null;
            if (value === 'image') {
                object = Object.assign({}, defaultImage);
                object.order = i;
                images.push(object)
            } else if (value === 'video') {
                object = Object.assign({}, defaultVideo);
                object.order = i;
                videos.push(object);
            }
        }

        let newGrid = {
            'id': null,
            'order': startOrder,
            'group': isNewGroup,
            'grid': isNewGrid,
            'images': images,
            'videos': videos,
        }

        newGrids.push(newGrid);

        setGrids(newGrids);
        setIsCreatingNewGrid(false);
        setNewGrid('1');
        setGridValues(['image']);
        setIsEditing(true);
        setNewGroup(false);
    }

    const addImage = (grid) => {
        const gridId = grids.indexOf(grid);
        let newGrids = grids;
        let newImage = Object.assign({}, defaultImage);

        if (grid.images.length > 0) {
            newImage['id'] = grid.images.slice(-1)[0].id + 1;
        } else {
            newImage['id'] = grid.order;
        }
        
        newGrids[gridId].images.push(newImage);
        setGrids(newGrids);
        forceUpdate(n => !n);
    }

    const updateImage = (key, grid, imageId, value) => {
        let newGrids = grids;

        const gridId = grids.indexOf(grid);
        const gridImage = grid.images.filter(img => Number(img.id || img.order) === Number(imageId))[0];
        const gridImageId = grid.images.indexOf(gridImage);

        newGrids[gridId]['images'][gridImageId][key] = value;
        setGrids(newGrids);
        forceUpdate(n => !n);
    }

    const updateVideo = (key, grid, videoId, value) => {
        let newGrids = grids;

        const gridId = grids.indexOf(grid);
        const gridVideo = grid.videos.filter(vid => Number(vid.id || vid.order) === Number(videoId))[0];
        const gridVideoId = grid.videos.indexOf(gridVideo);

        newGrids[gridId]['videos'][gridVideoId][key] = value;
        setGrids(newGrids);
        forceUpdate(n => !n);
    }

    const deleteGrid = (grid) => {
        dispatch(deleteProjectGrid(grid.id)).then(dispatch(getProjects()));
        const newGrids = grids.filter(gr => Number(gr.id) !== Number(grid.id));
        setGrids(newGrids);
    }

    const setGridArray = (value) => {
        const newArray = Array(Number(value)).fill('image');
        setNewGrid(value);
        setGridValues(newArray);
    }

    const setGridArrayValues = (value, i) => {
        let newGridValues = gridValues;
        newGridValues[i] = value; 
        setGridValues(newGridValues);
        forceUpdate(n => !n);
    }

    return (
        <Container>
            {grids.length > 0 &&
                grids.map((grid, i) => (
                    <ProjectGrid 
                        key={i}
                        grids={grids}
                        grid={grid}
                        gridId={i} 
                        updateImage={updateImage} 
                        updateVideo={updateVideo}
                        isEditing={isEditing}
                        deleteGrid={deleteGrid}
                        addImage={addImage}
                        setGrids={setGrids}
                    />
                ))
            }
            {isCreatingNewGrid ? <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px', gap: '2vw' }}>
                <Typography variant='h6'>Nieuw raster aanmaken</Typography>
                <div>
                    <Typography>Raster</Typography>
                    <Select
                        value={isNewGrid}
                        label="Raster"
                        onChange={(e) => setGridArray(e.target.value)}
                        style={{ height: '3vw', width: '10vw'}}
                    >
                        <MenuItem key={1} value='1'>Raster van 1</MenuItem>
                        <MenuItem key={2} value='2'>Raster van 2</MenuItem>
                        <MenuItem key={3} value='3'>Raster van 3</MenuItem>
                    </Select>
                </div>
                <div>
                    <Typography>Types content</Typography>
                    {Array.apply(null, { length: Number(isNewGrid) }).map((e, i) => {
                        return (
                            <div key={i} style={{ display: 'flex', alignItems: 'center', gap: '5px'}}>
                                <span>{i + 1}</span>
                                <Select
                                    key={i}
                                    value={gridValues[i]}
                                    label="Raster"
                                    onChange={(e) => setGridArrayValues(e.target.value, i) }
                                    style={{ height: '3vw', width: '10vw'}}
                                >
                                    <MenuItem key={6} value='image'>Afbeelding</MenuItem>
                                    {!isNewGroup && <MenuItem key={7} value='video'>Video</MenuItem>}
                                </Select>
                            </div>
                        )
                    })}
                </div>
                <div>
                    <Typography>Groep</Typography>
                    <Checkbox checked={isNewGroup} onChange={() => setNewGroup(!isNewGroup)} />
                </div>
                <div>
                    <Button onClick={createGrid} variant='contained' startIcon={<AddIcon/>} style={{ marginBottom: '1vw'}}>Aanmaken</Button>
                    <Button onClick={() => setIsCreatingNewGrid(false)} variant='contained' startIcon={<CloseIcon/>}>Annuleren</Button>
                </div>
            </Paper> 
            :
                <div style={{ display: 'flex', gap: '1vw', justifyContent: 'center'}}>
                    <Button onClick={() => setIsCreatingNewGrid(true)} variant='contained' startIcon={<AddIcon/>}>Nieuw raster aanmaken</Button>
                    {grids.length > 0 && !isEditing && <Button variant='contained' onClick={() => setIsEditing(true)} startIcon={<EditIcon/>}>Rasters aanpassen</Button>}
                    {grids.length > 0 && isEditing && <Button variant='contained' onClick={() => setIsEditing(false)} startIcon={<EditIcon/>}>Rasters bevestigen</Button>}
                </div>
            }
        </Container>
    )
}