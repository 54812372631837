import axiosInstance from './axios';
import { RESPONSE_OK } from './types';
import { eraseCookie, setCookie } from './cookies';

// GET BOOKINGS
export const logout = () => async dispatch => {
    // localStorage.removeItem('access_token');
    eraseCookie('access_token');
    eraseCookie('refresh_token');
    // localStorage.removeItem('refresh_token');
    setCookie('isAuthenticated', false, 30);
    // localStorage.setItem('isAuthenticated', false);
    axiosInstance.defaults.headers['Authorization'] = null;
    dispatch({
        type: RESPONSE_OK,
        payload: { 'status': 200, 'statusText': 'Uitloggen succesvol' }
    });
}