import React from 'react';
import { Grid } from '@mui/material';
import { colors } from '../../assets/colors';
import styled from 'styled-components';
import { LineButton, Typography, Button } from '../../components';

import photo from '../../assets/images/home/Studio-Valerie-Maas-grafisch-ontwerp-home-portret.jpg';

const GiantFour = styled.div`
    font-family: VM;
    font-size: 15vw;
    color: ${colors.illustration};

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 40vw;
    }
`;

const StyledImg = styled.img`
    height: 10vw;
    border-radius: 50% 50% 0 0;
    margin: 2vw 1vw 0 1vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        margin: 8vw 3vw 0 3vw;
        height: 25vw;
    }
`;

export default function NoMatch(props) {
    return (
        <div>
            <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'>
                    <Typography variant='h1' style={{ marginBottom: '20px' }}>Oeps! er ging iets mis</Typography>
                    <Typography>404-pagina</Typography>
            </Grid>
            {props.hasPhone ?
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                    <GiantFour>4</GiantFour>
                    <StyledImg src={photo} alt='0'/>
                    <GiantFour>4</GiantFour>
                </div>
                : <Grid
                container
                direction='row'
                justifyContent='space-evenly'
                alignItems='center'
                style={{ marginBottom: '3vw'}}>
                    <div style={{ textAlign: 'center' }}>
                        <LineButton to='/over'>Over mij</LineButton>
                        <LineButton to='/diensten'>Diensten</LineButton>
                        <LineButton to='/werkwijze'>Werkwijze</LineButton>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <GiantFour>4</GiantFour>
                        <StyledImg src={photo} alt='0'/>
                        <GiantFour>4</GiantFour>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <LineButton to='/shop'>Shop</LineButton>
                        <LineButton to='/blog'>Blog</LineButton>
                        <LineButton to='/contact'>Contact</LineButton>
                    </div>
            </Grid>}
            <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'>
                    <Button link='/'>Terug naar de home-pagina</Button>
            </Grid>
            {props.hasPhone && 
                <div style={{ marginTop: '15vw', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <LineButton to='/over'>Over mij</LineButton>
                    <LineButton to='/diensten'>Diensten</LineButton>
                    <LineButton to='/werkwijze'>Werkwijze</LineButton>
                    <LineButton to='/shop'>Shop</LineButton>
                    <LineButton to='/blog'>Blog</LineButton>
                    <LineButton to='/contact'>Contact</LineButton>
                </div>
            }
        </div>
    )
}