import { RESPONSE_OK, RESPONSE_UNAUTHORISED, RESPONSE_UNKNOWN, RESPONSE_ERROR } from '../actions/types';

const responses = (state = {}, action) => {
  switch (action.type) {
    case RESPONSE_OK:
    case RESPONSE_UNAUTHORISED:
      return {
        ...state,
        ...action.payload,
      };
    case RESPONSE_UNKNOWN:
      return {
        ...state,
        data: 'Onbekende fout',
      }
    case RESPONSE_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default responses;