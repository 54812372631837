import React, { useRef } from 'react';
import { Typography, PageTitle, ImageArch, Button, LineButton } from "../../components";
import { Instagram } from '../../subpages';
import { Grid } from "@mui/material";
import styled from 'styled-components';

import Document from './Document';

import lattefoto from '../../assets/images/vragen/Studio-Valerie-Maas-grafisch-ontwerp-Veelgestelde-vragen-Latte.jpg';
import portret from '../../assets/images/vragen/Studio-Valerie-Maas-grafisch-ontwerp-Veelgestelde-vragen-Portret.jpg';

const CustomGrid = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        gap: 5vw;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        gap: 3vw;
    }
`;

const AbsoluteImg = styled.div`
    position: absolute;
    width: ${props => props.width || '40vw'};
    left: ${props => props.left || '5vw'};
    bottom: ${props => props.bottom || '45vw'};
    right: ${props => props.right || '5vw'};
    top: ${props => props.top || '45vw'};
`;

export default function Vragen(props) {
    // refs
    const algemeenRef = useRef(null);
    const brandingRef = useRef(null);
    const websiteRef = useRef(null);
    const typografieRef = useRef(null);
    const socialMediaRef = useRef(null);
    const shopRef = useRef(null);

    const isPhone = props.hasPhone;
    const isTablet = props.hasTablet;

    const getVoorwaarden = (name, myRef, left, props) => {
        return (
            <div ref={myRef} style={{ margin: '5vw 0'}}>
                <PageTitle title={name} left={left}>
                    <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant='h2'>{name}</Typography>
                            <Document documentName='Veelgestelde vragen' typeName={name} {...props} />
                        </Grid>
                </PageTitle>
            </div>
        )

    }

    const executeScroll = (myRef) => {
        myRef && myRef.current && window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop - 250 })
    };

    return (
        <div>
            {!props.hasPhone ? 
                <Grid container justifyContent='space-evenly' alignItems='flex-start'>
                    <div style={{ width: props.hasTablet ? '30vw' : '22vw', textAlign: 'center', margin: '3vw 0 3vw 0'}}>
                        <Typography variant='sub'>Frequently asked questions</Typography>
                        <Typography variant='h1'>veelgestelde vragen</Typography>
                        <Typography>Heb jij een vraag? Neem een kijkje in de veelgestelde vragen hieronder. Heb je toch nog een vraag? Neem even contact met me op, ik beantwoord met alle liefde je vragen. </Typography>
                        <Button onClick={() => window.open('mailto:info@valeriemaas.nl')} style={{ margin: '1vw 0 2vw 0'}}>info@valeriemaas.nl</Button>
                    </div>
                    <div style={{ position: 'relative', width: '30vw' }}>
                        <ImageArch src={portret} alt='portret Valerie' width='15vw' />
                        <img src={lattefoto} alt='moodboard' style={{ position: 'absolute', width: '15vw', top: '9vw', right: '-3vw', zIndex: '998' }} />
                    </div>
                </Grid> : 
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', gap: '3vw'}}>
                    <Typography variant='sub'>Frequently asked questions</Typography>
                    <Typography variant='h2'>veelgestelde vragen</Typography>
                    <Typography style={{ margin: '0 5vw'}}>Heb jij een vraag? Neem een kijkje in de veelgestelde vragen hieronder. Heb je toch nog een vraag? Neem even contact met me op, ik beantwoord met alle liefde je vragen. </Typography>
                    <Button onClick={() => window.open('mailto:info@valeriemaas.nl')}>info@valeriemaas.nl</Button>
                    <div style={{ width: '100%', height: '80vw'}} />
                    <AbsoluteImg top='default' bottom='25vw'>
                        <ImageArch src={portret} alt='portret Valerie' width='100%' />
                    </AbsoluteImg>
                    <AbsoluteImg top='default' bottom='5vw' right='5vw' left='default'>
                        <img src={lattefoto} alt='moodboard' width='100%' />
                    </AbsoluteImg>
                </div>
            }
            <div style={{ position: 'relative', margin: isPhone ? '15vw 0' : isTablet ? '10vw 0' : '5vw 0'}}>
                <CustomGrid>
                    <LineButton onClick={() => executeScroll(algemeenRef)} margin='0'>Algemeen</LineButton>
                    <LineButton onClick={() => executeScroll(brandingRef)} margin='0'>Branding</LineButton>
                    <LineButton onClick={() => executeScroll(websiteRef)} margin='0'>Website</LineButton>
                    <LineButton onClick={() => executeScroll(typografieRef)} margin='0'>Typografie</LineButton>
                    <LineButton onClick={() => executeScroll(socialMediaRef)} margin='0'>Social media</LineButton>
                    <LineButton onClick={() => executeScroll(shopRef)} margin='0'>Shop</LineButton>
                </CustomGrid>
            </div>
            {getVoorwaarden('Algemeen', algemeenRef, '-15px', props)}
            {getVoorwaarden('Branding', brandingRef, '-15px', props)}
            {getVoorwaarden('Website', websiteRef, '-10px', props)}
            {getVoorwaarden('Typografie', typografieRef, '-20px', props)}
            {getVoorwaarden('Social media', socialMediaRef, '-25px', props)}
            {getVoorwaarden('Shop', shopRef, '1px', props)}
            <Instagram {...props} />
        </div>
    )
}