import axiosInstance from './axios';
import { PUT_BLOG_IMAGE, POST_BLOG_IMAGE } from './types';
import { reportResponse } from './helpers';
import { getCookie } from './cookies';

const getFormData = (object, blob, fileName) => Object.keys(object).reduce((formData, key) => {
    if (key === 'file') {
      formData.append(key, blob, fileName);
    } else {
      formData.append(key, object[key]);
    }
    
    return formData;
}, new FormData());

const config = {
  headers: {
    'Authorization': getCookie('access_token') ? "Bearer " + getCookie('access_token') : null,
  }
}

// PUT BLOG IMAGE
export const putBlogImage = (blogImage, blob, fileName) => async dispatch => {
  const res = await axiosInstance.put(`/api/blog_images/${blogImage.id}/`, getFormData(blogImage, blob, fileName), config);
    
  return reportResponse(res, PUT_BLOG_IMAGE, 'Blog afbeelding', dispatch);
};

// POST BLOG IMAGE
export const postBlogImage = (blogImage, blob, fileName) => async dispatch => {
  const res = await axiosInstance.post(`/api/blog_images/`, getFormData(blogImage, blob, fileName), config);
  
  return reportResponse(res, POST_BLOG_IMAGE, 'Blog afbeelding', dispatch)
};